import { observer } from 'mobx-react'
import React, { useContext, useEffect } from 'react'
import { StoreContext } from '../../../../App'
import EditTag from '../../genericTree/editTag/EditTree.component'
import AssignTag from '../edits/assignTag/AssignTag.component'
import Table from '../../../TableGeneric/table/Table.component'
import { Cell } from 'react-table'
import { ArrayDisplayCont, ObjectDisplayCont } from '../Tags.styles'
import { SlidingPanel } from '../../../slidingContainer/SlidingContainer.component'
import FilterIcon from '../../../../assets/icons/filter-icon.svg'
import CreateTagComponent from '../edits/createTag/CreateTag.component'
import TableNewTagsComponent from '../../../TableGeneric/tableNewTags/TableNewTags.component'
import { LanguageBasedCell } from '../edits/languageBasedCell/LanguageBasedCell.component'
import TableCollectionTagsComponent from '../../../TableGeneric/tableCollectionTags/TableCollectionTags.component'
import TableBool from '../../../TableGeneric/TableBool/TableBool.component'

export const CollectionTagsTableComponent = observer(() => {
  const store = useContext(StoreContext)
  const tableStore = store.TableStore

  const columns = {
    id: {
      canFilter: false,
      editable: false,
      mandatory: false,
      editType: 'text',
    },
    name: {
      Cell: (cell: Cell) => <LanguageBasedCell cellValue={cell.value} />,
      canFilter: false,
      editable: true,
      mandatory: true,
      editType: 'languageObject',
      mandatoryLanguage: 'EN',
    },
    tags: {
      Cell: (cell: Cell) => <TableCollectionTagsComponent tags={cell.value} />,
      canFilter: false,
      editable: false,
    },
    active: {
      Cell: (cell: Cell) => <TableBool value={cell.value} />,
      canFilter: false,
      editable: true,
      editType: 'selectBool',
      selectValues: [
        { value: 'true', label: 'True' },
        { value: 'false', label: 'False' },
      ],
    },
    description: {
      Cell: (cell: Cell) => <LanguageBasedCell cellValue={cell.value} />,
      canFilter: false,
      editable: true,
      mandatory: true,
      editType: 'languageObject',
      mandatoryLanguage: 'EN',
    },
  }

  useEffect(() => {
    store.TagsStore.getCollectionsData()
    return () => {
      store.TagsStore.clearCollectionsData()
    }
  }, [])

  return (
    <Table
      config={{
        tableName: 'collectiontags',
        data: store.TagsStore.collectionsData || [],
        fetchData: () => store.TagsStore.getCollectionsData(),
        columnHeaders: columns,
        columnVisibility: false,
        allowColumnResize: true,
        displaySearch: false,
        displaySelect: false,
        displayExpand: false,
        displayTooltip: false,
        displayEntry: false,
        displayPaginator: false,
        displayInfinitySwitch: false,
        allowSort: false,
        bank: false,
        actions: {
          display: true,
          label: 'Collection',
          edit: `${process.env.REACT_APP_BASE_URL}experience/v1/tag/collection`,
          create: `${process.env.REACT_APP_BASE_URL}experience/v1/tag/collection/create`,
          remove: `${process.env.REACT_APP_BASE_URL}experience/v1/tag/collection/remove`,
          assignTag: `${process.env.REACT_APP_BASE_URL}experience/v1/tag/collection/assign`,
          custom: [],
        },
        headline: {
          visible: true,
          title: 'Collections',
          subTitle: 'List of Collections',
        },
        breadcrumb: [],
        loaded: store.TagsStore.dataLoaded,
        pagination: store.TagsStore.pagination,
        setPagination: store.TagsStore.setPagination,
      }}
    />
  )
})
