import styled from 'styled-components'
import color from 'color'
import { darken } from 'polished'
/* eslint-disable */
export const Header = styled.h2`
  display: flex;
  align-items: center;
`

interface PrimaryButtonProps {
  padding?: string
  bgColor?: string
  borderRadius?: string
  fontSize?: string
  fontWeight?: number
  lineHeight?: string
  letterSpacing?: string
  color?: string
}

export const PrimaryButton = styled.div<PrimaryButtonProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: fit-content;
  width: fit-content;
  cursor: pointer;
  padding: ${(props) => (props.padding ? props.padding : '4px 8px')};
  background-color: ${(props) => (props.bgColor ? props.bgColor : '#303030')};
  border-radius: ${(props) =>
    props.borderRadius ? props.borderRadius : '6px'};
  white-space: nowrap;
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : 600)};
  font-size: ${(props) => (props.fontSize ? props.fontSize : '24px')};
  line-height: ${(props) => (props.lineHeight ? props.lineHeight : '24px')};
  letter-spacing: ${(props) =>
    props.letterSpacing ? props.letterSpacing : '0.5px'};
  text-transform: uppercase;
  color: ${(props) => (props.color ? props.color : '#fafafa')};
  margin: 8px;
  transition: background-color 0.3s, transform 0.2s;

  &:hover {
    background-color: ${(props) =>
      darken(0.1, props.bgColor ? props.bgColor : '#303030')};
    transform: scale(1.02);
  }

  &:active {
    transform: scale(0.98);
  }
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: auto;

  ::-webkit-scrollbar {
    width: 11px;
  }

  ::-webkit-scrollbar-track {
    background: #aaaaaa;
    border-radius: 3px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #303030;
    border-radius: 6px;
    border: 3px solid #aaaaaa;
  }
`
export const Week = styled.div`
  flex: 4;
  position: relative;
  width: 100%;
  display: flex;
`
export const HeaderWeek = styled(Week)`
  flex: 1;

  > :first-child {
    color: red;
  }
`

interface CalendarDayInterface {
  today: boolean
  borderColor?: string
  borderTodayColor?: string
  fontSize?: string
}

/* eslint-disable */
export const Day = styled.div<CalendarDayInterface>`
  min-width: 120px;
  width: calc(100% / 7);
  display: flex;
  min-height: 100px;
  flex-wrap: wrap;
  flex-direction: column;
  font-size: ${(props) => (props.fontSize ? props.fontSize : '12px')};
  border: ${(props) => (props.today ? '3px solid' : '1px solid')};
  border-color: ${(props) =>
    props.today
      ? props.borderTodayColor
        ? props.borderTodayColor
        : '#000000'
      : props.borderColor
      ? props.borderColor
      : '#e5e5e5'};
`

interface ComponentWrapInterface {
  bgColor: string
}

export const ComponentWrap = styled.div<ComponentWrapInterface>`
  background-color: ${(props) =>
    color(props.bgColor).alpha(0.3).rgb().string()};
  margin: 4px;
  padding: 3px;
  border-radius: 16px;
`
export const ComponentList = styled.div`
  display: flex;
  flex-wrap: wrap;
`
export const HeaderDay = styled(Day)`
  border: transparent;
  height: min-content;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  letter-spacing: 0.4px;
  min-height: 24px;
`
export const TitleText = styled.p`
  font-size: 24px;
  font-weight: 700;
  margin: 8px;
`
