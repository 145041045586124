import { useDrag, useDrop } from 'react-dnd'
import { SingleTag } from '../../../TableGeneric/tableNewTags/TableNewTags.styles'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { ArcherElement } from 'react-archer'
import {
  TagContainer,
  Column,
  Row,
  AddButton,
  ButtonCont,
  EditButton,
} from './SingleTreeGeneric.styles'
import { set, toJS } from 'mobx'
import { StoreContext } from '../../../../App'
import { observer } from 'mobx-react'
import FilterIcon from '../../../../assets/icons/filter-icon.svg'
import { SlidingPanel } from '../../../slidingContainer/SlidingContainer.component'
import PlusWhite from '../../../../assets/icons/plus-white.svg'
import EditIcon from '../../../../assets/icons/edit.svg'
import CrossWhite from '../../../../assets/icons/cross-white.svg'
import EditTagComponent from '../editTag/EditTree.component'
import { Tooltip } from '@mui/material'
import AddTagTreeComponent from '../../tags/edits/addTagTree/AddTagTree.component'
import ChooseTagTree from '../chooseAddTree/ChooseTreeGeneric.component'
import { toast } from 'react-toastify'
import RemoveTagComponent from '../../tags/edits/removeTag/RemoveTag.component'
import Star from '../../../../assets/icons/tagIcons/star.svg'
import Circle from '../../../../assets/icons/tagIcons/circle.svg'
import ExpMark from '../../../../assets/icons/tagIcons/exp_mark.svg'
import Minus from '../../../../assets/icons/tagIcons/minus.svg'
import Plus from '../../../../assets/icons/tagIcons/plus.svg'
import QuestionMark from '../../../../assets/icons/tagIcons/question_mark.svg'
import Rectangle from '../../../../assets/icons/tagIcons/rectangle.svg'
import Smiley from '../../../../assets/icons/tagIcons/smiley.svg'
import Car from '../../../../assets/icons/tagIcons/car.svg'
import Sun from '../../../../assets/icons/tagIcons/sun.svg'
import Cross from '../../../../assets/icons/tagIcons/cross.svg'
import { getEmptyValue } from '../../../createGeneric/CreateGeneric.component'
import axios from 'axios'

/* eslint-disable */
interface SingleTreeTagProps {
  objectData: any
  firstLevel: boolean
  index?: number
  parentId?: string
  data?: any
  firstLevelChild: string
  secondLevelChild: string
  firstLevelParent: string
  secondLevelParent: string
  lang: string
  mainDesc: string
  baseUrl: string
  setData: () => void
  dropFunction: (id: string, newParent: string, newTag: string) => void
}

export const SingleTreeGenericAddComponent = observer(
  ({
    objectData,
    firstLevel,
    index,
    parentId,
    firstLevelChild,
    secondLevelChild,
    firstLevelParent,
    secondLevelParent,
    lang,
    mainDesc,
    baseUrl,
    setData,
    dropFunction,
  }: SingleTreeTagProps) => {
    const store = useContext(StoreContext)
    const [{ isDragging }, dragRef] = useDrag({
      type: 'TAG',
      item: objectData,
      collect: (monitor) => ({
        isDragging: !!monitor.isDragging(),
      }),
    })

    const [{ isOver }, dropRef] = useDrop({
      accept: 'TAG',
      drop: (item: any, monitor) => {
        handleDrop(objectData, item)
      },
      collect: (monitor) => ({
        isOver: !!monitor.isOver(),
      }),
    })

    const [{ isOver: isOverPlus }, dropPlusRef] = useDrop({
      accept: 'TAG',
      drop: (item: any, monitor) => {
        handlePlusDrop(objectData, item)
      },
      collect: (monitor) => ({
        isOver: !!monitor.isOver(),
      }),
    })

    const isDraggingOnChild = (
      draggedTagData: any,
      currentDataId: string
    ): boolean => {
      if (draggedTagData.id === currentDataId) return true
      if (
        draggedTagData[secondLevelChild] &&
        draggedTagData[secondLevelChild].length > 0
      ) {
        for (const child of draggedTagData[secondLevelChild]) {
          if (isDraggingOnChild(child, currentDataId)) {
            return true
          }
        }
      }
      return false
    }

    function canMoveTag(
      currentTagData: { [key: string]: any },
      draggedTagData: { [key: string]: any }
    ) {
      if (currentTagData.id === draggedTagData.id) {
        return false
      } else if (isDraggingOnChild(draggedTagData, currentTagData.id))
        return false
      return true
    }

    const handleDrop = async (
      currentTagData: { [key: string]: any },
      draggedTagData: { [key: string]: any }
    ) => {
      if (!canMoveTag(currentTagData, draggedTagData)) {
        console.log('Invalid drop action.')
        toast('Invalid drop action.', {
          type: 'error',
        })
        return
      }
      await store.TagsStore.setTreeLoaded(false)
      const data = {
        parent: !firstLevel ? currentTagData.id : '',
      }
      toast('Tag moved successfully.')
      await store.TagsStore.editTag(data, draggedTagData.id, 'tag')
      await store.TagsStore.getData()
      await store.TagsStore.setTreeLoaded(true)
    }

    const handlePlusDrop = async (
      currentTagData: { [key: string]: any },
      draggedTagData: { [key: string]: any }
    ) => {
      console.log(toJS(currentTagData), toJS(draggedTagData))
      await store.TagsStore.setTreeLoaded(false)
      await store.TagsStore.insertTag(
        draggedTagData.id,
        currentTagData.parent,
        currentTagData.id,
        {}
      )
      await store.TagsStore.getData()
      await store.TagsStore.setTreeLoaded(true)
    }
    const combinedRef = (node: any) => {
      dragRef(node)
      dropRef(node)
    }

    const divId = parentId ? `${parentId}-${index}` : 'treetagtoot'
    /* eslint-disable */
    const relations: any = parentId
      ? [
          {
            targetId: parentId,
            targetAnchor: 'right',
            sourceAnchor: 'left',
            style: {
              zIndex: 0,
            },
            label: (
              <Tooltip
                title={`Add new ${mainDesc} as parent`}
                placement={'bottom'}
              >
                <AddButton
                  style={{
                    backgroundColor: isOverPlus ? 'lightgray' : undefined,
                  }}
                  ref={dropPlusRef}
                  onClick={() =>
                    store.SlidingPanelState.setSelectedSlider(
                      `createNewParent-${objectData.id}`
                    )
                  }
                >
                  <img src={PlusWhite} />
                </AddButton>
              </Tooltip>
            ),
          },
        ]
      : []
    /* eslint-enable */

    const tagColumns = {
      id: {
        canFilter: false,
        editable: false,
      },
      name: {
        canFilter: false,
        editable: true,
        mandatory: true,
        editType: 'languageObject',
        mandatoryLanguage: 'EN',
      },
      root_id: {
        canFilter: false,
        editable: true,
        mandatory: true,
        editType: 'rootSearch',
      },
      colour: {
        canFilter: false,
        editable: true,
        editType: 'color',
      },
      priority: {
        canFilter: false,
        editable: true,
        mandatory: true,
        editType: 'number',
      },
      parent: {
        canFilter: false,
        editable: true,
        mandatory: true,
        editType: 'tagSearch',
      },
      tag_size: {
        canFilter: false,
        editable: true,
        editType: 'select',
        selectValues: [
          { value: 'default', label: 'default' },
          { value: 'big', label: 'big' },
          { value: 'small', label: 'small' },
        ],
      },
      tag_icon: {
        canFilter: false,
        editable: true,
        mandatory: true,
        editType: 'select',
        selectValues: store.TagsStore.avaliableIcons.map((icon) => ({
          value: icon,
          label: icon,
        })),
      },
      description: {
        canFilter: false,
        editable: true,
        mandatory: true,
        editType: 'languageObject',
        mandatoryLanguage: 'EN',
      },
      path: {
        canFilter: false,
        editable: false,
      },
    }

    const categoryColumns = {
      id: {
        canFilter: false,
        editable: false,
      },
      parent_id: {
        canFilter: false,
        editable: false,
        mandatory: false,
        editType: 'text',
      },
      desc_short: {
        canFilter: false,
        editable: true,
        mandatory: true,
        editType: 'languageObject',
        mandatoryLanguage: 'en_GB',
      },
      desc_long: {
        canFilter: false,
        editable: true,
        mandatory: true,
        editType: 'languageObject',
        mandatoryLanguage: 'en_GB',
      },
      active: {
        canFilter: false,
        editable: true,
        mandatory: true,
        editType: 'selectBool',
        selectValues: [
          { value: 'true', label: 'True' },
          { value: 'false', label: 'False' },
        ],
      },
      active_positioning: {
        canFilter: false,
        editable: true,
        mandatory: true,
        editType: 'selectBool',
        selectValues: [
          { value: 'true', label: 'True' },
          { value: 'false', label: 'False' },
        ],
      },
      positioning_priority: {
        canFilter: false,
        editable: true,
        mandatory: true,
        editType: 'number',
      },
      name: {
        canFilter: false,
        editable: true,
        mandatory: true,
        editType: 'languageObject',
        mandatoryLanguage: 'en_GB',
      },
      note: {
        canFilter: false,
        editable: true,
        mandatory: true,
        editType: 'text',
      },
    }

    const columns: any = () => {
      switch (mainDesc) {
        case 'Tag':
          return tagColumns
        case 'Category':
          return categoryColumns
      }
    }

    const editHeaders = Object.keys(columns()).map((header) => {
      return {
        header: header,
        value: objectData[header],
      }
    })

    const columnHeaders = Object.entries(columns()).map(
      ([header, params]: any) => {
        return {
          header: header,
          value: getEmptyValue(params.editType, {
            editCustomOptions: {
              selectValues:
                params.editType === 'select' ? params.selectValues : [],
            },
          }),
        }
      }
    )

    const getCopyValues = (header: string) => {
      if (header === 'parent') {
        return !firstLevel ? objectData.id : ''
      } else if (header === 'root_id') {
        return !firstLevel ? objectData.root_id : objectData.id
      } else {
        return objectData[header] ? objectData[header] : ''
      }
    }

    const copyHeaders = editHeaders.map((value) => {
      return {
        header: value.header,
        value: getCopyValues(value.header),
      }
    })

    const mapTableSizes = (size: string) => {
      switch (size) {
        case 'small':
          return '10px'
        case 'default':
          return '12px'
        case 'big':
          return '16px'
        default:
          return '12px'
      }
    }

    const mapIcons = (icon: string) => {
      switch (icon) {
        case 'star':
          return <img src={Star} />
        case 'circle':
          return <img src={Circle} />
        case 'exp-mark':
          return <img src={ExpMark} />
        case 'minus':
          return <img src={Minus} />
        case 'plus':
          return <img src={Plus} />
        case 'question-mark':
          return <img src={QuestionMark} />
        case 'rectangle':
          return <img src={Rectangle} />
        case 'smiley':
          return <img src={Smiley} />
        case 'car':
          return <img src={Car} />
        case 'sun':
          return <img src={Sun} />
        case 'cross':
          return <img src={Cross} />
        default:
          return <></>
      }
    }

    const hasChild = () => {
      if (firstLevel) {
        return !!objectData[firstLevelChild]
      } else return !!objectData[secondLevelChild]
    }

    return (
      <Row>
        <TagContainer>
          {store.TagsStore.treeLoaded ? (
            <SingleTag
              ref={combinedRef}
              style={{
                backgroundColor: isOver ? 'lightgray' : undefined,
                zIndex: 1,
                position: 'relative',
              }}
              size={mapTableSizes(objectData.tag_size)}
              color={objectData.colour ? objectData.colour : '#505050'}
            >
              {mapIcons(objectData.tag_icon)}
              <ArcherElement id={divId} relations={relations}>
                <p style={{ width: '100%' }}>{`${objectData.name[lang]}`}</p>
              </ArcherElement>
            </SingleTag>
          ) : (
            <ArcherElement id={divId} relations={relations}>
              <SingleTag
                style={{ opacity: 0.5 }}
                size={mapTableSizes(objectData.tag_size)}
                color={objectData.colour ? objectData.colour : '#505050'}
              >
                <p>{objectData.name[lang]}</p>
              </SingleTag>
            </ArcherElement>
          )}
          <ButtonCont>
            {!firstLevel ? (
              <Tooltip title={`Edit this ${mainDesc}`} placement={'bottom'}>
                <EditButton
                  onClick={() =>
                    store.SlidingPanelState.setSelectedSlider(
                      `editTag-${objectData.id}`
                    )
                  }
                >
                  <img src={EditIcon} />
                </EditButton>
              </Tooltip>
            ) : (
              <></>
            )}
            {!firstLevel ? (
              <Tooltip title={`Remove this ${mainDesc}`} placement={'bottom'}>
                <EditButton
                  style={{ backgroundColor: '#FF4F4B' }}
                  onClick={() =>
                    store.SlidingPanelState.setSelectedSlider(
                      `removeTag-${objectData.id}`
                    )
                  }
                >
                  <img src={CrossWhite} />
                </EditButton>
              </Tooltip>
            ) : (
              <></>
            )}
            <Tooltip
              title={`Add new ${mainDesc} as child`}
              placement={'bottom'}
            >
              <AddButton
                onClick={() =>
                  store.SlidingPanelState.setSelectedSlider(
                    `createNewTag-${objectData.id}`
                  )
                }
              >
                <img src={PlusWhite} />
              </AddButton>
            </Tooltip>
          </ButtonCont>
        </TagContainer>
        {/* Separate Column for[secondLevelChild] */}
        <Column>
          {hasChild() ? (
            (firstLevel
              ? objectData[firstLevelChild]
              : objectData[secondLevelChild]
            ).map((childTag: any, i: number) => (
              <SingleTreeGenericAddComponent
                key={childTag.id}
                objectData={childTag}
                firstLevel={false}
                parentId={divId}
                index={i}
                firstLevelChild={firstLevelChild}
                secondLevelChild={secondLevelChild}
                firstLevelParent={firstLevelParent}
                secondLevelParent={secondLevelParent}
                lang={lang}
                mainDesc={mainDesc}
                baseUrl={baseUrl}
                setData={setData}
                dropFunction={dropFunction}
              />
            ))
          ) : (
            <></>
          )}
          <span style={{ marginBottom: '4px', visibility: 'hidden' }}>
            cont
          </span>
        </Column>
        <SlidingPanel
          contentId={`createNewTag-${objectData.id}`}
          title={`Add New ${mainDesc} for parent ${objectData.name[lang]}`}
          icon={FilterIcon}
        >
          <ChooseTagTree
            mainDesc={mainDesc}
            baseUrl={baseUrl}
            id={objectData.id}
            parentId={''}
            columns={columnHeaders}
            copyColumns={copyHeaders}
            hideParent={true}
            view={'tag'}
            firstLevel={firstLevel}
          />
        </SlidingPanel>{' '}
        <SlidingPanel
          contentId={`createNewParent-${objectData.id}`}
          title={`Add New parent for ${mainDesc} ${objectData.name[lang]}`}
          icon={FilterIcon}
        >
          <ChooseTagTree
            id={objectData.id}
            parentId={objectData.parent}
            columns={columnHeaders}
            copyColumns={copyHeaders}
            hideParent={true}
            left={true}
            view={'tag'}
            firstLevel={firstLevel}
            mainDesc={mainDesc}
            baseUrl={baseUrl}
          />
        </SlidingPanel>
        <SlidingPanel
          contentId={`addTag-${objectData.id}`}
          title={`Add ${mainDesc} to: ${objectData.name[lang]}`}
          icon={FilterIcon}
        >
          <AddTagTreeComponent id={objectData.id} parentId={''} />
        </SlidingPanel>
        <SlidingPanel
          contentId={`removeTag-${objectData.id}`}
          title={`Remove ${mainDesc}: ${objectData.name[lang]}`}
          icon={FilterIcon}
        >
          <RemoveTagComponent
            columns={[{ header: 'id', value: objectData.id }]}
            baseUrl={baseUrl}
            setData={setData}
            mainDesc={mainDesc}
          />
        </SlidingPanel>
        <SlidingPanel
          contentId={`editTag-${objectData.id}`}
          title={`Edit ${mainDesc}: ${objectData.name[lang]}`}
          icon={FilterIcon}
        >
          <EditTagComponent
            columns={editHeaders}
            hideParent={true}
            view={'tag'}
            baseUrl={baseUrl}
            setData={setData}
            mainDesc={mainDesc}
          />
        </SlidingPanel>
      </Row>
    )
  }
)
