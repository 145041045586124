import React from 'react'
import { Container } from '../../styles/LayoutStyles'
import { InnerCont, SubTitle, Title } from './NotFound.styles'

const NotJS = () => {
  return (
    <Container>
      <InnerCont>
        <Title>Coś poszło nie tak</Title>
        <SubTitle>
          Poproś o pomoc Twojego administratora, albo napisz na it@moodnook.com
        </SubTitle>
      </InnerCont>
    </Container>
  )
}

export default NotJS
