import { observer } from 'mobx-react'
import React, { useContext } from 'react'
import { StoreContext } from '../../../App'
import { Cell } from 'react-table'
import { toJS } from 'mobx'

interface TableDefaultCellInterface {
  cell: Cell
}

const TableCompleteable = observer(({ cell }: TableDefaultCellInterface) => {
  const store = useContext(StoreContext)
  const locales = store.TranslationsState.translations
  const column = cell.column.id
  const boldText = store.TableStore.appliedQuery.rules.find(
    (e) => e.field === column && e.operator === 'contains'
  )?.value
  const startIndex = boldText
    ? cell.value.toLowerCase().indexOf(boldText.toLowerCase())
    : 0

  return (
    <div>
      {!boldText ? (
        <p>{cell.value}</p>
      ) : (
        <p>
          {cell.value.slice(0, startIndex)}
          <b style={{ color: '#e4bf71' }}>
            {cell.value.slice(startIndex, startIndex + boldText.length)}
          </b>
          {cell.value.slice(startIndex + boldText.length, cell.value.length)}
        </p>
      )}
    </div>
  )
})
export default TableCompleteable
