import styled from 'styled-components'
import { breakpoints } from '../../../static/breakpoints'
import Pagination from '@mui/material/Pagination'

interface SelectWrapperProps {
  bgColor?: string
  marginTop?: string
  color?: string
  boxShadow?: string
}

export const SelectedWrapper = styled.div<SelectWrapperProps>`
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 50;
  margin-top: ${(props) => (props.marginTop ? props.marginTop : '8px')};
  width: 100%;
  background-color: ${(props) => (props.bgColor ? props.bgColor : '#000000')};
  color: ${(props) => (props.color ? props.color : '#fafafa')};
  display: flex;
  justify-content: flex-start;
  align-items: center;
  box-shadow: ${(props) =>
    props.boxShadow ? props.boxShadow : '0 6px 16px rgba(0, 0, 0, 0.1))'};
  padding-bottom: 4px;
  margin-left: 4px;

  flex-wrap: wrap;
  overflow: visible;
`

interface SelectedParagraphProps {
  fontWeight?: number
  fontSize?: string
  lineHeight?: string
  color?: string
}

export const SelectedParagraph = styled.p<SelectedParagraphProps>`
  display: flex;
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : 400)};
  font-size: ${(props) => (props.fontSize ? props.fontSize : '12px')};
  line-height: ${(props) => (props.lineHeight ? props.lineHeight : '16px')};
  color: ${(props) => (props.color ? props.color : '#ffffff')};
  cursor: pointer;
`
export const CreateOrderCont = styled(SelectedParagraph)`
  background-color: #926f34;
  padding: 4px 8px;
  border-radius: 6px;
  margin-left: 8px;
  cursor: pointer;
  white-space: nowrap;
`
export const RowsCount = styled.b`
  margin-left: 4px;
`
export const StyledPlus = styled.img`
  margin-right: 7px;
  cursor: pointer;
`
export const StyledCross = styled.div`
  margin: 14px;
  cursor: pointer;
`
