import styled from 'styled-components'
import { darken } from 'polished'
import Select from 'react-select'

export const SettingsCategory = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid gray;
  border-radius: 8px;
  box-sizing: border-box;
  padding: 30px;

  h2 {
    font-size: 18px;
    border-bottom: 1px solid gray;
    margin: 0 0 15px 0;
    padding: 0 0 15px 0;
  }
`

export const Setting = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;

  select {
    color: black;
    border: 1px solid gray;
    padding: 10px;
    margin-bottom: 20px;
  }
`
export const Footer = styled.div`
  display: flex;
  position: sticky !important;
  bottom: 0;
  padding-bottom: 12px;
  width: 100%;
  background: rgba(255, 255, 255, 0.8);

  button {
    margin: 4px;
  }
`
export const Label = styled.span`
  font-weight: 500;
`
export const ButtonCont = styled.div`
  display: flex;
  margin-top: 8px;
`

interface PrimaryButtonProps {
  padding?: string
  bgColor?: string
  borderRadius?: string
  fontSize?: string
  fontWeight?: number
  lineHeight?: string
  letterSpacing?: string
  color?: string
}

export const PrimaryButton = styled.button<PrimaryButtonProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: ${(props) => (props.padding ? props.padding : '8px 16px')};
  background-color: ${(props) => (props.bgColor ? props.bgColor : '#303030')};
  border-radius: ${(props) =>
    props.borderRadius ? props.borderRadius : '6px'};
  white-space: nowrap;
  text-transform: uppercase;
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : 600)};
  font-size: ${(props) => (props.fontSize ? props.fontSize : '14px')};
  line-height: ${(props) => (props.lineHeight ? props.lineHeight : '16px')};
  letter-spacing: ${(props) =>
    props.letterSpacing ? props.letterSpacing : '0.5px'};
  color: ${(props) => (props.color ? props.color : '#fafafa')};

  transition: background-color 0.3s, transform 0.2s;

  &:hover {
    background-color: ${(props) =>
    darken(0.1, props.bgColor ? props.bgColor : '#303030')};
    transform: scale(1.02);
  }

  &:active {
    transform: scale(0.98);
  }
`
export const InnerCont = styled.div`
  margin-top: 8px;
  padding: 8px;
`
export const Entry = styled.div`
  display: flex;
  flex-direction: column;

  input {
    border-bottom: 1px solid #e5e5e5;
  }
`
export const StyledSelect = styled.div`
  margin-bottom: 12px;
  width: 250px;
`
