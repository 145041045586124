import { observer } from 'mobx-react'
import { useContext, useRef } from 'react'
import Profile from '../../assets/icons/profile.png'

import { StoreContext } from '../../App'
import {
  Container,
  Image,
  Option,
  OptionCont,
  PrimaryButton,
  SecondaryButton,
  SubTitleText,
  TitleText,
} from './HeaderProfile.styles'
import useClickOutside from '../../methods/methods/useClickOutside'
import { ContextHolder } from '@frontegg/react'

interface HeaderProfileInterface {
  close: () => void
}

const HeaderProfile = observer((props: HeaderProfileInterface) => {
  const store = useContext(StoreContext)
  const { Theme } = store.Theme
  const headerRef = useRef(null)
  useClickOutside(headerRef, props.close)

  const signOut = () => {
    const baseUrl = ContextHolder.getContext().baseUrl
    window.location.href = `${baseUrl}/oauth/logout?post_logout_redirect_uri=${window.location}`
  }

  return (
    <Container ref={headerRef}>
      <Image
        src={store.UserStore.image !== '' ? store.UserStore.image : Profile}
      />
      <TitleText>{store.UserStore.user}</TitleText>
      <SubTitleText>{store.UserStore.email}</SubTitleText>
      <PrimaryButton
        onClick={props.close}
        to={'/admin/user/myaccount'}
        {...Theme.buttons.primaryButton}
      >
        {'My Account'}
      </PrimaryButton>
      <PrimaryButton
        onClick={props.close}
        to={'/admin/user/myuserprofile'}
        {...Theme.buttons.primaryButton}
      >
        {'My User Profile'}
      </PrimaryButton>
      <SecondaryButton onClick={signOut} {...Theme.buttons.secondaryButton}>
        {'sign out'}
      </SecondaryButton>
      <OptionCont>
        <Option
          href={'https://wiki.apihulk.com/hc/pl/requests/new'}
          target='_blank'
          rel='noopener noreferrer'
        >
          {'Get Help'}
        </Option>
        <Option
          href={'https://wiki.apihulk.com/'}
          target='_blank'
          rel='noopener noreferrer'
        >
          {'Wiki'}
        </Option>
      </OptionCont>
    </Container>
  )
})

export default HeaderProfile
