/* eslint-disable */
import {
  PropsWithChildren,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import { Row } from 'react-table'

import { hiddenColumns, ReplaceEmptyStringsForNulls } from '../../columnsConfig'

import {
  Container,
  Footer,
  InnerCont,
  PrimaryButton,
  SecondaryButton,
} from './EditRenderedProduct.styles'

import { StoreContext } from '../../App'
import useWindowDimensions from '../../methods/hooks/useWindowDimensions'
import { socket } from '../../socket/socket'
import { DataInput } from '../dataInput/DataInput.component'

export interface EditProductsProps extends PropsWithChildren {
  id: string
  row: Row
  columns: Array<{ header: string; value: string }>
  toggleClick: () => void
  name: string
  tableRow: string
}

const EditProducts: (props: EditProductsProps) => JSX.Element = (
  props: EditProductsProps
) => {
  const store = useContext(StoreContext)
  const { Theme } = store.Theme
  const { width, height } = useWindowDimensions()
  const [changedObject, setChangedObject] = useState(false)
  const divRef = useRef<any>(null)
  const columns = props.columns.filter((e) => !hiddenColumns.includes(e.header))
  const initialState = [
    ...columns.map((e) => {
      return {
        header: e.header,
        value: e.value,
        error: '',
        rules: ['required', 'length'],
      }
    }),
  ]
  const [form, setForm] = useState(initialState)
  const [inputColumns, setInputColumns] = useState(1)

  const handleSetForm = (
    tempForm: Array<{
      header: string
      value: string
      error: string
      rules: Array<string>
    }>
  ) => {
    setForm([...tempForm])
  }

  const handleSave = async () => {
    let fetchObject: { [key: string]: string | object } = {
      id: props.tableRow,
    }

    let singleProduct = {}

    form.map((e) => {
      const selected =
        divRef.current.querySelector(`input[name="${e.header}"]`) &&
        divRef.current.querySelector(`input[name="${e.header}"]`).checked
      if (selected)
        singleProduct = {
          [e.header]: e.value,
          ...singleProduct,
        }
    })
    const code = props.row.allCells.find(
      (e) => e.column.id === 'ProductCode'
    )?.value

    fetchObject = {
      products: { [code.toString()]: singleProduct },
      ...fetchObject,
    }

    store.SlidingPanelState.setVisibility(false)
    const formattedProduct = store.TableStore.formatRequestData(fetchObject)
    await store.TableStore.updateData(formattedProduct)
    await socket.emit('editOrder', {
      id: props.tableRow,
      user: store.UserStore.user,
      page: store.TableStore.getApiRequestFromUrl(),
    })

    store.TableStore.setSelectedExpanded([
      { rowId: props.tableRow, values: singleProduct },
    ])
    /*setTimeout(async () => {
                                      await store.TableStore.fetchData()
                                    }, 3000)*/
  }

  useEffect(() => {
    setInputColumns(Math.ceil((90 * columns.length) / (height * 0.8)))
  }, [height])

  useEffect(() => {
    let changed = false
    form.map((e, index) => {
      if (e.value !== initialState[index].value) {
        changed = true
        return
      }
    })
    setChangedObject(changed)
  }, [form])

  return (
    <Container {...Theme.editProductsStyles.container}>
      <InnerCont cols={inputColumns} ref={divRef}>
        {columns
          ? columns.map((e, index) => (
              <DataInput
                vals={e}
                key={`data-input--${index}`}
                index={index}
                form={form}
                setForm={handleSetForm}
              />
            ))
          : null}
      </InnerCont>
      <Footer {...Theme.editProductsStyles.styledFooter}>
        <SecondaryButton
          onClick={() => store.SlidingPanelState.setVisibility(false)}
          {...Theme.buttons.secondaryButton}
        >
          {'CANCEL'}
        </SecondaryButton>
        <PrimaryButton
          onClick={() => changedObject && handleSave()}
          active={changedObject}
          {...Theme.buttons.primaryButton}
        >
          {'SAVE'}
        </PrimaryButton>
      </Footer>
    </Container>
  )
}
export default EditProducts
