import { Column } from 'react-table'
import axios from '../../../axiosconfig'
import { StoreContext } from '../../../App'
import CreateOrderPopUp from '../createOrderPopup/CreateOrderPopup.component'
import CopyToClipboard from '../copyToClipboard/CopyToClipboard.component'

import {
  CreateOrderCont,
  RowsCount,
  SelectedParagraph,
  SelectedWrapper,
  StyledCross,
  StyledPlus,
} from './Footer.styles'

import { observer } from 'mobx-react'
import { useContext, useState } from 'react'
import { ReactComponent as CrossWhite } from '../../../assets/icons/cross-white.svg'
import PlusWhite from '../../../assets/icons/plus-white.svg'
import TableCSV from '../tableCSV/TableCSV.component'
import { SlidingPanel } from '../../slidingContainer/SlidingContainer.component'
import EditSupplierProduct from '../../editSupplierProduct/EditSupplierProduct.component'
import EditMultipleProducts from '../../editMultipleProducts/EditMultipleProducts.component'
import { Tooltip } from '@mui/material'
import AddMultipleSupplierProductComponent from '../../addMultipleSupplierProduct/AddMultipleSupplierProduct.component'
import useWindowDimensions from '../../../methods/hooks/useWindowDimensions'
import EditMultipleProductsByQueryComponent from '../../editMultipleProductsByQuery/EditMultipleProductsByQuery.component'
import AssignMultipleTagsComponent from '../../settings/tags/edits/assignMultipleTags/AssignMultipleTags.component'
import AssignSupplierProductsTagComponent from '../../settings/tags/edits/assignSupplierProductsTag/AssignSupplierProductsTags.component'
import RemoveMultipleTagsComponent from '../../settings/tags/edits/removeMultipleTags/RemoveMultipleTags.component'
import AssignCollectionGenericMultipleComponent from '../../assignCollectionGenericMultiple/AssignCollectionGenericMultiple.component'
import EditMultipleStatusesComponent from '../../editMultipleStatuses/EditMultipleStatuses.component'
import RemoveMultipleTransactionsComponent from '../../removeMultipleTransactions/RemoveMultipleTransactions.component'

/* eslint-disable */
interface PaginatorProps {
  toggleAllRowsSelected: any
  selectedRows: string
  columns: Array<Column>
  fetchData: () => void
  tableName: string
  actions?: {
    display: boolean
    label: string
    edit: string | null
    create: string | null
    remove: string | null
    assignTag: string | null
    custom: any[]
  } | null
}

const Footer = observer(
  ({
    toggleAllRowsSelected,
    tableName,
    selectedRows,
    columns,
    fetchData,
    actions,
  }: PaginatorProps) => {
    const store = useContext(StoreContext)
    const { Theme } = store.Theme
    const locales = store.TranslationsState.translations
    const { width } = useWindowDimensions()
    const [createOrderPopup, setCreateOrderPopup] = useState<boolean>(false)
    const [exportBankTransactionLoading, setExportBankTransactionLoading] =
      useState<boolean>(false)
    const [labelLoading, setLabelLoading] = useState<boolean>(false)
    const [documentLoading, setDocumentLoading] = useState<boolean>(false)

    const getObjectDownload = async () => {
      setExportBankTransactionLoading(true)
      const urlLink = await store.TableStore.exportBankTransaction()
      const hrefLink = `${urlLink}?sv=2021-06-08&ss=bfqt&srt=sco&sp=rwlacupitfx&se=2030-01-05T19:45:37Z&st=2023-01-05T11:45:37Z&spr=https&sig=de5llLawOuvgxJoZ6XC2bSNQitD5spBwV2kFijl%2F9j4%3D`
      if (hrefLink) {
        const res = await axios.get(hrefLink)
        const data = [res.data]
        const date = new Date()
        const blob = new Blob(data, { type: 'text/plain' })
        const url = URL.createObjectURL(blob)
        const a = document.createElement('a')
        a.href = url
        a.download = `transaction-${
          date.getFullYear() +
          '-' +
          (date.getMonth() + 1) +
          '-' +
          date.getDate() +
          '-' +
          date.getHours() +
          ':' +
          date.getMinutes() +
          ':' +
          date.getSeconds()
        }.csv`
        document.body.appendChild(a)
        a.click()
        setTimeout(() => {
          document.body.removeChild(a)
          URL.revokeObjectURL(url)
        }, 0)
        toggleAllRowsSelected(false)
        store.TableStore.setAllSelectedRows(false)
        store.TableStore.setSelectedRows([])
      }
      await setExportBankTransactionLoading(false)
    }

    const exportDocuments = async (all: boolean) => {
      all ? setDocumentLoading(true) : setLabelLoading(true)
      const url = `${process.env.REACT_APP_BASE_URL}experience/v1/packages/documents_print`
      const data = {
        ids: store.TableStore.selectedRowId,
        documents_types: all ? ['all'] : ['labels'],
      }
      const config = {
        headers: {
          'x-apikey': `${process.env.REACT_APP_API_KEY}`,
        },
      }

      try {
        console.log(url, data, config)
        const response = await axios.post(url, data, config)
        const fileUrl = response.data.url
        const link = document.createElement('a')
        link.href = fileUrl
        link.setAttribute('download', 'download.zip')
        document.body.appendChild(link)
        link.click()
        link.remove()
      } catch (error) {
        console.error('Error during file download:', error)
      }
      await setDocumentLoading(false)
      await setLabelLoading(false)
    }
    const HandleSlidingName = () => {
      const array = store.TableStore.selectedRowId.join(', ')
      if (array.length > 10)
        return (
          <Tooltip
            title={`${locales.buttons.editSelected} ${
              actions
                ? actions.label
                : locales.tableNames[store.RouteStore.currentPage]
            }: ${array}`}
          >
            <p>{`${locales.buttons.editSelected} ${
              actions
                ? actions.label
                : locales.tableNames[store.RouteStore.currentPage]
            }: ${array.substring(0, 10)}...`}</p>
          </Tooltip>
        )
      return `${locales.buttons.editSelected} ${
        actions
          ? actions.label
          : locales.tableNames[store.RouteStore.currentPage]
      }: ${array}`
    }

    const getMutipleEdit = () => {
      switch (actions?.label) {
        case 'Product':
          return (
            <EditMultipleProductsByQueryComponent
              keys={[...columns.map((e: any) => e.accessor)]}
            />
          )
        case 'Status':
          return <EditMultipleStatusesComponent columns={columns} />
        default:
          return (
            <EditMultipleProducts
              keys={[...columns.map((e: any) => e.accessor)]}
            />
          )
      }
    }

    const displaySelected = () => {
      const data: any = {
        rules: [],
        combinator: 'or',
        not: false,
      }

      store.TableStore.selectedRowId.map((e: string, index: number) =>
        data.rules.push({
          field: 'id',
          operator: '=',
          valueSource: 'value',
          value: e,
        })
      )

      store.TableStore.setQuery(data)
      store.TableStore.applyQuery(fetchData)
    }

    return (
      <>
        {createOrderPopup && (
          <CreateOrderPopUp
            columns={columns}
            setActive={setCreateOrderPopup}
            setCreateOrderPopup={() => setCreateOrderPopup(false)}
            toggleAllRowsSelected={toggleAllRowsSelected}
          />
        )}
        {store.TableStore.selectedRowId.length > 0 ||
        store.TableStore.selectedExpanded.length > 0 ||
        store.TableStore.allSelectedRows ? (
          <SelectedWrapper {...Theme.paginatorStyles.selectedWrapper}>
            <StyledCross
              onClick={() => {
                toggleAllRowsSelected(false)
                store.TableStore.setAllSelectedRows(false)
                store.TableStore.setSelectedRows([])
                store.TableStore.setDisableAllSubRows()
                store.TableStore.setSelectedExpanded([])
              }}
            >
              <CrossWhite />
            </StyledCross>
            <SelectedParagraph
              onClick={() => displaySelected()}
              {...Theme.paginatorStyles.selectedParagraph}
            >
              {'Selected: '}
              <RowsCount>{store.TableStore.selectedRowId.length}</RowsCount>
            </SelectedParagraph>
            {store.TableStore.selectedExpanded.length ? (
              <SelectedParagraph {...Theme.paginatorStyles.selectedParagraph}>
                {'Selected subrows: '}
                <RowsCount>
                  {store.TableStore.selectedExpanded.length}
                </RowsCount>
              </SelectedParagraph>
            ) : (
              <></>
            )}
            {store.RouteStore.currentPage === 'stock' ? (
              <CreateOrderCont
                onClick={() => setCreateOrderPopup(true)}
                {...Theme.paginatorStyles.selectedParagraph}
              >
                <StyledPlus src={PlusWhite} />{' '}
                {locales.buttons.createPurchaseOrder}
              </CreateOrderCont>
            ) : (
              <></>
            )}
            {store.RouteStore.currentPage === 'bank' ? (
              <CreateOrderCont
                onClick={() => getObjectDownload()}
                {...Theme.paginatorStyles.selectedParagraph}
              >
                {exportBankTransactionLoading
                  ? 'Loading...'
                  : 'Export Bank Transaction'}
              </CreateOrderCont>
            ) : (
              <></>
            )}
            {store.RouteStore.currentPage === 'packages' ? (
              <>
                <CreateOrderCont
                  onClick={() => !documentLoading && exportDocuments(true)}
                  {...Theme.paginatorStyles.selectedParagraph}
                >
                  {documentLoading ? 'Loading...' : 'Export Documents'}
                </CreateOrderCont>
                <CreateOrderCont
                  onClick={() => !labelLoading && exportDocuments(false)}
                  {...Theme.paginatorStyles.selectedParagraph}
                >
                  {labelLoading ? 'Loading...' : 'Export Labels'}
                </CreateOrderCont>
              </>
            ) : (
              <></>
            )}
            {store.RouteStore.currentPage === 'supplierorders' &&
            store.TableStore.selectedExpanded.length ? (
              <CreateOrderCont
                onClick={() =>
                  store.SlidingPanelState.setSelectedSlider(
                    'EditSupplierProduct'
                  )
                }
                {...Theme.paginatorStyles.selectedParagraph}
              >
                {`${locales.buttons.editSelected} ${locales.buttons.supplierProducts}`}
              </CreateOrderCont>
            ) : (
              <></>
            )}
            {store.RouteStore.currentPage === 'supplierorders' &&
            store.TableStore.selectedExpanded.length ? (
              <CreateOrderCont
                onClick={() =>
                  store.SlidingPanelState.setSelectedSlider(
                    'AddTagToSupplierProduct'
                  )
                }
                {...Theme.paginatorStyles.selectedParagraph}
              >
                {`Add tags to selected supplier products`}
              </CreateOrderCont>
            ) : (
              <></>
            )}
            {store.TableStore.selectedRowId.length ? (
              <>
                {[
                  'customerorders',
                  'stock',
                  'supplierorders',
                  'admin',
                ].includes(store.RouteStore.currentPage) ? (
                  <CreateOrderCont
                    onClick={() =>
                      store.SlidingPanelState.setSelectedSlider(
                        'EditMultipleProducts'
                      )
                    }
                    {...Theme.paginatorStyles.selectedParagraph}
                  >
                    {`${locales.buttons.editSelected} ${
                      actions
                        ? actions.label
                        : locales.tableNames[store.RouteStore.currentPage]
                    }`}
                  </CreateOrderCont>
                ) : (
                  <></>
                )}
                {actions?.assignTag ? (
                  <>
                    <CreateOrderCont
                      onClick={() =>
                        store.SlidingPanelState.setSelectedSlider(
                          'AddMultipleTags'
                        )
                      }
                      {...Theme.paginatorStyles.selectedParagraph}
                    >
                      {`Add tag to ${
                        actions
                          ? actions.label
                          : locales.tableNames[store.RouteStore.currentPage]
                      }`}
                    </CreateOrderCont>
                    <CreateOrderCont
                      onClick={() =>
                        store.SlidingPanelState.setSelectedSlider(
                          'AddMultipleCollections'
                        )
                      }
                      {...Theme.paginatorStyles.selectedParagraph}
                    >
                      {`Add collection to ${
                        actions
                          ? actions.label
                          : locales.tableNames[store.RouteStore.currentPage]
                      }`}
                    </CreateOrderCont>
                  </>
                ) : (
                  <></>
                )}

                {store.RouteStore.currentPage === 'supplierorders' ? (
                  <CreateOrderCont
                    onClick={() =>
                      store.SlidingPanelState.setSelectedSlider(
                        'AddMultipleSupplierProducts'
                      )
                    }
                    {...Theme.paginatorStyles.selectedParagraph}
                  >
                    <StyledPlus src={PlusWhite} />{' '}
                    {locales.buttons.addToSelected}
                  </CreateOrderCont>
                ) : (
                  <></>
                )}
              </>
            ) : (
              <></>
            )}
            {store.RouteStore.currentPage === 'bank' ? (
              <CreateOrderCont
                onClick={() =>
                  store.SlidingPanelState.setSelectedSlider(
                    'RemoveMultipleTransactions'
                  )
                }
                {...Theme.paginatorStyles.selectedParagraph}
              >
                {`Remove selected transactions`}
              </CreateOrderCont>
            ) : (
              <></>
            )}
            {actions?.assignTag && store.TableStore.selectedRowId.length ? (
              <CreateOrderCont
                onClick={() =>
                  store.SlidingPanelState.setSelectedSlider(
                    'RemoveMultipleTags'
                  )
                }
                {...Theme.paginatorStyles.selectedParagraph}
              >
                {`Remove tags from selected ${
                  actions
                    ? actions.label
                    : locales.tableNames[store.RouteStore.currentPage]
                }`}
              </CreateOrderCont>
            ) : (
              <></>
            )}
            <CopyToClipboard text={selectedRows} />
            <TableCSV data={store.TableStore.data} tableName={tableName} />
          </SelectedWrapper>
        ) : (
          <></>
        )}
        <SlidingPanel
          contentId={'EditSupplierProduct'}
          title={
            store.TableStore.selectedExpanded.length == 1
              ? 'Edit Supplier Order Product'
              : 'Edit Selected Supplier Products'
          }
        >
          {store.TableStore.selectedExpanded.length && <EditSupplierProduct />}
        </SlidingPanel>
        <SlidingPanel
          contentId={'AddTagToSupplierProduct'}
          title={'Add Tags to selected Supplier Products'}
        >
          {store.TableStore.selectedExpanded.length && (
            <AssignSupplierProductsTagComponent />
          )}
        </SlidingPanel>
        <SlidingPanel
          contentId={'EditMultipleProducts'}
          title={HandleSlidingName()}
        >
          {store.TableStore.selectedRowId.length && getMutipleEdit()}
        </SlidingPanel>
        <SlidingPanel
          contentId={'AddMultipleSupplierProducts'}
          title={HandleSlidingName()}
        >
          {store.TableStore.selectedRowId.length && (
            <AddMultipleSupplierProductComponent />
          )}
        </SlidingPanel>
        <SlidingPanel contentId={'AddMultipleTags'} title={HandleSlidingName()}>
          <AssignMultipleTagsComponent />
        </SlidingPanel>
        <SlidingPanel
          contentId={'AddMultipleCollections'}
          title={HandleSlidingName()}
        >
          <AssignCollectionGenericMultipleComponent />
        </SlidingPanel>
        <SlidingPanel
          contentId={'RemoveMultipleTags'}
          title={HandleSlidingName()}
        >
          <RemoveMultipleTagsComponent />
        </SlidingPanel>
        <SlidingPanel
          contentId={'RemoveMultipleTransactions'}
          title={HandleSlidingName()}
        >
          <RemoveMultipleTransactionsComponent />
        </SlidingPanel>
      </>
    )
  }
)

export default Footer
