import { observer } from 'mobx-react'
import { FC, useContext } from 'react'
import { StoreContext } from '../../../App'
import { Bottom, Container, Top } from './TableStockInProfress.styles'
import { NavLink } from 'react-router-dom'

/* eslint-disable */
interface Props {
  data: {
    SupplierDeliveryDate?: string
    Quantity?: string
    Id?: string
    SupplierOrderId?: string
    DeliveredQuantity?: string | null
    Status?: string | null
    Date?: string | null
    Notes?: string | null
  }
  secondary?: boolean
}

const TableStockInProgress: FC<Props> = observer((props: Props) => {
  const store = useContext(StoreContext)
  const { Theme } = store.Theme
  const locales = store.TranslationsState.translations
  return (
    <Container /*{...Theme.tableStockInProgressStyles.container}*/>
      <Top {...Theme.tableStockInProgressStyles.top}>
        <p>
          {!props.secondary
            ? `${
                props.data.DeliveredQuantity ? props.data.DeliveredQuantity : 0
              }/${props.data.Quantity}`
            : props.data.Quantity}
        </p>{' '}
        from{' '}
        {!props.secondary ? (
          <NavLink
            to={'/supplierorders'}
            onClick={() => {
              store.RouteStore.setQueryHandler(true)
              localStorage.setItem(
                'supplierorders-query',
                JSON.stringify({
                  rules: [
                    {
                      field: 'id',
                      operator: '=',
                      valueSource: 'value',
                      value: props.data.Id,
                    },
                  ],
                  combinator: 'and',
                  not: false,
                })
              )
            }}
          >
            {props.data.Id}
            {props.data.SupplierOrderId ? `,${props.data.SupplierOrderId}` : ''}
          </NavLink>
        ) : (
          <NavLink
            to={'/customerorders'}
            onClick={() => {
              store.RouteStore.setQueryHandler(true)
              localStorage.setItem(
                'customerorders-query',
                JSON.stringify({
                  rules: [
                    {
                      field: 'order',
                      operator: '=',
                      valueSource: 'value',
                      value: props.data.Id,
                    },
                  ],
                  combinator: 'and',
                  not: false,
                })
              )
            }}
          >
            {props.data.Id}
            {props.data.SupplierOrderId
              ? `,${props.data.SupplierOrderId}`
              : ''}{' '}
            ({props.data.Date})
          </NavLink>
        )}
      </Top>
      <Bottom>
        {props.data.SupplierDeliveryDate
          ? `(${props.data.SupplierDeliveryDate})`
          : ''}
      </Bottom>
      {props.data.Status ? (
        <Bottom>
          {`${locales.status[props.data.Status]} (${props.data.Status})` ||
            `${locales.status.default} (${props.data.Status})`}
        </Bottom>
      ) : (
        <></>
      )}
      {props.data.Notes ? (
        <Bottom>
          {`${locales.status[props.data.Notes]} (${props.data.Notes})` ||
            `${locales.status.default} (${props.data.Notes})`}
        </Bottom>
      ) : (
        <></>
      )}
    </Container>
  )
})
export default TableStockInProgress
