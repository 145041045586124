import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { breakpoints } from '../../static/breakpoints'
import { MenuItem } from 'rc-menu'

interface ContainerProps {
  color?: string
}

export const OuterCont = styled.div`
  position: relative;
  height: auto;
  width: 100%;
  min-height: calc(100vh - 55px);
  display: flex;

  @media screen and (max-width: ${breakpoints.tablet}) {
    flex-direction: column;
  }
`
export const StyledMenuItem = styled(MenuItem)`
  display: flex;
  flex-wrap: wrap;
`
export const SettingsList = styled.div`
  position: relative;
  width: 20%;
  height: 100%;
  min-height: calc(100vh - 54px);
  padding: 16px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.06);
  @media screen and (max-width: ${breakpoints.tablet}) {
    height: auto;
    width: 100%;
    min-height: 0;
  }
`
export const StyledLink = styled(Link)`
  position: relative;
  height: 100%;
  width: 100%;
  text-wrap: wrap;
`
export const Container = styled.div<ContainerProps>`
  width: 100%;
  height: 100%;
  display: inline-block;
  box-sizing: border-box;
  padding: 40px;
  color: ${(props) => (props.color ? props.color : 'black')};

  h1 {
    font-size: 24px;
    width: 100%;
  }

  iframe {
    width: 100%;
    height: 100%;
  }

  @media screen and (max-width: ${breakpoints.tablet}) {
    width: 100%;
  }
`

export const SettingsCategory = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  border: 1px solid gray;
  border-radius: 8px;
  box-sizing: border-box;
  padding: 30px;

  h2 {
    font-size: 18px;
    border-bottom: 1px solid gray;
    margin: 0 0 15px 0;
    padding: 0 0 15px 0;
  }
`

export const Setting = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  select {
    color: black;
    border: 1px solid gray;
    padding: 10px;
    margin-bottom: 20px;
  }
`

export const Label = styled.span`
  font-weight: 500;
`
