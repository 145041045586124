import styled from 'styled-components'

interface ContainerProps {
  borderRadius?: string
  active: boolean
}
/* eslint-disable */
export const Container = styled.div<ContainerProps>`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: visible;
  background-color: ${(props) =>
    props.active ? 'rgba(255, 0, 0, 0.4)' : 'rgba(0, 255, 0, 0.4)'};
  @media print {
    background-color: ${(props) =>
      props.active ? 'rgba(255, 0, 0, 0.2)' : 'rgba(0, 255, 0, 0.2)'};
  }
`

interface TextProps {
  fontWeight?: number
  fontSize?: string
  lineHeight?: string
  letterSpacing?: string
  color?: string
}

export const Text = styled.p<TextProps>`
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : 400)};
  font-size: ${(props) => (props.fontSize ? props.fontSize : '12px')};
  line-height: ${(props) => (props.lineHeight ? props.lineHeight : '16px')};
  letter-spacing: ${(props) =>
    props.letterSpacing ? props.letterSpacing : '0.15px'};
  @media print {
    font-size: 14px;
    font-weight: 700;
  }
`
