import styled from 'styled-components'

interface EntryProps {
  fontSize?: string
  lineHeight?: string
  color?: string
}

export const Container = styled.div`
  display: flex;
`
export const Entry = styled.p<EntryProps>`
  color: ${(props) => (props.color ? props.color : '#1f1f1f')};
  font-size: ${(props) => (props.fontSize ? props.fontSize : '16px')};
  line-height: ${(props) => (props.lineHeight ? props.lineHeight : '20px')};
`
