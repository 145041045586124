export const mainTheme = {
  global: {
    global: {
      bgColor: '#ffffff',
      color: '#000000',
      inputColor: '#ffffff',
    },
  },
  buttons: {
    primaryButton: {
      padding: '8px 16px',
      bgColor: '#303030',
      borderRadius: '6px',
      fontWeight: 600,
      fontSize: '14px',
      lineHeight: '16px',
      color: '#fafafa',
      letterSpacing: '0.5px',
    },
    secondaryButton: {
      fontSize: '14px',
      fontWeight: 600,
      lineHeight: '16px',
      letterSpacing: '0.5px',
      color: '#424242',
    },
  },
  actionsHistoryStyles: {
    container: {
      bgColor: '#ffffff',
      border: '1px solid #d6d6d6',
      borderRadius: '4px',
      padding: '12px 12px',
    },
    headerTitle: {
      color: '#1f1f1f',
      fontSize: '20px',
      fontWeight: 500,
      lineHeight: '32px',
    },
    changesCont: {
      margin: '16px 0 24px 0',
    },
    changesCircle: {
      borderRadius: '50%',
      border: '1.5px solid #d6d6d6',
      margin: '0 0 4px 0',
    },
    changesLine: {
      border: '1px dashed #d6d6d6',
      margin: '0 0 4px 0',
    },
    changesRight: {
      margin: '0 0 0 24px',
    },
    changesTopText: {
      margin: '0 0 12px 0',
      color: '#1f1f1f',
      fontSize: '12px',
      fontWeight: 400,
      lineHeight: '16px',
    },
    changesBottom: {
      borderBottom: '1px solid #f5f5f5',
      padding: '0 0 12px 0',
    },
    changesBottomText: {
      fontWeight: 400,
      padding: '0 8px 0 0',
      fontSize: '10px',
      lineHeight: '12px',
      color: '#1f1f1f',
    },
  },
  adminStyles: {
    container: {
      bgColor: '#f5f5f5',
    },
    titleText: {
      fontWeight: 600,
      fontSize: '48px',
      lineHeight: '100%',
    },
    innerDiv: {
      padding: '24px',
      boxShadow: '0px 12px 32px rgba(0, 0, 0, 0.1)',
      borderRadius: '4px',
    },
    innerText: {
      fontWeight: 500,
      fontSize: '20px',
      lineHeight: '32px',
    },
  },
  breadcrumbStyles: {
    breadcrumbWrapper: {
      fontWeight: 400,
      fontSize: '10px',
      lineHeight: '16px',
      color: '#303030',
    },
  },
  calendarStyles: {
    day: {
      borderColor: '#000000',
      borderTodayColor: '#e5e5e5',
      fontSize: '12px',
    },
    popUpColors: {
      createdOrders: '#cdaa6e',
      estimatedDeliveryOrders: 'blue',
      firstProductDelivered: '#234F1e',
      lastProductDelivered: 'purple',
      deliveredProducts: 'green',
      estimatedDeliveryProducts: 'blue',
      delayedProducts: 'red',
    },
  },
  columnVisibilityStyles: {
    selectTitle: {
      color: '#1f1f1f',
      fontWeight: 500,
      fontSize: '20px',
      lineHeight: '28px',
      letterSpacing: '0.3px',
      marginBottom: '20px',
    },
    selectTitleImg: {
      margin: '3px 8px 4px 0px',
    },
    selectExit: {
      margin: '0 0 0 60px',
    },
    selectButton: {
      padding: '4px',
      fontSize: '12px',
      lineHeight: '16px',
      border: '1px solid #303030',
      borderRadius: '8px',
      color: '#303030',
    },
    columnHead: {
      marginBottom: '8px',
      borderBottom: '1px solid #e5e5e',
      color: 'black',
    },
    columnHeadText: {
      fontWeight: 600,
      fontSize: '14px',
      lineHeight: '20px',
    },
    columnRow: {
      marginBottom: '4px',
      borderBottom: '1px solid #e5e5e',
      color: 'black',
    },
    columnText: {
      fontSize: '12px',
      lineHeight: '16px',
      padding: '4px',
    },
    styledInput: {
      border: '1px solid #d6d6d6',
      boxShadow: '0 1px 3px rgba(0, 0, 0, 0.06)',
      borderRadius: '6px',
    },
    selectWrap: {
      padding: '30px',
    },
  },
  contButton: {
    contButton: {
      padding: '4px 8px',
      margin: '0 4px',
      fontSize: '12px',
      lineHeight: '16px',
      border: '1px solid #303030',
      borderRadius: '8px',
      color: '#303030',
    },
  },
  copyToClipBoardStyles: {
    contButton: {
      padding: '4px 8px',
      margin: '0 4px',
      fontSize: '12px',
      lineHeight: '16px',
      border: '1px solid #303030',
      borderRadius: '8px',
      color: '#303030',
    },
  },
  createOrderPopupStyles: {
    outerCont: {
      fontSize: '12px',
      borderRadius: '4px',
      bgColor: '#ffffff',
      padding: '8px 6px',
      border: '1px solid #e5e5e5',
      marginBottom: '12px',
      boxShadow: '0 2px 3px rgba(0, 0, 0, 0.3)',
    },
    container: {
      borderRadius: '8px',
      bgColor: '#ffffff',
      boxShadow: '0 2px 3px rgba(0, 0, 0, 0.3)',
      margin: '32px',
      padding: '32px',
    },
    row: {
      border: '1px dotted black',
      bgColor: '#e5e5e5',
    },
    primaryButton: {
      padding: '8px 16px',
      bgColor: '#303030',
      borderRadius: '6px',
      fontWeight: 600,
      fontSize: '14px',
      lineHeight: '16px',
      letterSpacing: '0.5px',
      color: '#fafafa',
    },
    errorMessage: {
      margin: '2px auto 0',
      fontSize: '10px',
    },
  },
  detailsDropDownStyles: {
    styledTable: {
      bgColor: '#ffffff',
      border: '1px solid #d6d6d6',
      borderRadius: '4px',
      padding: '12px 20px',
    },
    title: {
      fontWeight: 500,
      fontSize: '20px',
      lineHeight: '32px',
    },
    itemsTable: {
      marginTop: '9px',
      borderTop: '1px solid #f5f5f5',
    },
    singleItemCont: {
      borderBottom: '1px solid #f5f5f5',
    },
    singleItemTitle: {
      fontWeight: 400,
      fontSize: '12px',
      lineHeight: '16px',
      letterSpacing: '0.15px',
      color: '#737373',
    },
    singleItemValue: {
      fontWeight: 400,
      fontSize: '12px',
      lineHeight: '16px',
      letterSpacing: '0.15px',
      color: '#1f1f1f',
    },
  },
  editProductsStyles: {
    container: {
      bgColor: '#fcfcfc',
      borderRadius: '4px',
    },
    styledHeader: {
      padding: '12px 16px 0',
    },
    styledHeaderText: {
      marginLeft: '8px',
      fontWeight: 500,
      fontSize: '20px',
      lineHeight: '32px',
      letterSpacing: '0.3px',
      color: '#1f1f1f',
    },
    headerIcon: {
      margin: '16px 4px 16px 16px',
    },
    formCont: {
      padding: '0 16px 32px',
      boxShadow: '0 3px 3px rgba(0, 0, 0, 0.2)',
      trackBgColor: '#aaaaaa',
      trackBgRadius: '3px',
      thumbBgColor: '#303030',
      thumbBgRadius: '6px',
      thumbBorder: '3px solid #aaaaaa',
    },
    filterLabel: {
      marginLeft: '20px',
      marginTop: '20px',
      fontWeight: 500,
      fontSize: '14px',
      lineHeight: '24px',
      letterSpacing: '0.5px',
      color: '#424242',
    },
    styledInput: {
      marginLeft: '16px',
      activeBgColor: '1px solid #D6D6D6',
      inactiveBgColor: '1px solid #575757',
      boxShadow: '0 1px 3px rgba(0, 0, 0, 0.06)',
      borderRadius: '6px',
      padding: '8px',
      fontWeight: 400,
      fontSize: '16px',
      lineHeight: '24px',
      letterSpacing: '0.15px',
      color: '#1f1f1f',
    },
    styledCheckbox: {
      bgColor: '#f5f5f5',
      border: '2px solid #575757',
      marginLeft: '8px',
      boxShadow: '0 1px 3px rgba(0, 0, 0, 0.06)',
      borderRadius: '6px',
      checkedColor: '#f5f5f5',
    },
    styledFooter: {
      bgColor: '#f5f5f5',
      borderRadius: '0 0 4px 4px',
    },
    primaryButton: {
      padding: '8px 16px',
      bgColor: '#303030',
      borderRadius: '6px',
      margin: '12px',
      fontWeight: 600,
      fontSize: '14px',
      lineHeight: '16px',
      letterSpacing: '0.5px',
      color: '#fafafa',
    },
    secondaryButton: {
      fontWeight: 600,
      fontSize: '14px',
      lineHeight: '16px',
      letterSpacing: '0.5px',
      color: '#424242',
    },
    errorMessage: {
      margin: '2px auto 0',
    },
  },
  filtersStyles: {
    globalFilterInput: {
      borderRadius: '4px',
      padding: '8px 6px',
      border: ' 1px solid #e5e5e5',
      boxShadow: '0 1px 3px rgba(0, 0, 0, 0.06)',
    },
    defaultColumnInput: {
      borderRadius: '4px',
      padding: '8px 6px',
      border: ' 1px solid #e5e5e5',
      boxShadow: '0 1px 3px rgba(0, 0, 0, 0.06)',
    },
    styledSelectColumn: {
      borderRadius: '4px',
      bgColor: '#ffffff',
      padding: '8px 6px',
      border: '1px solid #e5e5e5',
      boxShadow: '0 1px 3px rgba(0, 0, 0, 0.06)',
    },
    styledSelectOption: {
      letterSpacing: '0.5px',
      color: '#575757',
    },
    sliderButton: {
      borderRadius: '4px',
      bgColor: '#ffffff',
      padding: '4px 3px',
      border: '1px solid #e5e5e5',
      fontWeight: 600,
      boxShadow: '0 1px 3px rgba(0, 0, 0, 0.06)',
    },
  },
  headerStyles: {
    layoutSwitch: {
      fontSize: '10px',
      lineHeight: '13px',
      color: '#f2f2f2',
    },
    container: {
      fontSize: '14px',
      bgColor: '#000000',
      color: '#f2f2f2',
      boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
      borderBottom: '1px solid #e0fbfc',
    },
    inputPanel: {
      border: '1px solid rgba(221,175,77,0.8)',
      borderRadius: '8px',
    },
    input: {
      margin: '4px',
      bgColor: '#000000',
      color: '#ffffff',
    },
    sidebarInner: {
      bgColor: '#000000',
    },
    sidebarBottom: {
      padding: '0 10px 120px',
    },
    sideBarLogo: {
      marginTop: '30px',
      fontSize: '12px',
      padding: '20px 10px',
      borderBottom: '1px solid #3b475a',
    },
  },
  headlineStyles: {
    mainTitle: {
      lineHeight: '2rem',
      fontWeight: 700,
      fontSize: '26px',
      color: 'black',
    },
  },
  moodNookLogo: {
    container: {
      padding: '6px 0',
    },
  },
  navButtonStyles: {
    button: {
      margin: '0px',
      paddingLeft: '12px',
      paddingRight: '12px',
      fontWeight: 500,
      fontSize: '14px',
      lineHeight: '18px',
      letterSpacing: '0.44px',
    },
  },
  paginatorStyles: {
    styledPaginator: {
      themebackgroundcolor: '#000000',
      themecolor: '#000000',
    },
    selectedWrapper: {
      marginTop: '8px',
      bgColor: '#000000',
      color: '#fafafa',
      boxShadow: '0 6px 16px rgba(0, 0, 0, 0.1))',
    },
    selectedParagraph: {
      fontWeight: 400,
      fontSize: '12px',
      lineHeight: '16px',
      color: '#ffffff',
    },
    paginationWrapper: {
      fontSize: '0.875rem',
      lineHeight: '1.25rem',
      margin: '15px 0 0px 0',
      color: 'black',
    },
    rowsPerPage: {
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '16px',
      color: '#1f1f1f',
    },
    optionsSelect: {
      padding: '4px 4px 4px 8px',
      border: '1.5px solid #303030',
      borderRadius: '6px',
    },
    paginationButton: {
      padding: '8px 14px',
      margin: '0 2px',
      fontSize: '1.2em',
      border: '2px solid black',
      borderRadius: '4px',
    },
    paginationNavButton: {
      padding: '8px 14px',
      margin: '0 2px',
      fontSize: '1.2em',
      borderRadius: '4px',
    },
    leftInnerPaginationBot: {
      fontSize: '12px',
    },
  },
  proformsDetailsStyles: {
    container: {
      bgColor: '#fafafa',
      padding: '16px',
    },
    headerTitle: {
      fontWeight: 600,
      fontSize: '26px',
      lineHeight: '32px',
      color: '#1f1f1f',
    },
    headerButton: {
      color: '#424242',
      fontWeight: 400,
      fontSize: '12px',
      lineHeight: '16px',
    },
    headerButtonProps: {
      borderRadius: '6px',
      border: '1px solid',
      fontWeight: 600,
      fontSize: '14px',
      lineHeight: '16px',
      letterSpacing: '0.5px',
    },
    secondaryButton: {
      borderColor: '#303030',
      color: '#303030',
    },
    primaryButton: {
      bgColor: '#303030',
      borderColor: '#303030',
      color: '#fafafa',
    },
    headerDropDown: {
      color: '#000000',
      bgColor: '#ffffff',
      boxShadow: '0 1px 3px rgba(0, 0, 0, 0.06)',
      borderRadius: '6px',
    },
    dropDownItem: {
      color: '#1f1f1f',
      fontSize: '16px',
      lineHeight: '40px',
      letterSpacing: '0.15px',
      hoverBgColor: '#f8f8f8',
    },
  },
  slidingContainerStyles: {
    container: {
      bgColor: '#ffffff',
      boxShadow: '0px 0px 5px 0px rgba(0, 0, 0, 0.75)',
    },
  },
  tableBankExportsStyles: {
    contButton: {
      padding: '4px 8px',
      margin: '0 4px',
      fontSize: '12px',
      lineHeight: '16px',
      border: '1px solid #303030',
      borderRadius: '8px',
      color: '#303030',
    },
    contDrop: {
      bgColor: 'rgba(0, 0, 0, 0.3)',
    },
    innerContDrop: {
      padding: '32px',
      border: '1px solid black',
      bgColor: '#ffffff',
    },
    dropArea: {
      border: '1px dashed black',
      padding: '32px',
    },
    selectedFile: {
      bgColor: '#ffffff',
      border: '2px solid #909090',
      padding: '8px',
      borderRadius: '8px',
      color: '#303030',
      fontWeight: 700,
      fontSize: '14px',
      lineHeight: '32px',
    },
    primaryButton: {
      padding: '8px 16px',
      bgColor: '#303030',
      borderRadius: '6px',
      color: '#fafafa',
      fontWeight: 600,
      fontSize: '14px',
      lineHeight: '16px',
      letterSpacing: '0.5px',
    },
  },
  tableCSVStyles: {
    contButton: {
      padding: '4px 8px',
      margin: '0 4px',
      fontSize: '12px',
      lineHeight: '16px',
      border: '1px solid #303030',
      borderRadius: '8px',
      color: '#303030',
    },
  },
  tableDocumentsStyles: {
    link: {
      fontSize: '10px',
    },
  },
  tableEntryStyles: {
    entry: {
      color: '#1f1f1f',
      fontSize: '16px',
      lineHeight: '20px',
    },
  },
  tableHeaderOptionsStyles: {
    headerOptionWrap: {
      padding: '30px',
    },
  },
  tableImageStyles: {
    displayed: {
      bgColor: 'rgba(0, 0, 0, 0.6)',
      padding: '0 24px',
    },
  },
  tableLabelStyles: {
    text: {
      color: '#fafafa',
      fontWeight: 400,
      fontSize: '12px',
      lineHeight: '16px',
      letterSpacing: '0.15px',
    },
  },
  tableLockStyles: {
    activeButton: {
      padding: '4px 12px',
      border: '1px solid #20c33a',
      bgColor: '#f6fef7',
      borderRadius: '6px',
      color: '#20c33a',
    },
    text: {
      color: '#575757',
      fontWeight: 400,
      fontSize: '10px',
      lineHeight: '16px',
      letterSpacing: '0.35px',
    },
  },
  tableMoreActionsStyles: {
    actionList: {
      bgColor: '#ffffff',
      border: '1px solid #e5e5e5',
      boxShadow: '0 1px 3px rgba(0, 0, 0, 0.06)',
      padding: '8px 10px',
    },
    singleAction: {
      borderTop: '1px solid #e5e5e5',
      padding: '4px 10px',
      color: '#000000',
      fontWeight: 400,
      fontSize: '12px',
      lineHeight: '24px',
      letterSpacing: '0.35px',
      borderRadius: '6px',
      hoverBgColor: '#d8d8d8',
    },
    actionEdit: {
      bgColor: 'rgba(0, 0, 0, 0.6)',
    },
  },
  tableNotesStyles: {
    dataValue: {
      fontSize: '10px',
      lineHeight: '12px',
    },
  },
  tableRenderedStyles: {
    container: {
      bgColor: '#f5f5f5',
    },
    rowStyle: {
      oddBgColor: '#f5f5f5',
      evenBgColor: '#e5e5e5',
    },
    cellStylesProps: {
      fontWeight: 400,
      fontSize: '12px',
      lineHeight: '16px',
      letterSpacing: '0.5px',
      padding: '4px',
    },
    spinnerContainer: {
      bgColor: '#e5e5e5',
    },
    spinner: {
      borderColor: '#303030 transparent #303030 transparent',
    },
  },
  tableStatusStyles: {
    text: {
      color: '#fafafa',
      fontWeight: 400,
      fontSize: '12px',
      lineHeight: '16px',
      letterSpacing: '0.15px',
    },
  },
  tableStockInProgressStyles: {
    container: {
      secondaryColor: 'rgba(120, 50, 50)',
      primaryColor: 'rgba(50,120,50)',
      margin: '4px 0',
      padding: '4px 0',
      borderRadius: '4px',
      fontSize: '11px',
    },
    top: {
      padding: '0 6px',
      margin: '0 4px',
      fontWeight: 700,
    },
  },
  tableStyles: {
    tableCont: {
      color: '#111827',
      fontSize: '0.75rem',
      lineHeight: '1rem',
    },
    tableHead: {
      marginTop: '0.75rem',
      bgColor: '#f9fafb',
    },
    switchStyled: {
      fontSize: '0.875rem',
      lineHeight: '1.25rem',
    },
    switchCont: {
      color: 'black',
    },
    innerHeader: {
      borderColor: '1px solid #4b5563',
    },
    headerTitle: {
      fontWeightSelected: 700,
      fontWeightNotSelected: 500,
    },
    tableRow: {
      paddingTop: '4px',
      paddingBottom: '4px',
      selectBg: 'rgba(221,175,77,0.4)',
      oddBg: '#f2f2f2',
      bgColor: 'transparent',
      selectBgHover: 'rgba(211,165,67,0.4)',
      oddBgHover: '#e8e8e8',
      bgColorHover: '#e8e8e8',
      borderColor: '#ffffff',
    },
    tableData: {
      selectedColor: 'rgba(203, 165, 175, 0.4)',
      boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.06)',
    },
    tableCellExpand: {
      height: '12px',
      width: '12px',
      margin: '16px 0 24px 0',
    },
    headerCheckboxes: {
      fontSize: '10px',
      color: 'white',
    },
    styledCheckBox: {
      bgColor: '#303030',
      borderRadius: '5px',
      marginBottom: '2px',
      marginRight: '2px',
    },
    headerCheckboxParagraph: {
      fontWeight: 500,
      fontSize: '10.5',
      color: '#ffffff',
    },
    resize: {
      border: '1px solid #d6d6d6',
    },
    addonsColumnWrapper: {
      padding: '0 10px 0 0',
      marginRight: '10px',
      borderRight: '1px solid gray',
    },
    arrowsContainer: {
      marginLeft: '4px',
    },
    arrowExpandedImg: {
      marginTop: '4px',
    },
  },
  tableTooltipStyles: {
    toolTip: {
      padding: '4px 8px',
      border: '1px solid #1f1f1f',
      color: '#fafafa',
      bgColor: '#1f1f1f',
      boxShadow: '0 6px 16px rgba(0, 0, 0, 0.1)',
      borderRadius: '4px',
    },
  },
  unAuthenticatedStyles: {
    container: {
      bgColor: '#f5f5f5',
    },
    titleText: {
      fontWeight: 600,
      fontSize: '48px',
      lineHeight: '100%',
    },
    styledMs: {
      padding: '2px',
      margin: '2px',
    },
    logText: {
      fontWeight: 500,
      fontSize: '20px',
      lineHeight: '32px',
    },
    innerDiv: {
      padding: '24px',
      boxShadow: ' 0px 12px 32px rgba(0, 0, 0, 0.1)',
      borderRadius: '4px',
    },
    loginButton: {
      border: '2px solid #303030',
      borderRadius: '6px',
    },
  },
  SettingsPage: {
    container: {
      color: '#000000',
    },
  },
}
