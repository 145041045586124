import React, { useContext } from 'react'
import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer'
import ReactPDF from '@react-pdf/renderer'
import { StoreContext } from '../../App'
import TableTags from '../../components/TableGeneric/tableTags/TableTags.component'
import { toJS } from 'mobx'
import { useParams } from 'react-router-dom'
/* eslint-disable */
const styles = StyleSheet.create({
  body: {},
  table: {
    display: 'flex',
    flexDirection: 'column',
    width: '90%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
    margin: '5%',
    marginTop: '0px',
  },
  tableRow: {
    margin: 'auto',
    display: 'flex',
    flexDirection: 'row',
  },
  tableCol: {
    width: '50%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableCell: {
    margin: 'auto',
    paddingTop: 5,
    fontSize: 10,
  },
  titleText: {
    fontSize: 18,
    fontWeight: 'black',
    top: 0,
    left: '5%',
    marginBottom: '1%',
    textAlign: 'left',
    color: 'black',
  },
})

interface PrintUserInterface {
  data: any
  columns: { header: string; value: string }[]
  title: string
}

export const PrintUserDetails = ({
  data,
  columns,
  title,
}: PrintUserInterface) => {
  const store = useContext(StoreContext)
  const params = useParams()
  const locales = store.TranslationsState.translations

  const getValue = (e: string) => {
    if (e === 'tags') {
      //@ts-ignore
      return (
        <Text style={styles.tableCell}>
          {data['tags'] ? Object.values(data['tags']).join(', ') : ''}
        </Text>
      )
    }
    if (e === 'object_tags') {
      return (
        <Text style={styles.tableCell}>
          {data[e]
            ? data[e]
                .flatMap((tag: any) => tag.tags.map((t: any) => t.name.EN))
                .join(', ')
            : ''}
        </Text>
      )
    }
    if (e === 'completable') {
      switch (data[e]) {
        case 0:
          return `${locales.packable.allMissing} (0)`
        case 1:
          return `${locales.packable.someMissing} (1)`
        case 2:
          return `${locales.packable.packable} (2)`
        case null:
          return 'null'
        default:
          return `${typeof data[e]} ${data[e]}`
      }
    } else if (
      e === 'Sum' ||
      e === 'Payment difference' ||
      e === 'tax' ||
      e === 'taxAmount' ||
      e === 'orderWithOutTaxes'
    ) {
      let sum = parseFloat(data.shipping_cost)
      let nettoSum = parseFloat(data.shipping_cost)
      const products: any = store.TableStore.detailsData.products
        ? Object.keys(
            //@ts-ignore
            store.TableStore.detailsData.products
            //@ts-ignore
          ).map((key) => store.TableStore.detailsData.products[key])
        : []
      products?.map((e: any) => {
        sum = sum + parseFloat(e.price) * parseInt(e.quantity)
        nettoSum =
          nettoSum +
          (parseFloat(e.price) * parseInt(e.quantity) * parseInt(e.tax_value)) /
            100
      })

      if (e === 'Sum') return Math.round(sum * 100) / 100
      else if (e === 'Payment difference')
        return Math.round((sum - parseFloat(data['paid'])) * 100) / 100
      else if (e === 'taxAmount') return Math.round(nettoSum * 100) / 100
      else if (e === 'orderWithOutTaxes')
        return Math.round((sum - nettoSum) * 100) / 100
      else return Math.round((sum - parseFloat(data['paid'])) * 100) / 100
    } else if (
      (e === 'user_id' ||
        e === 'email' ||
        e === 'phone' ||
        e === 'country_code') &&
      data.customer
    ) {
      //@ts-ignore
      return data.customer[e]
    } else if (e === 'lastEdit' || e === 'lastEditedBy') {
      let newestDate = null
      let newestField: string | null = null
      const checkListItems: any = toJS(data.checkListItems)
      for (const field in checkListItems) {
        if (
          Object.prototype.hasOwnProperty.call(checkListItems, field) &&
          checkListItems[field].selected
        ) {
          const currentDate = new Date(checkListItems[field].date)

          if (newestDate === null || currentDate > newestDate) {
            newestDate = currentDate
            newestField = field
          }
        }
      }
      if (newestField && e === 'lastEdit')
        return checkListItems[newestField].date
      if (newestField && e === 'lastEditedBy')
        return checkListItems[newestField].userName
    } else if (e === 'pageId') {
      return params.id
    } else if (e === 'vies') {
      const viesObj: any = store.TableStore.detailsData.vies
      if (viesObj !== null && viesObj !== undefined) {
        return viesObj.valid
      } else return 'No VIES data'
    } else if (data[e] && data[e] !== null) return data[e].toString()
  }
  const renderRow = (label: string, value: any) => (
    <View style={styles.tableRow}>
      <View style={styles.tableCol}>
        <Text style={styles.tableCell}>{label}</Text>
      </View>
      <View style={styles.tableCol}>
        <Text style={styles.tableCell}>{getValue(value)}</Text>
      </View>
    </View>
  )

  return (
    <>
      {data ? (
        <>
          <View style={styles.titleText}>
            <Text>{title}</Text>
          </View>
          <View style={styles.table}>
            {columns && data ? (
              columns.map((e: any, index) => renderRow(e.header, e.value))
            ) : (
              <></>
            )}
          </View>
        </>
      ) : (
        <></>
      )}
    </>
  )
}
