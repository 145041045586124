import styled from 'styled-components'

interface DefaultColumnInputProps {
  borderRadius?: string
  border?: string
  boxShadow?: string
  padding?: string
  margin?: string
}

export const DefaultColumnInput = styled.input<DefaultColumnInputProps>`
  border-radius: ${(props) =>
    props.borderRadius ? props.borderRadius : '4px'};
  height: 32px;
  padding: ${(props) => (props.padding ? props.padding : '8px 6px')};
  border: ${(props) => (props.border ? props.border : ' 1px solid #e5e5e5')};
  width: 95%;
  margin-bottom: 12px;
  box-shadow: ${(props) =>
    props.boxShadow ? props.boxShadow : '0 1px 3px rgba(0, 0, 0, 0.06)'};
`

export const DefaultColumnSelect = styled.select<DefaultColumnInputProps>`
  border-radius: ${(props) =>
    props.borderRadius ? props.borderRadius : '4px'};
  height: 32px;
  padding: ${(props) => (props.padding ? props.padding : '8px 6px')};
  border: ${(props) => (props.border ? props.border : ' 1px solid #e5e5e5')};
  width: 95%;
  margin-bottom: 12px;
  box-shadow: ${(props) =>
    props.boxShadow ? props.boxShadow : '0 1px 3px rgba(0, 0, 0, 0.06)'};
`
