import styled from 'styled-components'

export const HeaderRightInner = styled.div`
  position: relative;

  svg {
    margin-right: 5px;
  }

  @media print {
    display: none;
  }
`

interface HeaderOptionWrap {
  padding?: string
}

export const HeaderOptionWrap = styled.div<HeaderOptionWrap>`
  display: flex;
  flex-direction: column;
  width: 360px;
  max-width: 360px;

  .rc-menu-item {
    padding-right: 16px !important;
  }
`
export const HeaderRight = styled.div`
  display: flex;
`

interface HeaderTitleInterface {
  selected: boolean
  fontWeightSelected?: number
  fontWeightNotSelected?: number
  isResizing: boolean
}

export const HeaderTitle = styled.div<HeaderTitleInterface>`
  position: relative;
  padding-left: 0.5rem;
  text-transform: capitalize;
  text-overflow: ellipsis;
  max-width: 280px;
  min-width: 40px;
  height: fit-content;
  overflow-x: hidden;
  overflow-y: visible;
  flex-grow: 1;
  z-index: 10;
  user-select: ${(props) => (props.isResizing ? 'none' : 'inherit')};
  font-weight: ${(props) =>
    props.selected
      ? props.fontWeightSelected || 700
      : props.fontWeightNotSelected || 500};
`

interface InnerContInterface {
  selected: boolean
  borderColor?: string
}

export const InnerHeader = styled.div<InnerContInterface>`
  display: flex;
  width: 100%;
  padding-top: 0.25rem;
  margin-bottom: 0.5rem;
  border-color: ${(props) =>
    props.borderColor ? props.borderColor : '1px solid #4b5563'};
  cursor: pointer;
  justify-content: space-between;
`

interface ResizeProps {
  border?: string
}

export const Resize = styled.div<ResizeProps>`
  height: 20px;
  cursor: col-resize;
  border-left: ${(props) =>
    props.border ? props.border : '1.5px solid #d6d6d6'};
  padding-right: 2px;
`
