import styled from 'styled-components'
import { darken } from 'polished'

interface InnerContInterface {
  cols: number
}

export const InnerCont = styled.div<InnerContInterface>`
  height: 80vh;
  display: inline-block;
  column-count: ${(props) => props.cols};
`
interface FooterProps {
  bgColor?: string
  borderRadius?: string
}

export const Footer = styled.div<FooterProps>`
  width: 100%;
  height: 56px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 16px;
  background-color: ${(props) => (props.bgColor ? props.bgColor : '#f5f5f5')};
  border-radius: ${(props) =>
    props.borderRadius ? props.borderRadius : '0 0 4px 4px'};
`

interface PrimaryButtonProps {
  padding?: string
  bgColor?: string
  borderRadius?: string
  fontSize?: string
  fontWeight?: number
  lineHeight?: string
  letterSpacing?: string
  margin?: string
  color?: string
  active?: boolean
}

export const PrimaryButton = styled.button<PrimaryButtonProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: ${(props) => (props.padding ? props.padding : '8px 16px')};
  background-color: ${(props) => (props.bgColor ? props.bgColor : '#303030')};
  border-radius: ${(props) =>
    props.borderRadius ? props.borderRadius : '6px'};
  white-space: nowrap;
  text-transform: uppercase;
  margin: ${(props) => (props.margin ? props.margin : '12px')};
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : 600)};
  font-size: ${(props) => (props.fontSize ? props.fontSize : '14px')};
  line-height: ${(props) => (props.lineHeight ? props.lineHeight : '16px')};
  letter-spacing: ${(props) =>
    props.letterSpacing ? props.letterSpacing : '0.5px'};
  color: ${(props) => (props.color ? props.color : '#fafafa')};
  opacity: ${(props) => (props.active ? 1 : 0.5)};

  transition: background-color 0.3s, transform 0.2s;

  &:hover {
    background-color: ${(props) =>
    darken(0.1, props.bgColor ? props.bgColor : '#303030')};
    transform: scale(1.02);
  }

  &:active {
    transform: scale(0.98);
  }
`

interface SecondaryButtonProps {
  padding?: string
  bgColor?: string
  borderRadius?: string
  fontSize?: string
  fontWeight?: number
  lineHeight?: string
  letterSpacing?: string
  margin?: string
  color?: string
}

export const SecondaryButton = styled.button<SecondaryButtonProps>`
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : 600)};
  font-size: ${(props) => (props.fontSize ? props.fontSize : '14px')};
  line-height: ${(props) => (props.lineHeight ? props.lineHeight : '16px')};
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: ${(props) =>
    props.letterSpacing ? props.letterSpacing : '0.5px'};
  text-transform: uppercase;
  color: ${(props) => (props.color ? props.color : '#424242')};
  margin-top: 20px;
  margin-bottom: 20px;
  margin-right: 24px;
  white-space: nowrap;
  &:hover {
    color: ${(props) => darken(0.1, props.color ? props.color : '#424242')};
    transform: scale(1.05);
  }

  &:active {
    color: ${(props) => darken(0.2, props.color ? props.color : '#424242')};
    transform: scale(0.98);
  }
`
export const LoadingWrapper = styled.div`
  width: 150px;
`
