import { observer } from 'mobx-react'
import { FC, useContext } from 'react'
import { StoreContext } from '../../../App'
import { IconNotes } from '@tabler/icons-react'
import { Tooltip } from '@mui/material'
import { styled } from '@mui/material/styles'
import { tooltipClasses, TooltipProps } from '@mui/material/Tooltip'
interface Props {
  row: any
}

const NoMaxWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 'none',
  },
})

const TableTooltip: FC<Props> = observer((props: Props) => {
  const store = useContext(StoreContext)
  const { Theme } = store.Theme

  const tooltipContent = props.row.notes_priv || ''

  if (props.row.notes_priv) {
    return (
      <>
        <NoMaxWidthTooltip
          title={
            <div
              dangerouslySetInnerHTML={{
                __html: tooltipContent.replace(/\r\n|\r|\n/g, '<br>'),
              }}
            />
          }
          arrow
        >
          <IconNotes size='18px' style={{ marginRight: '4px' }} />
        </NoMaxWidthTooltip>
      </>
    )
  } else {
    return <></>
  }
})

export default TableTooltip
