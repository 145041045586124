import styled from 'styled-components'
import { darken } from 'polished'

interface ContButtonProps {
  padding?: string
  margin?: string
  fontSize?: string
  lineHeight?: string
  border?: string
  borderRadius?: string
}

export const ContButton = styled.button<ContButtonProps>`
  display: flex;
  justify-content: center;
  width: 122px;
  padding: ${(props) => (props.padding ? props.padding : '4px 8px')};
  margin: ${(props) => (props.margin ? props.margin : '0 4px')};
  font-size: ${(props) => (props.fontSize ? props.fontSize : '12px')};
  line-height: ${(props) => (props.lineHeight ? props.lineHeight : '16px')};
  border: ${(props) => (props.border ? props.border : '1px solid #303030')};
  border-radius: ${(props) =>
    props.borderRadius ? props.borderRadius : '8px'};
  height: 24px;
  white-space: nowrap;
  align-items: center;
  color: ${(props) => (props.color ? props.color : '#303030')};
`

interface ContDropProps {
  bgColor?: string
}

export const ContDrop = styled.div<ContDropProps>`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) =>
    props.bgColor ? props.bgColor : 'rgba(0, 0, 0, 0.3)'};
`

interface InnerContDropProps {
  padding?: string
  border?: string
  bgColor?: string
}

export const InnerContDrop = styled.div<InnerContDropProps>`
  padding: ${(props) => (props.padding ? props.padding : '32px')};
  border: ${(props) => (props.border ? props.border : '1px solid black')};
  background-color: ${(props) => (props.bgColor ? props.bgColor : '#ffffff')};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

interface DropAreaProps {
  padding?: string
  border?: string
  bgColor?: string
}

export const DropArea = styled.div<DropAreaProps>`
  border: ${(props) => (props.border ? props.border : '1px dashed black')};
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: ${(props) => (props.padding ? props.padding : '32px')};
  margin-bottom: 8px;
`

interface SelectedFileProps {
  padding?: string
  border?: string
  bgColor?: string
  borderRadius?: string
  fontWeight?: number
  fontSize?: string
  lineHeight?: string
  color?: string
}

export const SelectedFile = styled.div<SelectedFileProps>`
  background-color: ${(props) => (props.bgColor ? props.bgColor : '#ffffff')};
  border: ${(props) => (props.border ? props.border : '2px solid #909090')};
  position: relative;
  width: 110%;
  padding: ${(props) => (props.padding ? props.padding : '8px')};
  border-radius: ${(props) =>
    props.borderRadius ? props.borderRadius : '8px'};
  margin-bottom: 16px;

  p {
    color: ${(props) => (props.color ? props.color : '#303030')};
    font-weight: ${(props) => (props.fontWeight ? props.fontWeight : 700)};
    font-size: ${(props) => (props.fontSize ? props.fontSize : '14px')};
    line-height: ${(props) => (props.lineHeight ? props.lineHeight : '32px')};
  }
`

interface PrimaryButtonProps {
  padding?: string
  border?: string
  bgColor?: string
  borderRadius?: string
  fontWeight?: number
  fontSize?: string
  lineHeight?: string
  color?: string
  letterSpacing?: string
}

export const PrimaryButton = styled.button<PrimaryButtonProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: ${(props) => (props.padding ? props.padding : '8px 16px')};
  background-color: ${(props) => (props.bgColor ? props.bgColor : '#303030')};
  border-radius: ${(props) =>
    props.borderRadius ? props.borderRadius : '6px'};
  white-space: nowrap;
  text-transform: uppercase;
  margin-top: 12px;
  color: ${(props) => (props.color ? props.color : '#fafafa')};

  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : 600)};
  font-size: ${(props) => (props.fontSize ? props.fontSize : '14px')};
  line-height: ${(props) => (props.lineHeight ? props.lineHeight : '16px')};
  letter-spacing: ${(props) =>
    props.letterSpacing ? props.letterSpacing : '0.5px'};

  transition: background-color 0.3s, transform 0.2s;

  &:hover {
    background-color: ${(props) =>
    darken(0.1, props.bgColor ? props.bgColor : '#303030')};
    transform: scale(1.02);
  }

  &:active {
    transform: scale(0.98);
  }
`
export const StyledCross = styled.img`
  position: fixed;
  top: 32px;
  right: 32px;
  height: 24px;
  width: 24px;
  cursor: pointer;
`
export const FileNameHeader = styled.div`
  display: flex;
  justify-content: space-between;
`
export const StyledCloseCross = styled.img`
  top: 32px;
  right: 32px;
  height: 24px;
  width: 24px;
  cursor: pointer;
  filter: invert(1);
`
export const ExitCrossCont = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: flex-end;
`
export const ExitCross = styled.img`
  height: 24px;
  width: 24px;
  margin-bottom: 8px;
  position: relative;
  cursor: pointer;
  filter: invert(1);
`
