import styled from 'styled-components'

interface ContainerProps {
  borderRadius?: string
  active: boolean
}

export const Container = styled.div<ContainerProps>`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: visible;
  background-color: ${(props) =>
    props.active ? 'rgba(255, 0, 0, 0.4)' : 'transparent'};
`

interface TextProps {
  fontWeight?: number
  fontSize?: string
  lineHeight?: string
  letterSpacing?: string
  color?: string
}

export const Text = styled.div<TextProps>`
  position: relative;
  width: 100%;
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : 400)};
  font-size: ${(props) => (props.fontSize ? props.fontSize : '12px')};
  line-height: ${(props) => (props.lineHeight ? props.lineHeight : '16px')};
  letter-spacing: ${(props) =>
    props.letterSpacing ? props.letterSpacing : '0.15px'};
  display: flex;
  justify-content: center;
  align-content: center;
`
