import styled from 'styled-components'
import Breadcrumb from '../../components/breadcrumb/Breadcrumb.component'
import SuppliersTable from '../../components/suppliersTable/SuppliersTable.component'
import { Container } from '../../styles/LayoutStyles'
import { observer } from 'mobx-react'
import { useContext } from 'react'
import { StoreContext } from '../../App'
import { FeatureDisable } from '../../components/FeatureDisable/FeatureDisable.component'

const Proforms = observer(() => {
  const store = useContext(StoreContext)
  return (
    <Container>
      {store.UserStore.features.includes('purchaseordersmgm') ? (
        <SuppliersTable />
      ) : (
        <FeatureDisable />
      )}
    </Container>
  )
})
export default Proforms
