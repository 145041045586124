/* eslint-disable */
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'
import { observer } from 'mobx-react'
import {
  Cell,
  Column,
  ColumnInstance,
  HeaderProps,
  Row,
  TableToggleRowsSelectedProps,
  useColumnOrder,
  useExpanded,
  useFilters,
  useFlexLayout,
  useGlobalFilter,
  useGroupBy,
  useResizeColumns,
  useRowSelect,
  useSortBy,
  useTable,
} from 'react-table'
import { ScrollSync, ScrollSyncPane } from 'react-scroll-sync'
import Menu, { Item as MenuItem, SubMenu } from 'rc-menu'

import Headline from '../../headline/Headline.component'
import QueryFilter from '../../queryFilter/QueryFilter.component'
import ColumnVisibility from '../columnVisibility/ColumnVisibility.component'
import Paginator from '../paginator/Paginator.component'
import TableHeader from '../tableHeader/TableHeader.component'
import TableHeaderCheckboxes from '../tableHeaderCheckboxes/TableHeaderCheckboxes.component'
import CopyToClipboard from '../copyToClipboard/CopyToClipboard.component'
import TableBankExports from '../tableBankExports/TableBankExports.component'
import TableCell from '../tableCell/TableCell.component'
import TableCellFirstColumn from '../tableCellFirstColumn/TableCellFirstColumn.component'
import TableCSV from '../tableCSV/TableCSV.component'
import TableMoreActions from '../tableMoreActions/TableMoreActions.component'
import TableRendered from '../tableRendered/TableRendered.component'
import SearchFieldButton from '../searchFieldButton/SearchFieldButton.component'
import Visibility from '../../../assets/icons/visibility.svg'
import MobileOptions from '../mobilePanel/MobilePanel.component'

import { StoreContext } from '../../../App'
import { SlidingPanel } from '../../slidingContainer/SlidingContainer.component'
import { DefaultColumnFilter } from '../../filters/Filters.components'

import {
  MainCont,
  MobileBreakLine,
  MobileSwitchStyled,
  QueryRulesCont,
  SearchSwitchWrapper,
  SwitchButtons,
  SwitchCont,
  SwitchInput,
  SwitchInputText,
  SwitchStyled,
  TableBody,
  TableCont,
  TableFooter,
  TableFooterWrap,
  TableOptionsCont,
  TableRow,
  TableRowWrapper,
  UpperCont,
} from './Table.styles'
import { Container, Wrap } from '../../../styles/Loading.styles'

import ClearFilters from '../../../assets/icons/clear-filters.svg'
import FilterIcon from '../../../assets/icons/filter-icon.svg'
import TableOptions from '../../../assets/icons/table-options.svg'
import { IconChevronDown, IconSquareChevronDown } from '@tabler/icons-react'
import { Tooltip } from '@mui/material'
import Breadcrumb from '../../breadcrumb/Breadcrumb.component'
import { toJS } from 'mobx'
import TableActionsComponent from '../tableActions/TableActions.component'
import CreateTagComponent from '../../settings/tags/edits/createTag/CreateTag.component'
import Plus from '../../../assets/icons/plus.svg'
import { CreateGeneric } from '../../createGeneric/CreateGeneric.component'
import ImportSelectComponent from '../../importSelect/ImportSelect.component'
import TableDefaultCellComponent from '../tableDefaultCell/tableDefaultCell.component'
import Footer from '../footer/Footer.component'
import Views from '../../views/Views.component'

export interface TransactionsTableProps {
  config: {
    tableName: string
    data: Array<object>
    fetchData: () => void
    columnHeaders: {
      [e: string]: {
        Cell?: (cell: Cell) => JSX.Element
        Header?: string | JSX.Element
        filter?: string
        Filter?: any
        canFilter?: boolean
        canSort?: boolean
        aggregate?: string
        Aggregated?: (val: any) => string
        editable?: boolean
        editType?: string
        dateFormat?: string
        mandatory?: boolean
        selectValues?: { value: string; label: string }[]
        arrayValues?: string[]
        mandatoryLanguage?: string
        isVisible?: boolean
      }
    }
    columnVisibility: boolean
    allowColumnResize: boolean
    displaySearch: boolean
    displaySelect: boolean
    displayExpand: boolean
    displayTooltip: boolean
    displayEntry: boolean
    displayPaginator: boolean
    displayInfinitySwitch: boolean
    allowSort: boolean
    bank?: boolean
    views?: boolean
    actions?: {
      display: boolean
      label: string
      edit: string | null
      create: string | null
      remove: string | null
      assignTag: string | null
      custom: any[]
    } | null
    headline: {
      visible: boolean
      title: string
      subTitle: string
    }
    breadcrumb: Array<string>
    loaded: boolean
    importSelect?: boolean
    pagination?: {
      tableSize: number
      pageSize: number
      pageIndex: number
      totalCount: number
    }
    setPagination?: (
      tableSize: number,
      pageSize: number,
      pageIndex: number,
      totalCount: number,
      noLoading?: boolean
    ) => void
  }
}

const Table = observer(({ config }: TransactionsTableProps) => {
  const {
    tableName,
    data,
    fetchData,
    columnHeaders,
    columnVisibility,
    allowColumnResize,
    displaySearch,
    displaySelect,
    displayExpand,
    displayTooltip,
    displayEntry,
    displayPaginator,
    displayInfinitySwitch,
    allowSort,
    bank,
    actions,
    headline,
    views,
    breadcrumb,
    loaded,
    importSelect,
    pagination,
    setPagination,
  } = config

  const store = useContext(StoreContext)
  const { Theme } = store.Theme
  const locales = store.TranslationsState.translations

  const [change, toggleChange] = useState(false)
  const [tableLoaded, setTableLoaded] = useState(false)
  const [selectedRows, setSelectedRows] = useState({})
  const containerRef = useRef<any>(null)

  const handleDefaultRowSelect = () => {
    let arrayIndexes: string[] = []
    data &&
      data.map((e: any, index) => {
        if (
          store.TableStore.selectedRowId.includes(e.id) ||
          store.TableStore.allSelectedRows
        ) {
          arrayIndexes = [`${index}`, ...arrayIndexes]
        }
      })
    let selectedObject = {}
    arrayIndexes.map((e) => {
      selectedObject = { ...selectedObject, [e]: 'true' }
    })
    return selectedObject
  }

  const handleDefaultExpanded = () => {
    let expandObject = {}
    Object.keys(store.TableStore.expandedRows).map((e: string) => {
      const index = data.findIndex((i: any) => i.id === e)
      if (index !== -1) {
        expandObject = { [index.toString()]: true, ...expandObject }
      }
    })
    return expandObject
  }

  const columns: Column<object>[] = useMemo(
    () => [
      ...Object.keys(columnHeaders).map((e) => {
        return {
          Header: e,
          accessor: e,
          defaultWidth: 70,
          width:
            JSON.parse(
              localStorage.getItem(
                `${store.RouteStore.currentPage}-columnWidths`
              )!
            )?.[e] || 70,
          id: e,
          ...(columnHeaders[e].Filter && { Filter: columnHeaders[e].Filter }),
          ...(columnHeaders[e].Header && { Header: columnHeaders[e].Header }),
          ...(columnHeaders[e].filter && {
            filter: { name: columnHeaders[e].filter },
          }),
          ...(columnHeaders[e].Cell && { Cell: columnHeaders[e].Cell }),
          ...(columnHeaders[e].aggregate && {
            aggregate: columnHeaders[e].aggregate,
          }),
          ...(columnHeaders[e].Aggregated && {
            Aggregated: columnHeaders[e].Aggregated,
          }),
          ...(columnHeaders[e].isVisible && {
            isVisible: columnHeaders[e].isVisible,
          }),
          disableSortBy: !allowSort,
          headerCustomOptions: {
            canFilter: columnHeaders[e].canFilter !== false ? true : false,
            canSort: columnHeaders[e].canSort !== false ? true : false,
            sort: {
              desc: true,
            },
            settings: {
              wrapText: !store.TableStore.clipTextColumns.includes(e),
              color:
                store.ViewStore.columnColors.find((c) => c.column === e)
                  ?.color || 'transparent',
              border:
                store.ViewStore.columnBorder.find((c) => c.column === e)
                  ?.border || false,
            },
          },
          editCustomOptions: {
            ...(columnHeaders[e].hasOwnProperty('editable') && {
              editable: columnHeaders[e].editable,
            }),
            ...(columnHeaders[e].editType && {
              editType: columnHeaders[e].editType,
            }),
            ...(columnHeaders[e].mandatory && {
              mandatory: columnHeaders[e].mandatory,
            }),
            ...(columnHeaders[e].selectValues && {
              selectValues: columnHeaders[e].selectValues,
            }),
            ...(columnHeaders[e].dateFormat && {
              dateFormat: columnHeaders[e].dateFormat,
            }),
            ...(columnHeaders[e].arrayValues && {
              arrayValues: columnHeaders[e].arrayValues,
            }),
            ...(columnHeaders[e].mandatoryLanguage && {
              mandatoryLanguage: columnHeaders[e].mandatoryLanguage,
            }),
          },
        }
      }),
    ],
    [
      displaySelect,
      data,
      selectedRows,
      store.TableStore.selectedRowId.length,
      store.TableStore.allSelectedRows,
      store.TableStore.selectedRowId,
      store.TableStore.selectedExpanded,
      store.TableStore.expandedRows,
    ]
  )

  const defaultColumn = useMemo(
    () => ({
      Filter: DefaultColumnFilter,
      minWidth: 60,
      width: 70,
      maxWidth: 400,
      Cell: (cell: Cell) => <TableDefaultCellComponent cell={cell} />,
      headerCustomOptions: {
        sort: {
          desc: true,
        },
        settings: {
          wrapText: true,
          color: 'transparent',
          border: false,
        },
      },
      editCustomOptions: {},
    }),
    [store.TableStore.selectedExpanded]
  )

  type RowProps = {
    getToggleRowSelectedProps?: (
      props?: Partial<TableToggleRowsSelectedProps>
    ) => TableToggleRowsSelectedProps
    isExpanded?: boolean
    original?: boolean
    row: Row
  }

  const tableInstance = useTable(
    {
      columns,
      data,
      defaultColumn,
      initialState: {
        hiddenColumns: store.ViewStore.hiddenColumns,
        columnOrder: store.ViewStore.columnOrder,
        selectedRowIds: handleDefaultRowSelect(),
        expanded: handleDefaultExpanded(),
      },
    },
    useFilters,
    useGlobalFilter,
    useColumnOrder,
    useGroupBy,
    useSortBy,
    useExpanded,
    useRowSelect,
    useResizeColumns,
    useFlexLayout,
    (hooks) => {
      if (displaySelect)
        hooks.visibleColumns.push((columns: ColumnInstance<object>[]) => [
          {
            id: 'selection',
            disableSortBy: true,
            editCustomOptions: {},
            minWidth: 100,
            width: 100,
            maxWidth: 100,
            Header: ({
              getToggleAllRowsSelectedProps,
            }: HeaderProps<object>) => (
              <TableHeaderCheckboxes
                getToggleAllRowsSelectedProps={getToggleAllRowsSelectedProps}
                actions={actions}
              />
            ),
            Cell: (props: any) => (
              <TableCellFirstColumn
                row={props.row}
                rowData={props.data[props.row.id.toString()]}
                displayTooltip={displayTooltip}
                displayEntry={displayEntry}
                displayExpand={displayExpand}
              />
            ),
          },
          ...columns,
        ])
      if (actions)
        hooks.visibleColumns.push((columns: ColumnInstance<object>[]) => [
          ...columns,
          {
            id: '_options',
            minWidth: 40,
            width: 40,
            maxWidth: 40,
            disableSortBy: true,
            editCustomOptions: {},
            Cell: ({ row }: RowProps) => {
              if (actions)
                return (
                  <TableActionsComponent
                    tableName={tableName}
                    actions={actions}
                    loaded={loaded}
                    columns={Object.keys(columnHeaders)}
                    row={row}
                    fetchData={fetchData}
                  />
                )
              else return <></>
            },
          },
        ])
    }
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    rows,
    prepareRow,
    setColumnOrder,
    setSortBy,
    selectedFlatRows,
    state: {
      globalFilter,
      selectedRowIds,
      columnResizing,
      columnOrder,
      hiddenColumns,
    },
    preGlobalFilteredRows,
    setGlobalFilter,
    getToggleHideAllColumnsProps,
    allColumns,
  } = tableInstance

  useEffect(() => {
    setSelectedRows(selectedRowIds)
    return () => store.ViewStore.clearValues()
  }, [])

  useEffect(() => {
    store.ViewStore.setColumnOrder(columnOrder)
    store.ViewStore.setHiddenColumns(hiddenColumns)
  }, [columnOrder, hiddenColumns])

  useEffect(() => {
    if (Object.keys(columnResizing.columnWidths).length > 0) {
      localStorage.setItem(
        `${store.RouteStore.currentPage}-columnWidths`,
        JSON.stringify(columnResizing.columnWidths)
      )
    }
  }, [columnResizing])

  useEffect(() => {
    if (Object.keys(selectedRowIds).length !== 0)
      store.TableStore.setSelectedExpanded([])
  }, [Object.keys(selectedRowIds).length])

  useEffect(() => {
    localStorage.setItem(
      'state',
      JSON.stringify(tableInstance.state.hiddenColumns)
    )
  }, [tableInstance.state.hiddenColumns])

  useEffect(() => {
    setTableLoaded(true)
  }, [data])

  useEffect(() => {
    let newPageSize = 10
    const handleScroll = () => {
      const { scrollTop, scrollHeight, clientHeight } = document.documentElement
      if (scrollTop + clientHeight >= scrollHeight) {
        //@ts-ignore
        newPageSize = newPageSize + 10
        if (setPagination)
          setPagination(
            //@ts-ignore
            pagination?.tableSize,
            newPageSize,
            pagination?.pageIndex,
            pagination?.totalCount,
            true
          )
      }
    }

    if (store.TableStore.infinitySwitch) {
      window.addEventListener('scroll', handleScroll)
    }

    if (
      store.TableStore.infinitySwitch &&
      document.body.scrollHeight <= window.innerHeight
    ) {
      newPageSize = newPageSize + 10
      if (setPagination)
        setPagination(
          //@ts-ignore
          pagination?.tableSize,
          newPageSize,
          pagination?.pageIndex,
          pagination?.totalCount,
          true
        )
    }

    return () => {
      if (store.TableStore.infinitySwitch) {
        window.removeEventListener('scroll', handleScroll)
      }
    }
  }, [store.TableStore.infinitySwitch])

  return (
    <>
      <MainCont>
        <SearchSwitchWrapper>
          <div>
            {headline.visible ? (
              <Headline title={headline.title} subTitle={headline.subTitle} />
            ) : (
              <></>
            )}
            <Breadcrumb treeArray={breadcrumb} />
          </div>
          <UpperCont>
            {actions?.create ? (
              <SearchFieldButton
                text={`Create New ${actions?.label}`}
                onClick={() => {
                  store.SlidingPanelState.setSelectedSlider(
                    `table-create-${tableName}`
                  )
                }}
                icon={<img src={Plus} />}
              />
            ) : (
              <></>
            )}
            {store.TableStore.appliedQuery.rules.length > 0 &&
            store.RouteStore.currentDataId === '' ? (
              <QueryRulesCont>
                <Tooltip
                  arrow
                  title={`Filters: ${store.TableStore.appliedQuery.rules.map(
                    (e: any) => {
                      if (e.field && e.operator && e.value) {
                        return `${e.field} ${e.operator} ${e.value}, `
                      }
                    }
                  )}`}
                >
                  <div>
                    <SearchFieldButton
                      text={'Clear Filters'}
                      onClick={() => {
                        store.TableStore.clearQuery()
                        store.TableStore.applyQuery(fetchData)
                      }}
                      icon={<img src={ClearFilters} />}
                    />
                  </div>
                </Tooltip>
              </QueryRulesCont>
            ) : (
              <></>
            )}
            {bank ? (
              <TableBankExports
                data={data}
                selectedData={selectedFlatRows.map((d) => d.original)}
              />
            ) : (
              <></>
            )}
            {displaySelect && loaded ? (
              <TableOptionsCont>
                <img
                  onClick={() =>
                    store.SlidingPanelState.setSelectedSlider('optionsList')
                  }
                  src={TableOptions}
                />
              </TableOptionsCont>
            ) : (
              <></>
            )}
          </UpperCont>
        </SearchSwitchWrapper>{' '}
        {loaded && tableLoaded ? (
          <ScrollSync>
            <TableCont
              className={'hide-print'}
              ref={containerRef}
              {...getTableProps({ style: { minWidth: '1px' } })}
              {...Theme.tableStyles.tableCont}
            >
              <TableHeader
                columnWidths={columnResizing}
                headerGroups={headerGroups}
                loaded={loaded}
                pagination={pagination}
                setPagination={setPagination}
                columnWidth={allowColumnResize}
                filterSwitch={store.TableStore.showSearchField}
                search={displaySearch}
                setSortBy={setSortBy}
                preGlobalFilteredRows={preGlobalFilteredRows}
                globalFilter={globalFilter}
                setGlobalFilter={setGlobalFilter}
                change={change}
                toggleChange={toggleChange}
                setColumnOrder={setColumnOrder}
                fetchData={fetchData}
                infinity={store.TableStore.infinitySwitch}
                {...Theme.tableStyles.tableHead}
              />
              <TableBody {...getTableBodyProps()}>
                {rows.map((row, index) => {
                  prepareRow(row)
                  return (
                    <TableRowWrapper expanded={row.isExpanded} key={row.id}>
                      <ScrollSyncPane>
                        <TableRow
                          {...row.getRowProps({ style: { minWidth: '1px' } })}
                          select={row.isSelected}
                          odd={index % 2 == 0}
                          key={'row' + index}
                          {...Theme.tableStyles.tableRow}
                        >
                          {row.cells.map((cell: Cell, idx: number) => (
                            <TableCell
                              cell={cell}
                              key={idx}
                              key2={idx}
                              column={columns[index]}
                              row={row}
                            />
                          ))}
                        </TableRow>
                      </ScrollSyncPane>
                      {row.isExpanded && !row.isGrouped && (
                        <TableRendered
                          id={
                            store.TableStore.pagination[
                              store.RouteStore.currentPage
                            ].pageIndex *
                              store.TableStore.pagination[
                                store.RouteStore.currentPage
                              ].pageSize +
                            index
                          }
                          row={row}
                          toggleAllRowsSelected={
                            tableInstance.toggleAllRowsSelected
                          }
                          selectedMainRows={Object.keys(selectedRowIds).length}
                          //@ts-ignore
                          data={data[index].products}
                        />
                      )}
                    </TableRowWrapper>
                  )
                })}
              </TableBody>
              <TableFooterWrap
                selected={
                  store.TableStore.selectedRowId.length > 0 ||
                  store.TableStore.selectedExpanded.length > 0 ||
                  store.TableStore.allSelectedRows
                }
              >
                {footerGroups.map((footerGroup: any, index) => {
                  return (
                    <ScrollSyncPane key={'footergroup' + index}>
                      <TableFooter
                        {...footerGroup.getFooterGroupProps({
                          style: { minWidth: '1px' },
                        })}
                      >
                        {footerGroup.headers.map((columns: any, i: number) => {
                          return (
                            <div
                              key={`footer-${i}`}
                              {...columns.getFooterProps()}
                            ></div>
                          )
                        })}
                      </TableFooter>
                    </ScrollSyncPane>
                  )
                })}
              </TableFooterWrap>
            </TableCont>
          </ScrollSync>
        ) : (
          <Wrap>
            <Container></Container>
          </Wrap>
        )}
      </MainCont>
      {displayPaginator && pagination && setPagination ? (
        <Paginator
          pagination={pagination}
          loaded={loaded}
          setPagination={setPagination}
          infinity={store.TableStore.infinitySwitch}
        />
      ) : (
        <></>
      )}
      {displaySelect ? (
        <Footer
          toggleAllRowsSelected={tableInstance.toggleAllRowsSelected}
          tableName={tableName}
          selectedRows={JSON.stringify(
            { ...selectedFlatRows.map((d) => d.original) },
            null,
            2
          )}
          columns={columns}
          fetchData={fetchData}
          actions={actions}
        />
      ) : (
        <></>
      )}
      {actions?.create ? (
        <SlidingPanel
          contentId={`table-create-${tableName}`}
          title={`Create New ${actions?.label}`}
          icon={FilterIcon}
        >
          <CreateGeneric
            tableName={tableName}
            url={actions.create}
            columns={columns}
            name={'Create'}
            fetchData={fetchData}
          />
        </SlidingPanel>
      ) : (
        <></>
      )}
      {displaySearch ? (
        <>
          <SlidingPanel
            contentId={'optionsList'}
            title={'Options List'}
            icon={FilterIcon}
          >
            <Menu
              mode='inline'
              style={{ margin: 0, width: '100%' }}
              expandIcon={<IconChevronDown size='18px' />}
              defaultOpenKeys={['1', '3']}
            >
              <SubMenu title={locales.options.tableButtons} key={'1'}>
                <SwitchStyled {...Theme.tableStyles.switchStyled}>
                  <SwitchCont>
                    <SwitchInput
                      onColor={'#e4bf71'}
                      offColor={'#f0dcb1'}
                      checked={!store.TableStore.showSearchField}
                      onChange={() =>
                        store.TableStore.setShowSearchField(
                          !store.TableStore.showSearchField
                        )
                      }
                      uncheckedIcon={false}
                      checkedIcon={false}
                      height={20}
                      width={42}
                      handleDiameter={16}
                    />{' '}
                    <SwitchInputText>{locales.actions.search}</SwitchInputText>
                  </SwitchCont>
                  {displayInfinitySwitch ? (
                    <SwitchCont>
                      <SwitchInput
                        onColor={'#e4bf71'}
                        offColor={'#f0dcb1'}
                        checked={store.TableStore.infinitySwitch}
                        onChange={() =>
                          store.TableStore.setInfinitySwitch(
                            !store.TableStore.infinitySwitch
                          )
                        }
                        uncheckedIcon={false}
                        checkedIcon={false}
                        height={20}
                        width={42}
                        handleDiameter={16}
                      />{' '}
                      <SwitchInputText>{'Set Infinity Scroll'}</SwitchInputText>
                    </SwitchCont>
                  ) : (
                    <></>
                  )}
                </SwitchStyled>
              </SubMenu>
              <SubMenu title={locales.options.columnVisibility} key={'2'}>
                {columnVisibility ? (
                  <ColumnVisibility
                    getToggleHideAllColumnsProps={getToggleHideAllColumnsProps}
                    allColumns={allColumns}
                  />
                ) : null}
              </SubMenu>
              <SubMenu title={locales.options.queryFilers} key={'3'}>
                <QueryFilter allColumns={allColumns} fetchData={fetchData} />
              </SubMenu>
              {importSelect ? (
                <SubMenu title={'Select Rows by File'} key={'4'}>
                  <ImportSelectComponent allColumns={allColumns} />
                </SubMenu>
              ) : (
                <></>
              )}
              {views ? (
                <SubMenu title={'Manage Views'} key={'5'}>
                  <Views
                    tableName={tableName}
                    pagination={pagination?.pageSize}
                    fetchData={fetchData}
                  />
                </SubMenu>
              ) : (
                <></>
              )}
            </Menu>
          </SlidingPanel>
          {store.TableStore.mobileOptions && (
            <MobileOptions>
              <ColumnVisibility
                getToggleHideAllColumnsProps={getToggleHideAllColumnsProps}
                allColumns={allColumns}
                mobile={true}
              />
              <MobileBreakLine />
              <QueryFilter
                allColumns={allColumns}
                fetchData={fetchData}
                mobile={true}
              />
            </MobileOptions>
          )}
        </>
      ) : null}
    </>
  )
})
export default Table
