import { Container } from './tableCompleteable.styles'
import { observer } from 'mobx-react'
import { useContext } from 'react'
import { StoreContext } from '../../../App'

interface TableCompleteableInterface {
  value: number | null
}
const TableCompleteable = observer(({ value }: TableCompleteableInterface) => {
  const store = useContext(StoreContext)
  const locales = store.TranslationsState.translations
  const MapStatuses = () => {
    if (Number.isNaN(value)) {
      return ''
    }
    switch (value) {
      case 0:
        return `${locales.packable.allMissing} (0)`
      case 1:
        return `${locales.packable.someMissing} (1)`
      case 2:
        return `${locales.packable.packable} (2)`
      case null:
        return ''
      default:
        return `${locales.packable.incorrectValue} (${value})`
    }
  }
  const getColor = () => {
    switch (value) {
      case 2:
        return 'rgba(0,255,0,0.4)'
      case 1:
        return 'rgba(255,255,0,0.4)'
      case 0:
        return 'rgba(255,0,0,0.4)'
      default:
        return 'transparent'
    }
  }
  return <Container color={getColor()}>{MapStatuses()}</Container>
})
export default TableCompleteable
