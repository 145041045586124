import React, { useContext, useEffect } from 'react'
import {
  CalendarFilterCont,
  Container,
  Day,
  Header,
  HeaderCenter,
  HeaderLeft,
  HeaderRight,
  PrimaryButton,
  Row,
  SearchFieldCont,
  TitleText,
  ViewButton,
} from './Calendar.styles'
import { StoreContext } from '../../../../App'
import { observer } from 'mobx-react'
import CalendarDay from '../calendarDay/CalendarDay.component'
import CalendarWeek from '../calendarWeek/CalendarWeek.component'
import CalendarMonths from '../calendarMonth/CalendarMonth.component'
import {
  Container as LoadingContainer,
  Wrap,
} from '../../../../styles/Loading.styles'
import TableOptions from '../../../../assets/icons/table-options.svg'
import { TableOptionsCont } from '../../../TableGeneric/table/Table.styles'
import FilterIcon from '../../../../assets/icons/filter-icon.svg'
import Menu, { SubMenu } from 'rc-menu'
import { IconChevronDown, IconSquareChevronDown } from '@tabler/icons-react'
import { SlidingPanel } from '../../../slidingContainer/SlidingContainer.component'
import CalendarVisibility from '../calendarVisibility/CalendarVisibility.component'
import AddProductSearchComponent from '../../../addProductSearch/AddProductSearch.component'
import SearchFieldButton from '../../../TableGeneric/searchFieldButton/SearchFieldButton.component'
import ClearFilters from '../../../../assets/icons/clear-filters.svg'

const Calendar = observer(() => {
  const store = useContext(StoreContext)
  const { Theme } = store.Theme
  const currentDate = store.CalendarStore.currentDate
  const currentView = store.CalendarStore.view

  const dayNames = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ]
  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ]

  const getTitle = () => {
    switch (currentView) {
      case 'day':
        return `${dayNames[currentDate.getDay()]}, ${
          monthNames[currentDate.getMonth()]
        } ${currentDate.getDate()}, ${currentDate.getFullYear()}`
      case 'week': {
        const startOfWeekDate = new Date(currentDate)
        startOfWeekDate.setDate(
          startOfWeekDate.getDate() - startOfWeekDate.getDay()
        )
        const endOfWeekDate = new Date(startOfWeekDate)
        endOfWeekDate.setDate(endOfWeekDate.getDate() + 6)

        return `${startOfWeekDate.getDate()} ${
          monthNames[startOfWeekDate.getMonth()]
        } - ${endOfWeekDate.getDate()} ${
          monthNames[endOfWeekDate.getMonth()]
        } ${endOfWeekDate.getFullYear()}`
      }
      case 'month':
      default:
        return `${
          monthNames[currentDate.getMonth()]
        } ${currentDate.getFullYear()}`
    }
  }

  const renderView = () => {
    switch (currentView) {
      case 'day':
        return <CalendarDay />
      case 'week':
        return <CalendarWeek />
      case 'month':
        return <CalendarMonths />
      default:
        return <CalendarMonths />
    }
  }

  return (
    <Container>
      <Header>
        <HeaderLeft>
          <ViewButton
            selected={store.CalendarStore.view === 'day'}
            view={'left'}
            onClick={() => store.CalendarStore.setView('day')}
          >
            Day
          </ViewButton>
          <ViewButton
            selected={store.CalendarStore.view === 'week'}
            onClick={() => store.CalendarStore.setView('week')}
          >
            Week
          </ViewButton>
          <ViewButton
            view={'right'}
            selected={store.CalendarStore.view === 'month'}
            onClick={() => store.CalendarStore.setView('month')}
          >
            Month
          </ViewButton>
        </HeaderLeft>
        <HeaderCenter>
          <PrimaryButton
            onClick={() => store.CalendarStore.changeDate(-1, currentView)}
            {...Theme.buttons.primaryButton}
          >
            {'<'}
          </PrimaryButton>
          <TitleText>{getTitle()}</TitleText>
          <PrimaryButton
            onClick={() => store.CalendarStore.changeDate(1, currentView)}
            {...Theme.buttons.primaryButton}
          >
            {'>'}
          </PrimaryButton>
        </HeaderCenter>
        <HeaderRight>
          {store.CalendarStore.filterProductId ? (
            <SearchFieldCont>
              <SearchFieldButton
                text={'Clear Filter Product'}
                onClick={() => {
                  store.CalendarStore.setFilterProductId('')
                }}
                icon={<img src={ClearFilters} />}
              />
            </SearchFieldCont>
          ) : (
            <></>
          )}
          <ViewButton
            selected={true}
            onClick={() => store.CalendarStore.resetDate()}
          >
            Today
          </ViewButton>
          <TableOptionsCont>
            <img
              onClick={() =>
                store.SlidingPanelState.setSelectedSlider('calendarOptionsList')
              }
              src={TableOptions}
            />
          </TableOptionsCont>
        </HeaderRight>
      </Header>
      <SlidingPanel
        contentId={'calendarOptionsList'}
        title={'Calendar Options List'}
        icon={FilterIcon}
      >
        <Menu
          mode='inline'
          style={{ margin: 0, width: '100%' }}
          expandIcon={<IconChevronDown size='18px' />}
          defaultOpenKeys={['1']}
        >
          <SubMenu title={'Options'} key={'1'}>
            <CalendarVisibility />
          </SubMenu>
          <SubMenu title={'Filter'} key={'2'}>
            <CalendarFilterCont>
              <AddProductSearchComponent
                applySearch={(product) => {
                  store.CalendarStore.setFilterProductId(product.id)
                }}
              />
              {store.CalendarStore.filterProductId ? (
                <SearchFieldCont>
                  <SearchFieldButton
                    text={'Clear Filter Product'}
                    onClick={() => {
                      store.CalendarStore.setFilterProductId('')
                    }}
                    icon={<img src={ClearFilters} />}
                  />
                </SearchFieldCont>
              ) : (
                <></>
              )}
            </CalendarFilterCont>
          </SubMenu>
        </Menu>
      </SlidingPanel>
      {store.CalendarStore.loaded ? (
        renderView()
      ) : (
        <LoadingContainer>
          <Wrap></Wrap>
        </LoadingContainer>
      )}
    </Container>
  )
})

export default Calendar
