/* eslint-disable */
import { observer } from 'mobx-react'
import { useContext } from 'react'
import { StoreContext } from '../../App'
import { Container } from './MoodNookLogo.styles'

export const MoodNookLogo = observer(() => {
  const store = useContext(StoreContext)
  const { Theme } = store.Theme

  const handleClick = () => {
    // Loop through localStorage keys
    for (const key in localStorage) {
      if (
        key.endsWith('-query') ||
        key.endsWith('-appliedQuery') ||
        key.endsWith('-sort') ||
        key.endsWith('-columnWidths') ||
        key.startsWith('color') ||
        key.startsWith('border') ||
        key === 'state'
      ) {
        localStorage.removeItem(key)
      }
    }
    store.TableStore.setPaginationToDefault()
  }

  return (
    <Container onClick={handleClick} {...Theme.moodNookLogo.container}>
      <svg
        version='1.1'
        id='Warstwa_1'
        xmlns='http://www.w3.org/2000/svg'
        x='0px'
        y='0px'
        viewBox='0 0 246.6 177.9'
      >
        <g>
          <path
            className='st0'
            fill='#E0FBFC'
            d='M246.6,177.9H0V0h246.6V177.9z M14.2,163.8h218.2V14.2H14.2V163.8z'
          />
        </g>
        <g>
          <path
            className='st0'
            fill='#E0FBFC'
            d='M29.8,120.4V79.9h10.5l19.3,24.8h0.1V79.9h10.5v40.5H59.7L40.4,95.6h-0.1v24.8H29.8z'
          />
          <path
            className='st0'
            fill='#E0FBFC'
            d='M88.2,81.1c3.4-1.7,7.1-2.6,11-2.6c3.9,0,7.6,0.9,11,2.6c3.4,1.7,6.2,4.2,8.3,7.4c2.1,3.2,3.2,6.8,3.2,10.8
		c0,6.6-2.1,11.9-6.3,16.1c-4.2,4.2-9.6,6.3-16.1,6.3c-6.5,0-11.9-2.1-16.1-6.3c-4.2-4.2-6.3-9.5-6.3-16.1c0-4,1.1-7.6,3.2-10.8
		C82,85.4,84.8,82.9,88.2,81.1z M107.2,92c-2.3-2.1-4.9-3.2-8.1-3.2s-5.8,1.1-8.1,3.2c-2.3,2.1-3.4,4.6-3.4,7.5
		c0,3.4,1.1,6.3,3.3,8.6c2.2,2.3,4.9,3.5,8.1,3.5c3.2,0,5.9-1.2,8.1-3.5c2.2-2.3,3.3-5.2,3.3-8.6C110.6,96.6,109.5,94.1,107.2,92z'
          />
          <path
            className='st0'
            fill='#E0FBFC'
            d='M137.5,81.1c3.4-1.7,7.1-2.6,11-2.6s7.6,0.9,11,2.6c3.4,1.7,6.2,4.2,8.3,7.4c2.1,3.2,3.2,6.8,3.2,10.8
		c0,6.6-2.1,11.9-6.3,16.1c-4.2,4.2-9.6,6.3-16.1,6.3c-6.5,0-11.9-2.1-16.1-6.3c-4.2-4.2-6.3-9.5-6.3-16.1c0-4,1.1-7.6,3.2-10.8
		C131.3,85.4,134.1,82.9,137.5,81.1z M156.5,92c-2.3-2.1-4.9-3.2-8.1-3.2s-5.8,1.1-8.1,3.2c-2.3,2.1-3.4,4.6-3.4,7.5
		c0,3.4,1.1,6.3,3.3,8.6c2.2,2.3,4.9,3.5,8.1,3.5c3.2,0,5.9-1.2,8.1-3.5c2.2-2.3,3.3-5.2,3.3-8.6C159.9,96.6,158.8,94.1,156.5,92z'
          />
          <path
            className='st0'
            fill='#E0FBFC'
            d='M188.1,96.6l12.7-16.7h13.1l-16.1,19.2l17.6,21.3h-13.6l-13.6-17.6H188v17.6h-10.5V79.9H188v16.7H188.1z'
          />
        </g>
        <g>
          <path
            className='st0'
            fill='#E0FBFC'
            d='M30,70.1l6.9-40.5h10.4l8.2,21.7l8.7-21.7h10.5l6.1,40.5H70.3l-3-23.3h-0.1l-9.7,23.3h-4.2L44,46.7h-0.1
		l-3.4,23.3H30z'
          />
          <path
            className='st0'
            fill='#E0FBFC'
            d='M94.7,30.8c3.4-1.7,7.1-2.6,11-2.6c3.9,0,7.6,0.9,11,2.6c3.4,1.7,6.2,4.2,8.3,7.4c2.1,3.2,3.2,6.8,3.2,10.8
		c0,6.6-2.1,11.9-6.3,16.1c-4.2,4.2-9.6,6.3-16.1,6.3c-6.5,0-11.9-2.1-16.1-6.3c-4.2-4.2-6.3-9.5-6.3-16.1c0-4,1.1-7.6,3.2-10.8
		C88.5,35,91.3,32.6,94.7,30.8z M113.7,41.6c-2.3-2.1-4.9-3.2-8.1-3.2s-5.8,1.1-8.1,3.2c-2.3,2.1-3.4,4.6-3.4,7.5
		c0,3.4,1.1,6.3,3.3,8.6c2.2,2.3,4.9,3.5,8.1,3.5c3.2,0,5.9-1.2,8.1-3.5c2.2-2.3,3.3-5.2,3.3-8.6C117.1,46.3,115.9,43.8,113.7,41.6z
		'
          />
          <path
            className='st0'
            fill='#E0FBFC'
            d='M142.3,30.8c3.4-1.7,7.1-2.6,11-2.6s7.6,0.9,11,2.6c3.4,1.7,6.2,4.2,8.3,7.4c2.1,3.2,3.2,6.8,3.2,10.8
		c0,6.6-2.1,11.9-6.3,16.1c-4.2,4.2-9.6,6.3-16.1,6.3c-6.5,0-11.9-2.1-16.1-6.3c-4.2-4.2-6.3-9.5-6.3-16.1c0-4,1.1-7.6,3.2-10.8
		C136.2,35,138.9,32.6,142.3,30.8z M161.3,41.6c-2.3-2.1-4.9-3.2-8.1-3.2s-5.8,1.1-8.1,3.2c-2.3,2.1-3.4,4.6-3.4,7.5
		c0,3.4,1.1,6.3,3.3,8.6c2.2,2.3,4.9,3.5,8.1,3.5c3.2,0,5.9-1.2,8.1-3.5c2.2-2.3,3.3-5.2,3.3-8.6C164.7,46.3,163.6,43.8,161.3,41.6z
		'
          />
          <path
            className='st0'
            fill='#E0FBFC'
            d='M180.6,70.1V29.6h14.9c5.7,0,10.5,2,14.5,5.9c4,3.9,6,8.7,6,14.4c0,5.7-2,10.5-6,14.4c-4,3.9-8.9,5.9-14.5,5.9
		H180.6z M191.1,38.5v22.7h2.4c3.4,0,6.2-0.9,8.4-2.8c2.2-1.9,3.3-4.7,3.3-8.5c0-3.5-1.1-6.3-3.2-8.3c-2.1-2-4.9-3-8.4-3H191.1z'
          />
        </g>
      </svg>
    </Container>
  )
})
