import Breadcrumb from '../../components/breadcrumb/Breadcrumb.component'
import StockTable from '../../components/stockTable/StockTable.component'
import { Container } from '../../styles/LayoutStyles'

const Stock = () => {
  return (
    <Container>
      <StockTable />
    </Container>
  )
}
export default Stock
