import styled from 'styled-components'
import { darken } from 'polished'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  width: 100%;
  height: 100%;
`
export const Header = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
`

export const HeaderLeft = styled.div`
  display: flex;
`
export const HeaderCenter = styled.div`
  display: flex;
`
export const HeaderRight = styled.div`
  display: flex;
  align-items: center;

  div {
    margin-left: 8px;
  }

  > :first-child {
    border-radius: 6px;
  }
`

interface PrimaryButtonProps {
  padding?: string
  bgColor?: string
  borderRadius?: string
  fontSize?: string
  fontWeight?: number
  lineHeight?: string
  letterSpacing?: string
  color?: string
}

export const PrimaryButton = styled.div<PrimaryButtonProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: fit-content;
  width: fit-content;
  cursor: pointer;
  padding: ${(props) => (props.padding ? props.padding : '4px 8px')};
  background-color: ${(props) => (props.bgColor ? props.bgColor : '#303030')};
  border-radius: ${(props) =>
    props.borderRadius ? props.borderRadius : '6px'};
  white-space: nowrap;
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : 600)};
  font-size: ${(props) => (props.fontSize ? props.fontSize : '24px')};
  line-height: ${(props) => (props.lineHeight ? props.lineHeight : '24px')};
  letter-spacing: ${(props) =>
    props.letterSpacing ? props.letterSpacing : '0.5px'};
  text-transform: uppercase;
  color: ${(props) => (props.color ? props.color : '#fafafa')};
  margin: 8px;

  transition: background-color 0.3s, transform 0.2s;

  &:hover {
    background-color: ${(props) =>
    darken(0.1, props.bgColor ? props.bgColor : '#303030')};
    transform: scale(1.02);
  }

  &:active {
    transform: scale(0.98);
  }
`

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`
export const Day = styled.div`
  /* your styles here */
`

export const TitleText = styled.p`
  font-size: 24px;
  font-weight: 700;
  margin: 8px;
`

interface ViewButtonInterface {
  selected: boolean
  view?: 'left' | 'right'
}

/* eslint-disable */
export const ViewButton = styled.button<ViewButtonInterface>`
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: #303030;
  padding: 8px 20px;
  border-radius: ${(props) =>
    props.view === 'left'
      ? '6px 0 0 6px'
      : props.view === 'right'
      ? '0 6px 6px 0'
      : '0'};
  border: 1px solid ${(props) => (!props.selected ? '#575757' : '#D6D6D6')};
  background-color: ${(props) => (!props.selected ? '#F5F5F5' : '#FFFFFF')};
`
export const CalendarFilterCont = styled.div`
  display: flex;
  flex-direction: column;

  > * {
    margin: 4px;
  }
`
export const SearchFieldCont = styled.div`
  margin-left: 16px;
`
