import React, { useContext, useEffect, useState } from 'react'
import { StoreContext } from '../../../App'
import { observer } from 'mobx-react'
import AddProductSearchComponent from '../../addProductSearch/AddProductSearch.component'
import {
  Container as LoadingContainer,
  Wrap,
} from '../../../styles/Loading.styles'
import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineOppositeContent,
} from '@material-ui/lab'
import { Order } from '../../../stores/Calendar.store'
import { Typography } from '@mui/material'
import {
  Container,
  IconCont,
  TimelineDotStyled,
  TitleText,
} from './Timeline.styles'

import OrderCreation from '../../../assets/icons/plus.svg'
import EstimationDate from '../../../assets/icons/estimate.svg'
import DeliveryDate from '../../../assets/icons/delivery.svg'
import { NavLink } from 'react-router-dom'

const TimelineComponent: React.FC = observer(() => {
  const store = useContext(StoreContext)
  const locales = store.TranslationsState.translations

  const [productID, setProductID] = useState('')
  const [product, setProduct] = useState<any>({})

  // Filter orders to only those that contain the product
  const ordersWithProduct = store.CalendarStore.allData.filter((order) =>
    Object.prototype.hasOwnProperty.call(order.products, productID)
  )

  // Merge all actions into one array and sort them by date
  const actions: {
    type:
      | 'Supplier Order Creation'
      | 'Last Supplier Order Edit'
      | 'Delivery Date'
      | 'Product Creation'
      | 'Last Product Edit'
    date: string
    order?: Order
    product?: any
  }[] = []

  const getIcon = (type: string) => {
    switch (type) {
      case 'Supplier Order Creation':
      case 'Product Creation':
        return <IconCont src={OrderCreation} />
      case 'Last Supplier Order Edit':
        return <IconCont src={EstimationDate} />
      case 'Last Product Edit':
        return <IconCont src={DeliveryDate} />
      default:
        return <IconCont src={OrderCreation} />
    }
  }

  const getBackgroundColor = (type: string) => {
    switch (type) {
      case 'Supplier Order Creation':
        return '#cdaa6e'
      case 'Last Supplier Order Edit':
        return 'blue'
      case 'Delivery Date':
        return '#234F1e'
      case 'Product Creation':
        return '#f4c2c2'
      case 'Last Product Edit':
        return '#dfc98a'
      default:
        return '#cdaa6e'
    }
  }

  product.addDate &&
    actions.push({
      type: 'Product Creation',
      date: product.addDate,
      product,
    })

  product.lastEditDate &&
    actions.push({
      type: 'Last Product Edit',
      date: product.lastEditDate,
      product,
    })

  ordersWithProduct.forEach((order) => {
    order.creationDate &&
      actions.push({
        type: 'Supplier Order Creation',
        date: order.creationDate,
        order,
      })
    order.lastEditDate &&
      actions.push({
        type: 'Last Supplier Order Edit',
        date: order.estimateDate,
        order,
      })
  })

  actions.sort(
    (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()
  )

  const TimeLineOrder = (action: {
    type:
      | 'Supplier Order Creation'
      | 'Last Supplier Order Edit'
      | 'Delivery Date'
      | 'Product Creation'
      | 'Last Product Edit'
    date: string
    order?: Order
    product?: any
  }) => {
    if (action.order !== undefined)
      return (
        <TimelineItem>
          <TimelineOppositeContent>
            <Typography color='textSecondary'>{action.date}</Typography>
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineDotStyled bgColor={getBackgroundColor(action.type)}>
              {getIcon(action.type)}
            </TimelineDotStyled>
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent>
            <Typography variant='h6' component='span'>
              {action.type}
            </Typography>
            <Typography color='textSecondary'>
              <NavLink
                to={'/supplierorders'}
                onClick={() => {
                  localStorage.setItem(
                    'suppliers-query',
                    JSON.stringify({
                      rules: [
                        {
                          field: 'supplierOrderId',
                          operator: '=',
                          valueSource: 'value',
                          value: action.order?.supplierOrderId,
                        },
                      ],
                      combinator: 'and',
                      not: false,
                    })
                  )
                }}
              >
                {action.order.supplierOrderId}
              </NavLink>
            </Typography>
            <Typography color='textSecondary'>
              {`${locales.status[action.order.status]} (${
                action.order.status
              })`}
            </Typography>
          </TimelineContent>
        </TimelineItem>
      )
    else if (action.product)
      return (
        <TimelineItem>
          <TimelineOppositeContent>
            <Typography color='textSecondary'>{action.date}</Typography>
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineDotStyled bgColor={getBackgroundColor(action.type)}>
              {getIcon(action.type)}
            </TimelineDotStyled>
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent>
            <Typography variant='h6' component='span'>
              {action.type}
            </Typography>
            <Typography color='textSecondary'>{action.product.name}</Typography>
          </TimelineContent>
        </TimelineItem>
      )
    else return <></>
  }

  return (
    <Container>
      {store.CalendarStore.loaded ? (
        <>
          <AddProductSearchComponent
            applySearch={(product) => {
              setProductID(product.id)
              setProduct(product)
            }}
            clearInputValue={true}
          />
          <Timeline align='alternate'>
            {productID.length ? (
              <TitleText>
                Product history: {productID}, {product.name}
              </TitleText>
            ) : (
              <></>
            )}
            {actions.length ? (
              actions.map((action, index) => (
                <TimeLineOrder key={index} {...action} />
              ))
            ) : (
              <TitleText>{'There is no data to display!'}</TitleText>
            )}
          </Timeline>
        </>
      ) : (
        <LoadingContainer>
          <Wrap></Wrap>
        </LoadingContainer>
      )}
    </Container>
  )
})

export default TimelineComponent
