import { observer } from 'mobx-react'
import {
  ChangeEvent,
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from 'react'
import { StoreContext } from '../../App'
import { hiddenColumns } from '../../columnsConfig'
import { validate } from '../../static/validate'
import {
  Checkbox,
  Container,
  ErrorMessage,
  FilterLabel,
  Input,
  InputCont,
  StyledSelect,
} from './DataInput.styles'

interface InnerProps extends PropsWithChildren {
  vals: { header: string; value: string }
  form: Array<{
    header: string
    value: any
    error: string
    rules: Array<string>
  }>
  index: number
  setForm: (
    val: Array<{
      header: string
      value: string
      error: string
      rules: Array<string>
    }>
  ) => void
  triggerChange?: () => void
}

export const DataInput = observer(
  ({ vals, index, form, setForm, triggerChange }: InnerProps) => {
    const store = useContext(StoreContext)
    const { Theme } = store.Theme
    const locales = store.TranslationsState.translations
    const [active, setActive] = useState(false)
    const [type, setType] = useState('text')

    const changeHandler = (val: string) => {
      const error = validate(form[index].rules, val)
      const tempForm = form
      tempForm[index] = {
        header: vals.header,
        value: val,
        error: error,
        rules: form[index].rules,
      }
      //console.log(tempForm[index])
      setForm(tempForm)
    }
    /* eslint-disable */
    const getType = (header: string, value: string) => {
      const dateArray = [
        'ordered',
        'creationDate',
        'lastEditDate',
        'availabilityDate',
        'SupplierDeliveryDate',
      ]
      const numberArray = [
        'stock',
        'stockAmountInPackage',
        'minimumWarnLevel',
        'secureWarnLevel',
        'maximumWarnLevel',
        'quantityToOrder',
        'minimumQuantityForSupplierOrder',
        'salesPriceBrutto',
        'salesPriceNetto',
        'defaultSupplierPrice',
        'wfirma_vat_id',
        'wfirma_id',
        'OrderedQuantity',
        'DeliveredQuantity',
        'priority',
      ]
      const dayArray = ['SupplierDeliveryDate', 'DeliveredDate', 'estimateDate']

      if (numberArray.includes(header)) return 'number'
      if (dayArray.includes(header)) return 'date'
      if (dateArray.includes(header)) return 'datetime-local'
      return 'text'
    }

    const shouldSelect = (header: string) => {
      const SelectArray = [
        'producer',
        'status',
        'supplier',
        'completable',
        'completableMicroserviceInt',
        'stockAmountInPackageBool',
        'active',
        'purchasable',
        'tag_size',
        'tag_icon',
        'icon',
        'avaliabilityByProducerID',
      ]
      if (
        header === 'status' &&
        store.RouteStore.currentPage === 'customerorders'
      ) {
        return false
      }
      let flag = false
      SelectArray.map((e: string) => {
        if (header === e) flag = true
      })
      return flag
    }
    const getSelectedValues = (header: string, value: any) => {
      if (header === 'completable' || header === 'completableMicroserviceInt') {
        return (
          <>
            <option value={''}>{''}</option>
            <option value={0} selected={value === 0 || value === '0'}>
              {`${locales.packable.allMissing} (0)`}
            </option>
            <option value={1} selected={value === 1 || value === '1'}>
              {`${locales.packable.someMissing} (1)`}
            </option>
            <option value={2} selected={value === 2 || value === '2'}>
              {`${locales.packable.packable} (2)`}
            </option>
          </>
        )
      }
      if (header === 'status') {
        return (
          <>
            {Object.entries(locales.status).map(([key, statusLabel]) => {
              if (key !== 'default') {
                return (
                  <option value={key} selected={value === key}>
                    {statusLabel}
                  </option>
                )
              }
            })}
          </>
        )
      }
      if (
        header === 'stockAmountInPackageBool' ||
        header === 'active' ||
        header === 'purchasable'
      ) {
        return (
          <>
            <option value={'true'} selected={value === true}>
              {'True'}
            </option>
            <option value={'false'} selected={value === false}>
              {'False'}
            </option>
          </>
        )
      }
      if (header === 'avaliabilityByProducerID') {
        return (
          <>
            {store.StatusesStore.avaliabilityByProducer.map(
              (e: any, index: number) => (
                <option value={e.id} key={`byproducer-option-${index}`}>
                  {e.name.en_GB}
                </option>
              )
            )}
          </>
        )
      }
      const SupplierList = [
        'Furnipart',
        'Beslag Design',
        'Viefe',
        'Skandynawskie Uchwyty',
        'Buster + Punch',
        'Baccman & Berglund Sweden',
        'Open & Hide',
        'Fibaro',
        'Goat Story',
        'Tatlo',
        'LagerForm',
        'Plankton',
        'Haven',
        'Frost',
        'Linfalk',
        'Habo',
        'EJK',
        'Marbelo',
        'PEKA',
        'Toniton',
        'Siro',
        'DOT Manufacture',
        'Bisetti Marella',
        'Vince',
        'Unidrain',
      ]
      if (header === 'supplier' || header === 'producer')
        return (
          <>
            <option value={vals.value} selected={true}>
              {vals.value}
            </option>
            {SupplierList.map((e) => {
              return (
                <option selected={value === e} value={e}>
                  {e}
                </option>
              )
            })}
          </>
        )
      if (header === 'tag_size')
        return (
          <>
            <option value={''}>{''}</option>
            {store.TagsStore.avaliableSizes.map((e) => {
              return (
                <option selected={value === e} value={e}>
                  {e}
                </option>
              )
            })}
          </>
        )
      if (header === 'tag_icon' || header === 'icon')
        return (
          <>
            <option value={''}>{''}</option>
            {store.TagsStore.avaliableIcons.map((e) => {
              return (
                <option value={e} selected={value === e}>
                  {e}
                </option>
              )
            })}
          </>
        )
    }

    const shouldLock = (header: string) => {
      const LockArray = ['id', 'backend_id', 'order', 'author']
      let flag = false
      LockArray.map((e: string) => {
        if (header === e) flag = true
      })
      return flag
    }

    const shouldNotBeVisible = (header: string) => {
      let flag = false
      hiddenColumns.map((e: string) => {
        if (header === e) flag = true
      })
      if (header === 'priority' && store.RouteStore.currentPage === 'admin')
        flag = false
      return flag
    }

    useEffect(() => {
      setType(getType(vals.header, vals.value))
    }, [])

    if (!shouldNotBeVisible(vals.header))
      return (
        <Container>
          <FilterLabel {...Theme.editProductsStyles.filterLabel}>
            {/*{locales[`${store.RouteStore.currentPage}Columns`][vals.header] ||
              vals.header}*/}
            {vals.header}
          </FilterLabel>
          <InputCont>
            {!shouldSelect(vals.header) ? (
              <Input
                id={`input-${store.RouteStore.currentPage}-${vals.header}`}
                type={type}
                disabled={!active || shouldLock(vals.header)}
                activeStyle={active && !shouldLock(vals.header)}
                value={form[index].value || ''}
                onChange={(el: ChangeEvent<HTMLInputElement>) => {
                  const val = el.target.value
                  changeHandler(val)
                  if (triggerChange) {
                    triggerChange()
                  }
                }}
                {...Theme.editProductsStyles.styledInput}
              />
            ) : (
              <StyledSelect
                disabled={!active || shouldLock(vals.header)}
                activeStyle={active && !shouldLock(vals.header)}
                onChange={(el) => {
                  changeHandler(el.target.value)
                  if (triggerChange) {
                    triggerChange()
                  }
                }}
              >
                {getSelectedValues(vals.header, form[index].value)}
              </StyledSelect>
            )}
            <Checkbox
              type={'checkbox'}
              name={`${form[index].header}`}
              onChange={() => {
                setActive(!active)
                store.TableStore.toggleDataInput()
                if (triggerChange) {
                  triggerChange()
                }
              }}
              {...Theme.editProductsStyles.styledCheckbox}
            />
          </InputCont>
          {active ? (
            <ErrorMessage {...Theme.editProductsStyles.errorMessage}>
              {form[index].error}
            </ErrorMessage>
          ) : (
            <></>
          )}
        </Container>
      )
    else return <></>
  }
)
