import React, { useContext } from 'react'
import { observer } from 'mobx-react'
import { StoreContext } from '../../../../App'
/* eslint-disable */
import {
  Container,
  Day,
  HeaderDay,
  HeaderWeek,
  Week,
} from './CalendarMonth.styles'
import { CreateOrders, CreateProducts } from '../calendar/Calendar.methods'

/* eslint-disable */
const chunkIntoWeeks = (array: any[], size: number) => {
  const results = []
  while (array.length) {
    results.push(array.splice(0, size))
  }
  return results
}

const CalendarMonths = observer(() => {
  const store = useContext(StoreContext)
  const { Theme } = store.Theme
  const currentDate = store.CalendarStore.currentDate
  const days = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wedesday',
    'Thursday',
    'Friday',
    'Saturday',
  ]

  const firstDay = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    1
  ).getDay()
  const totalDays = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth() + 1,
    0
  ).getDate()
  const daysArr = new Array(firstDay + totalDays)
    .fill(null)
    .map((_, index) => (index >= firstDay ? index - firstDay + 1 : ''))
  const weeks = chunkIntoWeeks(daysArr, 7)

  return (
    <Container>
      <HeaderWeek>
        {days.map((day) => (
          <HeaderDay key={day} today={false}>
            {day}
          </HeaderDay>
        ))}
      </HeaderWeek>
      {weeks.map((week, i) => (
        <Week key={i}>
          {week.map((day: string | number, j: number) => (
            <Day
              key={j}
              today={
                day === new Date().getDate() &&
                currentDate.getMonth() === new Date().getMonth() &&
                currentDate.getFullYear() === new Date().getFullYear()
              }
            >
              {day ? (
                <>
                  <p>{day}</p>
                  {store.CalendarStore.visibility.createdOrders ? (
                    <CreateOrders
                      title={'Created Orders:'}
                      color={Theme.calendarStyles.popUpColors.createdOrders}
                      dataGetter={() =>
                        store.CalendarStore.getOrderedDataForDate(
                          new Date(
                            currentDate.getFullYear(),
                            currentDate.getMonth(),
                            day as number
                          )
                        )
                      }
                    />
                  ) : (
                    <></>
                  )}
                  {store.CalendarStore.visibility.estimatedOrders ? (
                    <CreateOrders
                      title={'Estimated Delivery Orders:'}
                      color={
                        Theme.calendarStyles.popUpColors.estimatedDeliveryOrders
                      }
                      dataGetter={() =>
                        store.CalendarStore.getDataForDate(
                          new Date(
                            currentDate.getFullYear(),
                            currentDate.getMonth(),
                            day as number
                          )
                        )
                      }
                    />
                  ) : (
                    <></>
                  )}
                  {store.CalendarStore.visibility.deliveredOrders ? (
                    <CreateOrders
                      title={'First Product Delivered from Order:'}
                      color={
                        Theme.calendarStyles.popUpColors.firstProductDelivered
                      }
                      deliveredHover={true}
                      dataGetter={() =>
                        store.CalendarStore.getDeliveredDataForDate(
                          new Date(
                            currentDate.getFullYear(),
                            currentDate.getMonth(),
                            day as number
                          )
                        )
                      }
                    />
                  ) : (
                    <></>
                  )}
                  {store.CalendarStore.visibility.lastDeliveredOrders ? (
                    <CreateOrders
                      title={'Last Product Delivered from Order:'}
                      color={
                        Theme.calendarStyles.popUpColors.lastProductDelivered
                      }
                      deliveredHover={true}
                      dataGetter={() =>
                        store.CalendarStore.getLastDeliveredDataForDate(
                          new Date(
                            currentDate.getFullYear(),
                            currentDate.getMonth(),
                            day as number
                          )
                        )
                      }
                    />
                  ) : (
                    <></>
                  )}
                  {store.CalendarStore.visibility.deliveredProducts ? (
                    <CreateProducts
                      title={'Delivered Products:'}
                      color={Theme.calendarStyles.popUpColors.deliveredProducts}
                      dataGetter={() =>
                        store.CalendarStore.getDeliveredProductsForDate(
                          new Date(
                            currentDate.getFullYear(),
                            currentDate.getMonth(),
                            day as number
                          )
                        )
                      }
                    />
                  ) : (
                    <></>
                  )}
                  {store.CalendarStore.visibility.estimatedProducts ? (
                    <CreateProducts
                      title={'Estimated deliveries:'}
                      color={
                        Theme.calendarStyles.popUpColors
                          .estimatedDeliveryProducts
                      }
                      displayOrder={true}
                      dataGetter={() =>
                        store.CalendarStore.getEstimatedProductsForDate(
                          new Date(
                            currentDate.getFullYear(),
                            currentDate.getMonth(),
                            day as number
                          )
                        )
                      }
                    />
                  ) : (
                    <></>
                  )}
                  {store.CalendarStore.visibility.delayedProducts ? (
                    <CreateProducts
                      title={'Delayed Products:'}
                      color={Theme.calendarStyles.popUpColors.delayedProducts}
                      displayOrder={true}
                      dataGetter={() =>
                        store.CalendarStore.getDelayedProductsForDate(
                          new Date(
                            currentDate.getFullYear(),
                            currentDate.getMonth(),
                            day as number
                          )
                        )
                      }
                    />
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <></>
              )}
            </Day>
          ))}
        </Week>
      ))}
    </Container>
  )
})

export default CalendarMonths
