import React, { useContext, useEffect, useState } from 'react'
import { StoreContext } from '../../../../../App'
import {
  DeleteButton,
  Input,
  ArrayRow,
  PrimaryButton,
  ArrayContainer,
  MainContainer,
  SingleInputCont,
  Select,
} from './ArrayObjectInput.styles'
import { FilterLabel, InputLabel } from '../../../../dataInput/DataInput.styles'
import ClearFilters from '../../../../../assets/icons/clear-filters.svg'
import { hiddenColumns } from '../../../../../columnsConfig'

interface StringArrayInputProps {
  data?: { channel_id: string; channel_name: string; status_id: string }[]
  header: string
  index: number
  form: {
    header: string
    value: any
    error: string
    rules: Array<string>
  }[]
  handleChange: (
    form: Array<{
      header: string
      value: any
      error: string
      rules: Array<string>
    }>
  ) => void
}

const ArrayObjectInput = ({
  data,
  handleChange,
  header,
  form,
  index,
}: StringArrayInputProps) => {
  const store = useContext(StoreContext)
  const [inputData, setInputData] = useState(data || [])

  if (hiddenColumns.includes(header)) return null

  const handleInputChange = (i: number, key: string, value: string) => {
    const newData = inputData.map((item, idx) =>
      idx === i ? { ...item, [key]: value } : item
    )
    if (key === 'channel_id') {
      const channel = store.StatusesStore.salesChannels.find(
        (ch: any) => ch.id === value
      )
      //@ts-ignore
      const channelName = channel ? channel.name : ''
      newData[i].channel_name = channelName
    }
    setInputData(newData)
  }

  const deleteField = (i: number) =>
    setInputData(inputData.filter((_, idx) => idx !== i))
  const addNewField = () =>
    setInputData([
      ...inputData,
      { channel_id: '', channel_name: '', status_id: '' },
    ])

  const getSelectableChannels = (currentIndex: any) => {
    const selectedChannelIds = inputData.map((item) => item.channel_id)
    return store.StatusesStore.salesChannels.filter(
      (channel: any) =>
        !selectedChannelIds.includes(channel.id) ||
        inputData[currentIndex].channel_id === channel.id
    )
  }

  useEffect(() => {
    handleChange(
      form.map((f, idx) => (idx === index ? { ...f, value: inputData } : f))
    )
  }, [inputData])

  return (
    <MainContainer>
      <ArrayContainer id={`input-${store.RouteStore.currentPage}-${header}`}>
        {inputData.map((item, i) => (
          <ArrayRow key={i}>
            <SingleInputCont>
              <InputLabel>Sales Channel</InputLabel>
              <Select
                id={`input-${store.RouteStore.currentPage}-${header}-1-${i}`}
                value={item.channel_id}
                activeStyle={true}
                onChange={(e: any) =>
                  handleInputChange(i, 'channel_id', e.target.value)
                }
              >
                <option value=''></option>
                {getSelectableChannels(i).map((channel: any) => (
                  <option key={channel.id} value={channel.id}>
                    {channel.name}
                  </option>
                ))}
              </Select>
            </SingleInputCont>
            <SingleInputCont>
              <InputLabel>Status Id</InputLabel>
              <Input
                id={`input-${store.RouteStore.currentPage}-${header}-2-${i}`}
                type='text'
                activeStyle={true}
                value={item.status_id}
                onChange={(e) =>
                  handleInputChange(i, 'status_id', e.target.value)
                }
              />
            </SingleInputCont>
            <DeleteButton onClick={() => deleteField(i)}>
              <img src={ClearFilters} alt='Clear' />
            </DeleteButton>
          </ArrayRow>
        ))}
      </ArrayContainer>
      <PrimaryButton onClick={addNewField}>+ Add new</PrimaryButton>
    </MainContainer>
  )
}

export default ArrayObjectInput
