import { IconAlertCircle } from '@tabler/icons-react'
import { FC, PropsWithChildren, useContext } from 'react'
import { Container, Entry } from './TableSystem.styles'
import { mainTheme as theme } from '../../../static/themes/main.theme'
import { StoreContext } from '../../../App'
import { observer } from 'mobx-react'

interface Props extends PropsWithChildren {
  data: {
    order_url: string
    domain: string
    type: string
    url: string
  }
}

const TableSystem: FC<Props> = observer(({ data }: Props) => {
  const store = useContext(StoreContext)
  const locales = store.TranslationsState.translations

  if (data)
    return (
      <Container href={data.order_url}>
        <div>{data.domain}</div>
        <div>{data.type}</div>
        <div>{data.url}</div>
      </Container>
    )
  else return <></>
})
export default TableSystem
