import { PropsWithChildren, useContext, useEffect, useState } from 'react'
import { StoreContext } from '../../../../../App'
import {
  DeleteButton,
  Input,
  ObjectRow,
  PrimaryButton,
  Select,
} from './ObjectInput.styles'
import { FilterLabel } from '../../../../dataInput/DataInput.styles'
import ClearFilters from '../../../../../assets/icons/clear-filters.svg'
import { hiddenColumns } from '../../../../../columnsConfig'
import { observer } from 'mobx-react'
import { toJS } from 'mobx'

interface ObjectInputProps extends PropsWithChildren {
  data?: { [key: string]: string }
  header: string
  index: number
  form: Array<{
    header: string
    value: any
    error: string
    rules: Array<string>
  }>
  handleChange: (
    val: Array<{
      header: string
      value: any
      error: string
      rules: Array<string>
    }>
  ) => void
  defaultHeader: string
  notCutLang?: boolean
}

interface HeaderValueObject {
  header: string
  value: string
}

export const ObjectInput = observer(
  ({
    data,
    handleChange,
    header,
    defaultHeader,
    form,
    index,
    notCutLang,
  }: ObjectInputProps) => {
    const store = useContext(StoreContext)
    const { Theme } = store.Theme
    const extractLanguageCode = (langCode: string) => {
      return notCutLang ? langCode : langCode.split('_')[0].toUpperCase()
    }

    const [inputData, setInputData] = useState<HeaderValueObject[]>(
      data
        ? Object.entries(data).map(([header, value]) => ({ header, value }))
        : []
    )
    const availableHeaders =
      store.TranslationsState.avaliableLanguages.map(extractLanguageCode)

    const isHeaderAvailable = (header: string) => {
      return !inputData.some((item) => item.header === header)
    }

    const handleInputChange = (
      i: number,
      newHeader: string,
      newValue: string
    ) => {
      setInputData((prevData) => {
        const updatedData = [...prevData]
        updatedData[i] = { header: newHeader, value: newValue }
        return updatedData
      })
    }

    const deleteField = (i: number, header: string) => {
      if (header === defaultHeader) {
        return
      }

      setInputData((prevData) => {
        const updatedData = [...prevData]
        return updatedData.filter((item) => item.header !== header)
      })
    }
    const handleAddInput = () => {
      setInputData((prevData) => {
        for (const h of availableHeaders) {
          if (isHeaderAvailable(h)) {
            return [...prevData, { header: h, value: '' }]
          }
        }
        return prevData
      })
    }

    const convertToSingleObject = (
      dataArr: HeaderValueObject[]
    ): { [key: string]: string } => {
      const result: { [key: string]: string } = {}
      dataArr.forEach((item) => {
        result[item.header] = item.value
      })
      return result
    }

    const ensureEnExists = (
      dataArr: HeaderValueObject[]
    ): HeaderValueObject[] => {
      let hasEn = false
      for (const item of dataArr) {
        if (item.header === defaultHeader) {
          hasEn = true
          break
        }
      }

      if (!hasEn) {
        dataArr.push({ header: defaultHeader, value: '' })
      }
      return dataArr
    }

    useEffect(() => {
      setInputData(ensureEnExists(inputData))
      store.TableStore.setLanguages(Object.keys(inputData))
      return () => store.TableStore.setLanguages([])
    }, [])

    useEffect(() => {
      const convertedData = {
        header: header,
        value: convertToSingleObject(inputData),
        error: '',
        rules: [],
      }
      const tempForm = form
      tempForm[index] = convertedData
      handleChange(form)
      const languageHeaders = inputData.map((item) => item.header)
      store.TableStore.setLanguages(languageHeaders)
    }, [inputData])

    useEffect(() => {
      const currentLanguageKeys = inputData.map((item) => item.header)

      const languagesToAdd = store.TableStore.languages.filter(
        (e) => !currentLanguageKeys.includes(e)
      )
      const languagesToRemove = currentLanguageKeys.filter(
        (e) => !store.TableStore.languages.includes(e)
      )

      const tempInputData = inputData.filter(
        (item) => !languagesToRemove.includes(item.header)
      )

      languagesToAdd.forEach((lang) => {
        tempInputData.push({ header: lang, value: '' })
      })

      if (languagesToAdd.length || languagesToRemove.length)
        setInputData(tempInputData)
    }, [store.TableStore.languages])

    if (!hiddenColumns.includes(header))
      return (
        <div>
          {inputData.map((item, i) => (
            <ObjectRow
              id={`input-${store.RouteStore.currentPage}-${header}`}
              key={`object-input-${i}`}
            >
              <Select
                activeStyle={true}
                value={item.header}
                onChange={(e) => {
                  if (
                    isHeaderAvailable(e.target.value) ||
                    e.target.value === item.header
                  ) {
                    handleInputChange(i, e.target.value, item.value)
                  }
                }}
              >
                {/* Only render options that are available */}
                {availableHeaders.map(
                  (lang) =>
                    (lang === item.header || isHeaderAvailable(lang)) && (
                      <option key={lang} value={lang}>
                        {lang}
                      </option>
                    )
                )}
              </Select>
              <Input
                activeStyle={true}
                type='text'
                value={item.value}
                onChange={(e) =>
                  handleInputChange(i, item.header, e.target.value)
                }
              />
              <DeleteButton onClick={() => deleteField(i, item.header)}>
                <img src={ClearFilters} alt='Clear Filter' />
              </DeleteButton>
            </ObjectRow>
          ))}
          <PrimaryButton
            onClick={handleAddInput}
            {...Theme.buttons.primaryButton}
          >
            {'+ Add new'}
          </PrimaryButton>
        </div>
      )
    else return <></>
  }
)
