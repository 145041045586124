export const breakpoints = {
  desktop: '1440px',
  tablet: '1080px',
  vertical: '700px',
  mobile: '480px',
}
export const device = {
  minDesktop: `(min-width: ${breakpoints.desktop})`,
  minTablet: `(min-width: ${breakpoints.tablet})`,
  minMobile: `(min-width: ${breakpoints.mobile})`,
}
