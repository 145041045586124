import styled from 'styled-components'

interface ContainerInterface {
  secondary?: boolean
  secondaryColor?: string
  primaryColor?: string
  borderRadius?: string
  margin?: string
  padding?: string
  fontSize?: string
}

export const Container = styled.div<ContainerInterface>`
  /*background-color: ${(props) =>
    props.secondary
      ? props.secondaryColor || 'rgba(120, 50, 50)'
      : props.primaryColor || 'rgba(50,120,50)'};*/
  background-color: rgba(30, 30, 30, 0.8);
  color: white;
  margin: ${(props) => (props.margin ? props.margin : '4px 0')};
  padding: ${(props) => (props.padding ? props.padding : '4px 0')};
  border-radius: ${(props) =>
    props.borderRadius ? props.borderRadius : '4px'};
  font-size: ${(props) => (props.fontSize ? props.fontSize : '11px')};
  text-align: center;
`

interface TopInterface {
  margin?: string
  padding?: string
  fontWeight?: number
}

export const Top = styled.div<TopInterface>`
  padding: ${(props) => (props.padding ? props.padding : '0 6px')};
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  * {
    margin: ${(props) => (props.margin ? props.margin : '0 4px')};
  }

  p {
    font-weight: ${(props) => (props.fontWeight ? props.fontWeight : 700)};
  }
`
export const Bottom = styled.div``
