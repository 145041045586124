/* eslint-disable */
import {
  PropsWithChildren,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import { Row } from 'react-table'

import {
  hiddenColumns,
  ReplaceEmptyStringsForNulls,
  ValidateArray,
} from '../../../../columnsConfig'

import {
  Container,
  Footer,
  InnerCont,
  PrimaryButton,
  SecondaryButton,
} from './CreateTree.styles'

import { StoreContext } from '../../../../App'
import useWindowDimensions from '../../../../methods/hooks/useWindowDimensions'
import { DataInput } from '../../../dataInput/DataInput.component'
import { ObjectInput } from '../../tags/edits/objectInput/ObjectInput.component'
import SearchTagComponent from '../../tags/edits/searchTag/SearchTag.component'
import { ColorInput } from '../../tags/edits/colorInput/ColorInput.component'
import { StringSelectInput } from '../../tags/edits/arraySelect/ArraySelect.component'
import SearchTagNewComponent from '../../tags/edits/searchTagNew/SearchTagNew.component'
import { FilterLabel } from '../../../dataInput/DataInput.styles'

export interface EditProductsProps extends PropsWithChildren {
  columns: Array<{ header: string; value: string | object }>
  baseUrl: string
  hideParent?: boolean
  view?: 'group' | 'tag' | 'root'
  left?: boolean
  parentId?: string
  id?: string
}

const CreateTree: (props: EditProductsProps) => JSX.Element = (
  props: EditProductsProps
) => {
  const store = useContext(StoreContext)
  const { Theme } = store.Theme
  const { width, height } = useWindowDimensions()
  const [loading, setLoading] = useState(false)
  const divRef = useRef<any>(null)

  const initialState = [
    ...props.columns.map((e) => {
      return {
        header: e.header,
        value: e.value,
        error: '',
        rules: ValidateArray[e.header] ? ValidateArray[e.header] : [],
      }
    }),
  ]
  const [form, setForm] = useState(initialState)
  const [inputColumns, setInputColumns] = useState(1)
  const [action, setAction] = useState<{
    type: 'add' | 'delete' | 'none'
    language?: string
  }>({ type: 'none' })

  const handleAddField = () => {
    setAction({ type: 'add' })
  }

  const handleDeleteField = (language: string) => {
    setAction({ type: 'delete', language })
  }

  const handleSetForm = (
    tempForm: Array<{
      header: string
      value: string
      error: string
      rules: Array<string>
    }>
  ) => {
    setForm([...tempForm])
  }

  const handleSave = async () => {
    if (props.left) {
      await setLoading(true)
      let formData: any = {}
      form.forEach((e) => (formData = { ...formData, [e.header]: e.value }))
      if (formData.hasOwnProperty('priority') && formData.priority.length) {
        formData = { ...formData, priority: parseInt(formData.priority) }
      }
      await store.TagsStore.insertTag(
        '_create',
        props.parentId ? props.parentId : '',
        props.id ? props.id : '',
        formData
      )
      await setLoading(false)
    } else {
      await setLoading(true)
      let formData: any = {}
      form.forEach((e) => (formData = { ...formData, [e.header]: e.value }))
      if (formData.hasOwnProperty('priority') && formData.priority.length) {
        formData = { ...formData, priority: parseInt(formData.priority) }
      }
      await store.TagsStore.addTag(formData, props.view)
      await setLoading(false)
    }
  }

  useEffect(() => {
    setInputColumns(
      Math.ceil(
        (90 *
          props.columns.filter((e) => !hiddenColumns.includes(e.header))
            .length) /
          (height * 0.8)
      )
    )
  }, [height])

  useEffect(() => {
    if (action.type !== 'none') {
      setAction({ type: 'none' })
    }
  }, [action])

  return (
    <Container cols={inputColumns} {...Theme.editProductsStyles.container}>
      <InnerCont ref={divRef} cols={inputColumns}>
        {props.columns
          ? props.columns.map((e, index) => {
              if (e.header === 'groupID') {
                return (
                  <>
                    <FilterLabel {...Theme.editProductsStyles.filterLabel}>
                      {e.header}
                    </FilterLabel>
                    <SearchTagComponent
                      header={e.header}
                      applySearch={(e: any) => {
                        const tempForm = [...form]
                        tempForm[index].value = e.id
                        //@ts-ignore
                        handleSetForm(tempForm)
                      }}
                      view={'group'}
                    />
                    )
                  </>
                )
              } else if (e.header === 'colour') {
                const toStringE = {
                  header: form[index].header,
                  value: form[index].value
                    ? form[index].value.toString()
                    : '#000000',
                }
                return (
                  <>
                    <FilterLabel {...Theme.editProductsStyles.filterLabel}>
                      {e.header}
                    </FilterLabel>
                    <ColorInput
                      vals={toStringE}
                      key={`data-input--${index}`}
                      index={index}
                      form={form}
                      setForm={handleSetForm}
                    />
                  </>
                )
              } else if (e.header === 'parent') {
                return (
                  <>
                    <FilterLabel {...Theme.editProductsStyles.filterLabel}>
                      {e.header}
                    </FilterLabel>
                    <SearchTagNewComponent
                      header={e.header}
                      applySearch={(e) => {
                        const tempForm = [...form]
                        tempForm[index].value = e.id
                        //@ts-ignore
                        handleSetForm(tempForm)
                      }}
                      view={'tag'}
                    />{' '}
                  </>
                )
              } else if (e.header === 'root_id') {
                return (
                  <>
                    <FilterLabel {...Theme.editProductsStyles.filterLabel}>
                      {e.header}
                    </FilterLabel>
                    <SearchTagComponent
                      header={e.header}
                      applySearch={(e: any) => {
                        console.log(e)
                        const tempForm = [...form]
                        tempForm[index].value = e.id
                        //@ts-ignore
                        handleSetForm(tempForm)
                      }}
                      view={'root'}
                    />{' '}
                  </>
                )
              } else if (
                typeof e.value === 'object' &&
                !Array.isArray(e.value)
              ) {
                const eObject = { ...e.value }
                return (
                  <>
                    <FilterLabel {...Theme.editProductsStyles.filterLabel}>
                      {e.header}
                    </FilterLabel>
                    <ObjectInput
                      header={e.header}
                      index={index}
                      form={form}
                      handleChange={handleSetForm}
                      data={eObject}
                      defaultHeader={'EN'}
                    />{' '}
                  </>
                )
              } else if (Array.isArray(e.value)) {
                return (
                  <>
                    <FilterLabel {...Theme.editProductsStyles.filterLabel}>
                      {e.header}
                    </FilterLabel>
                    <StringSelectInput
                      data={e.value}
                      dataList={store.TagsStore.avaliableIndicies}
                      header={e.header}
                      index={index}
                      form={form}
                      handleChange={handleSetForm}
                    />{' '}
                  </>
                )
              } else {
                const toStringE = {
                  header: e.header,
                  value: e.value ? e.value.toString() : '',
                }
                return (
                  <DataInput
                    vals={toStringE}
                    key={`data-input--${index}`}
                    index={index}
                    form={form}
                    setForm={handleSetForm}
                  />
                )
              }
            })
          : null}
      </InnerCont>
      <Footer {...Theme.editProductsStyles.styledFooter}>
        <SecondaryButton
          onClick={() => store.SlidingPanelState.setVisibility(false)}
          {...Theme.buttons.secondaryButton}
        >
          {'CANCEL'}
        </SecondaryButton>
        <PrimaryButton
          onClick={() => !loading && handleSave()}
          active={!loading}
          {...Theme.buttons.primaryButton}
        >
          {'SAVE'}
        </PrimaryButton>
      </Footer>
    </Container>
  )
}
export default CreateTree
