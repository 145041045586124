import React, { forwardRef, useEffect, useRef } from 'react'
import { StyledInput } from './ColumnVisibility.styles'

interface IndeterminateCheckboxProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  indeterminate?: boolean
}

const IndeterminateCheckbox = forwardRef<
  HTMLInputElement,
  IndeterminateCheckboxProps
>(function IndeterminateCheckbox({ indeterminate, ...rest }, ref) {
  const defaultRef = useRef<HTMLInputElement>(null)
  const resolvedRef = ref || defaultRef

  useEffect(() => {
    if ('current' in resolvedRef && resolvedRef.current !== null) {
      // @ts-ignore
      resolvedRef.current.indeterminate = indeterminate
    }
  }, [resolvedRef, indeterminate])

  return <StyledInput type='checkbox' ref={resolvedRef} {...rest} />
})

IndeterminateCheckbox.displayName = 'IndeterminateCheckbox'

export default IndeterminateCheckbox
