import Table from '../../TableGeneric/table/Table.component'

import { observer } from 'mobx-react'
import React, { useContext, useEffect } from 'react'
import { StoreContext } from '../../../App'

import { Cell } from 'react-table'
import TableBool from '../../TableGeneric/TableBool/TableBool.component'
/* eslint-disable */

const CurrenciesTable = observer(() => {
  const store = useContext(StoreContext)
  const tableStore = store.TableStore
  const locales = store.TranslationsState.translations

  let columns: { [key: string]: any } = {
    id: {
      canFilter: false,
      editable: true,
      mandatory: true,
    },
    manual_rate: {
      canFilter: false,
      editable: true,
      editType: 'number',
      mandatory: true,
    },
    auto_rate: {
      canFilter: false,
      editable: false,
      editType: 'number',
      mandatory: true,
    },
    rate: {
      canFilter: false,
      editable: false,
      editType: 'number',
      mandatory: true,
    },
    active: {
      Cell: (cell: Cell) => <TableBool value={cell.value} />,
      canFilter: false,
      editable: true,
      canSort: false,
      editType: 'selectBool',
      selectValues: [
        { value: 'true', label: 'True' },
        { value: 'false', label: 'False' },
      ],
    },
    auto_exchange: {
      Cell: (cell: Cell) => <TableBool value={cell.value} />,
      canFilter: false,
      editable: true,
      canSort: false,
      editType: 'selectBool',
      selectValues: [
        { value: 'true', label: 'True' },
        { value: 'false', label: 'False' },
      ],
    },
  }

  useEffect(() => {
    store.StoreSettingsStore.fetchData('currencies')
    return () => store.StoreSettingsStore.clearData()
  }, [])

  return (
    <Table
      config={{
        tableName: 'currencies',
        data: store.StoreSettingsStore.data || [],
        fetchData: () => store.StoreSettingsStore.fetchData('currencies'),
        columnHeaders: columns,
        columnVisibility: true,
        allowColumnResize: true,
        displaySearch: false,
        displaySelect: false,
        displayExpand: false,
        displayTooltip: false,
        displayEntry: false,
        displayPaginator: false,
        displayInfinitySwitch: false,
        allowSort: true,
        bank: false,
        actions: {
          display: true,
          label: 'Currency',
          edit: `${process.env.REACT_APP_BASE_URL}experience/v1/storesettings/currencies?identifier=`,
          create: `${process.env.REACT_APP_BASE_URL}experience/v1/storesettings/currencies?identifier=_create`,
          remove: `${process.env.REACT_APP_BASE_URL}experience/v1/storesettings/currencies`,
          assignTag: null,
          custom: [],
        },
        //actions: null,
        headline: {
          visible: true,
          title: 'List of Currencies',
          subTitle: 'List of Currencies',
        },
        breadcrumb: [],
        loaded: store.StoreSettingsStore.loaded,
        pagination: tableStore.pagination.filtersview,
        setPagination: tableStore.setPagination,
      }}
    ></Table>
  )
})
export default CurrenciesTable
