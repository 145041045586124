import styled from 'styled-components'

export const Container = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: center;
  justify-content: center;

  svg {
    cursor: pointer;
  }
`

export const SvgCont = styled.div`
  padding: 12px;
  backdrop-filter: blur(4px) opacity(20%);
`
interface ActionListProps {
  color?: string
  bgColor?: string
  borderRadius?: string
  border?: string
  boxShadow?: string
  padding?: string
  showActions: boolean
  sliding: boolean
}

export const ActionList = styled.div<ActionListProps>`
  position: absolute;
  z-index: 2;
  transform: ${(props) => (props.sliding ? 'none' : 'translateY(40px)')};
  transition-duration: 0s;
  right: 40px;
  border: ${(props) => (props.border ? props.border : '1px solid #e5e5e5')};
  box-shadow: ${(props) =>
    props.boxShadow ? props.boxShadow : '0 1px 3px rgba(0, 0, 0, 0.06)'};
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  //padding: ${(props) => (props.padding ? props.padding : '8px 10px')};
`

interface SingleActionProps {
  color?: string
  bgColor?: string
  borderRadius?: string
  borderTop?: string
  boxShadow?: string
  padding?: string
  fontWeight?: number
  fontSize?: string
  lineHeight?: string
  letterSpacing?: string
  hoverBgColor?: string
}

/*eslint-disable */
export const SingleAction = styled.div<SingleActionProps>`
  position: relative;
  width: 100%;
  height: 32px;
  border-top: ${(props) =>
    props.borderTop ? props.borderTop : '1px solid #e5e5e5'};
  padding: ${(props) => (props.padding ? props.padding : '4px 10px')};
  color: ${(props) => (props.color ? props.color : '#000000')};

  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : 400)};
  font-size: ${(props) => (props.fontSize ? props.fontSize : '12px')};
  line-height: ${(props) => (props.lineHeight ? props.lineHeight : '24px')};
  letter-spacing: ${(props) =>
    props.letterSpacing ? props.letterSpacing : '0.35px'};
  font-feature-settings: 'tnum' on, 'lnum' on;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  border-radius: ${(props) =>
    props.borderRadius ? props.borderRadius : '6px'};
  background-color: ${(props) => (props.bgColor ? props.bgColor : '#ffffff')};

  :hover {
    background-color: ${(props) =>
      props.hoverBgColor ? props.hoverBgColor : '#d8d8d8'};
  }

  &:first-child {
    border-top: none;
  }

  z-index: 5;
`

/* eslint-enable */
interface ActionEditProps {
  bgColor?: string
}

export const ActionEdit = styled.div<ActionEditProps>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: ${(props) =>
    props.bgColor ? props.bgColor : 'rgba(0, 0, 0, 0.6)'};
  display: flex;
  justify-content: center;
  align-items: center;
`
