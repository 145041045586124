import styled from 'styled-components'

export const ButtonCont = styled.div`
  display: flex;
  > div {
    margin: 2px;
    margin-top: 0;
  }
`
export const TagContainer = styled.div`
  height: min-content;
  min-width: 90px;
  min-height: 62px;
  > ${ButtonCont} {
    display: none;
  }

  /* Show the ButtonCont when hovering over TagContainer */
  &:hover > ${ButtonCont} {
    display: flex;
  }
`
export const Column = styled.div`
  display: flex;
  flex-direction: column; // This will stack the tags on top of each other.
  margin-left: 50px; // This will give space between different levels/columns.
`

export const Row = styled.div`
  display: flex;
  align-items: center; // This will ensure tags start aligning from the top
`
export const AddButton = styled.div`
  border-radius: 50%;
  width: 24px;
  height: 24px;
  background-color: #303030;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  img {
    height: 16px;
    width: 16px;
  }
`
export const EditButton = styled(AddButton)`
  background-color: #dddddd;
`
