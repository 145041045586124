import { observer } from 'mobx-react'
import { FC, PropsWithChildren, useContext } from 'react'
import { TableToggleAllRowsSelectedProps } from 'react-table'

import { StoreContext } from '../../../App'
import {
  AllSelectCheckbox,
  IndeterminateCheckbox,
  PageSelectCheckbox,
} from '../../checkboxSelect/CheckboxSelect.component'

import {
  HeaderCheckboxCont,
  HeaderCheckboxes,
  HeaderCheckboxParagraph,
  HeaderInnerCheckBox,
} from './TableHeaderCheckboxes.styles'
import All from '../../../assets/icons/all.svg'
import Page from '../../../assets/icons/page.svg'

interface Props extends PropsWithChildren {
  getToggleAllRowsSelectedProps: (
    props?: Partial<TableToggleAllRowsSelectedProps> | undefined
  ) => TableToggleAllRowsSelectedProps
  actions?: {
    display: boolean
    label: string
    edit: string | null
    create: string | null
    remove: string | null
    assignTag: string | null
    custom: any[]
  } | null
}

const TableHeaderCheckboxes: FC<Props> = observer((props) => {
  const store = useContext(StoreContext)
  const { Theme } = store.Theme
  const locales = store.TranslationsState.translations

  if (props.actions?.label === 'Status') return <></>

  return (
    <HeaderCheckboxes {...Theme.tableStyles.headerCheckboxes}>
      <HeaderInnerCheckBox>
        <HeaderCheckboxCont>
          <AllSelectCheckbox allSelected={store.TableStore.allSelectedRows} />{' '}
          <HeaderCheckboxParagraph
            {...Theme.tableStyles.headerCheckboxParagraph}
          >
            <img src={All} />
          </HeaderCheckboxParagraph>
        </HeaderCheckboxCont>
        <HeaderCheckboxCont>
          <PageSelectCheckbox {...props.getToggleAllRowsSelectedProps()} />
          <HeaderCheckboxParagraph
            {...Theme.tableStyles.headerCheckboxParagraph}
          >
            <img src={Page} />
          </HeaderCheckboxParagraph>
        </HeaderCheckboxCont>
      </HeaderInnerCheckBox>
    </HeaderCheckboxes>
  )
})
export default TableHeaderCheckboxes
