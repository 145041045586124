import { observer } from 'mobx-react'
import {
  ChangeEvent,
  Dispatch,
  FC,
  PropsWithChildren,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react'

import { Column } from 'react-table'
import { StoreContext } from '../../../App'
import { validate } from '../../../static/validate'

import {
  Body,
  Container,
  ErrorMessage,
  Header,
  HeaderIcon,
  HeaderIconCont,
  OuterCont,
  PrimaryButton,
  Row,
  TableCell,
  TableCont,
  StyledSelect,
  TableSecondRow,
  NotesInput,
  StyledTableCell,
  SecondRowRight,
  SecondRowLeft,
  TableInputCell,
  TableHeaderCell,
  HeaderTitle,
} from './CreateOrderPopup.styles'
import Cross from '../../../assets/icons/cross-white.svg'
import axios from '../../../axiosconfig'
import {
  Wrap,
  Container as Spinner,
  Container as LoadingContainer,
} from '../../../styles/Loading.styles'
import { toast } from 'react-toastify'
import { Tooltip } from '@mui/material'
import { ScrollSync, ScrollSyncPane } from 'react-scroll-sync'
import TableTags from '../tableTags/TableTags.component'

/* eslint-disable */
interface Props extends PropsWithChildren {
  columns: Column[]
  setActive: Dispatch<SetStateAction<boolean>>
  toggleAllRowsSelected: any
  setCreateOrderPopup: any
}

interface Product {
  ProductID: string
  Name: string
  ProductCode: string
  SupplierName: string
  SupplierDeliveryCode: string
  SupplierOrderId: string
  OrderLink: string
  Notes: string
  ProducerName: string
  OrderedQuantity: string
  SupplierDeliveryDate: string
  DeliveredQuantity: string
  DeliveredDate: string
  PurchasePrice: string
  Unit: string
  CreatedBy: string
  LastEditAuthor: string
}

interface OrderObject {
  status: string
  author: string
  supplier: string
  creationDate: string
  lastEditDate: string
  lastEditAuthor: string
  orderLink: string
  deliveryDate: string
  deliveryCode: string
  notes: string
  products: { [key: string]: Product }
  wfirma_id: string
  vat: string
  wfirma_vat_id: string
  currency: string
}

const CreateOrderPopUp: FC<Props> = observer((props) => {
  const store = useContext(StoreContext)
  const { Theme } = store.Theme
  const locales = store.TranslationsState.translations
  const [data, setData] = useState<any[]>([])
  const [SupplierList, setSupplierList] = useState<any[]>([])
  const [loading, setLoading] = useState(false)

  const headerList = [
    'name',
    'code',
    'vendor',
    'tags',
    'price',
    'supplierPrice',
    'stock',
    'afterPacking',
    'demand',
    //'inProgress',
    'afterOrders',
    'AmountInPackage',
    'minimumWarnLevel',
    'secureWarnLevel',
    'maximumWarnLevel',
    'quantityToOrder',
    'minimumQuantityForSupplierOrder',
  ]
  const columnList = [
    'name',
    'code',
    'producer',
    'tags',
    'price',
    'supplierPrice',
    'stock',
    'stockAfterPacking',
    'stockDemand',
    //'stockInProgress',
    'stockAfterOrders',
    'stockAmountInPackage',
    'minimumWarnLevel',
    'secureWarnLevel',
    'maximumWarnLevel',
    'quantityToOrder',
    'minimumQuantityForSupplierOrder',
  ]
  const CurrencyList = ['PLN', 'USD', 'EUR', 'GBP', 'SEK']

  const [supplier, setSupplier] = useState<string>('Click to select!')
  const [currency, setCurrency] = useState<string>('Click to select!')
  const [notes, setNotes] = useState('')
  const [inputData, setInputData] = useState(
    [
      ...store.TableStore.selectedRowId.map((e) => ({
        id: e,
        notes: { value: '', error: '', rules: [] },
        price: { value: 0, error: '', rules: [] },
        quantity: { value: 1, error: '', rules: ['amount'] },
      })),
    ].reverse()
  )

  const fetchData = async () => {
    let data: any = {
      filterGroups: {
        rules: [],
        combinator: 'or',
        not: false,
      },
    }
    console.log(inputData)

    inputData.forEach((item) =>
      data.filterGroups.rules.push({
        field: 'id',
        operator: '=',
        valueSource: 'value',
        value: item.id,
      })
    )

    const url = `${process.env.REACT_APP_BASE_URL}experience/v1/stock_view?size=${inputData.length}&from=0`
    console.log(data)
    const response = await axios.post(url, data, {
      headers: {
        'Content-Type': 'application/json',
        'x-apikey': `${process.env.REACT_APP_API_KEY}`,
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET,HEAD,PUT,PATCH,POST,DELETE',
        'Access-Control-Allow-Credentials': 'true',
      },
    })
    console.log(response.data)
    const productData: any[] = [
      ...store.TableStore.selectedRowId.map((e) =>
        response.data.products.find((f: any) => f.id === e)
      ),
    ].reverse()
    await setData(productData)

    const updatedInputData = [...inputData]
    console.log(productData)
    productData.forEach((e: any, index: number) => {
      updatedInputData[index].quantity.value = e.stockCalculated || 0
    })

    setInputData(updatedInputData)

    const producersUrl = `${process.env.REACT_APP_BASE_URL}experience/v1/producers_view?size=100&from=0`
    const producersResponse = await axios.post(
      producersUrl,
      {},
      {
        headers: {
          'Content-Type': 'application/json',
          'x-apikey': `${process.env.REACT_APP_API_KEY}`,
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Methods': 'GET,HEAD,PUT,PATCH,POST,DELETE',
          'Access-Control-Allow-Credentials': 'true',
        },
      }
    )

    await setSupplierList(producersResponse.data.producers)
    await setLoading(true)
  }

  const sendData = async () => {
    if (
      supplier !== 'Click to select!' &&
      currency !== 'Click to select!' &&
      !isNaN(
        inputData.reduce((partialSum, a) => partialSum + a.quantity.value, 0)
      )
    ) {
      props.setCreateOrderPopup()
      const date = new Date()
      let object: OrderObject = {
        status: '3',
        author: store.UserStore.user,
        supplier: supplier,
        creationDate: date.toISOString(),
        lastEditDate: date.toISOString(),
        lastEditAuthor: store.UserStore.user,
        orderLink: '',
        deliveryDate: '',
        deliveryCode: '',
        notes: notes,
        products: {},
        wfirma_id: SupplierList.find((e) => e.name === supplier).wfirma_id,
        vat: SupplierList.find((e) => e.name === supplier).vat,
        wfirma_vat_id: SupplierList.find((e) => e.name === supplier)
          .wfirma_vat_id,
        currency: currency,
      }

      inputData.reverse().forEach((item, index) => {
        const product: any = data.find((prod: any) => prod.id === item.id)
        if (product) {
          object.products[product.code] = {
            ProductID: product.code,
            Name: product.name,
            ProductCode: product.code,
            SupplierName: supplier,
            SupplierDeliveryCode: '',
            SupplierOrderId: '',
            OrderLink: '',
            Notes: item.notes.value,
            ProducerName: product.producer,
            OrderedQuantity: item.quantity.value.toString(),
            SupplierDeliveryDate: '',
            DeliveredQuantity: '0',
            DeliveredDate: '',
            PurchasePrice: item.price.value.toString(),
            Unit: '',
            LastEditAuthor: store.UserStore.user,
            CreatedBy: store.UserStore.user,
          }
        }
      })

      const url = `${process.env.REACT_APP_BASE_URL}experience/v1/supplier_order/_create`
      console.log(url, object)
      const response = await axios.post(url, object, {
        headers: {
          'Content-Type': 'application/json',
          'x-apikey': `${process.env.REACT_APP_API_KEY}`,
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Methods': 'GET,HEAD,PUT,PATCH,POST,DELETE',
          'Access-Control-Allow-Credentials': 'true',
        },
      })

      props.toggleAllRowsSelected(false)
      store.TableStore.setAllSelectedRows(false)
      store.TableStore.setSelectedRows([])
      await toast('Success!', {
        type: 'success',
        autoClose: 5000,
      })
    } else
      toast('You need to select currency and supplier!', {
        type: 'error',
      })
  }

  useEffect(() => {
    fetchData()
    console.log(inputData)
  }, [])

  return (
    <OuterCont {...Theme.createOrderPopupStyles.outerCont}>
      <ScrollSync>
        <Container {...Theme.createOrderPopupStyles.container}>
          {loading ? (
            <>
              <TableCont>
                <HeaderIconCont>
                  <HeaderTitle>
                    {locales.buttons.createPurchaseOrder}
                  </HeaderTitle>
                  <HeaderIcon
                    src={Cross}
                    alt={'X'}
                    onClick={() => props.setActive(false)}
                  />
                </HeaderIconCont>
                <ScrollSyncPane>
                  <Header>
                    <Tooltip title={'No:'} placement='bottom' arrow>
                      <TableHeaderCell>{'No:'}</TableHeaderCell>
                    </Tooltip>
                    {headerList.map((e: string, index: number) => (
                      <Tooltip
                        title={e}
                        placement='bottom'
                        arrow
                        key={`cell-${index}`}
                      >
                        <TableHeaderCell>{e}</TableHeaderCell>
                      </Tooltip>
                    ))}
                    <Tooltip title={'Notes'} placement='bottom' arrow>
                      <TableHeaderCell>{'Notes'}</TableHeaderCell>
                    </Tooltip>
                    <Tooltip title={'Purchase Price'} placement='bottom' arrow>
                      <TableHeaderCell>{'Purchase Price'}</TableHeaderCell>
                    </Tooltip>
                    <Tooltip title={'Order'} placement='bottom' arrow>
                      <TableHeaderCell>{'Order'}</TableHeaderCell>
                    </Tooltip>
                  </Header>
                </ScrollSyncPane>
                <Body>
                  {data.map((e: any, index) => {
                    return (
                      <ScrollSyncPane key={`tablerow-${index}`}>
                        <Row
                          odd={index % 2 == 0}
                          lastChild={index + 1 == data.length}
                          {...Theme.createOrderPopupStyles.row}
                        >
                          <TableCell>{index + 1}</TableCell>
                          {columnList.map((f: any, i: number) => (
                            <Tooltip
                              key={`tablecell-${index}-${i}`}
                              title={f !== 'tags' ? e[f] : 'tags'}
                            >
                              <TableCell>
                                {f !== 'tags'
                                  ? e[f]
                                  : e[f] && <TableTags tags={e[f]} />}
                              </TableCell>
                            </Tooltip>
                          ))}
                          <TableCell>
                            <input
                              type='text'
                              value={inputData[index].notes.value}
                              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                const newData = [...inputData]
                                newData[index].notes.value = e.target.value
                                setInputData(newData)
                              }}
                            />
                          </TableCell>
                          <TableInputCell
                            active={inputData[index].price.error.length > 0}
                          >
                            <input
                              type='number'
                              step='0.01'
                              value={inputData[index].price.value}
                              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                const newData = [...inputData]
                                newData[index].price.value = parseFloat(
                                  e.target.value
                                )
                                newData[index].price.error = validate(
                                  newData[index].price.rules,
                                  e.target.value
                                )
                                setInputData(newData)
                              }}
                            />
                          </TableInputCell>
                          <TableInputCell
                            active={inputData[index].quantity.error.length > 0}
                          >
                            <input
                              type='number'
                              value={inputData[index].quantity.value}
                              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                const newData = [...inputData]
                                newData[index].quantity.value = parseInt(
                                  e.target.value,
                                  10
                                )
                                newData[index].quantity.error = validate(
                                  newData[index].quantity.rules,
                                  e.target.value
                                )
                                setInputData(newData)
                              }}
                            />
                          </TableInputCell>
                        </Row>
                      </ScrollSyncPane>
                    )
                  })}
                </Body>
              </TableCont>
              <TableSecondRow>
                <SecondRowLeft>
                  <div>
                    <b>
                      Products count: {store.TableStore.selectedRowId.length}{' '}
                      Orders count:{'  '}
                      {inputData.reduce(
                        (partialSum, a) => partialSum + a.quantity.value,
                        0
                      )}
                    </b>
                  </div>
                  <div>
                    <p>{'Notes: '}</p>
                    <NotesInput
                      value={notes}
                      onChange={(e) => setNotes(e.target.value)}
                    />
                  </div>
                </SecondRowLeft>
                <SecondRowRight>
                  <div>
                    <p>{'Currency:'}</p>
                    <StyledSelect
                      active={currency !== 'Click to select!'}
                      onChange={(e) => setCurrency(e.target.value)}
                    >
                      <option value={'Click to select!'}>
                        {'Click to select!'}
                      </option>
                      {CurrencyList.map((e, index) => (
                        <option key={`option-currency-${index}`} value={e}>
                          {e}
                        </option>
                      ))}
                    </StyledSelect>
                  </div>
                  <div>
                    <p>{'Supplier:'}</p>
                    <StyledSelect
                      active={supplier !== 'Click to select!'}
                      onChange={(e) => setSupplier(e.target.value)}
                    >
                      <option value={'Click to select!'}>
                        {'Click to select!'}
                      </option>
                      {SupplierList.map((e, index) => (
                        <option key={`option-${index}`} value={e.name}>
                          {e.name}
                        </option>
                      ))}
                    </StyledSelect>
                  </div>
                  <PrimaryButton
                    {...Theme.buttons.primaryButton}
                    onClick={() => sendData()}
                  >
                    {locales.buttons.createPurchaseOrder}
                  </PrimaryButton>
                </SecondRowRight>
              </TableSecondRow>
            </>
          ) : (
            <LoadingContainer>
              <Wrap></Wrap>
            </LoadingContainer>
          )}
        </Container>
      </ScrollSync>
    </OuterCont>
  )
})
export default CreateOrderPopUp
