import { RootStore } from './Root.store'
import { makeAutoObservable } from 'mobx'

export class HeaderStore {
  rootStore

  constructor(rootStore: RootStore) {
    makeAutoObservable(this)
    this.rootStore = rootStore
  }
}
