import { action, makeAutoObservable } from 'mobx'
import { RootStore } from './Root.store'
import axios from '../axiosconfig'
import { toast } from 'react-toastify'
import removeAccents from 'remove-accents'

/* eslint-disable */
export class UserStore {
  rootStore
  userObject: { [key: string]: any } = {}
  user = ''
  id = ''
  userID = ''
  image = ''
  email = ''
  token = ''
  features: string[] = []
  featureId = ''
  mainLoaded = false
  defaultLanguage = 'en_GB'
  defaultTheme = 'default'
  defaultAction = {}

  constructor(rootStore: RootStore) {
    makeAutoObservable(this)
    this.rootStore = rootStore
  }

  @action.bound authenticate = async (
    name: string,
    image: string,
    token: string,
    userId: string,
    userObject: any
  ) => {
    this.user = removeAccents(name)
    this.image = image
    this.userID = userId
    this.userObject = userObject
    this.id = userObject.id
    const url = 'https://api.frontegg.com/auth/vendor/'
    const data = {
      clientId: `${process.env.REACT_APP_FRONTEGG_CLIENT_ID}`,
      secret: `${process.env.REACT_APP_FRONTEGG_SECRET}`,
    }
    const config = {}
    const res = await axios.post(url, data, config)
    this.token = await res.data.token
    await this.getAvaliableFeatures()
    if (this.id) await this.getDefaultSettings()
  }

  @action.bound getAvaliableFeatures = async () => {
    const url =
      'https://api.frontegg.com/entitlements/resources/feature-bundles/v1'
    const config = {
      headers: {
        authorization: `Bearer ${this.token}`,
      },
      params: {
        tenantIds: this.userObject.tenantId,
      },
    }

    const res = await axios.get(url, config)
    this.featureId = res.data.items[0].id

    const url2 = `https://api.frontegg.com/entitlements/resources/feature-bundles/v1/${this.featureId}`
    const config2 = {
      headers: {
        authorization: `Bearer ${this.token}`,
      },
      params: { offset: '0', limit: '100' },
    }
    const res2 = await axios.get(url2, config2)
    this.features = res2.data.features.map((feature: any) => feature.key)
    this.mainLoaded = true
  }

  @action.bound getDefaultSettings = async () => {
    const url = `${process.env.REACT_APP_BASE_URL}experience/v1/storesettings/usersettings/${this.id}`
    const config = {
      headers: {
        'x-apikey': `${process.env.REACT_APP_API_KEY}`,
        username: this.user,
      },
    }
    try {
      const response = await axios.get(url, config)
      this.defaultTheme = await response.data.user_default_theme_id
      this.defaultLanguage = await response.data.language
      this.defaultAction = (await response.data.default_action) || {}
    } catch (e) {
      const data = {
        language: 'en_GB',
        user_default_theme_id: 'default',
        default_action: {},
      }
      await axios.post(url, data, config)
      const response = await axios.get(url, config)
      this.defaultTheme = await response.data.user_default_theme_id
      this.defaultLanguage = await response.data.language
      this.defaultAction = (await response.data.default_action) || {}
    }
    this.rootStore.TranslationsState.setTranslations(this.defaultLanguage)
    this.rootStore.Theme.setTheme(this.defaultTheme)

    this.mainLoaded = true
  }

  @action.bound updateDefaultSettings = async (
    defaultLanguage: string,
    defaultTheme: string,
    defaultAction: any
  ) => {
    const url = `${process.env.REACT_APP_BASE_URL}experience/v1/storesettings/usersettings/${this.id}`
    const data = {
      language: defaultLanguage,
      user_default_theme_id: defaultTheme,
      default_action: defaultAction,
    }
    const config = {
      headers: {
        'x-apikey': `${process.env.REACT_APP_API_KEY}`,
        username: this.user,
      },
    }
    try {
      await axios.post(url, data, config)
      await toast('Saved', {
        type: 'success',
      })
      this.defaultLanguage = defaultLanguage
      this.defaultTheme = defaultTheme
    } catch (e: any) {
      toast(e.message.toString(), { type: 'error' })
    }
  }
}
