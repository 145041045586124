import { PropsWithChildren } from 'react'

interface Props extends PropsWithChildren {
  value: string
}
const TablePriorityMap = ({ value }: Props) => {
  const getValue = () => {
    switch (value) {
      case 'low':
        return 'high'
      case 'medium':
        return 'very high'
      case 'high':
        return 'extremly high'
      default:
        return 'default'
    }
  }
  return <>{getValue()}</>
}
export default TablePriorityMap
