import { observer } from 'mobx-react'
import { useContext } from 'react'
import { StoreContext } from '../../App'
import { HeadlineWrapper, MainTitle } from './Headline.styles'

interface HeadlineProps {
  title: string
  subTitle: string
}

const Headline = observer(({ title }: HeadlineProps) => {
  const store = useContext(StoreContext)
  const { Theme } = store.Theme

  return (
    <HeadlineWrapper>
      <div>
        <MainTitle {...Theme.headlineStyles.mainTitle}>{title}</MainTitle>
      </div>
    </HeadlineWrapper>
  )
})

export default Headline
