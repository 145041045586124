import styled from 'styled-components'
import { breakpoints } from '../../../static/breakpoints'

interface SelectTitleProps {
  margin?: string
}

export const SelectTitleImg = styled.img<SelectTitleProps>`
  height: 20px;
  margin: ${(props) => (props.margin ? props.margin : '3px 8px 4px 0px')};
`

interface SelectButtonProps {
  padding?: string
  borderRadius?: string
  border?: string
  margin?: string
  color?: string
  fontSize?: string
  fontWeight?: number
  lineHeight?: string
}

interface ColumnHeadProps {
  marginBottom?: string
  borderBottom?: string
  color?: string
}

export const ColumnHead = styled.div<ColumnHeadProps>`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${(props) =>
    props.marginBottom ? props.marginBottom : '8px'};
  border-bottom: ${(props) =>
    props.borderBottom ? props.borderBottom : '1px solid #e5e5e'};
  color: ${(props) => (props.color ? props.color : 'black')};
`

interface ChangesTopTextProps {
  fontSize?: string
  fontWeight?: number
  lineHeight?: string
}

export const ColumnHeadText = styled.div<ChangesTopTextProps>`
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : 600)};
  font-size: ${(props) => (props.fontSize ? props.fontSize : '14px')};
  line-height: ${(props) => (props.lineHeight ? props.lineHeight : '20px')};
  @media screen and (max-width: ${breakpoints.desktop}) {
  }
`

interface ColumnRowProps {
  marginBottom?: string
  borderBottom?: string
  color?: string
  active: boolean
}

export const ColumnRow = styled.div<ColumnRowProps>`
  margin-bottom: ${(props) =>
    props.marginBottom ? props.marginBottom : '4px'};
  border-bottom: ${(props) =>
    props.borderBottom ? props.borderBottom : '1px solid #e5e5e'};
  display: flex;
  justify-content: space-between;
  color: ${(props) => (props.color ? props.color : 'black')};
  background-color: ${(props) =>
    props.active ? 'rgba(221,175,77,0.4)' : 'transparent'};
`
export const AllColumnRow = styled(ColumnRow)`
  display: flex;
  align-items: center;
  padding: 8px 4px 4px;
  border-radius: 8px;
`

interface ColumnTextProps {
  fontSize?: string
  padding?: string
  lineHeight?: string
}

export const ColumnText = styled.div<ColumnTextProps>`
  font-size: ${(props) => (props.fontSize ? props.fontSize : '12px')};
  line-height: ${(props) => (props.lineHeight ? props.lineHeight : '16px')};
  padding: ${(props) => (props.padding ? props.padding : '4px')};
  @media screen and (max-width: ${breakpoints.desktop}) {
  }
`

interface StyledInputProps {
  background?: string
  borderRadius?: string
  boxShadow?: string
  border?: string
}

export const StyledInput = styled.input<StyledInputProps>`
  background: #ffffff;
  border: ${(props) => (props.border ? props.border : '1px solid #d6d6d6')};
  margin-right: 8px;
  box-shadow: ${(props) =>
    props.boxShadow ? props.boxShadow : '0 1px 3px rgba(0, 0, 0, 0.06)'};
  border-radius: ${(props) =>
    props.borderRadius ? props.borderRadius : '6px'};
`

interface StyledWrapProps {
  padding?: string
  mobilePadding?: string
  mobile?: boolean
}

/* eslint-disable */
export const SelectWrap = styled.div<StyledWrapProps>`
  box-sizing: border-box;
  padding: ${(props) =>
    props.mobile
      ? props.mobilePadding
        ? props.mobilePadding
        : '12px 24px'
      : '4px 24px'};
  position: relative;
  width: 100%;
  max-width: 400px;
`
