import styled from 'styled-components'

interface ContainerInterface {
  color: string
}
export const Container = styled.div<ContainerInterface>`
  background-color: ${(props) => props.color};
  color: white;
  height: min-content;
  padding: 4px;
  border-radius: 16px;
  display: flex;
  margin: 2px;
  font-size: 12px;
  div {
    margin-right: 4px;
  }
`
export const DayContainer = styled(Container)`
  flex-direction: column;
`
