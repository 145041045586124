import { observer } from 'mobx-react'
import React, {
  ChangeEvent,
  DragEvent,
  FC,
  PropsWithChildren,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'

import { StoreContext } from '../../App'
/* eslint-disable */
import {
  ActiveButton,
  Body,
  Container,
  Footer,
  Header,
  ModalCont,
  PrimaryButton,
  SecondaryButton,
  StyledModal,
  Text,
  ViewButton,
  SelectInput,
  SelectCont,
  InnerBody,
  TextArea,
  ButtonCont,
  Label,
  SeparatorCont,
  ContDrop,
  DropArea,
  InnerContDrop,
  SelectedFile,
} from './ImportSelect.styles'
import { DefaultColumnSelect } from '../headerFilter/HeaderFilter.styles'
import SearchSelectComponent from '../searchSelect/SearchSelect.component'
import axios from 'axios'
import { toast } from 'react-toastify'
import Cross from '../../assets/icons/cross-white.svg'
import Select from 'react-select'
import { FileDrop } from 'react-file-drop'

interface Props extends PropsWithChildren {
  allColumns: any
}

interface FileBlob extends Blob {
  name: string
}

const TableLock: FC<Props> = observer(({ allColumns }: Props) => {
  const store = useContext(StoreContext)
  const { Theme } = store.Theme
  const locales = store.TranslationsState.translations

  const [openModal, setOpenModal] = useState(false)
  const [selectedText, setSelectedText] = useState(true)
  const [selectedColumn, setSelectedColumn] = useState('')
  const [selectedOptions, setSelectedOptions] = useState<any[]>([])
  const [textInput, setTextInput] = useState('')
  const [separatorValue, setSeparatorValue] = useState(',')
  const [file, setFile] = useState<FileBlob | null>(null)

  const inputRef = useRef<HTMLInputElement>()

  const separatorOptions = [
    { value: ',', label: 'Comma (,)' },
    { value: ';', label: 'Semicolon (;)' },
    { value: '\n', label: 'New Line' },
  ]

  const sendData = async () => {
    if (!selectedColumn) {
      toast('Select Column!', { type: 'error' })
    } else {
      const array = [
        ...textInput
          .split(separatorValue)
          .map((item) => item.replace(/[\r\n]/g, ''))
          .filter((e) => e.length),
      ]
      const url = `${process.env.REACT_APP_BASE_URL}product/v1/search_by_file/${selectedColumn}`
      const data = {
        payload: array,
      }
      const config = {
        headers: {
          'x-apikey': `${process.env.REACT_APP_API_KEY}`,
          username: store.UserStore.user,
        },
      }
      const response = await axios.post(url, data, config)

      await store.TableStore.setSelectedRows(response.data.ids)
      await store.TableStore.fetchData(true)
      await setOpenModal(false)
      await store.SlidingPanelState.setVisibility(false)
    }
  }

  const filePicker = () => {
    if (inputRef.current) inputRef.current.click()
  }

  const onFileInputChange = async (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files !== null) {
      setFile(e.target.files[0])
    }
  }

  const fileHandler = (
    files: FileList | null,
    e: DragEvent<HTMLDivElement>
  ) => {
    e.preventDefault()
    if (files) setFile(files[0])
  }

  useEffect(() => {
    const options: any[] = [
      ...allColumns.map((e: any) => {
        if (e.Header && e.editCustomOptions.editType === 'text')
          return { label: e.Header, value: e.id }
      }),
    ]
    setSelectedOptions(options.filter((e: any) => e))
  }, [])

  useEffect(() => {
    const loadFile = async () => {
      if (file) {
        const reader = new FileReader()

        reader.onload = (event) => {
          const csvData = event.target?.result
          if (csvData?.toString()) {
            setTextInput(csvData?.toString())
            setSelectedText(true)
            setFile(null)
          }
        }

        reader.readAsText(file)
      }
    }

    loadFile()
  }, [file])

  return (
    <Container>
      <PrimaryButton onClick={() => setOpenModal(true)}>{'Add'}</PrimaryButton>
      <StyledModal open={openModal} onClose={() => setOpenModal(false)}>
        <ModalCont>
          <Header style={{ display: 'flex' }}>
            <ViewButton
              selected={selectedText}
              view={'left'}
              onClick={() => setSelectedText(false)}
            >
              Import From File
            </ViewButton>
            <ViewButton
              view={'right'}
              selected={!selectedText}
              onClick={() => setSelectedText(true)}
            >
              Text Input
            </ViewButton>
          </Header>
          <Body>
            <SelectCont>
              <Text>Select Column:</Text>
              <SearchSelectComponent
                Container={SelectInput}
                value={selectedColumn}
                onClick={(e) => {
                  setSelectedColumn(e.value)
                }}
                emptyValue={'No column...'}
                options={[{ value: '', label: ' ' }, ...selectedOptions]}
              />
            </SelectCont>
            {!selectedText ? (
              <InnerBody>
                <Text>Import From File</Text>
                {file ? (
                  <SelectedFile {...Theme.tableBankExportsStyles.selectedFile}>
                    <Text>
                      <p>{file.name}</p>
                    </Text>
                  </SelectedFile>
                ) : (
                  <>
                    <FileDrop
                      onTargetClick={filePicker}
                      onDrop={(f, e) => fileHandler(f, e)}
                    >
                      <DropArea {...Theme.tableBankExportsStyles.dropArea}>
                        <Text>DRAG FILE HERE OR BROWSE</Text>
                        <input
                          value=''
                          style={{ display: 'none' }}
                          //@ts-ignore
                          ref={inputRef}
                          type='file'
                          onChange={(e: ChangeEvent<HTMLInputElement>) =>
                            onFileInputChange(e)
                          }
                        />
                      </DropArea>
                    </FileDrop>
                  </>
                )}
              </InnerBody>
            ) : (
              <InnerBody>
                <Text>Text Input</Text>
                <TextArea
                  value={textInput}
                  onChange={(e) => setTextInput(e.target.value)}
                />
                <SeparatorCont>
                  <Text>Text Separator:</Text>
                  {separatorOptions.map((option, index) => (
                    <Label key={index}>
                      <input
                        type='radio'
                        value={option.value}
                        checked={separatorValue === option.value}
                        onChange={(e) => setSeparatorValue(e.target.value)}
                      />
                      <Text>{option.label}</Text>
                    </Label>
                  ))}
                </SeparatorCont>
                <ButtonCont>
                  <PrimaryButton onClick={() => sendData()}>
                    {'Confirm'}
                  </PrimaryButton>
                </ButtonCont>
              </InnerBody>
            )}
          </Body>
          <Footer>
            <SecondaryButton
              onClick={() => setOpenModal(false)}
              {...Theme.buttons.secondaryButton}
            >
              {'CANCEL'}
            </SecondaryButton>
          </Footer>
        </ModalCont>
      </StyledModal>
    </Container>
  )
})
export default TableLock
