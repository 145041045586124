import styled from 'styled-components'
import { darken } from 'polished'
import { breakpoints } from '../../../static/breakpoints'

/* eslint-disable */
interface SettingsCategoryInterface {
  active: boolean
}

export const SettingsCategory = styled.div<SettingsCategoryInterface>`
  display: flex;
  z-index: 999;
  background-color: white;
  position: fixed;
  right: 0;
  flex-direction: column;
  border: 1px solid gray;
  box-sizing: border-box;
  width: 300px;
  transform: ${(props) => (props.active ? 'none' : 'translatex(300px)')};
  max-height: max(100vh);
  overflow-y: scroll;

  h2 {
    font-size: 18px;
    border-bottom: 1px solid gray;
    margin: 24px 0 4px 0;
    padding: 0 0 0 0;
  }

  h4 {
    font-size: 14px;
    color: #404040;
  }
`

export const Setting = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;

  select {
    color: black;
    border: 1px solid gray;
    padding: 10px;
    margin-bottom: 20px;
  }
`

export const SingleGroup = styled.div`
  border: 1px solid #303030;
  padding: 4px;
  border-radius: 4px;
  margin: 4px;
`
export const Footer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: sticky !important;
  bottom: 0;
  width: 100%;
  background: rgba(255, 255, 255, 0.8);

  button {
    margin: 4px;
  }
`
export const Label = styled.span`
  font-weight: 500;
`
export const ButtonCont = styled.div`
  display: flex;
  margin-top: 8px;
`

interface PrimaryButtonProps {
  padding?: string
  bgColor?: string
  borderRadius?: string
  fontSize?: string
  fontWeight?: number
  lineHeight?: string
  letterSpacing?: string
  color?: string
}

export const PrimaryButton = styled.button<PrimaryButtonProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: ${(props) => (props.padding ? props.padding : '8px 16px')};
  background-color: ${(props) => (props.bgColor ? props.bgColor : '#303030')};
  border-radius: ${(props) =>
    props.borderRadius ? props.borderRadius : '6px'};
  white-space: nowrap;
  text-transform: uppercase;
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : 600)};
  font-size: ${(props) => (props.fontSize ? props.fontSize : '14px')};
  line-height: ${(props) => (props.lineHeight ? props.lineHeight : '16px')};
  letter-spacing: ${(props) =>
    props.letterSpacing ? props.letterSpacing : '0.5px'};
  color: ${(props) => (props.color ? props.color : '#fafafa')};
  transition: background-color 0.3s, transform 0.2s;

  &:hover {
    background-color: ${(props) =>
      darken(0.1, props.bgColor ? props.bgColor : '#303030')};
    transform: scale(1.02);
  }

  &:active {
    transform: scale(0.98);
  }
`
export const InnerCont = styled.div`
  margin-top: 8px;
  padding: 8px;
`
export const Entry = styled.div`
  display: flex;
  flex-direction: column;

  input {
    border-bottom: 1px solid #e5e5e5;
  }
`
export const FooterInputCont = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  p {
    font-weight: 700;
  }

  > * {
    margin-left: 4px;
  }
`
export const StyledInput = styled.input`
  border: 1px solid black;
  border-radius: 4px;
`

interface ThemeOpenButtonInterface {
  active: boolean
}

export const ThemeOpenMenu = styled.div<ThemeOpenButtonInterface>`
  position: fixed;
  right: ${(props) => (props.active ? '285px' : '-20px')};
  background-color: #f3f3f3;
  z-index: 999999;
  border-radius: 12px;
  top: calc(50% - 25px);
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease-in-out;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
  cursor: pointer;

  * {
    transition: all 0.2s ease-in-out;
  }

  svg {
    margin-left: ${(props) => (props.active ? '0' : '-14px')};
    width: 24px;
  }

  @media screen and (max-width: ${breakpoints.tablet}) {
    left: 50%;
    position: relative;
    top: -5px;
    svg {
      rotate: 90deg;
    }
  }
`
