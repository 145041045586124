/* eslint-disable */
import { observer } from 'mobx-react'
import React, {
  ChangeEvent,
  FC,
  memo,
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from 'react'
import { HeaderGroup, Row, SortingRule } from 'react-table'

import { StoreContext } from '../../../App'
import { GlobalFilter } from '../../filters/Filters.components'
import TableHeaderOptions from '../tableHeaderOptions/TableHeaderOptions.component'

import {
  TableHead,
  TableHead2,
  TableHeaderRow,
  TableRow,
} from './TableHeader.styles'
import { ScrollSyncPane } from 'react-scroll-sync'
import HeaderFilter from '../../headerFilter/HeaderFilter.component'
import {
  LeftInnerPagination,
  LeftInnerPaginationBot,
  LeftInnerPaginationTop,
  OptionsSelect,
  PaginationWrapper,
  RightInnerPagination,
  RowsPerPage,
  SelectedCount,
  StyledPaginator,
} from '../paginator/Paginator.styles'
import { mainTheme as theme } from '../../../static/themes/main.theme'
import useWindowDimensions from '../../../methods/hooks/useWindowDimensions'
import { QueryRulesCont } from '../table/Table.styles'
import { Tooltip } from '@mui/material'
import SearchFieldButton from '../searchFieldButton/SearchFieldButton.component'
import ClearFilters from '../../../assets/icons/clear-filters.svg'

interface Props extends PropsWithChildren {
  columnWidths?: any
  headerGroups: HeaderGroup<object>[]
  columnWidth: boolean
  fetchData: () => void
  filterSwitch: boolean
  search: boolean
  preGlobalFilteredRows: Row<object>[]
  globalFilter: string | undefined
  setGlobalFilter: (value: string | undefined) => void
  setSortBy: (sortBy: SortingRule<object>[]) => void
  change: boolean
  loaded: boolean
  toggleChange: (val: boolean) => void
  setColumnOrder: (updater: Array<string>) => void
  pagination?: {
    tableSize: number
    pageSize: number
    pageIndex: number
    totalCount: number
  }
  setPagination?: (
    tableSize: number,
    pageSize: number,
    pageIndex: number,
    totalCount: number
  ) => void
  infinity?: boolean
}

const TableHeader: FC<Props> = observer((props) => {
  const store = useContext(StoreContext)
  const { Theme } = store.Theme
  const { width } = useWindowDimensions()
  const locales = store.TranslationsState.translations
  const [clearFiltersVisible, setClearFiltersVisible] = useState(false)
  const handlePageChange = (val: number) => {
    if (props.pagination && props.setPagination) {
      props.setPagination(
        props.pagination.tableSize,
        props.pagination.pageSize,
        val,
        props.pagination.totalCount
      )
    }
  }

  const handlePageSize = (e: ChangeEvent<HTMLSelectElement>) => {
    if (
      e.target.value === locales.buttons.selectAll &&
      props.pagination &&
      props.setPagination
    ) {
      props.setPagination(
        props.pagination.tableSize,
        props.pagination.tableSize,
        1,
        props.pagination.totalCount
      )
    } else if (props.pagination && props.setPagination)
      props.setPagination(
        props.pagination.tableSize,
        parseInt(e.target.value),
        1,
        props.pagination.totalCount
      )
  }

  // set clear filters button visibility if page is scrolled
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setClearFiltersVisible(true)
      } else {
        setClearFiltersVisible(false)
      }
    }
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return (
    <TableHead {...Theme.tableStyles.tableHead}>
      {props.headerGroups.map((headerGroup: any, index) => (
        <ScrollSyncPane key={'headerGroup' + index}>
          <TableRow
            {...Theme.tableStyles.tableRow}
            {...headerGroup.getHeaderGroupProps({
              style: { minWidth: '1px' },
            })}
          >
            {headerGroup.headers.map((columns: any, index: number) => (
              <TableHeaderRow
                {...columns.getHeaderProps()}
                key={'header' + index}
              >
                <TableHeaderOptions
                  columns={columns}
                  setSortBy={props.setSortBy}
                  columnWidth={props.columnWidth}
                  change={props.change}
                  toggleChange={props.toggleChange}
                  index={index}
                  identifier={'main'}
                  setColumnOrder={props.setColumnOrder}
                  keys={[
                    ...headerGroup.headers.map((e: { id: string }) => {
                      return e.id
                    }),
                  ]}
                />
                {columns.canFilter &&
                  columns.headerCustomOptions.canFilter &&
                  !props.filterSwitch &&
                  props.search &&
                  columns.id !== 'selection' &&
                  columns.id !== '_options' && (
                    <HeaderFilter
                      column={columns}
                      fetchData={props.fetchData}
                    />
                  )}
              </TableHeaderRow>
            ))}
          </TableRow>
        </ScrollSyncPane>
      ))}
      {!props.filterSwitch && props.search ? (
        <TableRow {...Theme.tableStyles.tableRow}>
          <TableHead2>
            <GlobalFilter fetchData={props.fetchData} />
            {!props.infinity && (
              <PaginationWrapper {...Theme.paginatorStyles.paginationWrapper}>
                {store.TableStore.appliedQuery.rules.length > 0 &&
                store.RouteStore.currentDataId === '' &&
                clearFiltersVisible ? (
                  <QueryRulesCont>
                    <Tooltip
                      arrow
                      title={`Filters: ${store.TableStore.appliedQuery.rules.map(
                        (e: any) => {
                          if (e.field && e.operator && e.value) {
                            return `${e.field} ${e.operator} ${e.value}, `
                          }
                        }
                      )}`}
                    >
                      <div>
                        <SearchFieldButton
                          text={'Clear Filters'}
                          onClick={() => {
                            store.TableStore.clearQuery()
                            store.TableStore.applyQuery(props.fetchData)
                          }}
                          icon={<img src={ClearFilters} />}
                        />
                      </div>
                    </Tooltip>
                  </QueryRulesCont>
                ) : (
                  <></>
                )}
                <LeftInnerPagination>
                  <LeftInnerPaginationTop>
                    {props.loaded && props.pagination && props.setPagination ? (
                      <StyledPaginator
                        count={Math.ceil(
                          props.pagination.tableSize / props.pagination.pageSize
                        )}
                        page={props.pagination.pageIndex}
                        onChange={(e, val) => handlePageChange(val)}
                        showFirstButton
                        showLastButton
                        size={width < 600 ? 'small' : 'medium'}
                        {...Theme.paginatorStyles.styledPaginator}
                      />
                    ) : (
                      <></>
                    )}
                  </LeftInnerPaginationTop>
                  <LeftInnerPaginationBot>
                    {props.loaded && props.pagination && props.setPagination ? (
                      <SelectedCount>
                        {(props.pagination.pageIndex - 1) *
                          props.pagination.pageSize +
                          1}
                        -
                        {props.pagination.pageIndex * props.pagination.pageSize}{' '}
                        {locales.pagination.of} {props.pagination.tableSize}{' '}
                        {locales.pagination.entries}
                        {store.TableStore.appliedQuery.rules.length
                          ? ` (Filtered from ${props.pagination.totalCount})`
                          : ''}
                      </SelectedCount>
                    ) : (
                      <></>
                    )}
                  </LeftInnerPaginationBot>
                </LeftInnerPagination>
                <RightInnerPagination>
                  <RowsPerPage {...theme.paginatorStyles.rowsPerPage}>
                    {locales.pagination.showing}
                  </RowsPerPage>
                  {props.loaded && props.pagination ? (
                    <OptionsSelect
                      value={props.pagination.pageSize}
                      onChange={(e) => handlePageSize(e)}
                      {...theme.paginatorStyles.optionsSelect}
                    >
                      {[8, 10, 20, 50, 100, 200, 500, 1000].map((pageSize) => (
                        <option
                          key={pageSize}
                          id={pageSize.toString()}
                          value={pageSize}
                        >
                          {pageSize}
                        </option>
                      ))}
                    </OptionsSelect>
                  ) : (
                    <></>
                  )}
                </RightInnerPagination>
              </PaginationWrapper>
            )}
          </TableHead2>
        </TableRow>
      ) : null}
    </TableHead>
  )
})
export default TableHeader
