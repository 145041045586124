import { observer } from 'mobx-react'
import React, {
  FC,
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from 'react'

import { StoreContext } from '../../App'
import QueryBuilder from '../queryBuilder/QueryBuilder.component'
/* eslint-disable */
import { QueryPredefinedFilters } from './Views.methods'

import {
  CheckBoxCont,
  FooterCont,
  LeftFilter,
  PrimaryButton,
  QueryBuilderWrap,
  QueryFilterWrap,
  RightFilter,
  SaveInput,
  SecondaryButton,
  SelectButtonCont,
  SelectCont,
  SelectInput,
  SelectLabel,
  StyledParagraph,
  ViewButton,
  Header,
} from './Views.styles'
import { toast } from 'react-toastify'
import SearchSelectComponent from '../searchSelect/SearchSelect.component'
import { toJS } from 'mobx'

interface Props extends PropsWithChildren {
  tableName: string
  pagination?: number
  fetchData: any
}

const Views: FC<Props> = observer(({ tableName, pagination, fetchData }) => {
  const store = useContext(StoreContext)
  const { Theme } = store.Theme
  const locales = store.TranslationsState.translations

  const [createMode, setCreateMode] = useState(false)
  const [saveValue, setSaveValue] = useState('')
  const [reset, setReset] = useState(false)
  const setSaveView = async () => {
    await store.ViewStore.saveView(saveValue, tableName, true, pagination)
    await toast('Saved', {
      type: 'success',
    })
    await store.SlidingPanelState.setVisibility(false)
  }

  const deleteView = (view: { value: string; label: string }) => {
    const savedView = localStorage.getItem(`viewObject-${view.value}`)
    if (savedView) {
      localStorage.removeItem(`viewObject-${view.value}`)
      toast('View deleted', {
        type: 'success',
      })
    } else {
      toast('View not found', {
        type: 'error',
      })
    }
  }

  useEffect(() => {
    if (store.TableStore.loaded) {
      if (!reset) setReset(true)
      else store.ViewStore.setSelectedView('')
    }
  }, [
    store.ViewStore.hiddenColumns,
    store.ViewStore.columnOrder,
    store.ViewStore.columnColors,
    store.ViewStore.columnBorder,
    store.TableStore.clipTextColumns,
    store.TableStore.query,
    store.TableStore.appliedPredefinedQuery,
  ])

  return (
    <SelectCont>
      <Header>
        <ViewButton
          selected={createMode}
          view={'left'}
          onClick={() => setCreateMode(false)}
        >
          Predefined View
        </ViewButton>
        <ViewButton
          view={'right'}
          selected={!createMode}
          onClick={() => setCreateMode(true)}
        >
          New View
        </ViewButton>
      </Header>
      {createMode ? (
        <>
          <SelectLabel>Set a name for this view</SelectLabel>
          <SaveInput
            value={saveValue}
            onChange={(e) => setSaveValue(e.target.value)}
          />
          <FooterCont>
            <SecondaryButton
              onClick={() => store.SlidingPanelState.setVisibility(false)}
              {...Theme.buttons.secondaryButton}
            >
              {locales.buttons.cancel}
            </SecondaryButton>
            <PrimaryButton
              onClick={() => {
                setSaveView()
              }}
              {...Theme.buttons.primaryButton}
            >
              {'SAVE'}
            </PrimaryButton>
          </FooterCont>
        </>
      ) : (
        <>
          <SelectLabel>Select view:</SelectLabel>
          <SearchSelectComponent
            Container={SelectInput}
            value={store.ViewStore.selectedView}
            options={store.ViewStore.getViewsList(true)}
            onClick={(e) => {
              store.ViewStore.setSelectedView(e.value)
            }}
            deleteFunction={(e) => deleteView(e)}
          />
          {store.ViewStore.selectedView.length ? (
            <FooterCont>
              <SecondaryButton
                onClick={() => store.SlidingPanelState.setVisibility(false)}
                {...Theme.buttons.secondaryButton}
              >
                {locales.buttons.cancel}
              </SecondaryButton>
              <PrimaryButton
                onClick={() =>
                  store.ViewStore.applyView(
                    store.ViewStore.selectedView,
                    fetchData
                  )
                }
                {...Theme.buttons.primaryButton}
              >
                {'APPLY'}
              </PrimaryButton>
            </FooterCont>
          ) : (
            <></>
          )}
        </>
      )}
    </SelectCont>
  )
})
export default Views
