import { observer } from 'mobx-react'
import {
  ButtonCont,
  Label,
  PrimaryButton,
  Setting,
  SettingsCategory,
  StyledLink,
} from '../../../pages/settings/settings.styles'
import React, { useContext, useEffect, useState } from 'react'
import { StoreContext } from '../../../App'
import { StringArrayInput } from '../tags/edits/arrayInput/ArrayInput.component'
import Select from 'react-select'
import { toJS } from 'mobx'
import axios from '../../../axiosconfig'
import { toast } from 'react-toastify'
/* eslint-disable */
const StoreSettingsComponent = observer(() => {
  const store = useContext(StoreContext)
  const { Theme } = store.Theme
  const [loaded, setLoaded] = useState(false)
  const [data, setData] = useState<any>({
    shop_currencies: [],
    currencies: [],
    default_currency: '',
    language: '',
    timezone: {
      tz: 'Europe/Warsaw',
    },
    customer_orders_count: 0,
    purchase_orders_count: 0,
  })

  const selectedCurrencies = data.shop_currencies
    ? data.shop_currencies.map((currencyId: any) => {
        return {
          value: currencyId,
          label: `${currencyId.toUpperCase()}`,
        }
      })
    : []

  const currencyOptions = data.currencies
    ? data.currencies.map((currency: any) => ({
        value: currency.id,
        label: `${currency.id.toUpperCase()}`,
      }))
    : {}

  const handleChange = (selectedOptions: any, key: string) => {
    setData({
      ...data,
      [key]: selectedOptions,
    })
  }

  const handleSave = async () => {
    const url = `${process.env.REACT_APP_BASE_URL}experience/v1/storesettings`
    const config = {
      headers: {
        'x-apikey': `${process.env.REACT_APP_API_KEY}`,
        username: store.UserStore.user.length
          ? store.UserStore.user
          : 'No data',
      },
    }
    console.log(url, toJS(data), config)
    await axios.post(url, data, config)
    await store.SlidingPanelState.setVisibility(false)
    await toast('Success!', {
      type: 'success',
      autoClose: 5000,
    })
    await store.StoreSettingsStore.fetchAllData()
  }

  useEffect(() => {
    store.StoreSettingsStore.fetchAllData()
    store.StoreSettingsStore.fetchData('timezones')
  }, [])

  useEffect(() => {
    console.log(toJS(data))
    console.log(toJS(store.StoreSettingsStore.data))
  }, [data])

  useEffect(() => {
    const tempData: any = store.StoreSettingsStore.allData
    setData(tempData)
    setLoaded(true)
  }, [store.StoreSettingsStore.allData])

  if (loaded)
    return (
      <SettingsCategory>
        <h1>Store Settings Options</h1>
        <h2></h2>

        <Setting>
          <Label>Shop Currencies:</Label>
          <Select
            isMulti
            value={selectedCurrencies}
            onChange={(e) => {
              const newShopCurrencies = e.map((option) => option.value)
              handleChange(newShopCurrencies, 'shop_currencies')
            }}
            styles={{
              control: (provided) => ({
                ...provided,
                borderColor: 'black',
                marginBottom: '20px',
                borderRadius: '0px',
                '&:hover': {
                  borderColor: 'black',
                },
              }),
            }}
            options={currencyOptions}
          />
        </Setting>

        <Setting>
          <Label>Default Currency:</Label>
          <select
            value={data.default_currency}
            onChange={(e) => {
              handleChange(e.target.value, 'default_currency')
            }}
          >
            {data.currencies ? (
              data.currencies.map((e: any, index: number) => (
                <option key={`currency-option-${index}`} value={e.id}>
                  {e.id}
                </option>
              ))
            ) : (
              <></>
            )}
          </select>
        </Setting>

        <Setting>
          <Label>Timezone:</Label>
          <select
            value={data.timezone && data.timezone.id}
            onChange={(e) => {
              let timeZone: any = toJS(
                store.StoreSettingsStore.data.find(
                  (f: any) => f.id === e.target.value
                )
              )
              if (timeZone) {
                console.log(timeZone)
                handleChange(timeZone, 'timezone')
              }
            }}
          >
            {store.StoreSettingsStore.data ? (
              store.StoreSettingsStore.data.map((e: any, index: number) => (
                <option key={`timezone-option-${index}`} value={e.id}>
                  {`${e.id} (${e.abbr}, ${e.offset})`}
                </option>
              ))
            ) : (
              <></>
            )}
          </select>
        </Setting>

        <Setting>
          <Label>Language:</Label>
          <select
            value={data.language}
            onChange={(e) => {
              handleChange(e.target.value, 'language')
            }}
          >
            {store.TranslationsState.avaliableLanguages.map((langKey) => (
              <option key={`themeKey-${langKey}`} value={langKey}>
                {langKey}
              </option>
            ))}
          </select>
        </Setting>

        <PrimaryButton
          onClick={() => handleSave()}
          {...Theme.buttons.primaryButton}
        >
          {'SAVE'}
        </PrimaryButton>
      </SettingsCategory>
    )
  else return <></>
})
export default StoreSettingsComponent
