import { observer } from 'mobx-react'
import React, { useContext, useEffect } from 'react'
import { StoreContext } from '../../../App'
import Table from '../../TableGeneric/table/Table.component'
import { Cell } from 'react-table'
import TableStockInProgress from '../../TableGeneric/tableStockInProgress/TableStockInProgress.component'
import ParcelsComponent from './parcels/Parcels.component'
import SenderComponent from './sender/Sender.component'
import RecevierComponent from './receiver/Recevier.component'
import URL from '../../../assets/icons/url.png'
import Edit from '../../../assets/icons/edit.svg'
import TableDate from '../../TableGeneric/tableDate/TableDate.component'
import TableLock from '../../TableGeneric/tableLock/TableLock.component'
import { ActiveButton } from '../../TableGeneric/tableLock/TableLock.styles'
import { NavLink } from 'react-router-dom'
import { Container } from '../../../styles/LayoutStyles'

export const PackagesTableComponent = observer(() => {
  const store = useContext(StoreContext)
  const { Theme } = store.Theme
  const locales = store.TranslationsState.translations
  const tableStore = store.TableStore

  const serviceList = [
    'ambroexpress',
    'deligoo',
    'dhl',
    'dpd',
    'fedex',
    'furgonetka',
    'gls',
    'inpost',
    'meest',
    'orlen',
    'poczta_kurier48',
    'poczta_ekspres24',
    'ups',
    'xpressdelivery',
    'pocztex',
    'pallex',
    'swiatprzesylek',
    'wza',
    'postivo',
  ]

  const stateList = [
    'waiting',
    'ordered',
    'collect-problem',
    'collected',
    'transit',
    'delivery',
    'delivery-problem',
    'delivered',
    'unexpected-situation',
    'canceled',
    'returned',
    'ready-for-transport',
    'waiting-for-receiver',
  ]

  const columns = {
    id: {
      canFilter: false,
    },
    user_reference_number: {
      Cell: (cell: Cell) => (
        <NavLink to={`/customerorders/sklep440800_${cell.value}`}>
          <ActiveButton active={true} {...Theme.tableLockStyles.activeButton}>
            {cell.value}
          </ActiveButton>
        </NavLink>
      ),
      canFilter: true,
      editType: 'number',
    },
    service: {
      canFilter: true,
      editType: 'select',
      selectValues: serviceList.map((service) => ({
        value: service.toString(),
        label: service.toString(),
      })),
    },
    state: {
      canFilter: true,
      editType: 'select',
      selectValues: stateList.map((state) => ({
        value: state.toString(),
        label: state.toString(),
      })),
    },
    name: {
      canFilter: false,
    },
    package_id: {
      canFilter: false,
    },
    sender: {
      Cell: (cell: Cell) => (
        <SenderComponent data={cell.value} secondary={true} />
      ),
      canFilter: false,
    },
    pickup: {
      Cell: (cell: Cell) => (
        <RecevierComponent data={cell.value} secondary={true} />
      ),
      canFilter: false,
    },
    receiver: {
      Cell: (cell: Cell) => (
        <RecevierComponent data={cell.value} secondary={true} />
      ),
      canFilter: false,
    },
    parcels: {
      Cell: (cell: Cell) => (
        <div>
          {cell.value &&
            cell.value.map((f: any, i: number) => {
              return <ParcelsComponent data={f} key={i} secondary={true} />
            })}
        </div>
      ),
      canFilter: false,
    },
    documents_url: {
      Cell: (cell: Cell) => (
        <a href={cell.value} target={'_blank'} rel='noreferrer'>
          <img style={{ width: '24px' }} src={URL} />
        </a>
      ),
      canFilter: false,
    },
    add_similar_url: {
      Cell: (cell: Cell) => (
        <a href={cell.value} target={'_blank'} rel='noreferrer'>
          <img style={{ width: '24px' }} src={URL} />
        </a>
      ),
      canFilter: false,
    },
    edit_url: {
      Cell: (cell: Cell) => (
        <a href={cell.value} target={'_blank'} rel='noreferrer'>
          {cell.value ? <img style={{ width: '24px' }} src={Edit} /> : <></>}
        </a>
      ),
      canFilter: false,
    },
    datetime_order: {
      Cell: (cell: Cell) => <TableDate date={cell.value} />,
      canFilter: true,
      editType: 'date',
    },
    datetime_add: {
      Cell: (cell: Cell) => <TableDate date={cell.value} />,
      canFilter: false,
    },
    datetime_delivery: {
      Cell: (cell: Cell) => <TableDate date={cell.value} />,
      canFilter: false,
    },
    delivery_time: {
      Cell: (cell: Cell) => <TableDate date={cell.value} />,
      canFilter: false,
    },
  }

  useEffect(() => {
    store.PackagesStore.fetchData()
    return () => store.PackagesStore.clearData()
  }, [])
  return (
    <>
      <Container>
        <Table
          config={{
            tableName: 'packages',
            data: store.PackagesStore.data || [],
            fetchData: () => store.PackagesStore.fetchData(),
            columnHeaders: columns,
            columnVisibility: true,
            allowColumnResize: true,
            displaySearch: true,
            displaySelect: true,
            displayExpand: false,
            displayTooltip: false,
            displayEntry: false,
            displayPaginator: true,
            displayInfinitySwitch: false,
            allowSort: true,
            bank: false,
            actions: {
              display: true,
              label: 'Packages',
              edit: null,
              create: null,
              remove: null,
              assignTag: null,
              custom: ['getPackageDocuments'],
            },
            headline: {
              visible: store.Theme.Navigation === 'side',
              title: 'Packages List',
              subTitle: 'Packages List',
            },
            breadcrumb: [],
            loaded: store.PackagesStore.loaded,
            pagination: store.PackagesStore.pagination,
            setPagination: store.PackagesStore.setPagination,
          }}
        ></Table>
      </Container>
    </>
  )
})
