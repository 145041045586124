import { observer } from 'mobx-react'
import React, {
  FC,
  PropsWithChildren,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import { StoreContext } from '../../../App'
import {
  ActionsPrimaryButton,
  NextStepCont,
  PrimaryButtonCont,
} from '../../../pages/proformsDetails/ProformsDetails.styles'
import ArrowExpanded from '../../../assets/icons/arrow-expanded.svg'
import TasksDropDownComponent from '../tasksDropDown/TasksDropDown.component'
import axios from '../../../axiosconfig'
import { setDefaultDetails } from '../../../pages/proformsDetails/proformsDetails.methods'
import { useParams } from 'react-router-dom'
import { socket } from '../../../socket/socket'
import { toast } from 'react-toastify'

interface Props extends PropsWithChildren {}

const StepsButton: FC<Props> = observer(() => {
  const store = useContext(StoreContext)

  const [productList, setProductList] = useState(false)
  const [nextStep, setNextStep] = useState<{ value: string; label: string }>({
    value: '',
    label: '',
  })
  const [currentStep, setCurrentStep] = useState<{
    value: string
    label: string
  }>({ value: '', label: '' })
  const { Theme } = store.Theme

  const locales = store.TranslationsState.translations
  const params = useParams()

  const TaskList: { value: string; label: string }[] = [
    { value: 'controlInvoice', label: locales.steps.controlInvoice },
    { value: 'printListProducts', label: locales.steps.printListProducts },
    { value: 'pickProducts', label: locales.steps.pickProducts },
    { value: 'countProducts', label: locales.steps.countProducts },
    {
      value: 'checkQualityProducts',
      label: locales.steps.checkQualityProducts,
    },
    { value: 'packProducts', label: locales.steps.packProducts },
    { value: 'packLeeflets', label: locales.steps.packLeeflets },
    { value: 'issueSalesInvoice', label: locales.steps.issueSalesInvoice },
    {
      value: 'sendSalesInvoiceEmail',
      label: locales.steps.sendSalesInvoiceEmail,
    },
    {
      value: 'controlTagsStatusesOrderDetailsOnInvoice',
      label: locales.steps.controlTagsStatusesOrderDetailsOnInvoice,
    },
    { value: 'generateShipping', label: locales.steps.generateShipping },
    {
      value: 'stickShippingLabelOnPackageAndSend',
      label: locales.steps.stickShippingLabelOnPackageAndSend,
    },
  ]

  const debounceTimer = useRef<NodeJS.Timeout | null>(null)

  const activateNextStep = async (listItems: any) => {
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}experience/v1/customer_order/${params.id}`,
      {
        checkListItems: listItems,
      },
      {
        headers: {
          'x-apikey': `${process.env.REACT_APP_API_KEY}`,
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Methods': 'GET,HEAD,PUT,PATCH,POST,DELETE',
          'Access-Control-Allow-Credentials': 'true',
        },
      }
    )
    store.TableStore.setCheckListItems(listItems)

    configureSteps()
  }

  const handleNextStepClick = () => {
    const listItems = store.TableStore.detailsData.checkListItems

    listItems[nextStep.value] = {
      date: new Date().toISOString(),
      username: store.UserStore.user.length ? store.UserStore.user : 'No data',
      selected: !listItems[nextStep.value].selected,
    }
    store.TableStore.setCheckListItems(listItems)
    configureSteps()

    if (debounceTimer.current) {
      clearTimeout(debounceTimer.current)
    }
    debounceTimer.current = setTimeout(() => {
      activateNextStep(listItems)
    }, 2000)
  }

  const configureSteps = async () => {
    const checkListItems = store.TableStore.detailsData.checkListItems
    let flag = false
    for (const [index, e] of TaskList.entries()) {
      if (!checkListItems[e.value].selected) {
        setNextStep(e)
        if (index > 0) {
          setCurrentStep(TaskList[index - 1])
        } else setCurrentStep({ value: '', label: '' })
        flag = true
        break
      }
    }
    if (!flag) {
      setNextStep({ value: '', label: '' })
    }
  }
  const getDataFetch = async () => {
    await store.TableStore.fetchDetailsData(params.id, true)
    const checkListItems = store.TableStore.detailsData.checkListItems

    if (checkListItems === null && params.id) {
      await setDefaultDetails(params.id)
      setCurrentStep({ value: '', label: '' })
      setNextStep(TaskList[0])
      await setTimeout(async () => {
        await store.TableStore.fetchDetailsData(params.id, true)
      }, 1000)
    } else if (checkListItems) {
      await configureSteps()
    }
  }

  useEffect(() => {
    socket.emit('pageLock', {
      id: store.RouteStore.socketId,
      user: store.UserStore.user,
      lockId: store.RouteStore.currentDataId,
    })
    getDataFetch()
  }, [])

  useEffect(() => {
    const checkListItems = store.TableStore.detailsData.checkListItems
    if (checkListItems) {
      configureSteps()
    }
  }, [store.TableStore.loaded, store.TableStore.detailsData.checkListItems])

  return (
    <PrimaryButtonCont>
      <ActionsPrimaryButton
        onClick={() => setProductList(!productList)}
        {...Theme.proformsDetailsStyles.headerButtonProps}
        {...Theme.proformsDetailsStyles.primaryButton}
      >
        <p>
          {currentStep.label !== ''
            ? currentStep.label
            : locales.buttons.selectFirstStep}
        </p>
        <img src={ArrowExpanded} alt={'>'} />
        {productList ? (
          <TasksDropDownComponent
            currentStep={currentStep}
            nextStep={nextStep}
            table={TaskList}
          />
        ) : (
          <></>
        )}
      </ActionsPrimaryButton>
      {nextStep.value !== '' ? (
        <NextStepCont onClick={handleNextStepClick}>
          {locales.headers.nextStep}:{' '}
          <b>{TaskList.find((e) => e.value == nextStep.value)?.label}</b>
        </NextStepCont>
      ) : (
        <></>
      )}
    </PrimaryButtonCont>
  )
})
export default StepsButton
