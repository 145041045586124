import styled from 'styled-components'
import Breadcrumb from '../../components/breadcrumb/Breadcrumb.component'
import ProformsTable from '../../components/proformsTable/ProformsTable.component'
import { Container } from '../../styles/LayoutStyles'

interface ProformsProps {
  navType: string
}

const ProformsComponent = ({ navType }: ProformsProps) => {
  return (
    <Container sideType={navType === 'side'}>
      <ProformsTable />
    </Container>
  )
}
export default ProformsComponent
