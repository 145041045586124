import React, { useContext } from 'react'
import { StoreContext } from '../../../App'
import {
  MobileContent,
  MobileContentCross,
  MobileContentHeader,
  MobileContentOptionsBody,
  MobileHeaderTitle,
} from './MobilePanel.styles'
import Cross from '../../../assets/icons/cross-white.svg'

interface Props {
  children: React.ReactNode
}

const MobileOptions = ({ children }: Props) => {
  const store = useContext(StoreContext)

  return (
    <MobileContent>
      <MobileContentHeader>
        <MobileHeaderTitle>Mobile Content Options</MobileHeaderTitle>
        <MobileContentCross
          src={Cross}
          onClick={() => store.TableStore.setMobileOptions(false)}
        />
      </MobileContentHeader>
      <MobileContentOptionsBody>{children}</MobileContentOptionsBody>
    </MobileContent>
  )
}
export default MobileOptions
