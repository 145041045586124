import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  > img {
    width: 200px;
    height: 200px;
    transform: scaleX(1.1);
  }
`
export const HeaderText = styled.p`
  font-weight: 600;
  font-size: 24px;
  line-height: 100%;
  margin-bottom: 48px;
`
