/* eslint-disable */
import React, { useEffect, useState } from 'react'
import Table from '../../TableGeneric/table/Table.component'

import { observer } from 'mobx-react'
import { useContext } from 'react'
import { Cell } from 'react-table'
import { StoreContext } from '../../../App'
import TableImage from '../../TableGeneric/tableImage/TableImage.component'
import TableTags from '../../TableGeneric/tableTags/TableTags.component'
import TableStockInProgress from '../../TableGeneric/tableStockInProgress/TableStockInProgress.component'
import TableCompareValuesComponent from '../../TableGeneric/TableCompareValues/TableCompareValues.component'
import TableMicroservice from '../../TableGeneric/tableMicroservice/TableMicroservice.component'
import styled from 'styled-components'
import TableCountedInvoices from '../../TableGeneric/tableCountedInvoices/TableCountedInvoices.component'
import TableNewTagsComponent from '../../TableGeneric/tableNewTags/TableNewTags.component'
import TableDifferentValues from '../../TableGeneric/TableDifferentValues/TableDifferentValues.component'
import { TableQuickEditComponent } from '../../TableGeneric/TableQuickEdit/TableQuickEdit.component'
import { toJS } from 'mobx'

interface ProductListInterface {
  view: string
}

const ProductListTable = observer(({ view }: ProductListInterface) => {
  const store = useContext(StoreContext)
  const tableStore = store.TableStore
  const locales = store.TranslationsState.translations

  const columns = {
    name: { Header: locales.customerorderDetailsColumns.name },
    code: { Header: locales.customerorderDetailsColumns.code },
    stock_quantity: {
      Header: locales.customerorderDetailsColumns.stock_quantity,
    },
    afterThis: { Header: locales.customerorderDetailsColumns.afterThis },
    afterPackingOtherInvoices: {
      Header: locales.customerorderDetailsColumns.afterPackingOtherInvoices,
    },
    is_missing: {
      Header: locales.customerorderDetailsColumns.is_missing,
      Cell: (cell: Cell) => <TableMicroservice value={cell.value ? 0 : 1} />,
    },
    quantity: { Header: locales.customerorderDetailsColumns.quantity },
    after_orders: { Header: locales.customerorderDetailsColumns.after_orders },
    supplier_orders: {
      Header: locales.customerorderDetailsColumns.supplier_orders,
      Cell: (cell: Cell) => (
        <TableCountedInvoices data={cell.value} cell={cell} secondary={false} />
      ),
    },
    product_tags: {
      Header: locales.customerorderDetailsColumns.product_tags,
      Cell: (cell: Cell) => <TableTags tags={cell.value} />,
    },
    ean: { Header: 'ean' },
    tax: { Header: locales.customerorderDetailsColumns.tax },
    unit: { Header: locales.customerorderDetailsColumns.unit },
    weight: { Header: locales.customerorderDetailsColumns.weight },
    price: { Header: locales.customerorderDetailsColumns.price },
    image_url: {
      Header: locales.customerorderDetailsColumns.image,
      Cell: (cell: Cell) => <TableImage url={cell.value} />,
    },
  }
  const packingColumns = {
    name: { Header: locales.packingDetailsColumns.name },
    code: { Header: locales.packingDetailsColumns.code },
    stock_quantity: { Header: locales.packingDetailsColumns.stock_quantity },
    afterPackingOtherInvoices: {
      Header: locales.packingDetailsColumns.afterPackingOtherInvoices,
    },
    completableMissingMicroservice: {
      Header: locales.packingDetailsColumns.is_missing,
      Cell: (cell: Cell) => <TableMicroservice value={cell.value ? 0 : 1} />,
    },
    quantity: {
      Header: locales.packingDetailsColumns.quantity,
      Cell: (cell: Cell) => (
        <TableCompareValuesComponent
          value={cell.value}
          fromValue={
            cell.row.allCells.find((e) => e.column.id === 'stock_quantity')
              ?.value
          }
        />
      ),
    },
    product_tags: {
      Header: locales.packingDetailsColumns.product_tags,
      Cell: (cell: Cell) => <TableTags tags={cell.value} />,
    },
    image_url: {
      Header: locales.customerorderDetailsColumns.image,
      Cell: (cell: Cell) => <TableImage url={cell.value} />,
    },
  }

  const supplerColumns = {
    ProductID: {},
    ProductCode: {},
    Name: {},
    ProducerName: {},
    SupplierDeliveryCode: {},
    OrderLink: {},
    ean: {},
    PurchacePrice: {},
    /*Tags: {
                                              Cell: (cell: Cell) => <TableTags key={cell.value} tags={cell.value} />,
                                            },*/
    OrderLineTags: {},
    SupplierDeliveryDate: {},
    OrderedQuantity: {},
    DeliveredQuantity: {},
    DeliveredDate: {},
    Notes: {},
    CreatedBy: {},
    LastEditAuthor: {},
  }

  const [data, setData] = useState<object[]>([])
  const [columnVisibility] = useState(true)
  const [allowColumnResize] = useState(true)
  const [search] = useState(false)
  const [select] = useState(false)
  const [expand] = useState(false)
  const [tooltip] = useState(true)
  const [entry] = useState(false)
  const [paginator] = useState(false)
  const [sort] = useState(true)
  const [actions] = useState(null)

  const getColumns = () => {
    switch (view) {
      case 'customerorder':
        return columns
      case 'packing':
        return packingColumns
      case 'supplier':
        return supplerColumns
    }
  }

  const objectToArray = (obj: any) => {
    return Object.keys(obj).map((key) => obj[key])
  }

  useEffect(() => {
    if (tableStore.detailsData && tableStore.detailsData.products) {
      setData(objectToArray(tableStore?.detailsData?.products))
    }
  }, [tableStore?.detailsData])

  return (
    <Table
      config={{
        tableName: 'listOfProducts',
        data: data || [],
        fetchData: () => store.TableStore.fetchDetailsData(),
        //@ts-ignore
        columnHeaders: getColumns(),
        columnVisibility: columnVisibility,
        allowColumnResize: allowColumnResize,
        displaySearch: search,
        displaySelect: select,
        displayExpand: expand,
        displayTooltip: tooltip,
        displayEntry: entry,
        displayPaginator: paginator,
        allowSort: sort,
        actions: actions,
        headline: {
          visible: true,
          title: 'List of products',
          subTitle: 'List of products',
        },
        breadcrumb: [],
        loaded: true,
      }}
    ></Table>
  )
})
export default ProductListTable
