import styled from 'styled-components'

interface ItemsTableProps {
  marginTop?: string
  borderTop?: string
  rowDisplay?: boolean
}

export const ItemsTable = styled.div<ItemsTableProps>`
  margin-top: ${(props) => (props.marginTop ? props.marginTop : '9px')};
  border-top: ${(props) =>
    props.borderTop ? props.borderTop : '1px solid #f5f5f5'};
  //display: flex;
  //flex-direction: ${(props) => (props.rowDisplay ? 'row' : 'column')};
  ${(props) =>
    props.rowDisplay &&
    `
    display: flex;
    flex-wrap: wrap;
    `}
`

interface SingleItemProps {
  borderBottom?: string
  rowDisplay?: boolean
}

export const SingleItemCont = styled.div<SingleItemProps>`
  border-bottom: ${(props) =>
    props.borderBottom ? props.borderBottom : '1px solid #f5f5f5'};
  width: 100%;
  min-height: 32px;
  padding-top: 4px;
  padding-bottom: 4px;
  display: flex;
  flex-direction: ${(props) => (props.rowDisplay ? 'column' : 'row')};
  flex-wrap: wrap;
  ${(props) =>
    props.rowDisplay &&
    `
    flex: 1;
    min-width: 80px;
    text-align: center;
    border-right: '1px solid #f5f5f5';
    padding: 4px;
    `}
  align-items: center;
  justify-content: space-between;
  @media print {
    padding: 0;
  }
`

interface SingleItemTitleProps {
  color?: string
  fontSize?: string
  fontWeight?: number
  lineHeight?: string
  letterSpacing?: string
  rowDisplay?: boolean
}

export const SingleItemTitle = styled.p<SingleItemTitleProps>`
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : 400)};
  font-size: ${(props) => (props.fontSize ? props.fontSize : '12px')};
  line-height: ${(props) => (props.lineHeight ? props.lineHeight : '16px')};
  letter-spacing: ${(props) =>
    props.letterSpacing ? props.letterSpacing : '0.15px'};
  color: ${(props) => (props.color ? props.color : '#737373')};
  ${(props) =>
    !props.rowDisplay &&
    `
    margin-right: 16px;
    `}//width: 200px;
`

interface SingleItemValueProps {
  color?: string
  fontSize?: string
  fontWeight?: number
  lineHeight?: string
  letterSpacing?: string
  rowDisplay?: boolean
}

export const SingleItemValue = styled.p<SingleItemValueProps>`
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : 400)};
  font-size: ${(props) => (props.fontSize ? props.fontSize : '12px')};
  line-height: ${(props) => (props.lineHeight ? props.lineHeight : '16px')};
  letter-spacing: ${(props) =>
    props.letterSpacing ? props.letterSpacing : '0.15px'};
  display: flex;
  flex-wrap: wrap;
`

interface StyledTableProps {
  borderRadius?: string
  border?: string
  padding?: string
  bgColor?: string
}

export const StyledTable = styled.div<StyledTableProps>`
  background: ${(props) => (props.bgColor ? props.bgColor : '#ffffff')};
  border: ${(props) => (props.border ? props.border : '1px solid #d6d6d6')};
  border-radius: ${(props) =>
    props.borderRadius ? props.borderRadius : '4px'};
  padding: ${(props) => (props.padding ? props.padding : '12px 20px')};
  height: max-content !important;
  margin-left: 4px;
  margin-right: 4px;
  @media print {
    padding: 0;
    margin: 0;
    border-color: transparent;
  }
`

interface TableHeadInterface {
  active: boolean
}

export const TableHead = styled.div<TableHeadInterface>`
  display: flex;
  justify-content: space-between;

  img {
    transform: ${(props) => (props.active ? 'none' : 'rotate(180deg)')};
  }
`

interface TitleProps {
  fontWeight?: number
  padding?: string
  fontSize?: string
  lineHeight?: string
  letterSpacing?: string
  color?: string
}

export const Title = styled.p<TitleProps>`
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : 500)};
  font-size: ${(props) => (props.fontSize ? props.fontSize : '20px')};
  line-height: ${(props) => (props.lineHeight ? props.lineHeight : '32px')};
  letter-spacing: ${(props) =>
    props.letterSpacing ? props.letterSpacing : '0.3px'};
`
