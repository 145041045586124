import { observer } from 'mobx-react'
import { FC, PropsWithChildren, useContext, useState } from 'react'
import { StoreContext } from '../../../App'

import LeftArrow from '../../../assets/icons/arrow-left.svg'
import RightArrow from '../../../assets/icons/arrow-right.svg'
import Cross from '../../../assets/icons/cross-white.svg'

import {
  Arrow,
  Container,
  CrossStyles,
  Displayed,
  DisplayedImage,
} from './TableImage.styles'

interface Props extends PropsWithChildren {
  url?: string
}

const TableImage: FC<Props> = observer((props) => {
  const store = useContext(StoreContext)
  const { Theme } = store.Theme

  const [displayed, setDisplayed] = useState(false)
  const [displayedIndex, setDisplayedIndex] = useState(0)

  return (
    <Container>
      {props.url ? (
        <>
          <DisplayedImage
            onClick={() => setDisplayed(!displayed)}
            src={props.url}
            alt={'image'}
            loading='lazy'
          />
          {displayed ? (
            <>
              <Displayed {...Theme.tableImageStyles.displayed}>
                <img
                  src={props.url}
                  //src={props.url[Math.abs(displayedIndex % props.url.length)]}
                  alt={'image'}
                  loading='lazy'
                />
                <CrossStyles
                  src={Cross}
                  alt={'X'}
                  onClick={() => setDisplayed(false)}
                />
              </Displayed>
            </>
          ) : (
            <></>
          )}
        </>
      ) : (
        <p>{'No photo'}</p>
      )}
    </Container>
  )
})

export default TableImage
