import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { darken } from 'polished'

export const Container = styled.div`
  position: absolute;
  top: 53px;
  right: 0;
  background-color: #f5f5f5;
  z-index: 10;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 12px;

  * {
    color: black;
  }

  p,
  a {
    margin-bottom: 4px;
  }
`
export const Image = styled.img`
  border-radius: 50%;
  height: 100px;
  width: 100px;
  margin-bottom: 12px;
`
export const TitleText = styled.p`
  font-size: 16px;
  font-weight: 700;
`
export const SubTitleText = styled.p``
export const OptionCont = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 12px;
  padding-top: 12px;
  border-top: 1px solid #909090;
`
export const Option = styled.a`
  color: #909090;
  margin: 0 36px;
`

interface PrimaryButtonProps {
  padding?: string
  bgColor?: string
  borderRadius?: string
  fontSize?: string
  fontWeight?: number
  lineHeight?: string
  letterSpacing?: string
  color?: string
}

export const PrimaryButton = styled(Link)<PrimaryButtonProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: fit-content;
  width: fit-content;
  cursor: pointer;
  padding: ${(props) => (props.padding ? props.padding : '8px 16px')};
  background-color: ${(props) => (props.bgColor ? props.bgColor : '#303030')};
  border-radius: ${(props) =>
    props.borderRadius ? props.borderRadius : '6px'};
  white-space: nowrap;
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : 600)};
  font-size: ${(props) => (props.fontSize ? props.fontSize : '14px')};
  line-height: ${(props) => (props.lineHeight ? props.lineHeight : '16px')};
  letter-spacing: ${(props) =>
    props.letterSpacing ? props.letterSpacing : '0.5px'};
  text-transform: uppercase;
  color: ${(props) => (props.color ? props.color : '#fafafa')};
  margin-top: 12px;
  width: 160px;
  transition: background-color 0.3s, transform 0.2s;

  &:hover {
    background-color: ${(props) =>
    darken(0.1, props.bgColor ? props.bgColor : '#303030')};
    transform: scale(1.02);
  }

  &:active {
    transform: scale(0.98);
  }
`

interface SecondaryButtonProps {
  padding?: string
  bgColor?: string
  borderRadius?: string
  fontSize?: string
  fontWeight?: number
  lineHeight?: string
  letterSpacing?: string
  margin?: string
  color?: string
}

export const SecondaryButton = styled.button<SecondaryButtonProps>`
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : 600)};
  font-size: ${(props) => (props.fontSize ? props.fontSize : '14px')};
  line-height: ${(props) => (props.lineHeight ? props.lineHeight : '16px')};
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: ${(props) =>
    props.letterSpacing ? props.letterSpacing : '0.5px'};
  text-transform: uppercase;
  color: ${(props) => (props.color ? props.color : '#424242')};
  margin-top: 20px;
  margin-bottom: 6px;
  white-space: nowrap;
  &:hover {
    color: ${(props) => darken(0.1, props.color ? props.color : '#424242')};
    transform: scale(1.05);
  }

  &:active {
    color: ${(props) => darken(0.2, props.color ? props.color : '#424242')};
    transform: scale(0.98);
  }
`
