import { observer } from 'mobx-react'
import { FC, PropsWithChildren, useContext, useEffect, useState } from 'react'

import { StoreContext } from '../../App'
import QueryBuilder from '../queryBuilder/QueryBuilder.component'
/* eslint-disable */
import { QueryPredefinedFilters } from './QueryFilter.methods'

import {
  CheckBoxCont,
  FooterCont,
  LeftFilter,
  PrimaryButton,
  QueryBuilderWrap,
  QueryFilterWrap,
  RightFilter,
  SaveInput,
  SecondaryButton,
  SelectButtonCont,
  SelectCont,
  SelectInput,
  SelectLabel,
  StyledParagraph,
} from './QueryFilter.styles'
import axios from '../../axiosconfig'
import { toast } from 'react-toastify'
import SearchSelectComponent from '../searchSelect/SearchSelect.component'
import { toJS } from 'mobx'

interface Props extends PropsWithChildren {
  mobile?: boolean
  fetchData: () => void
  allColumns: any
}

const QueryFilter: FC<Props> = observer(({ mobile, allColumns, fetchData }) => {
  const store = useContext(StoreContext)
  const { Theme } = store.Theme

  const locales = store.TranslationsState.translations

  const [selectedFilter, setSelectedFilter] = useState(false)
  const [defaultFiltersValue, setDefaultFiltersValue] = useState(
    store.TableStore.appliedPredefinedQuery.defaultFilter
  )
  const [predefinedFiltersValue, setPredefinedFiltersValue] = useState('empty')
  const [saveFilter, setSaveFilter] = useState(false)
  const [saveValue, setSaveValue] = useState('')
  const [privateFilter, setPrivateFilter] = useState(false)
  const [predefinedFilters, setPredefinedFilters] = useState<
    {
      combinator: string
      rules: any[]
      not: boolean
      id: string
    }[]
  >([])

  const sendRequest = async () => {
    store.TableStore.applyPredefinedQuery(
      defaultFiltersValue,
      predefinedFiltersValue
    )
    store.TableStore.applyQuery(fetchData)
  }

  const clearFilters = async () => {
    setPredefinedFiltersValue('empty')
    if (store.TableStore.query.rules.length > 0) {
      store.TableStore.clearQuery()
    }
  }

  const cancelFilters = () => {
    setDefaultFiltersValue(
      store.TableStore.appliedPredefinedQuery.defaultFilter
    )
    setPredefinedFiltersValue(
      store.TableStore.appliedPredefinedQuery.predefinedFilter
    )
    store.TableStore.setQuery(store.TableStore.appliedQuery)
  }
  const saveQuery = async () => {
    const url = `${process.env.REACT_APP_BASE_URL}experience/v1/filter/_create`
    const data = {
      filter: {
        query: store.TableStore.query,
        label: saveValue,
      },
      access_group: privateFilter ? 'private' : 'public',
      created_by: store.UserStore.userID,
      view: store.TableStore.getApiRequestFromUrl(),
    }
    const config = {
      headers: {
        'x-apikey': `${process.env.REACT_APP_API_KEY}`,
        username: store.UserStore.user.length
          ? store.UserStore.user
          : 'No data',
      },
    }
    const response = await axios.post(url, data, config)
    await toast('Success!', {
      type: 'success',
      autoClose: 5000,
    })
    await store.FiltersStore.fetchAllFilters()
  }

  const filterIds = (query: any) => {
    const filteredQuery: any = {
      combinator: query.combinator,
      rules: query.rules.map((rule: any) => {
        const { id, ...rest } = rule
        return rest
      }),
      not: query.not,
    }
    return filteredQuery
  }

  function areQueriesEqual(query1: any, query2: any): boolean {
    if (
      query1.combinator !== query2.combinator ||
      query1.not !== query2.not ||
      query1.rules.length !== query2.rules.length
    ) {
      return false
    }

    for (let i = 0; i < query1.rules.length; i++) {
      const rule1 = query1.rules[i]
      const rule2 = query2.rules[i]

      if (JSON.stringify(rule1) !== JSON.stringify(rule2)) {
        return false
      }
    }

    return true
  }

  useEffect(() => {
    if (store.SlidingPanelState.selectedSlider === 'optionsList') {
      store.TableStore.setQuery(store.TableStore.appliedQuery)
      setDefaultFiltersValue(
        store.TableStore.appliedPredefinedQuery.defaultFilter
      )
      setPredefinedFiltersValue(
        store.TableStore.appliedPredefinedQuery.predefinedFilter
      )
    }
  }, [store.SlidingPanelState.selectedSlider])

  return (
    <>
      {saveFilter ? (
        <SelectCont>
          <SelectLabel>Set a name for the input</SelectLabel>
          <SaveInput
            value={saveValue}
            onChange={(e) => setSaveValue(e.target.value)}
          />
          <CheckBoxCont>
            <StyledParagraph>Make this filter private</StyledParagraph>
            <input
              type={'checkbox'}
              checked={privateFilter}
              onChange={(e) => setPrivateFilter(e.target.checked)}
            />
          </CheckBoxCont>
          <FooterCont>
            <SecondaryButton
              onClick={() => setSaveFilter(false)}
              {...Theme.buttons.secondaryButton}
            >
              {locales.buttons.cancel}
            </SecondaryButton>
            <PrimaryButton
              onClick={() => {
                saveQuery()
                setPredefinedFiltersValue(saveValue)
                setSaveValue('')
                setSaveFilter(false)
                //store.TableStore.clearQuery()
              }}
              {...Theme.buttons.primaryButton}
            >
              {'SAVE'}
            </PrimaryButton>
          </FooterCont>
        </SelectCont>
      ) : (
        <QueryFilterWrap>
          <SelectButtonCont>
            <LeftFilter
              selected={selectedFilter}
              onClick={() => setSelectedFilter(false)}
            >
              {locales.buttons.predefinedFilter}
            </LeftFilter>
            <RightFilter
              selected={selectedFilter}
              onClick={() => setSelectedFilter(true)}
            >
              {locales.buttons.newFilter}
            </RightFilter>
          </SelectButtonCont>
          <div>
            {selectedFilter ? (
              <QueryBuilderWrap>
                <QueryBuilder
                  keys={allColumns}
                  query={store.TableStore.query}
                  setQuery={(q: any) => store.TableStore.setQuery(q)}
                />
              </QueryBuilderWrap>
            ) : (
              <SelectCont>
                <SelectLabel>{locales.actions.chooseDefaultFilter}</SelectLabel>
                <SearchSelectComponent
                  Container={SelectInput}
                  value={defaultFiltersValue}
                  options={QueryPredefinedFilters()}
                  onClick={(e) => {
                    setDefaultFiltersValue(e.value)
                  }}
                />
                <SelectLabel>
                  {locales.actions.choosePredefinedFilter}
                </SelectLabel>
                <SearchSelectComponent
                  Container={SelectInput}
                  value={predefinedFiltersValue}
                  options={QueryPredefinedFilters()}
                  onClick={(e) => {
                    setPredefinedFiltersValue(e.value)
                    const selectedFilter = store.FiltersStore.allFilters.find(
                      (f: any) => f.id === e.value
                    )
                    selectedFilter
                      ? store.TableStore.setQuery(selectedFilter.action.query)
                      : console.log('')
                  }}
                />
                {/*<SelectInput
                  value={predefinedFiltersValue}
                  onChange={(e) => {
                    setPredefinedFiltersValue(e.target.value)
                    const selectedFilter = store.FiltersStore.allFilters.find(
                      (f: any) => f.id === e.target.value
                    )
                    selectedFilter
                      ? store.TableStore.setQuery(selectedFilter.action.query)
                      : console.log('')
                  }}
                >
                  {QueryPredefinedFilters().map((e, index) => (
                    <option
                      key={index}
                      value={e.value}
                      label={e.label}
                    ></option>
                  ))}
                </SelectInput>*/}
              </SelectCont>
            )}
          </div>
          <FooterCont>
            {!areQueriesEqual(
              filterIds(store.TableStore.appliedQuery),
              filterIds(store.TableStore.query)
            ) ? (
              <SecondaryButton
                onClick={() => cancelFilters()}
                {...Theme.buttons.secondaryButton}
              >
                {'Back to default query'}
              </SecondaryButton>
            ) : (
              <></>
            )}
            {store.TableStore.appliedQuery.rules.length ? (
              <SecondaryButton
                onClick={() => clearFilters()}
                {...Theme.buttons.secondaryButton}
              >
                {locales.buttons.clearFilters}
              </SecondaryButton>
            ) : (
              <></>
            )}
            {store.TableStore.query.rules.length > 0 && (
              <>
                {selectedFilter ? (
                  <SecondaryButton
                    onClick={() => setSaveFilter(true)}
                    {...Theme.buttons.secondaryButton}
                  >
                    {'SAVE'}
                  </SecondaryButton>
                ) : (
                  <></>
                )}
              </>
            )}
            {!areQueriesEqual(
              filterIds(store.TableStore.appliedQuery),
              filterIds(store.TableStore.query)
            ) ? (
              <PrimaryButton
                onClick={() => sendRequest()}
                {...Theme.buttons.primaryButton}
              >
                {locales.buttons.apply}
              </PrimaryButton>
            ) : (
              <></>
            )}
            {store.TableStore.appliedPredefinedQuery.defaultFilter !==
            defaultFiltersValue ? (
              <PrimaryButton
                onClick={() => {
                  localStorage.setItem(
                    `${store.RouteStore.currentPage}-defaultFilter`,
                    defaultFiltersValue
                  )
                  store.TableStore.applyPredefinedQuery(
                    defaultFiltersValue,
                    predefinedFiltersValue
                  )
                  store.TableStore.setLocalQuery()
                  toast('Saved Default Filter!', {
                    type: 'success',
                    autoClose: 5000,
                  })
                }}
                {...Theme.buttons.primaryButton}
              >
                {'SAVE DEFAULT'}
              </PrimaryButton>
            ) : (
              <></>
            )}
          </FooterCont>
        </QueryFilterWrap>
      )}
    </>
  )
})
export default QueryFilter
