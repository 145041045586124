import { RootStore } from './Root.store'
import { action, makeAutoObservable, toJS } from 'mobx'
import axios from '../axiosconfig'
import { toast } from 'react-toastify'
import { Row } from 'react-table'

export class PackagesStore {
  rootStore
  data: { [key: string]: any }[] = []
  currentFilters: { value: string; label: string }[] = []
  loaded = false
  pagination: {
    tableSize: number
    pageSize: number
    pageIndex: number
    totalCount: number
  } = { tableSize: 0, pageSize: 10, pageIndex: 1, totalCount: 0 }

  constructor(rootStore: RootStore) {
    makeAutoObservable(this)
    this.rootStore = rootStore
  }

  convertRulesToFilters = (rules: any, globalSearch: any) => {
    const filters: any = {
      global_search: globalSearch,
      filters: {},
    }
    //test

    rules.forEach((rule: any) => {
      if (rule.field === 'datetime_order') {
        if (!filters.filters.datetime_order) {
          filters.filters.datetime_order = {}
        }
        if (rule.operator === '>') {
          filters.filters.datetime_order.from = rule.value
        } else if (rule.operator === '<') {
          filters.filters.datetime_order.to = rule.value
        }
      } else if (rule.field === 'user_reference_number') {
        filters.ref_num = rule.value
      } else {
        if (!filters.filters[rule.field]) {
          filters.filters[rule.field] = []
        }
        filters.filters[rule.field].push(rule.value)
      }
    })

    if (Object.keys(filters.filters).length === 0) delete filters.filters
    else
      Object.keys(filters.filters).forEach((field) => {
        if (
          Array.isArray(filters.filters[field]) &&
          filters.filters[field].length === 0
        ) {
          delete filters.filters[field]
        }
      })

    if (filters.global_search === '') delete filters.global_search
    console.log(filters)
    return filters
  }

  //test

  @action.bound fetchData = async (): Promise<void> => {
    this.loaded = false
    const url = `${
      process.env.REACT_APP_BASE_URL
    }experience/v1/packages_view?size=${this.pagination.pageSize}&from=${
      (this.pagination.pageIndex - 1) * this.pagination.pageSize + 1
    }`

    const data = this.convertRulesToFilters(
      this.rootStore.TableStore.appliedQuery.rules,
      this.rootStore.TableStore.appliedGlobalSearch
    )

    const config = {
      headers: {
        'x-apikey': `${process.env.REACT_APP_API_KEY}`,
        username: this.rootStore.UserStore.user,
      },
    }
    console.log(url, data, config)
    const response = await axios.post(url, data, config)
    this.data = await response.data.packages
    console.log(toJS(response.data))
    this.pagination.tableSize = await response.data.count
    this.loaded = true
  }

  @action.bound fetchDetailsData = async (): Promise<void> => {
    this.loaded = false
    const url = `${process.env.REACT_APP_BASE_URL}experience/v1/packages/order/${this.rootStore.RouteStore.currentDataId}`

    const config = {
      headers: {
        'x-apikey': `${process.env.REACT_APP_API_KEY}`,
        username: this.rootStore.UserStore.user,
      },
    }
    const response = await axios.get(url, config)
    this.data = await response.data
    this.loaded = true
  }

  @action.bound setPagination = (
    tableSize: number,
    pageSize: number,
    pageIndex: number,
    totalCount: number
  ) => {
    this.rootStore.SlidingPanelState.setSelectedSlider('')
    this.pagination = {
      tableSize,
      pageSize,
      pageIndex,
      totalCount,
    }
    try {
      this.fetchData()
    } catch (e: any) {
      toast(e.message.toString(), { type: 'error' })
    }
  }

  @action.bound exportPackageDocuments = async (row: Row, all: boolean) => {
    const url = `${process.env.REACT_APP_BASE_URL}experience/v1/packages/documents_print`
    const id = row.allCells.find(
      (e: any) => e.column.id === 'package_id'
    )?.value

    const data = {
      ids: [id],
      documents_types: all ? ['all'] : ['labels'],
    }
    const config = {
      headers: {
        'x-apikey': `${process.env.REACT_APP_API_KEY}`,
        username: this.rootStore.UserStore.user,
      },
    }

    try {
      console.log(url, data, config)
      const response = await axios.post(url, data, config)
      const fileUrl = response.data.url
      const link = document.createElement('a')
      link.href = fileUrl
      link.setAttribute('download', 'download.zip')
      document.body.appendChild(link)
      link.click()
      link.remove()
    } catch (error) {
      console.error('Error during file download:', error)
    }
  }

  @action.bound clearData = () => {
    this.data = []
  }
}
