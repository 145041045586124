import styled from 'styled-components'

interface ContainerInterface {
  color: string
}
export const Container = styled.div<ContainerInterface>`
  position: relative;
  width: 100%;
  height: 100%;
  background-color: ${(props) => props.color};
`
