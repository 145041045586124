import { IconAlertCircle } from '@tabler/icons-react'
import { FC, PropsWithChildren, useContext } from 'react'
import { Container, Entry } from './TableLastAction.styles'
import { mainTheme as theme } from '../../../static/themes/main.theme'
import { StoreContext } from '../../../App'
import { observer } from 'mobx-react'

interface Props extends PropsWithChildren {
  checkListItems: {
    [key: string]: {
      date: string
      username: string
      selected: boolean
    }
  } | null
}

const TableLastAction: FC<Props> = observer(({ checkListItems }: Props) => {
  const store = useContext(StoreContext)
  const locales = store.TranslationsState.translations

  function getFieldWithNewestDate() {
    let newestDate = null
    let newestField = null

    for (const field in checkListItems) {
      if (
        Object.prototype.hasOwnProperty.call(checkListItems, field) &&
        checkListItems[field].selected
      ) {
        const currentDate = new Date(checkListItems[field].date)

        if (newestDate === null || currentDate > newestDate) {
          newestDate = currentDate
          newestField = field
        }
      }
    }
    return newestField
  }

  const newestField = getFieldWithNewestDate()
  if (checkListItems && newestField) {
    return (
      <Container>
        <div>{newestField}</div>
        <div>{checkListItems[newestField].date}</div>
        <div>{checkListItems[newestField].username}</div>
      </Container>
    )
  } else return <div>{locales.actions.noActions}</div>
})
export default TableLastAction
