import {
  ComponentList,
  ComponentWrap,
} from '../calendarMonth/CalendarMonth.styles'
import CalendarEntry from '../calendarEntry/CalendarEntry.component'
import DelayedProductEntry from '../delayedProducyEntry/DelayedProductEntry.component'
import React, { useContext, useEffect } from 'react'
import { Order, Products } from '../../../../stores/Calendar.store'
import { observer } from 'mobx-react'
import { StoreContext } from '../../../../App'

interface CreateOrdersInterface {
  title: string
  color: string
  deliveredHover?: boolean
  dataGetter: () => Order[]
}

export const CreateOrders = observer(
  ({ title, dataGetter, color, deliveredHover }: CreateOrdersInterface) => {
    const store = useContext(StoreContext)
    let data = dataGetter()
    if (store.CalendarStore.filterProductId.length) {
      data = data.filter((order) =>
        Object.keys(order.products).includes(
          store.CalendarStore.filterProductId
        )
      )
    }

    return (
      <>
        {data.length ? (
          <ComponentWrap bgColor={color}>
            <p>{data.length ? title : null}</p>
            <ComponentList>
              {data.map((entryData, idx) => (
                <CalendarEntry
                  deliveredHover={deliveredHover}
                  color={color}
                  key={idx}
                  entryData={entryData}
                />
              ))}
            </ComponentList>
          </ComponentWrap>
        ) : (
          <></>
        )}
      </>
    )
  }
)

interface CreateProductsInterface {
  title: string
  color: string
  dataGetter: () => Products[]
  displayOrder?: boolean
}

export const CreateProducts = observer(
  ({ title, dataGetter, color, displayOrder }: CreateProductsInterface) => {
    const store = useContext(StoreContext)

    let data = dataGetter()
    if (store.CalendarStore.filterProductId.length) {
      data = data.filter(
        (product) => product.ProductID === store.CalendarStore.filterProductId
      )
    }

    return (
      <>
        {data.length ? (
          <ComponentWrap bgColor={color}>
            <p>{data.length ? title : null}</p>
            <ComponentList>
              {data.map((entryData, idx) => (
                <DelayedProductEntry
                  color={color}
                  key={idx}
                  delayedProductData={entryData}
                  displayOrder={displayOrder}
                />
              ))}
            </ComponentList>
          </ComponentWrap>
        ) : (
          <></>
        )}
      </>
    )
  }
)
