import styled from 'styled-components'

export const ObjectDisplayCont = styled.div`
  display: flex;
  flex-direction: column;
`

export const ArrayDisplayCont = styled.div`
  display: flex;
  flex-direction: column;
`
export const Header = styled.div`
  display: flex;
  justify-content: space-between;
`
export const HeaderLeft = styled.div`
  display: flex;
`
/* eslint-disable */

interface ViewButtonInterface {
  selected: boolean
  view?: 'left' | 'right'
}
export const ViewButton = styled.button<ViewButtonInterface>`
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: #303030;
  padding: 8px 20px;
  border-radius: ${(props) =>
    props.view === 'left'
      ? '6px 0 0 6px'
      : props.view === 'right'
      ? '0 6px 6px 0'
      : '0'};
  border: 1px solid ${(props) => (!props.selected ? '#575757' : '#D6D6D6')};
  background-color: ${(props) => (!props.selected ? '#F5F5F5' : '#FFFFFF')};
`
