import { observer } from 'mobx-react'
import { FC, useContext } from 'react'
import { StoreContext } from '../../App'
import { Container, HeaderText } from './FeatureDisable.styles'
import FallingStar from '../../assets/icons/falling-star.svg'
interface Props {}

export const FeatureDisable: FC<Props> = observer((props: Props) => {
  const store = useContext(StoreContext)
  const { Theme } = store.Theme

  return (
    <Container>
      <img src={FallingStar} />
      <HeaderText>This feature is disabled in your current plan.</HeaderText>
    </Container>
  )
})
