import React, { useEffect } from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import 'react-toastify/dist/ReactToastify.css'
import { FronteggProvider } from '@frontegg/react'

const contextOptions = {
  baseUrl: `${process.env.REACT_APP_FRONTEGG_BASEURL}`,
  clientId: `${process.env.REACT_APP_FRONTEGG_CLIENT_ID}`,
}
const authOptions = {
  keepSessionAlive: true, // Uncomment this in order to maintain the session alive
}
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <>
    <FronteggProvider
      contextOptions={contextOptions}
      hostedLoginBox={true}
      authOptions={authOptions}
    >
      <App />
    </FronteggProvider>
  </>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
