import styled from 'styled-components'

export const Container = styled.a`
  display: flex;
  position: relative;
  width: 100%;
  flex-direction: column;

  div {
    padding: 2px;
  }
`
export const Entry = styled.p``
