import styled from 'styled-components'

export const DataCont = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
`

interface DataValueProps {
  fontSize?: string
  lineHeight?: string
}

export const DataValue = styled.p<DataValueProps>`
  font-size: ${(props) => (props.fontSize ? props.fontSize : '10px')};
  line-height: ${(props) => (props.lineHeight ? props.lineHeight : '12px')};
`
