import { observer } from 'mobx-react'
import { FC, PropsWithChildren, useContext, useState } from 'react'
import { StoreContext } from '../../../App'
import { ContButton } from './TableCSV.styles'
import Papa from 'papaparse'
import { saveAs } from 'file-saver'
import { toast } from 'react-toastify'
import axios from '../../../axiosconfig'
import { toJS } from 'mobx'

interface Props extends PropsWithChildren {
  data: Array<{
    [key: string]: any
  }>
  tableName: string
}

const TableCSV: FC<Props> = observer((props) => {
  const store = useContext(StoreContext)
  const { Theme } = store.Theme
  const locales = store.TranslationsState.translations
  const [loading, setLoading] = useState(false)
  const exportData = async () => {
    setLoading(true)
    if (store.TableStore.selectedRowId.length > 0) {
      const data: any = {
        filterGroups: {
          rules: [],
          combinator: 'or',
          not: false,
        },
      }

      store.TableStore.selectedRowId.map((e: string, index: number) =>
        data.filterGroups.rules.push({
          field: 'id',
          operator: '=',
          valueSource: 'value',
          value: e,
        })
      )
      const url = `${
        process.env.REACT_APP_BASE_URL
      }experience/v1//${store.TableStore.getApiRequestFromUrl()}?size=9999&from=0`
      const response: any = await axios.post(url, data, {
        headers: {
          'Content-Type': 'application/json',
          'x-apikey': `${process.env.REACT_APP_API_KEY}`,
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Methods': 'GET,HEAD,PUT,PATCH,POST,DELETE',
          'Access-Control-Allow-Credentials': 'true',
        },
      })
      const csvData = response.data[store.TableStore.getResponseParam()]
      const csv = Papa.unparse(csvData)
      const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' })
      const date = new Date()
      saveAs(
        blob,
        `${props.tableName}-${
          date.getFullYear() +
          '-' +
          (date.getMonth() + 1) +
          '-' +
          date.getDate() +
          '-' +
          date.getHours() +
          ':' +
          date.getMinutes() +
          ':' +
          date.getSeconds()
        }.csv`
      )
    } else if (store.TableStore.selectedExpanded.length > 0) {
      console.log(toJS(store.TableStore.selectedExpanded))
      const csvData = [
        ...store.TableStore.selectedExpanded.map((e) => {
          return {
            ...e.values,
            SupplierId: e.rowId,
          }
        }),
      ]
      const csv = Papa.unparse(csvData)
      const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' })
      const date = new Date()
      const name = `${store.RouteStore.currentPage}-${
        date.getFullYear() +
        '-' +
        (date.getMonth() + 1) +
        '-' +
        date.getDate() +
        '-' +
        date.getHours() +
        ':' +
        date.getMinutes() +
        ':' +
        date.getSeconds()
      }.csv`
      saveAs(blob, `data.csv`)
    } else toast('No data selected', { type: 'error' })
    setLoading(false)
  }

  if (props.data.length == 0) {
    return <></>
  } else
    return (
      <ContButton
        onClick={() => !loading && exportData()}
        {...Theme.tableCSVStyles.contButton}
      >
        {loading ? 'Loading...' : locales.buttons.downloadFile}
      </ContButton>
    )
})
export default TableCSV
