import styled from 'styled-components'
import { darken } from 'polished'

interface ContainerProps {
  padding?: string
  borderRadius?: string
}

export const Container = styled.div<ContainerProps>`
  border-radius: ${(props) =>
    props.borderRadius ? props.borderRadius : '4px'};
  padding: 0 16px 16px;
  display: inline-flex;
  flex-direction: column;
`

interface InnerContInterface {
  cols: number
}

export const InnerCont = styled.div<InnerContInterface>`
  height: 80vh;
  display: flex;
  column-count: ${(props) => props.cols};
  flex-direction: column;
`

interface HeaderProps {
  padding?: string
}

export const Header = styled.div<HeaderProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${(props) => (props.padding ? props.padding : '12px 16px 0')};
`
export const HeaderFirstLine = styled.div`
  display: flex;
  align-items: center;
`

interface HeaderTextProps {
  color?: string
  fontSize?: string
  fontWeight?: number
  lineHeight?: string
  letterSpacing?: string
  marginLeft?: string
}

export const HeaderText = styled.p<HeaderTextProps>`
  margin-left: ${(props) => (props.marginLeft ? props.marginLeft : '8px')};
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : 500)};
  font-size: ${(props) => (props.fontSize ? props.fontSize : '20px')};
  line-height: ${(props) => (props.lineHeight ? props.lineHeight : '32px')};
  letter-spacing: ${(props) =>
    props.letterSpacing ? props.letterSpacing : '0.3px'};
  color: ${(props) => (props.color ? props.color : '#1f1f1f')};
`

interface HeaderIconProps {
  margin?: string
}

export const HeaderIcon = styled.img<HeaderIconProps>`
  height: 16px;
  margin: ${(props) => (props.margin ? props.margin : '16px 4px 16px 16px')};
  filter: invert(1);
  cursor: pointer;
`

interface StyledSubHeaderProps {
  color?: string
  fontSize?: string
  fontWeight?: number
  lineHeight?: string
  letterSpacing?: string
  marginLeft?: string
  marginTop?: string
}

export const StyledSubHeader = styled.p<StyledSubHeaderProps>`
  margin-top: ${(props) => (props.marginTop ? props.marginTop : '4px')};
  text-align: left;
  font-feature-settings: 'pnum' on, 'lnum' on;
  margin-left: ${(props) => (props.marginLeft ? props.marginLeft : '20px')};
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : 400)};
  font-size: ${(props) => (props.fontSize ? props.fontSize : '16px')};
  line-height: ${(props) => (props.lineHeight ? props.lineHeight : '16px')};
  letter-spacing: ${(props) =>
    props.letterSpacing ? props.letterSpacing : '0.25px'};
  color: ${(props) => (props.color ? props.color : '#737373')};
`

interface FormContProps {
  padding?: string
  boxShadow?: string
  trackBgColor?: string
  trackBgRadius?: string
  thumbBgColor?: string
  thumbBgRadius?: string
  thumbBorder?: string
}

/* eslint-disable */
export const FormCont = styled.div<FormContProps>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: ${(props) => (props.padding ? props.padding : '0 16px 32px')};
  max-height: 75vh;
  overflow-y: scroll;
  box-shadow: ${(props) =>
    props.boxShadow ? props.boxShadow : ' 0 3px 3px rgba(0, 0, 0, 0.2)'};

  ::-webkit-scrollbar {
    width: 11px;
  }

  ::-webkit-scrollbar-track {
    background: ${(props) =>
      props.trackBgColor ? props.trackBgColor : '#aaaaaa'};
    border-radius: ${(props) =>
      props.trackBgRadius ? props.trackBgRadius : '3px'};
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${(props) =>
      props.thumbBgColor ? props.thumbBgColor : '#303030'};
    border-radius: ${(props) =>
      props.thumbBgRadius ? props.thumbBgRadius : '6px'};
    border: ${(props) =>
      props.thumbBorder ? props.thumbBorder : '3px solid #aaaaaa'};
  }
`

interface FooterProps {
  bgColor?: string
  borderRadius?: string
}

export const Footer = styled.div<FooterProps>`
  width: 100%;
  height: 56px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 16px;
  background-color: ${(props) => (props.bgColor ? props.bgColor : '#f5f5f5')};
  border-radius: ${(props) =>
    props.borderRadius ? props.borderRadius : '0 0 4px 4px'};
`

interface PrimaryButtonProps {
  padding?: string
  bgColor?: string
  borderRadius?: string
  fontSize?: string
  fontWeight?: number
  lineHeight?: string
  letterSpacing?: string
  margin?: string
  color?: string
  active?: boolean
}

export const PrimaryButton = styled.button<PrimaryButtonProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: ${(props) => (props.padding ? props.padding : '8px 16px')};
  background-color: ${(props) => (props.bgColor ? props.bgColor : '#303030')};
  border-radius: ${(props) =>
    props.borderRadius ? props.borderRadius : '6px'};
  white-space: nowrap;
  text-transform: uppercase;
  margin: ${(props) => (props.margin ? props.margin : '12px')};
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : 600)};
  font-size: ${(props) => (props.fontSize ? props.fontSize : '14px')};
  line-height: ${(props) => (props.lineHeight ? props.lineHeight : '16px')};
  letter-spacing: ${(props) =>
    props.letterSpacing ? props.letterSpacing : '0.5px'};
  color: ${(props) => (props.color ? props.color : '#fafafa')};
  opacity: ${(props) => (props.active ? 1 : 0.5)};

  transition: background-color 0.3s, transform 0.2s;

  &:hover {
    background-color: ${(props) => darken(0.1, props.bgColor ? props.bgColor : '#303030')};
    transform: scale(1.02);
  }

  &:active {
    transform: scale(0.98);
  }
`

interface SecondaryButtonProps {
  padding?: string
  bgColor?: string
  borderRadius?: string
  fontSize?: string
  fontWeight?: number
  lineHeight?: string
  letterSpacing?: string
  margin?: string
  color?: string
}

export const SecondaryButton = styled.button<SecondaryButtonProps>`
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : 600)};
  font-size: ${(props) => (props.fontSize ? props.fontSize : '14px')};
  line-height: ${(props) => (props.lineHeight ? props.lineHeight : '16px')};
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: ${(props) =>
    props.letterSpacing ? props.letterSpacing : '0.5px'};
  text-transform: uppercase;
  color: ${(props) => (props.color ? props.color : '#424242')};
  margin-top: 20px;
  margin-bottom: 20px;
  margin-right: 24px;
  white-space: nowrap;
  &:hover {
    color: ${(props) => darken(0.1, props.color ? props.color : '#424242')};
    transform: scale(1.05);
  }

  &:active {
    color: ${(props) => darken(0.2, props.color ? props.color : '#424242')};
    transform: scale(0.98);
  }
`
