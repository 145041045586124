import { FC, PropsWithChildren } from 'react'

interface Props extends PropsWithChildren<any> {
  date: string
}

const TableDate: FC<Props> = (props) => {
  function formatDate(dateString: number) {
    const date = new Date(dateString)
    const year = date.getFullYear()
    const month = String(date.getMonth() + 1).padStart(2, '0')
    const day = String(date.getDate()).padStart(2, '0')
    const hours = String(date.getHours()).padStart(2, '0')
    const minutes = String(date.getMinutes()).padStart(2, '0')
    const seconds = String(date.getSeconds()).padStart(2, '0')
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`
  }

  if (!props.date) return <div></div>
  return <>{formatDate(Date.parse(props.date)).toString()}</>
  //return <>{props.date}</>
}
export default TableDate
