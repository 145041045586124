import { observer } from 'mobx-react'
import { FC, PropsWithChildren, useContext } from 'react'
import { StoreContext } from '../../../App'
import { DropDownItem, HeaderDropDown } from './TasksDropDown.styles'
import axios from '../../../axiosconfig'
import { useParams } from 'react-router-dom'
import { toJS } from 'mobx'
import { toast } from 'react-toastify'

interface Props extends PropsWithChildren {
  table: Array<{ value: string; label: string }>
  currentStep: { value: string; label: string }
  nextStep: { value: string; label: string }
}

const TasksDropDown: FC<Props> = observer(
  ({ table, currentStep, nextStep }: Props) => {
    const store = useContext(StoreContext)
    const params = useParams()

    const { Theme } = store.Theme

    const handleChange = async (
      e: { value: string; label: string },
      index: number
    ) => {
      const id = store.RouteStore.currentDataId
      const listItems = store.TableStore.detailsData.checkListItems
      console.log(listItems)
      const item = listItems[e.value]
      if (
        (item.selected && e.value === currentStep.value) ||
        (!item.selected && e.value === nextStep.value) ||
        index + 1 === table.length
      ) {
        listItems[e.value] = {
          date: new Date().toISOString(),
          username: store.UserStore.user.length
            ? store.UserStore.user
            : 'No data',
          selected: !listItems[e.value].selected,
        }
        const response = await axios.post(
          `${process.env.REACT_APP_BASE_URL}experience/v1/customer_order/${id}`,
          {
            checkListItems: listItems,
          },
          {
            headers: {
              'x-apikey': `${process.env.REACT_APP_API_KEY}`,
              'Access-Control-Allow-Origin': '*',
              'Access-Control-Allow-Methods': 'GET,HEAD,PUT,PATCH,POST,DELETE',
              'Access-Control-Allow-Credentials': 'true',
            },
          }
        )
        await setTimeout(async () => {
          await store.TableStore.fetchDetailsData(params.id)
        }, 2000)
      } else
        toast('You try to modify element outside of the queue!', {
          type: 'warning',
        })
    }
    return (
      <HeaderDropDown {...Theme.proformsDetailsStyles.headerDropDown}>
        {table.map((e, index) => {
          return (
            <DropDownItem
              onClick={async () => {
                await handleChange(e, index)
              }}
              key={index}
              active={
                store.TableStore.detailsData.checkListItems &&
                store.TableStore.detailsData.checkListItems[e.value].selected
              }
              {...Theme.proformsDetailsStyles.dropDownItem}
            >
              {e.label}
            </DropDownItem>
          )
        })}
      </HeaderDropDown>
    )
  }
)
export default TasksDropDown
