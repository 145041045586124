/* eslint-disable */
import {
  PropsWithChildren,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import { Row } from 'react-table'

import {
  hiddenColumns,
  ReplaceEmptyStringsForNulls,
  ValidateArray,
} from '../../../../columnsConfig'

import {
  Container,
  Footer,
  InnerCont,
  PrimaryButton,
  SecondaryButton,
} from './EditTree.styles'

import { StoreContext } from '../../../../App'
import useWindowDimensions from '../../../../methods/hooks/useWindowDimensions'
import { socket } from '../../../../socket/socket'
import { DataInput } from '../../../dataInput/DataInput.component'
import { ObjectInput } from '../../tags/edits/objectInput/ObjectInput.component'
import { StringArrayInput } from '../../tags/edits/arrayInput/ArrayInput.component'
import SearchTagComponent from '../../tags/edits/searchTag/SearchTag.component'
import { ColorInput } from '../../tags/edits/colorInput/ColorInput.component'
import { StringSelectInput } from '../../tags/edits/arraySelect/ArraySelect.component'
import SearchTagNewComponent from '../../tags/edits/searchTagNew/SearchTagNew.component'
import { FilterLabel } from '../../../dataInput/DataInput.styles'
import axios from '../../../../axiosconfig'
import { toast } from 'react-toastify'

export interface EditProductsProps extends PropsWithChildren {
  columns: Array<{ header: string; value: string | object }>
  row?: Row
  hideParent?: boolean
  view?: 'group' | 'tag' | 'root'
  baseUrl: string
  mainDesc: string
  setData: () => void
}

const EditTree: (props: EditProductsProps) => JSX.Element = ({
  columns,
  hideParent,
  view,
  baseUrl,
  mainDesc,
  setData,
}: EditProductsProps) => {
  const store = useContext(StoreContext)
  const { Theme } = store.Theme
  const { width, height } = useWindowDimensions()
  const [changedObject, setChangedObject] = useState(false)
  const [loading, setLoading] = useState(false)
  const divRef = useRef<any>(null)

  const initialState = [
    ...columns.map((e) => {
      return {
        header: e.header,
        value: e.value,
        error: '',
        rules: ValidateArray[e.header] ? ValidateArray[e.header] : [],
      }
    }),
  ]
  const [form, setForm] = useState(initialState)
  const [inputColumns, setInputColumns] = useState(2)

  const handleSetForm = (
    tempForm: Array<{
      header: string
      value: string
      error: string
      rules: Array<string>
    }>
  ) => {
    setForm([...tempForm])
  }

  const handleSave = async () => {
    await setLoading(true)
    let formData = {}
    form.map((e, index) => {
      if (JSON.stringify(e.value) !== JSON.stringify(initialState[index].value))
        formData = { ...formData, [e.header]: e.value }
    })
    const id: any = columns.find((e) => e.header === 'id')?.value || ''
    if (mainDesc === 'Tag') await store.TagsStore.editTag(formData, id, view)
    else {
      const url = `${baseUrl}${id}`
      const data = store.TableStore.formatRequestData(formData)
      const config = {
        headers: {
          'x-apikey': `${process.env.REACT_APP_API_KEY}`,
          username: store.UserStore.user,
        },
      }
      await axios.post(url, data, config)
      await toast('Success!', { type: 'success' })
      await setTimeout(() => setData(), 1000)
    }
    await setLoading(false)
  }

  useEffect(() => {
    setInputColumns(
      Math.ceil(
        (90 * columns.filter((e) => !hiddenColumns.includes(e.header)).length) /
          (height * 0.8)
      )
    )
  }, [height])

  return (
    <Container {...Theme.editProductsStyles.container}>
      <InnerCont ref={divRef} cols={inputColumns}>
        {columns
          ? columns.map((e, index) => {
              if (e.header === 'groupID') {
                return (
                  <>
                    <FilterLabel {...Theme.editProductsStyles.filterLabel}>
                      {e.header}
                    </FilterLabel>
                    <SearchTagComponent
                      header={e.header}
                      applySearch={(e) => {
                        const tempForm = [...form]
                        tempForm[index].value = e.id
                        //@ts-ignore
                        handleSetForm(tempForm)
                      }}
                      view={'group'}
                    />
                    )
                  </>
                )
              } else if (e.header === 'colour') {
                const toStringE = {
                  header: form[index].header,
                  value: form[index].value
                    ? form[index].value.toString()
                    : '#000000',
                }
                return (
                  <>
                    <FilterLabel {...Theme.editProductsStyles.filterLabel}>
                      {e.header}
                    </FilterLabel>
                    <ColorInput
                      vals={toStringE}
                      key={`data-input--${index}`}
                      index={index}
                      form={form}
                      setForm={handleSetForm}
                    />
                  </>
                )
              } else if (e.header === 'parent') {
                if (!hideParent)
                  return (
                    <>
                      <FilterLabel {...Theme.editProductsStyles.filterLabel}>
                        {e.header}
                      </FilterLabel>
                      <SearchTagNewComponent
                        header={e.header}
                        applySearch={(e) => {
                          const tempForm = [...form]
                          tempForm[index].value = e.id
                          //@ts-ignore
                          handleSetForm(tempForm)
                        }}
                        view={'tag'}
                      />{' '}
                    </>
                  )
              } else if (e.header === 'root_id') {
                if (!hideParent)
                  return (
                    <>
                      <FilterLabel {...Theme.editProductsStyles.filterLabel}>
                        {e.header}
                      </FilterLabel>
                      <SearchTagComponent
                        header={e.header}
                        applySearch={(e) => {
                          console.log(e)
                          const tempForm = [...form]
                          tempForm[index].value = e.id
                          //@ts-ignore
                          handleSetForm(tempForm)
                        }}
                        view={'root'}
                      />{' '}
                    </>
                  )
              } else if (
                typeof e.value === 'object' &&
                !Array.isArray(e.value)
              ) {
                const eObject = { ...e.value }
                return (
                  <>
                    <FilterLabel {...Theme.editProductsStyles.filterLabel}>
                      {e.header}
                    </FilterLabel>
                    <ObjectInput
                      header={e.header}
                      index={index}
                      form={form}
                      handleChange={handleSetForm}
                      data={eObject}
                      defaultHeader={'EN'}
                    />{' '}
                  </>
                )
              } else if (Array.isArray(e.value)) {
                return (
                  <>
                    <FilterLabel {...Theme.editProductsStyles.filterLabel}>
                      {e.header}
                    </FilterLabel>
                    <StringSelectInput
                      data={e.value}
                      dataList={store.TagsStore.avaliableIndicies}
                      header={e.header}
                      index={index}
                      form={form}
                      handleChange={handleSetForm}
                    />{' '}
                  </>
                )
              } else {
                const toStringE = {
                  header: e.header,
                  value: e.value ? e.value.toString() : '',
                }
                return (
                  <DataInput
                    vals={toStringE}
                    key={`data-input--${index}`}
                    index={index}
                    form={form}
                    setForm={handleSetForm}
                  />
                )
              }
            })
          : null}
      </InnerCont>
      <Footer {...Theme.editProductsStyles.styledFooter}>
        <SecondaryButton
          onClick={() => store.SlidingPanelState.setVisibility(false)}
          {...Theme.buttons.secondaryButton}
        >
          {'CANCEL'}
        </SecondaryButton>
        <PrimaryButton
          onClick={() => !loading && handleSave()}
          active={!loading}
          {...Theme.buttons.primaryButton}
        >
          {'SAVE'}
        </PrimaryButton>
      </Footer>
    </Container>
  )
}
export default EditTree
