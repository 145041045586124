import styled from 'styled-components'

interface Props {
  bgColor?: string
  padding?: string
  borderRadius?: string
  minWidth?: string
  width?: string
  border?: string
  borderBottom?: string
  boxShadow?: string
  margin?: string
  color?: string
  bgColorHover?: string
  borderHover?: string
  colorHover?: string
  bgColorDisabled?: string
  borderDisabled?: string
  colorDisabled?: string
  fontSize?: string
  fontWeight?: number
  lineHeight?: string
}

interface ContainerProps {
  bgColor?: string
}

export const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background-color: ${(props) => (props.bgColor ? props.bgColor : '#f5f5f5')};
`

interface TitleTextProps {
  fontSize?: string
  fontWeight?: number
  lineHeight?: string
}

export const TitleText = styled.p<TitleTextProps>`
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : 600)};
  font-size: ${(props) => (props.fontSize ? props.fontSize : '48px')};
  line-height: ${(props) => (props.lineHeight ? props.lineHeight : '100%')};
  margin-bottom: 48px;
`

interface InnerDivProps {
  padding?: string
  boxShadow?: string
  borderRadius?: string
}

export const InnerDiv = styled.div<InnerDivProps>`
  padding: ${(props) => (props.padding ? props.padding : '24px')};
  box-shadow: ${(props) =>
    props.boxShadow ? props.boxShadow : '0px 12px 32px rgba(0, 0, 0, 0.1)'};
  border-radius: ${(props) =>
    props.borderRadius ? props.borderRadius : '4px'};
  display: flex;
  flex-direction: column;
  text-align: center;
`

interface InnerTextProps {
  fontSize?: string
  fontWeight?: number
  lineHeight?: string
}

export const InnerText = styled.p<InnerTextProps>`
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : 500)};
  font-size: ${(props) => (props.fontSize ? props.fontSize : '20px')};
  line-height: ${(props) => (props.lineHeight ? props.lineHeight : '32px')};
`
