/* eslint-disable */
import { FC } from 'react'
import { Cont, SingleTag, SingleTagCross } from './TableNewTags.styles'
import Cross from '../../../assets/icons/tagIcons/cross.svg'
import Star from '../../../assets/icons/tagIcons/star.svg'
import Circle from '../../../assets/icons/tagIcons/circle.svg'
import ExpMark from '../../../assets/icons/tagIcons/exp_mark.svg'
import Minus from '../../../assets/icons/tagIcons/minus.svg'
import Plus from '../../../assets/icons/tagIcons/plus.svg'
import QuestionMark from '../../../assets/icons/tagIcons/question_mark.svg'
import Rectangle from '../../../assets/icons/tagIcons/rectangle.svg'
import Smiley from '../../../assets/icons/tagIcons/smiley.svg'
import Car from '../../../assets/icons/tagIcons/car.svg'
import Sun from '../../../assets/icons/tagIcons/sun.svg'

interface Props {
  tags?: {
    name: {
      [key: string]: string
    }
    tag_size: string
    tag_icon?: string
    tags: any
    colour: string
  }[]
  clearFunction?: (e: any) => void
  orderLine?: boolean
}

const TableTags: FC<Props> = (props: Props) => {
  const mapTableSizes = (size: string) => {
    switch (size) {
      case 'small':
        return '10px'
      case 'default':
        return '12px'
      case 'big':
        return '18px'
      default:
        return '12px'
    }
  }

  const mapIcons = (icon: string) => {
    switch (icon) {
      case 'star':
        return <img src={Star} />
      case 'circle':
        return <img src={Circle} />
      case 'exp-mark':
        return <img src={ExpMark} />
      case 'minus':
        return <img src={Minus} />
      case 'plus':
        return <img src={Plus} />
      case 'question-mark':
        return <img src={QuestionMark} />
      case 'rectangle':
        return <img src={Rectangle} />
      case 'smiley':
        return <img src={Smiley} />
      case 'car':
        return <img src={Car} />
      case 'sun':
        return <img src={Sun} />
      case 'cross':
        return <img src={Cross} />
      default:
        return <></>
    }
  }

  if (props.orderLine)
    return (
      <Cont>
        {props.tags && props.tags.length ? (
          props.tags.map((e, index) => {
            return (
              <span key={index}>
                <SingleTag
                  key={`new-tag-${index}`}
                  size={mapTableSizes(e.tag_size)}
                  color={e.colour ? e.colour : '#505050'}
                >
                  {e.tag_icon && mapIcons(e.tag_icon)}
                  {e.name.EN}
                  {props.clearFunction ? (
                    <SingleTagCross
                      onClick={() => {
                        props.clearFunction && props.clearFunction(e)
                      }}
                    >
                      <img src={Cross} />
                    </SingleTagCross>
                  ) : (
                    <></>
                  )}
                </SingleTag>
              </span>
            )
          })
        ) : (
          <div>{'No tags!'}</div>
        )}
      </Cont>
    )
  else
    return (
      <Cont>
        {props.tags && props.tags.length ? (
          props.tags.map((e, index) => {
            return (
              <span key={index}>
                {e.tags ? (
                  e.tags.map((f: any, i: number) => (
                    <SingleTag
                      key={`new-tag-${i}`}
                      size={mapTableSizes(f.tag_size)}
                      color={f.colour ? f.colour : '#505050'}
                    >
                      {mapIcons(f.tag_icon)}
                      {f.name.EN}
                      {props.clearFunction ? (
                        <SingleTagCross
                          onClick={() => {
                            props.clearFunction && props.clearFunction(e)
                          }}
                        >
                          <img src={Cross} />
                        </SingleTagCross>
                      ) : (
                        <></>
                      )}
                    </SingleTag>
                  ))
                ) : (
                  <></>
                )}
              </span>
            )
          })
        ) : (
          <div>{'No tags!'}</div>
        )}
      </Cont>
    )
}
export default TableTags
