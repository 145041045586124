import React, { useCallback, useEffect, useRef, useState } from 'react'
import { RgbaStringColorPicker } from 'react-colorful'
import styled from 'styled-components'

import useClickOutside from '../../../../methods/methods/useClickOutside'
import { Picker, Swatch, Popover } from './Picker.styles'
import { PrimaryButton } from '../ThemeCreator.styles'

interface PopoverPickerProps {
  color: string
  onChange: any
  isAlwaysOpen?: boolean
}

export const PopoverPicker = ({
  color,
  onChange,
  isAlwaysOpen,
}: PopoverPickerProps) => {
  const popover = useRef<any>()
  const [isOpen, toggle] = useState(false)
  const [inputValue, setInputValue] = useState('')
  const [tempColor, setTempColor] = useState(color)

  const close = useCallback(() => toggle(false), [])
  useClickOutside(popover, close)

  function handleInputOnChange(e: any) {
    setInputValue(e.target.value)
    onChange(e.target.value)
  }

  useEffect(() => {
    setInputValue(color)
  }, [color])

  const handleColorChange = (newColor: string) => {
    setTempColor(newColor)
  }

  const applyColor = () => {
    setInputValue(tempColor)
    onChange(tempColor)
  }

  return (
    <Picker>
      <Swatch style={{ backgroundColor: color }} onClick={() => toggle(true)} />
      <input
        type='text'
        value={tempColor}
        onChange={(e) => setTempColor(e.target.value)}
      />
      <PrimaryButton
        style={{ fontSize: '8px', padding: '4px', marginLeft: '6px' }}
        onClick={applyColor}
      >
        OK
      </PrimaryButton>

      {(isOpen || isAlwaysOpen) && (
        <Popover ref={popover}>
          <RgbaStringColorPicker
            color={tempColor}
            onChange={handleColorChange}
            onMouseUp={applyColor}
          />
        </Popover>
      )}
    </Picker>
  )
}
