/*eslint-disable*/
import styled from 'styled-components'

interface AddonsColumnWrapperProps {
  padding?: string
  marginRight?: string
  borderRight?: string
}

export const AddonsColumnWrapper = styled.div<AddonsColumnWrapperProps>`
  display: flex;
  align-items: center;
  height: 100%;

  > div:first-child {
    padding: ${(props) => (props.padding ? props.padding : '0 10px 0 0')};
    margin-right: ${(props) =>
      props.marginRight ? props.marginRight : '10px'};
    border-right: ${(props) =>
      props.borderRight ? props.borderRight : '1px solid gray'};
  }
`

/*eslint-enable*/
interface ArrowsContainerInterface {
  marginLeft?: string
}

interface ArrowExpandedImgInterface {
  marginTop?: string
}

export const ArrowsContainer = styled.div<ArrowsContainerInterface>`
  margin-left: ${(props) => (props.marginLeft ? props.marginLeft : '4px')};
`
export const ArrowExpandedImg = styled.img<ArrowExpandedImgInterface>`
  margin-top: ${(props) => (props.marginTop ? props.marginTop : '4px')};
`
export const ArrowExpandImg = styled.img``
