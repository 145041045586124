import { IconAlertCircle } from '@tabler/icons-react'
import { FC, PropsWithChildren } from 'react'
import { Container, Entry } from './TableEntry.styles'
import { mainTheme as theme } from '../../../static/themes/main.theme'

interface Props extends PropsWithChildren {
  label?: string
  signal?: string
  row: any
}

const TableEntry: FC<Props> = (props) => {
  const getPriorityColor = () => {
    switch (props.row.priority) {
      case 'low':
        return 'yellow'
      case 'medium':
        return 'orange'
      case 'high':
        return 'red'
      default:
        return 'green'
    }
  }
  return (
    <Container>
      {props.row.priority != 'default' ? (
        <>
          <IconAlertCircle size='18px' color={getPriorityColor()} />
        </>
      ) : null}
      <Entry {...theme.tableEntryStyles.entry}>
        {props.label ? props.label : ''}
      </Entry>
    </Container>
  )
}
export default TableEntry
