import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  overflow: auto;

  ::-webkit-scrollbar {
    width: 11px;
  }

  ::-webkit-scrollbar-track {
    background: #aaaaaa;
    border-radius: 3px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #303030;
    border-radius: 6px;
    border: 3px solid #aaaaaa;
  }
`
export const Day = styled.div`
  min-width: 120px;
  width: calc(100% / 7);
  border: 1px solid #e5e5e5;
  display: flex;
  min-height: 100px;
  flex-wrap: wrap;
  flex-direction: column;
  font-size: 12px;
`
