import styled from 'styled-components'

export const Cont = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`

interface SingleCategoryInterface {
  bgColor?: string
  color?: string
  fontSize?: string
  borderRadius?: string
  hoverBgColor?: string
}

/* eslint-disable */
export const SingleCategory = styled.div<SingleCategoryInterface>`
  padding: 0.3rem;
  margin: 2px;
  cursor: pointer;
  color: #dddddd;
  background-color: ${(props) => (props.color ? props.color : '#505050')};
  border-radius: ${(props) =>
    props.borderRadius ? props.borderRadius : '12px'};
  font-size: 12px;
  align-items: center;
  display: flex;
  @media print {
    font-size: 14px;
    font-weight: 500;
    color: #000000;
    background-color: rgb(255, 255, 153);
  }

  :hover {
    background-color: ${(props) =>
      props.hoverBgColor ? props.hoverBgColor : '#303030'};
  }

  img {
    height: 16px;
    margin-right: 4px;
  }
`
