import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { breakpoints } from '../../static/breakpoints'
import { darken } from 'polished'

/* eslint-disable */
interface ContainerProps {
  color?: string
  active: boolean
}

export const OuterCont = styled.div`
  position: relative;
  width: 100%;
  display: flex;

  @media screen and (max-width: ${breakpoints.tablet}) {
    flex-direction: column;
    height: auto;
  }
`

interface SettingsListInterface {
  active: boolean
}

export const SettingsList = styled.div<SettingsListInterface>`
  position: absolute;
  width: 20%;
  height: calc(100vh - 54px);
  max-height: 100%;
  overflow: auto;
  margin-left: ${(props) => (props.active ? '0' : '-20%')};
  min-height: calc(100vh - 54px);
  padding: 16px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.06);
  @media screen and (max-width: ${breakpoints.tablet}) {
    position: relative;

    height: auto;
    width: 100%;
    min-height: 0;
    margin-left: 0;
    display: ${(props) => (props.active ? 'block' : 'none')};
  }
  transition: all 0.2s ease-in-out;

  * {
    transition: all 0.2s ease-in-out;
  }
`

interface SettingsOpenButtonInterface {
  active: boolean
}

export const SettingsOpenButton = styled.div<SettingsOpenButtonInterface>`
  position: absolute;
  left: ${(props) => (props.active ? 'calc(20% - 27px)' : '-30px')};
  top: 39%;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 5;
  transition: all 0.2s ease-in-out;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
  cursor: pointer;

  * {
    transition: all 0.2s ease-in-out;
  }

  svg {
    margin-left: ${(props) => (props.active ? '0' : '20px')};
    width: 22px;
  }

  @media screen and (max-width: ${breakpoints.tablet}) {
    left: calc(50% - 30px);
    position: relative;
    top: -25px;
    svg {
      rotate: 90deg;
      margin-left: 0;
      margin-top: ${(props) => (props.active ? '0' : '20px')};
    }
  }
`

export const StyledLink = styled(Link)`
  position: relative;
  height: 100%;
  width: 100%;
`

export const ButtonCont = styled.div`
  display: flex;

  > * {
    width: min-content !important;
    margin-right: 8px;
  }
`

export const Container = styled.div<ContainerProps>`
  width: ${(props) => (props.active ? '80%' : '100%')};
  display: inline-block;
  box-sizing: border-box;
  padding: 40px;
  margin-left: ${(props) => (props.active ? '20%' : '0')};
  color: ${(props) => (props.color ? props.color : 'black')};
  max-height: calc(100vh - 54px);
  overflow: auto;

  h1 {
    font-size: 24px;
    width: 100%;
  }

  @media screen and (max-width: ${breakpoints.tablet}) {
    width: 100%;
    margin-left: 0;
  }
`

export const SettingsCategory = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  border: 1px solid gray;
  border-radius: 8px;
  box-sizing: border-box;
  padding: 30px;

  h2 {
    font-size: 18px;
    border-bottom: 1px solid gray;
    margin: 0 0 15px 0;
    padding: 0 0 15px 0;
  }
`

export const Setting = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  select,
  input {
    color: black;
    border: 1px solid gray;
    padding: 10px;
    margin-bottom: 20px;
  }
`

export const Label = styled.span`
  font-weight: 500;
`

interface PrimaryButtonProps {
  padding?: string
  bgColor?: string
  borderRadius?: string
  fontSize?: string
  fontWeight?: number
  lineHeight?: string
  letterSpacing?: string
  color?: string
  inactive?: boolean
}

export const PrimaryButton = styled.div<PrimaryButtonProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: fit-content;
  width: fit-content;
  cursor: pointer;
  padding: ${(props) => (props.padding ? props.padding : '8px 16px')};
  background-color: ${(props) => (props.bgColor ? props.bgColor : '#303030')};
  border-radius: ${(props) =>
    props.borderRadius ? props.borderRadius : '6px'};
  white-space: nowrap;
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : 600)};
  font-size: ${(props) => (props.fontSize ? props.fontSize : '14px')};
  line-height: ${(props) => (props.lineHeight ? props.lineHeight : '16px')};
  letter-spacing: ${(props) =>
    props.letterSpacing ? props.letterSpacing : '0.5px'};
  text-transform: uppercase;
  color: ${(props) => (props.color ? props.color : '#fafafa')};
  margin-bottom: 12px;
  ${(props) =>
    props.inactive &&
    `
    opacity: 0.5;
    pointer-events: none;
    `}

  transition: background-color 0.3s, transform 0.2s;

  &:hover {
    background-color: ${(props) =>
      darken(0.1, props.bgColor ? props.bgColor : '#303030')};
    transform: scale(1.02);
  }

  &:active {
    transform: scale(0.98);
  }
`
