/* eslint-disable */
import { FC } from 'react'
import { Cont, SingleCategory } from './TableCategories.styles'
import Cross from '../../../assets/icons/tagIcons/cross.svg'
import Star from '../../../assets/icons/tagIcons/star.svg'
import Circle from '../../../assets/icons/tagIcons/circle.svg'
import ExpMark from '../../../assets/icons/tagIcons/exp_mark.svg'
import Minus from '../../../assets/icons/tagIcons/minus.svg'
import Plus from '../../../assets/icons/tagIcons/plus.svg'
import QuestionMark from '../../../assets/icons/tagIcons/question_mark.svg'
import Rectangle from '../../../assets/icons/tagIcons/rectangle.svg'
import Smiley from '../../../assets/icons/tagIcons/smiley.svg'
import Car from '../../../assets/icons/tagIcons/car.svg'
import Sun from '../../../assets/icons/tagIcons/sun.svg'

interface Props {
  categories?: {
    name: {
      [key: string]: string
    }
    tag_size: string
    tag_icon?: string
    tags: any
    colour: string
  }[]
}

const TableCategories: FC<Props> = (props: Props) => {
  return (
    <Cont>
      {props.categories && props.categories.length ? (
        props.categories.map((e, index) => {
          return (
            <span key={index}>
              <SingleCategory key={`new-tag-${index}`} color={'#505050'}>
                {e.name.en_GB}
              </SingleCategory>
            </span>
          )
        })
      ) : (
        <div>{'No categories!'}</div>
      )}
    </Cont>
  )
}
export default TableCategories
