import { action, makeAutoObservable } from 'mobx'
import { RootStore } from './Root.store'

interface ProductsListType {
  id: string
  name: string
  tags: string
  code: number
  original: number
  afterPackaging: number
  demand: number
  afterThis: number
  inProgress: number
  notesFromOrder: string
  afterOrders: number
  image: string
}

interface ProductsListFilters {
  [key: string]: string | number
}

interface ProductsListDataInterface extends Array<ProductsListType> {}

export class ProductsListStore {
  rootStore
  ProductsListData: ProductsListDataInterface = []
  ProductsListFilters: ProductsListFilters = {
    id: '',
    name: '',
    tags: '',
    code: '',
    original: '',
    afterPackaging: '',
    demand: '',
    afterThis: '',
    inProgress: '',
    notesFromOrder: '',
    afterOrders: '',
    image: '',
  }

  constructor(rootStore: RootStore) {
    makeAutoObservable(this)
    this.rootStore = rootStore
  }

  private fetchDataFiltered = async (): Promise<void> => {
    console.log('Requesting data filtered by: ', this.ProductsListFilters)
    // const response = await axios.post(
    //   'http://localhost:3000/productlist',
    //   this.SupplierFilters
    // )
    // this.SuppliersData = response.data
  }

  @action.bound setFilters = (
    filterName: string,
    filterValue: string | number
  ): void => {
    this.ProductsListFilters[filterName] = filterValue
    console.log('==============FILTER CHANGE================')
    console.log(this.ProductsListFilters)
    console.log('===========================================')
    this.fetchDataFiltered()
  }
}
