import styled from 'styled-components'

export const Container = styled.div`
  height: 70px;
  width: 70px;
  cursor: pointer;
  box-sizing: border-box;
`

interface DisplayedProps {
  bgColor?: string
  padding?: string
}

export const Displayed = styled.div<DisplayedProps>`
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.4);
  padding: ${(props) => (props.padding ? props.padding : '0 24px')};
  z-index: 70;
  img {
    max-width: 60vw;
    max-height: 80vh;
  }
`
export const Arrow = styled.img`
  filter: invert(1);
  height: min(2vw, 32px);
`
export const DisplayedImage = styled.img`
  z-index: 10;
  height: 64px;
  :hover {
    transform: scale(1.2);
  }
`
export const CrossStyles = styled.img`
  position: fixed;
  top: 6%;
  right: 3%;
  z-index: 71;
  height: min(2vw, 32px);
  width: min(2vw, 32px);
  filter: invert(0.8);
`
