// Custom component for language-based cell rendering
import { StoreContext } from '../../../../../App'
import { useContext } from 'react'

interface LanguageBasedCellComponentProps {
  cellValue: {
    [key: string]: string
  }
}

export const LanguageBasedCell = ({
  cellValue,
}: LanguageBasedCellComponentProps) => {
  const store = useContext(StoreContext)
  const currentLanguage = store.TranslationsState.setLanguage

  // First, check for the current language, then 'en', and finally return a default message
  const displayValue =
    cellValue[currentLanguage] || cellValue['EN'] || 'No description available'

  return <div>{displayValue}</div>
}
