import styled from 'styled-components'

interface SwitchToTopButtonProps {
  isVisible: boolean
}
export const ScrollToTopButtonWrapper = styled.button<SwitchToTopButtonProps>`
  display: ${(props) => (props.isVisible ? 'block' : 'none')};
  position: fixed;
  bottom: 12px;
  right: 16px;
  font-size: 12px;
  padding: 4px 4px;
  background-color: #333;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  z-index: 2;
  @media print {
    display: none;
  }
`
