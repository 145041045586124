import { observer } from 'mobx-react'
import React, { useContext } from 'react'
import { StoreContext } from '../../../../App'
import EditTag from '../../genericTree/editTag/EditTree.component'
import AssignTag from '../edits/assignTag/AssignTag.component'
import Table from '../../../TableGeneric/table/Table.component'
import { Cell } from 'react-table'
import { ArrayDisplayCont, ObjectDisplayCont } from '../Tags.styles'
import { SlidingPanel } from '../../../slidingContainer/SlidingContainer.component'
import FilterIcon from '../../../../assets/icons/filter-icon.svg'
import CreateTagComponent from '../edits/createTag/CreateTag.component'
import { LanguageBasedCell } from '../edits/languageBasedCell/LanguageBasedCell.component'
import RemoveTagComponent from '../edits/removeTag/RemoveTag.component'

const TagsTable = observer(() => {
  const store = useContext(StoreContext)
  const tableStore = store.TableStore
  const columns = {
    id: {
      canFilter: false,
      canSort: false,
      editable: false,
    },
    name: {
      Cell: (cell: Cell) => <LanguageBasedCell cellValue={cell.value} />,
      canFilter: false,
      canSort: false,
      editable: true,
      mandatory: true,
      editType: 'languageObject',
      mandatoryLanguage: 'EN',
    },
    root_id: {
      canFilter: false,
      canSort: false,
      editable: true,
      mandatory: true,
      editType: 'rootSearch',
    },
    colour: {
      canFilter: false,
      canSort: false,
      editable: true,
      editType: 'color',
    },
    priority: {
      canFilter: false,
      canSort: false,
      editable: true,
      mandatory: true,
      editType: 'number',
    },
    parent: {
      canFilter: false,
      canSort: false,
      editable: true,
      mandatory: true,
      editType: 'tagSearch',
    },
    tag_size: {
      canFilter: false,
      canSort: false,
      editable: true,
      editType: 'select',
      selectValues: [
        { value: 'default', label: 'default' },
        { value: 'big', label: 'big' },
        { value: 'small', label: 'small' },
      ],
    },
    tag_icon: {
      canFilter: false,
      canSort: false,
      editable: true,
      mandatory: true,
      editType: 'select',
      selectValues: store.TagsStore.avaliableIcons.map((icon) => ({
        value: icon,
        label: icon,
      })),
    },
    description: {
      Cell: (cell: Cell) => <LanguageBasedCell cellValue={cell.value} />,
      canFilter: false,
      canSort: false,
      editable: true,
      mandatory: true,
      editType: 'languageObject',
      mandatoryLanguage: 'EN',
    },
    path: {
      canFilter: false,
      canSort: false,
      editable: false,
      Cell: (cell: Cell) => (
        <ObjectDisplayCont>
          {cell.value &&
            Object.entries(cell.value).map(([header, value], index) => (
              <div key={`${index}-value-name`}>{`${header}: ${value}`}</div>
            ))}
        </ObjectDisplayCont>
      ),
    },
  }
  return (
    <Table
      config={{
        tableName: 'tags',
        data: store.TagsStore.data || [],
        fetchData: () => store.TagsStore.getData(),
        columnHeaders: columns,
        columnVisibility: false,
        allowColumnResize: true,
        displaySearch: true,
        displaySelect: false,
        displayExpand: false,
        displayTooltip: false,
        displayEntry: false,
        displayPaginator: true,
        displayInfinitySwitch: true,
        allowSort: false,
        bank: false,
        actions: {
          display: true,
          label: 'Tag',
          edit: `${process.env.REACT_APP_BASE_URL}experience/v1/tag/update`,
          create: `${process.env.REACT_APP_BASE_URL}experience/v1/tag/_create`,
          remove: `${process.env.REACT_APP_BASE_URL}experience/v1/tag/remove`,
          assignTag: null,
          custom: [],
        },
        headline: {
          visible: true,
          title: 'Tags',
          subTitle: 'List of Tags',
        },
        breadcrumb: [],
        loaded: store.TagsStore.dataLoaded,
        pagination: store.TagsStore.pagination,
        setPagination: store.TagsStore.setPagination,
      }}
    ></Table>
  )
})

export { TagsTable }
