import styled from 'styled-components'

interface ContainerProps {
  padding?: string
}

export const Container = styled.div<ContainerProps>`
  display: inline-block;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: ${(props) => (props.padding ? props.padding : '6px 0')};

  svg {
    width: 100%;
    height: 100%;
  }
`
