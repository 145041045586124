import styled from 'styled-components'
import { breakpoints } from '../../../static/breakpoints'
import Menu from 'rc-menu'

export const OuterContainer = styled.div`
  position: relative;
  height: 100%;
  min-height: 80vh;
  width: 100%;
  display: flex;
  flex-grow: inherit;

  * {
    transition: none 0s !important;
  }

  @media screen and (max-width: ${breakpoints.tablet}) {
    flex-direction: column;
    height: auto;
  }
`

export const LoadingPage = styled.div`
  position: absolute;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 80;
`
export const RootTagList = styled.div`
  position: absolute;
  flex-shrink: 0;
  padding: 16px;
  z-index: 50;
  white-space: nowrap;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;

  b {
    display: flex;
  }
`

interface ArrowInterface {
  selected: boolean
}

export const ArrowCont = styled.img<ArrowInterface>`
  transform: ${(props) =>
    props.selected ? 'rotate(90deg)' : 'rotate(270deg)'};
`

interface ContainerProps {
  color?: string
}

export const MenuList = styled(Menu)`
  position: absolute;
  background-color: white;
  width: min-content;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  right: 0;
  top: 45px;
`

export const MenuListButton = styled.button`
  text-decoration: underline;
  display: flex;
  flex-wrap: nowrap;
  margin-left: 4px;
  color: #303030;

  :hover {
    color: rgba(90, 34, 139, 1);
  }
`
export const Container = styled.div<ContainerProps>`
  width: 100%;
  display: flex;
  padding: 40px;
  overflow: auto;
  flex: 1;
  color: ${(props) => (props.color ? props.color : 'black')};
  transition: none 0s;

  h1 {
    font-size: 24px;
    width: 100%;
  }

  @media screen and (max-width: ${breakpoints.tablet}) {
    width: 100%;
  }
`
