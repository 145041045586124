import { observer } from 'mobx-react'
import { useContext } from 'react'
import { NavLink } from 'react-router-dom'
import { StoreContext } from '../../App'
import { Button } from './NavButton.styles'
import { Tooltip } from '@mui/material'

/* eslint-disable */
interface NavButtonProps {
  label?: string
  icon?: JSX.Element
  tooltip?: string
}

export const NavButton = observer(
  ({ icon, label = 'button', tooltip, ...props }: NavButtonProps) => {
    const store = useContext(StoreContext)
    const { Theme } = store.Theme
    const locales = store.TranslationsState.translations
    return (
      <NavLink
        to={label}
        onClick={() => {
          if (label != store.RouteStore.currentPage)
            store.TableStore.setLoading(false)
        }}
        style={(isActive) => ({
          color: isActive.isActive ? '#FFFFFF' : '#f2f2f2',
          fontWeight: isActive.isActive ? 500 : 200,
          borderBottom: isActive.isActive
            ? '3px solid rgba(221,175,77,0.8)'
            : '3px solid transparent',
          zIndex: 6,
        })}
      >
        {tooltip ? (
          <Tooltip title={tooltip} placement='right' arrow>
            <Button {...props} {...Theme.navButtonStyles.button}>
              {icon ? icon : locales.tableNames[label]}
            </Button>
          </Tooltip>
        ) : (
          <Button {...props} {...Theme.navButtonStyles.button}>
            {icon
              ? icon
              : locales.tableNames[label]
              ? locales.tableNames[label]
              : label}
          </Button>
        )}
      </NavLink>
    )
  }
)
