/* eslint-disable */
import {
  ChangeEvent,
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from 'react'
import { Row } from 'react-table'

import { hiddenColumns } from '../../../../../columnsConfig'

import {
  Container,
  Footer,
  InnerCont,
  PrimaryButton,
  SecondaryButton,
} from './AddTagTree.styles'

import { StoreContext } from '../../../../../App'
import SearchTagComponent from '../searchTag/SearchTag.component'
import SearchTagNewComponent from '../searchTagNew/SearchTagNew.component'

export interface EditProductsProps extends PropsWithChildren {
  id: string
  left?: boolean
  parentId: string
}

const AddSupplierProduct: (props: EditProductsProps) => JSX.Element = (
  props: EditProductsProps
) => {
  const store = useContext(StoreContext)
  const { Theme } = store.Theme
  const [changedObject, setChangedObject] = useState(false)
  const columns = ['id']
  const [id, setId] = useState('')
  const [data, setData] = useState<any>(null)
  const [loading, setLoading] = useState(false)
  const applySearch = (product: any) => {
    setId(product.id)
    setData(product)
  }
  const handleSave = async () => {
    if (props.left) {
      await setLoading(true)
      await store.TagsStore.insertTag(id, props.parentId, props.id, {})
    } else {
      await setLoading(true)
      const data = {
        parent: props.id,
      }
      await store.TagsStore.editTag(data, id, 'tag')
      await setLoading(false)
    }
  }

  return (
    <Container {...Theme.editProductsStyles.container}>
      <InnerCont cols={1}>
        <SearchTagNewComponent
          applySearch={applySearch}
          view={'tag'}
          header={'Search Tag'}
        />
      </InnerCont>
      <Footer {...Theme.editProductsStyles.styledFooter}>
        <SecondaryButton
          onClick={() => store.SlidingPanelState.setVisibility(false)}
          {...Theme.buttons.secondaryButton}
        >
          {'CANCEL'}
        </SecondaryButton>
        <PrimaryButton
          onClick={() => data && !loading && handleSave()}
          active={data && !loading}
          {...Theme.buttons.primaryButton}
        >
          {'SAVE'}
        </PrimaryButton>
      </Footer>
    </Container>
  )
}
export default AddSupplierProduct
