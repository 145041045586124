import { FC, PropsWithChildren, useContext, useState } from 'react'
import { ColumnInstance, TableToggleHideAllColumnProps } from 'react-table'
import { observer } from 'mobx-react'

import { StoreContext } from '../../../../App'

import {
  AllColumnRow,
  ColumnHead,
  ColumnHeadText,
  ColumnRow,
  ColumnText,
  SelectWrap,
  StyledInput,
} from './CalendarVisibility.styles'
/* eslint-disable */
interface Props extends PropsWithChildren {
  mobile?: boolean
}

const CalendarVisibility: FC<Props> = observer(({ mobile }: Props) => {
  const store = useContext(StoreContext)
  const { Theme } = store.Theme
  const locales = store.TranslationsState.translations

  return (
    <SelectWrap mobile={mobile || false}>
      <div>
        <ColumnHead {...Theme.columnVisibilityStyles.columnHead}>
          <ColumnHeadText {...Theme.columnVisibilityStyles.columnHeadText}>
            {'Calendar Options'}
          </ColumnHeadText>
          <ColumnHeadText {...Theme.columnVisibilityStyles.columnHeadText}>
            {locales.actions.show}
          </ColumnHeadText>
        </ColumnHead>
        {Object.keys(store.CalendarStore.visibility).map((column, i) => {
          return (
            <ColumnRow
              key={`calendar-column-${column}`}
              active={false}
              {...Theme.columnVisibilityStyles.columnRow}
            >
              <ColumnText {...Theme.columnVisibilityStyles.columnText}>
                {column}
              </ColumnText>
              <ColumnText {...Theme.columnVisibilityStyles.columnText}>
                <StyledInput
                  type='checkbox'
                  //@ts-ignore
                  checked={store.CalendarStore.visibility[column]}
                  onChange={() => store.CalendarStore.setVisibility(column)}
                  {...Theme.columnVisibilityStyles.styledInput}
                />
              </ColumnText>
            </ColumnRow>
          )
        })}
      </div>
    </SelectWrap>
  )
})
export default CalendarVisibility
