import React, { useContext, useEffect } from 'react'
import { StoreContext } from '../../../../App'
import { observer } from 'mobx-react'
import { Container, DayContainer } from './CalendarEntry.styles'
import { Tooltip } from '@mui/material'
import { toJS } from 'mobx'

interface Products {
  ProductID: string
  Name: string
  OrderedQuantity: string
  DeliveredQuantity: string
  ProductCode?: string
}

interface Order {
  id: string
  supplierOrderId: string
  supplier: string
  status: string
  estimateDate: string
  creationDate: string
  products: { [key: string]: Products }
}

interface CalendarEntryProps {
  entryData: Order
  color: string
  deliveredHover?: boolean
}
// Get products count
const getProductsCount = (products: { [key: string]: Products }) =>
  Object.keys(products).length

// Get list of products with required details
const getProductsList = (
  products: { [key: string]: Products },
  deliveredHover?: boolean
) =>
  Object.values(products).map((product) => {
    if (product)
      return (
        <div key={product.ProductID}>
          <div>
            {product.OrderedQuantity}
            {deliveredHover ? ` of ${product.DeliveredQuantity}` : `x`}:{' '}
            {product.ProductCode}, {product.Name}
          </div>
        </div>
      )
    else return <></>
  })

const OrderEntry = observer(
  ({ entryData, color, deliveredHover }: CalendarEntryProps) => {
    const store = useContext(StoreContext)
    const view = store.CalendarStore.view
    const locales = store.TranslationsState.translations

    const { supplierOrderId, supplier, products, status } = entryData
    const productsCount = entryData ? getProductsCount(products) : 0
    const productsList = getProductsList(products, deliveredHover)

    switch (view) {
      case 'month':
      case 'week':
      case 'day':
        return (
          <Tooltip title={productsList}>
            <Container color={color}>
              {`${
                supplierOrderId ? supplierOrderId : 'Unnamed'
              }, ${supplier}, ${locales.status[status]}`}
            </Container>
          </Tooltip>
        )
      /*case 'week':
        return (
          <Container color={color}>
            <div>{`${supplierOrderId ? supplierOrderId : 'Unnamed'}`} </div>
            <div>Products Count: {productsCount}</div>
          </Container>
        )
      case 'day':
        return (
          <DayContainer color={color}>
            <div>{supplierOrderId ? supplierOrderId : 'Unnamed'}</div>
            {productsList}
          </DayContainer>
        )*/
      default:
        return <></>
    }
  }
)

export default OrderEntry
