import styled from 'styled-components'

interface ContainerProps {
  bgColor?: string
  padding?: string
  borderRadius?: string
  border?: string
}

export const Container = styled.div<ContainerProps>`
  background: ${(props) => (props.bgColor ? props.bgColor : '#ffffff')};
  border: ${(props) => (props.border ? props.border : '1px solid #d6d6d6')};
  border-radius: ${(props) =>
    props.borderRadius ? props.borderRadius : '4px'};
  padding: ${(props) => (props.padding ? props.padding : '12px 12px')};
  min-height: 736px;
`

interface HeaderProps {
  color?: string
  fontSize?: string
  fontWeight?: number
  lineHeight?: string
}

export const HeaderTitle = styled.p<HeaderProps>`
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : 500)};
  font-size: ${(props) => (props.fontSize ? props.fontSize : '20px')};
  line-height: ${(props) => (props.lineHeight ? props.lineHeight : '32px')};
  letter-spacing: 0.3px;
  color: ${(props) => (props.color ? props.color : '#1f1f1f')};
`

interface ChangesProps {
  margin?: string
}

export const ChangesCont = styled.div<ChangesProps>`
  margin: ${(props) => (props.margin ? props.margin : '16px 0 24px 0')};
`
export const ChangesInner = styled.div`
  display: flex;
  height: 65px;
`
export const ChangesLeft = styled.div`
  width: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
`

interface ChangesCircleProps {
  borderRadius?: string
  border?: string
  margin?: string
}

export const ChangesCircle = styled.div<ChangesCircleProps>`
  height: 32px;
  width: 32px;
  margin: ${(props) => (props.margin ? props.margin : '0 0 4px 0')};
  border-radius: ${(props) =>
    props.borderRadius ? props.borderRadius : '50%'};
  border: ${(props) => (props.border ? props.border : '1.5px solid #d6d6d6')};
  display: flex;
  justify-content: center;
  align-items: center;
`

interface ChangesLineProps {
  border?: string
  margin?: string
}

export const ChangesLine = styled.div<ChangesLineProps>`
  height: 26px;
  width: 0;
  border: ${(props) => (props.border ? props.border : '1px dashed #d6d6d6')};
  margin: ${(props) => (props.margin ? props.margin : '0 0 4px 0')};
`

interface ChangesRightProps {
  margin?: string
}

export const ChangesRight = styled.div<ChangesRightProps>`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: ${(props) => (props.margin ? props.margin : '0 0 0 24px')};
`

interface ChangesTopTextProps {
  margin?: string
  color?: string
  fontSize?: string
  fontWeight?: number
  lineHeight?: string
}

export const ChangesTopText = styled.div<ChangesTopTextProps>`
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : 400)};
  font-size: ${(props) => (props.fontSize ? props.fontSize : '12px')};
  line-height: ${(props) => (props.lineHeight ? props.lineHeight : '16px')};
  letter-spacing: 0.15px;
  margin: ${(props) => (props.margin ? props.margin : '0 0 12px 0')};
  color: ${(props) => (props.color ? props.color : '#1f1f1f')};
`

interface ChangesBottomProps {
  padding?: string
  borderBottom?: string
}

export const ChangesBottom = styled.div<ChangesBottomProps>`
  display: flex;
  border-bottom: ${(props) =>
    props.borderBottom ? props.borderBottom : '1px solid #f5f5f5'};
  padding: ${(props) => (props.padding ? props.padding : '0 0 12px 0')};
`

interface ChangesBottomTextProps {
  fontWeight?: number
  padding?: string
  fontSize?: string
  lineHeight?: string
  color?: string
}

export const ChangesBottomText = styled.div<ChangesBottomTextProps>`
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : 400)};
  padding: ${(props) => (props.padding ? props.padding : '0 8px 0 0')};
  font-size: ${(props) => (props.fontSize ? props.fontSize : '10px')};
  line-height: ${(props) => (props.lineHeight ? props.lineHeight : '12px')};
  letter-spacing: 0.4px;
  font-feature-settings: 'tnum' on, 'lnum' on;
  color: ${(props) => (props.color ? props.color : '#1f1f1f')};
`
