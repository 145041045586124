/* eslint-disable */
import {
  PropsWithChildren,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import { Row } from 'react-table'

import {
  hiddenColumns,
  ReplaceEmptyStringsForNulls,
  ValidateArray,
} from '../../columnsConfig'

import {
  Container,
  Footer,
  InnerCont,
  PrimaryButton,
  SecondaryButton,
} from './EditGeneric.styles'

import { StoreContext } from '../../App'
import useWindowDimensions from '../../methods/hooks/useWindowDimensions'
import { socket } from '../../socket/socket'
import { DataInput } from '../dataInput/DataInput.component'
import axios from '../../axiosconfig'
import { DataInputGeneric } from '../dataInputGeneric/DataInputGeneric.component'
import { toJS } from 'mobx'

export interface EditProductsProps extends PropsWithChildren {
  tableName: string
  row: Row
  url: string
  columns: Array<{ header: string; value: string }>
  toggleClick: () => void
  name: string
  fetchData: () => void
}

const EditGeneric: (props: EditProductsProps) => JSX.Element = (
  props: EditProductsProps
) => {
  const store = useContext(StoreContext)
  const { SlidingPanelState } = store
  const { Theme } = store.Theme
  const { width, height } = useWindowDimensions()
  const [lockChecked, setLockChecked] = useState(true)
  const [locked, setLocked] = useState(false)
  const [lockedUser, setLockedUser] = useState('')
  const [triggerChange, setTriggerChange] = useState(false)
  const [changedObject, setChangedObject] = useState(true)

  const divRef = useRef<any>(null)

  const initialState = [
    ...props.columns.map((e) => {
      return {
        header: e.header,
        value: e.value,
        error: '',
        rules: ValidateArray[e.header] ? ValidateArray[e.header] : [],
      }
    }),
  ]

  const [form, setForm] = useState(initialState)
  const [inputColumns, setInputColumns] = useState(1)

  const handleSetForm = (
    tempForm: Array<{
      header: string
      value: string
      error: string
      rules: Array<string>
    }>
  ) => {
    setForm([...tempForm])
  }

  const getUrl = (id: string | undefined) => {
    switch (props.tableName) {
      case 'currencies':
        return `${props.url}${id}`
      default:
        return `${props.url}/${id}`
    }
  }
  const handleSave = async () => {
    const id = props.columns.find((e: any) => e.header === 'id')?.value
    let fetchObject: any = {}
    form.map((e, index) => {
      const customOptions = props.row.allCells.find(
        (f: any) => f.column.id === e.header
        //@ts-ignore
      )?.column.editCustomOptions
      if (
        JSON.stringify(e.value) !== JSON.stringify(initialState[index].value) ||
        props.tableName === 'supplierorders' ||
        customOptions.editType === 'filter'
      )
        fetchObject = {
          [e.header]: toJS(e.value),
          ...fetchObject,
        }
    })
    if (props.tableName === 'supplierorders') {
      fetchObject = {
        ...fetchObject,
        lastEditAuthor: store.UserStore.user,
        lastEditDate: new Date().toISOString(),
      }
    }

    store.SlidingPanelState.setVisibility(false)

    let data = store.TableStore.formatRequestData(fetchObject)
    if (props.tableName === 'predefinedfilters') {
      //@ts-ignore
      data = { filter: data.action }
    }
    const config = {
      headers: {
        'x-apikey': `${process.env.REACT_APP_API_KEY}`,
        username: store.UserStore.user.length
          ? store.UserStore.user
          : 'No data',
      },
    }

    try {
      console.log(getUrl(id), data, config)
      await axios.post(getUrl(id), data, config)
      await props.fetchData()
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    setInputColumns(
      Math.ceil(
        (90 *
          props.columns.filter((e) => !hiddenColumns.includes(e.header))
            .length) /
          (height * 0.8)
      )
    )
  }, [height])

  useEffect(() => {
    let changed = true
    form.map((e, index) => {
      if (e.error !== '') {
        changed = false
        return
      }
    })
    setChangedObject(changed)
  }, [triggerChange])

  return (
    <Container {...Theme.editProductsStyles.container}>
      {lockChecked && !locked ? (
        <>
          <InnerCont ref={divRef} cols={inputColumns}>
            {props.columns
              ? props.columns.map((e, index) => {
                  const customOptions = props.row.allCells.find(
                    (f: any) => f.column.id === e.header
                    //@ts-ignore
                  )?.column.editCustomOptions
                  if (customOptions.editable && e.header !== 'equal')
                    return (
                      <DataInputGeneric
                        type={'edit'}
                        vals={e}
                        editOptions={customOptions}
                        key={`data-input--${index}`}
                        index={index}
                        form={form}
                        setForm={handleSetForm}
                        triggerChange={() => setTriggerChange(!triggerChange)}
                      />
                    )
                })
              : null}
          </InnerCont>
          <Footer {...Theme.editProductsStyles.styledFooter}>
            <SecondaryButton
              onClick={() => store.SlidingPanelState.setVisibility(false)}
              {...Theme.buttons.secondaryButton}
            >
              {'CANCEL'}
            </SecondaryButton>
            <PrimaryButton
              onClick={() => changedObject && handleSave()}
              //onClick={() => handleSave()}
              active={changedObject}
              {...Theme.buttons.primaryButton}
            >
              {'SAVE'}
            </PrimaryButton>
          </Footer>
        </>
      ) : (
        <>
          {locked ? (
            <>This order is currently edited by {lockedUser}</>
          ) : (
            <>Checking order status</>
          )}
        </>
      )}
    </Container>
  )
}
export default EditGeneric
