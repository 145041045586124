import { Container } from './TableMicroservice.styles'

interface TableCompleteableInterface {
  value: number | null
}
const TableMicroservice = ({ value }: TableCompleteableInterface) => {
  const MapStatuses = () => {
    if (Number.isNaN(value)) {
      return ''
    }
    switch (value) {
      case 0:
        return 'Not enough products! (0)'
      case 1:
        return 'Enough products! (1)'
      case null:
        return ''
      default:
        return `Incorrect Value (${value})`
    }
  }
  const getColor = () => {
    switch (value) {
      case 1:
        return 'rgba(0,255,0,0.4)'
      case 0:
        return 'rgba(255,0,0,0.4)'
      default:
        return 'transparent'
    }
  }
  return <Container color={getColor()}>{MapStatuses()}</Container>
}
export default TableMicroservice
