import { observer } from 'mobx-react'
import { FC, useContext } from 'react'
import { StoreContext } from '../../../App'
import { Bottom, Container, Top } from './TableTransactionData.styles'
import { NavLink } from 'react-router-dom'

/* eslint-disable */
interface Props {
  transactionData: {
    DaneOperacjiMeta: {
      RachunekKontrahenta: string
      IdentyfikatorTransakcji: string
      NazwaiAdresKontrahenta: string
      Tytul: string
      Rachunek: string
      ReferencjeWlasne: string
    }
    DataWaluty: string
    DataOperacji: string
    Kwota: ''
    DaneOperacji: string
    TypOperacji: string
    Waluta: string
    Column1: string
  }
}

const TableTransactionData: FC<Props> = observer(
  ({ transactionData }: Props) => {
    const store = useContext(StoreContext)
    const { Theme } = store.Theme
    const locales = store.TranslationsState.translations
    return (
      <Container /*{...Theme.tableStockInProgressStyles.container}*/>
        <Top>
          <b>Operation Data</b>
        </Top>
        <Bottom {...Theme.tableStockInProgressStyles.top}>
          <p>Date: {transactionData.DaneOperacjiMeta.RachunekKontrahenta}</p>
          <p>Id: {transactionData.DaneOperacjiMeta.IdentyfikatorTransakcji}</p>
          <p>
            Name and adress:
            {transactionData.DaneOperacjiMeta.NazwaiAdresKontrahenta}
          </p>
          <p>Title: {transactionData.DaneOperacjiMeta.Tytul}</p>
          <p>Account: {transactionData.DaneOperacjiMeta.Rachunek}</p>
          <p>References: {transactionData.DaneOperacjiMeta.ReferencjeWlasne}</p>
        </Bottom>
        <Bottom>
          Currency: {transactionData.Waluta} (state for:{' '}
          {transactionData.DataWaluty})
        </Bottom>
        <Bottom>Amount: {transactionData.Kwota} </Bottom>
        <Bottom>Operation Type: {transactionData.TypOperacji} </Bottom>
        <Bottom>Operation Data: {transactionData.DaneOperacji} </Bottom>
      </Container>
    )
  }
)
export default TableTransactionData
