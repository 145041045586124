import { observer } from 'mobx-react'
import { FC, useContext, useEffect, useState } from 'react'
import { StoreContext } from '../../../App'
import { Bottom, Container, Top } from './TableCountedInvoices.styles'
import { NavLink } from 'react-router-dom'
import { Cell } from 'react-table'
import { toJS } from 'mobx'

/* eslint-disable */
interface Props {
  data: {
    SupplierDeliveryDate?: string
    Quantity?: string
    Id?: string
    SupplierOrderId?: string
    DeliveredQuantity?: string | null
    Status?: string | null
    Date?: string | null
  }[]
  cell: Cell
  secondary?: boolean
}

const TableCountedInvoices: FC<Props> = observer((props: Props) => {
  const store = useContext(StoreContext)
  const { Theme } = store.Theme
  const locales = store.TranslationsState.translations
  const [colors, setColorsState] = useState<string[]>([])
  const [demand, setDemand] = useState(
    parseInt(
      props.cell.row.allCells.find((e: any) => e.column.id === 'afterThis')
        ?.value
    )
  )
  console.log(toJS(props.data))

  const setColors = async () => {
    let colorData: string[] = []

    let tempDemand = demand
    props.data &&
      props.data.map((e: any, index) => {
        console.log(index)
        if (tempDemand > 0) colorData = [...colorData, 'red']
        else {
          console.log('dmvalue', tempDemand, 'quan', parseInt(e.Quantity))
          tempDemand = tempDemand + parseInt(e.Quantity)
          console.log(tempDemand, 'tempDemand while coloradd')
          tempDemand < 0
            ? (colorData = [...colorData, '#8B8000'])
            : (colorData = [...colorData, 'green'])
        }
      })
    setColorsState(colorData)
  }
  useEffect(() => {
    setColors()
  }, [])

  return (
    <div>
      {props.data
        ? props.data.map((e: any, index) => {
            return (
              <Container
                color={
                  colors[index] ? colors[index] : '#000000'
                } /*{...Theme.tableStockInProgressStyles.container}*/
              >
                <Top {...Theme.tableStockInProgressStyles.top}>
                  <p>
                    {`${e.DeliveredQuantity ? e.DeliveredQuantity : 0}/${
                      e.Quantity
                    }`}
                  </p>{' '}
                  from{' '}
                  <NavLink
                    to={'/supplierorders'}
                    onClick={() => {
                      store.RouteStore.setQueryHandler(true)
                      localStorage.setItem(
                        'supplierorders-query',
                        JSON.stringify({
                          rules: [
                            {
                              field: 'id',
                              operator: '=',
                              valueSource: 'value',
                              value: e.Id,
                            },
                          ],
                          combinator: 'and',
                          not: false,
                        })
                      )
                    }}
                  >
                    {e.Id}
                    {e.SupplierOrderId ? `,${e.SupplierOrderId}` : ''}
                  </NavLink>
                </Top>
                <Bottom>
                  {e.SupplierDeliveryDate ? `(${e.SupplierDeliveryDate})` : ''}
                </Bottom>
                {e.Status ? (
                  <Bottom>
                    {`${locales.status[e.Status]} (${e.Status})` ||
                      `${locales.status.default} (${e.Status})`}
                  </Bottom>
                ) : (
                  <></>
                )}
              </Container>
            )
          })
        : ''}
    </div>
  )
})
export default TableCountedInvoices
