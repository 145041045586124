import { observer } from 'mobx-react'
import { FC, useContext } from 'react'
import { StoreContext } from '../../../../App'
import { Bottom, Container, Top } from './Parcels.styles'
import { NavLink } from 'react-router-dom'

/* eslint-disable */
interface Props {
  data: {
    package_no?: string
    description?: string
    width?: number
    depth?: number
    height?: number
    weight?: number
    tracking_url?: string
  }
  secondary?: boolean
}

const ParcelsComponent: FC<Props> = observer((props: Props) => {
  const store = useContext(StoreContext)
  const { Theme } = store.Theme
  const locales = store.TranslationsState.translations
  return (
    <Container /*{...Theme.tableStockInProgressStyles.container}*/>
      <Top {...Theme.tableStockInProgressStyles.top}>
        <p>{props.data.description}</p>
      </Top>
      <Bottom>
        <a href={props.data.tracking_url} target='_blank'>
          {props.data.package_no}
        </a>
        <p>{`${props.data.width} x ${props.data.depth} x ${props.data.height}`}</p>
        <p>{`${props.data.weight}kg`}</p>
      </Bottom>
    </Container>
  )
})
export default ParcelsComponent
