import { observer } from 'mobx-react'
import { useContext } from 'react'
import { StoreContext } from '../../App'
import { Container, InnerDiv, InnerText, TitleText } from './admin.styles'

const Admin = observer(() => {
  const store = useContext(StoreContext)
  const { Theme } = store.Theme
  return (
    <Container {...Theme.adminStyles.container}>
      <TitleText {...Theme.adminStyles.titleText}>{'Admin Panel'}</TitleText>
      <InnerDiv {...Theme.adminStyles.innerDiv}>
        <InnerText {...Theme.adminStyles.innerText}>{'To be done.'}</InnerText>
      </InnerDiv>
    </Container>
  )
})
export default Admin
