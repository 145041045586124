import styled from 'styled-components'
import { CSVLink } from 'react-csv'

interface ContButtonProps {
  padding?: string
  border?: string
  bgColor?: string
  borderradius?: string
  fontweight?: number
  fontSize?: string
  lineheight?: string
  color?: string
  margin?: string
}

export const ContButton = styled.div<ContButtonProps>`
  background-color: #926f34;
  cursor: pointer;
  display: flex;
  justify-content: center;
  width: 122px;
  padding: ${(props) => (props.padding ? props.padding : '4px 8px')};
  margin: ${(props) => (props.margin ? props.margin : '0 4px')};
  font-size: ${(props) => (props.fontSize ? props.fontSize : '12px')};
  line-height: ${(props) => (props.lineheight ? props.lineheight : '16px')};
  border-radius: ${(props) =>
    props.borderradius ? props.borderradius : '6px'};
  height: 24px;
  white-space: nowrap;
  align-items: center;
`
