import styled from 'styled-components'
import Select from 'react-select'

export const Container = styled.div`
  height: 100%;
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  overflow: visible;
`
export const SelectStyled = styled(Select)`
  z-index: 0 !important;
  overflow: visible;
`

interface TextProps {
  fontWeight?: number
  fontSize?: string
  lineHeight?: string
  letterSpacing?: string
  color?: string
}

export const Text = styled.p<TextProps>`
  color: ${(props) => (props.color ? props.color : '#fafafa')};
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : 400)};
  font-size: ${(props) => (props.fontSize ? props.fontSize : '12px')};
  line-height: ${(props) => (props.lineHeight ? props.lineHeight : '16px')};
  letter-spacing: ${(props) =>
    props.letterSpacing ? props.letterSpacing : '0.15px'};
`
export const LockParagraph = styled.p`
  display: flex;
  flex-direction: column;
`
