import Table from '../../TableGeneric/table/Table.component'

import { observer } from 'mobx-react'
import { useContext } from 'react'
import { StoreContext } from '../../../App'

import { Cell } from 'react-table'

/* eslint-disable */
const PredefinedFiltersTable = observer(() => {
  const store = useContext(StoreContext)
  const tableStore = store.TableStore
  const locales = store.TranslationsState.translations

  let columns: { [key: string]: any } = {
    id: {
      canFilter: false,
      editable: false,
    },
    action: {
      Cell: (cell: Cell) => <p>{cell.value.label}</p>,
      canFilter: false,
      editable: true,
      editType: 'filter',
      Header: 'Name',
    },
    view: {
      canFilter: false,
      editable: false,
    },
    created_by: {
      canFilter: false,
      editable: false,
    },
    access_group: {
      canFilter: false,
      editable: false,
    },
  }

  return (
    <Table
      config={{
        tableName: 'predefinedfilters',
        data: store.FiltersStore.allFilters || [],
        fetchData: () => store.FiltersStore.fetchAllFilters(),
        columnHeaders: columns,
        columnVisibility: true,
        allowColumnResize: true,
        displaySearch: false,
        displaySelect: false,
        displayExpand: false,
        displayTooltip: false,
        displayEntry: false,
        displayPaginator: false,
        displayInfinitySwitch: false,
        allowSort: true,
        bank: false,
        actions: {
          display: true,
          label: 'Filter',
          edit: `${process.env.REACT_APP_BASE_URL}experience/v1/filter`,
          create: null,
          remove: `${process.env.REACT_APP_BASE_URL}experience/v1/filter`,
          assignTag: null,
          custom: [],
        },
        headline: {
          visible: true,
          title: 'Filters',
          subTitle: 'List of filters',
        },
        breadcrumb: [],
        loaded: true,
        pagination: tableStore.pagination.filtersview,
        setPagination: tableStore.setPagination,
      }}
    ></Table>
  )
})
export default PredefinedFiltersTable
