import styled from 'styled-components'

export const MobileContent = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 100;
  background-color: #e5e5e5;
  overflow: scroll;
  @media screen and (min-width: 520px) {
    display: none !important;
  }
`
export const MobileContentCross = styled.img`
  width: 28px;
  height: 28px;
`
export const MobileContentHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  background-color: #000000;
`
export const MobileContentOptionsBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  > * {
    margin-top: 12px;
  }
`
export const MobileHeaderTitle = styled.p`
  font-size: 24px;
  color: #e5e5e5;
  font-weight: 600;
`
