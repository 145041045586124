import styled from 'styled-components'

export const OptionCont = styled.div`
  width: 150px;
  position: absolute !important;
  background-color: #f6f6f6;
  border: 1px solid #ccc;
  z-index: 9999 !important;
`
export const Option = styled.div`
  position: relative;
  width: 100%;
  padding: 4px;
  padding-left: 12px;
  text-align: left;
  background-color: white;
  overflow: hidden;
  white-space: nowrap;
  align-items: center;
  text-overflow: ellipsis;
  min-height: 32px;
  display: flex;

  svg {
    filter: invert(1);
    margin-left: 6px;
    margin-top: 2px;
  }

  :hover {
    background-color: #e8e8e8;
  }
`

export const InputCont = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  text-align: left;
  margin-right: 16px;

  p {
    position: relative;
    width: 100%;
    margin-left: 16px;
    text-align: left;
    font-weight: 700;
  }
`
export const StyledInput = styled.input`
  border-radius: 4px;
  height: 32px;
  padding: 8px 6px;
  border: 1px solid #e5e5e5;
  width: 95%;
  margin-bottom: 12px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.06);
`
