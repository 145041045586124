import styled from 'styled-components'

interface WrapperProps {
  fontSize?: string
  fontWeight?: number
  lineHeight?: string
  color?: string
}

export const BreadcrumbWrapper = styled.div<WrapperProps>`
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : 400)};
  font-size: ${(props) => (props.fontSize ? props.fontSize : '10px')};
  line-height: ${(props) => (props.lineHeight ? props.lineHeight : '16px')};
  display: flex;
  color: ${(props) => (props.color ? props.color : '#303030')};
  margin-top: 4px;
  * {
    margin-right: 4px;
  }
  @media print {
    display: none;
  }
`
