import { RootStore } from './Root.store'
import { action, makeAutoObservable } from 'mobx'
import axios from '../axiosconfig'

/* eslint-disable */
export interface Products {
  ProductID: string
  Name: string
  OrderedQuantity: string
  DeliveredQuantity: string
  SupplierDeliveryDate: string
}

export interface Order {
  id: string
  supplierOrderId: string
  estimateDate: string
  creationDate: string
  lastEditDate: string
  supplier: string
  status: string
  products: { [key: string]: Products }
}

export class CalendarStore {
  rootStore
  loaded = false
  view: 'day' | 'week' | 'month' = 'month'
  currentDate: Date = new Date()
  allData: Order[] = []
  data: Map<string, Order[]> = new Map()
  orderedData: Map<string, Order[]> = new Map()
  deliveredData: Map<string, Order[]> = new Map()
  lastDeliveredData: Map<string, Order[]> = new Map()
  deliveredProductsData: Map<string, Products[]> = new Map()
  estimatedProductsData: Map<string, Products[]> = new Map()
  delayedProductData: Map<string, Products[]> = new Map()
  filterProductId = ''
  visibility: {
    createdOrders: boolean
    deliveredOrders: boolean
    lastDeliveredOrders: boolean
    estimatedOrders: boolean
    deliveredProducts: boolean
    estimatedProducts: boolean
    delayedProducts: boolean
  } = {
    createdOrders: true,
    deliveredOrders: true,
    lastDeliveredOrders: true,
    estimatedOrders: true,
    deliveredProducts: false,
    estimatedProducts: false,
    delayedProducts: false,
  }

  constructor(rootStore: RootStore) {
    makeAutoObservable(this)
    this.rootStore = rootStore
  }

  @action.bound setView = (view: 'day' | 'week' | 'month') => {
    this.view = view
  }

  @action.bound changeDate = (
    increment: number,
    unit: 'day' | 'week' | 'month'
  ) => {
    const currentDate = new Date(this.currentDate)
    switch (unit) {
      case 'day':
        currentDate.setDate(currentDate.getDate() + increment)
        break
      case 'week':
        currentDate.setDate(currentDate.getDate() + 7 * increment)
        break
      case 'month':
      default:
        currentDate.setMonth(currentDate.getMonth() + increment)
        break
    }

    this.currentDate = currentDate
  }

  @action.bound resetDate = () => {
    this.currentDate = new Date()
  }

  @action.bound loadData = async () => {
    const url = `${process.env.REACT_APP_BASE_URL}experience/v1/supplierorder_view?size=500&from=0`
    const data = {
      sort: [{ estimateDate: { order: 'desc' } }],
    }
    const config = {
      headers: {
        'x-apikey': `${process.env.REACT_APP_API_KEY}`,
        username: this.rootStore.UserStore.user,
      },
    }
    const response = await axios.post(url, data, config)
    this.allData = response.data.orders

    response.data.orders.forEach((item: Order) => {
      // estimated Delivery Data
      const date = item.estimateDate
      if (!this.data.has(date)) {
        this.data.set(date, [item])
      } else {
        this.data.get(date)?.push(item)
      }

      // ordered Data
      const creationDate = this.formatDate(new Date(item.creationDate))
      if (!this.data.has(creationDate)) {
        this.orderedData.set(creationDate, [item])
      } else {
        this.orderedData.get(creationDate)?.push(item)
      }

      // earliest product delivered Data
      let earliestDeliveryDate = ''
      for (const productID in item.products) {
        //@ts-ignore
        const product: any = item.products[productID]
        if (product && product.DeliveredDate) {
          if (
            !earliestDeliveryDate ||
            product.DeliveredDate < earliestDeliveryDate
          ) {
            earliestDeliveryDate = product.DeliveredDate
          }
        }
      }
      if (earliestDeliveryDate) {
        if (!this.deliveredData.has(earliestDeliveryDate)) {
          this.deliveredData.set(earliestDeliveryDate, [item])
        } else {
          this.deliveredData.get(earliestDeliveryDate)?.push(item)
        }
      }

      //lastest delivery date
      let latestDeliveryDate = ''
      for (const productID in item.products) {
        //@ts-ignore
        const product: any = item.products[productID]
        if (product && product.DeliveredDate) {
          if (
            !latestDeliveryDate ||
            product.DeliveredDate > latestDeliveryDate
          ) {
            latestDeliveryDate = product.DeliveredDate
          }
        }
      }
      if (latestDeliveryDate) {
        if (!this.lastDeliveredData.has(latestDeliveryDate)) {
          this.lastDeliveredData.set(latestDeliveryDate, [item])
        } else {
          this.lastDeliveredData.get(latestDeliveryDate)?.push(item)
        }
      }

      // data for products
      for (const productID in item.products) {
        //@ts-ignore
        const product: any = item.products[productID]
        if (product) {
          if (new Date(product.DeliveredDate)) {
            const productDate = this.formatDate(new Date(product.DeliveredDate))
            if (!this.deliveredProductsData.has(productDate)) {
              this.deliveredProductsData.set(productDate, [product])
            } else {
              this.deliveredProductsData.get(productDate)?.push(product)
            }
          }
          if (new Date(product.SupplierDeliveryDate)) {
            const productDate = this.formatDate(
              new Date(product.SupplierDeliveryDate)
            )
            if (!this.estimatedProductsData.has(productDate)) {
              this.estimatedProductsData.set(productDate, [product])
            } else {
              this.estimatedProductsData.get(productDate)?.push(product)
            }
            if (
              new Date(product.SupplierDeliveryDate) >
              new Date(item.estimateDate)
            ) {
              const productDate = this.formatDate(
                new Date(product.SupplierDeliveryDate)
              )
              if (!this.delayedProductData.has(productDate)) {
                this.delayedProductData.set(productDate, [product])
              } else {
                this.delayedProductData.get(productDate)?.push(product)
              }
            }
          }
        }
      }
    })
    this.loaded = true
  }

  @action.bound getDataForDate(date: Date): Order[] {
    const formattedDate = this.formatDate(date)
    return this.data.get(formattedDate) || []
  }

  @action.bound getOrderedDataForDate(date: Date): Order[] {
    const formattedDate = this.formatDate(date)
    return this.orderedData.get(formattedDate) || []
  }

  @action.bound getDeliveredDataForDate(date: Date): Order[] {
    const formattedDate = this.formatDate(date)
    return this.deliveredData.get(formattedDate) || []
  }

  @action.bound getLastDeliveredDataForDate(date: Date): Order[] {
    const formattedDate = this.formatDate(date)
    return this.lastDeliveredData.get(formattedDate) || []
  }

  @action.bound setFilterProductId = (id: string) => {
    this.filterProductId = id
  }

  @action.bound getDeliveredProductsForDate(date: Date): Products[] {
    const formattedDate = this.formatDate(date)
    return this.deliveredProductsData.get(formattedDate) || []
  }

  @action.bound getEstimatedProductsForDate(date: Date): Products[] {
    const formattedDate = this.formatDate(date)
    return this.estimatedProductsData.get(formattedDate) || []
  }

  @action.bound getDelayedProductsForDate(date: Date): Products[] {
    const formattedDate = this.formatDate(date)
    return this.delayedProductData.get(formattedDate) || []
  }

  formatDate(date: Date): string {
    const year = date.getFullYear()
    const month = (date.getMonth() + 1).toString().padStart(2, '0')
    const day = date.getDate().toString().padStart(2, '0')
    return `${year}-${month}-${day}`
  }

  @action.bound setLoaded(loaded: boolean) {
    this.loaded = loaded
  }

  @action.bound setVisibility(column: string) {
    if (this.visibility.hasOwnProperty(column)) {
      // @ts-ignore
      this.visibility[column] = !this.visibility[column]
    }
  }

  @action.bound clearData() {
    this.data.clear()
    this.orderedData.clear()
    this.deliveredData.clear()
    this.lastDeliveredData.clear()
    this.deliveredProductsData.clear()
    this.estimatedProductsData.clear()
    this.delayedProductData.clear()
    this.allData = []
    this.loaded = false
  }
}
