export const translations = {
  customerordersDetails: {
    lock: 'Information about the lock. You can access customer order details, and if someone else is present, it displays lock information.',
    id: 'Unique ID of the customer order.',
    order: 'Details about the order, such as order number, date, and time.',
    priority:
      'Information about the priority of the order. The higher the priority, the faster the order is processed.',
    completable:
      'Information on whether the order can be completed. If so, the order is ready for processing.',
    completableMicroserviceInt:
      'Information about the possibility of completing the order after packing other invoices.',
    status:
      'Current status of the order. It may show if the order is being processed, completed, or cancelled.',
    ordered: 'The date and time when the order was placed.',
    author: 'The author of the order, i.e., the person who created the order.',
    lastUpdateAuthor: 'The last person who updated the order.',
    client: 'Information about the client who placed the order.',
    paid: 'Information on whether the order has been paid for.',
    paymentMethod:
      'The payment method chosen by the client when placing the order.',
    shipping:
      'Shipping-related information such as shipping address and courier company.',
    tags: 'Tags associated with the order that help in organizing and searching for orders.',
    estimateProduct:
      'Estimated amount of product required to fulfill the order.',
    estimateDate:
      'Estimated date when the order will be fulfilled and delivered to the client.',
    details:
      'Detailed information about the order, such as list of products, prices, and other details.',
    checkListItems: 'Information about the last action performed on the order.',
  },
  stockDetails: {
    id: 'Unique ID of the product in the stock.',
    code: 'Code associated with the product.',
    backend_id: 'ID used in the backend system for the product.',
    name: 'Name of the product.',
    producer: 'The producer or manufacturer of the product.',
    addDate: 'Date when the product was added to the stock.',
    active: 'Indicates whether the product is active in the stock.',
    tags: 'Tags associated with the product that help in searching and organizing the stock.',
    stock: 'Current quantity of the product in the stock.',
    stockDemand: 'The demand for the product in the market.',
    stockInvoices: 'Invoices associated with the product.',
    stockAfterPacking: 'Stock left after packing orders.',
    stockLastInvoice: 'The last invoice issued for the product.',
    stockLastInvoiceDate: 'The date when the last invoice was issued.',
    stockCalculated:
      'Calculated stock quantity after accounting for pending orders.',
    sellPrice: 'Price at which the product is sold.',
    stockInProgress: 'Stock quantity in the process of being updated.',
    stockAfterOrders: 'Stock quantity after fulfilling orders.',
    stockAmountInPackage: 'Amount of product in each package.',
    stockAmountInPackageBool: 'Bool about amount of product in each package.',
    stockMOQAfterChecking: 'stockMOQAfterChecking.',
    minimumWarnLevel:
      'Minimum warning level of stock quantity. When stock goes below this level, a warning is issued.',
    secureWarnLevel:
      'Secure warning level of stock quantity. This is a safe level for the stock to avoid going out of stock.',
    maximumWarnLevel:
      'Maximum warning level of stock quantity. When stock reaches this level, a warning is issued.',
    quantityToOrder: 'Quantity of the product to order to refill stock.',
    minimumQuantityForSupplierOrder:
      'Minimum quantity of the product that can be ordered from the supplier.',
    availabilityDate: 'Date when the product will be available in stock.',
    salesPriceBrutto: 'Gross sales price of the product.',
    salesPriceNetto: 'Net sales price of the product.',
    defaultSupplierPrice: 'Default price of the product from the supplier.',
    defaultSupplierPriceCurrency: 'Currency of the default supplier price.',
    salesDate: 'Date of the product sale.',
    salesTime: 'Time of the product sale.',
    permalink: 'Permanent link to the product details.',
    image: 'Image of the product.',
  },

  supplierordersDetails: {
    id: 'Unique ID of the supplier order.',
    supplierOrderId: 'ID of the order from the supplier.',
    supplier: 'Supplier from whom the order was made.',
    creationDate: 'Date when the order was created.',
    author: 'Author of the order, i.e., the person who created the order.',
    price_type: 'Type of pricing used for the order.',
    wfirma_vat_id: 'VAT ID of the supplier in wfirma system.',
    wfirma_id: 'ID of the supplier in wfirma system.',
    vat: 'Value added tax applied on the order.',
    lastEditDate: 'Date when the order was last edited.',
    lastEditAuthor: 'The person who last edited the order.',
    estimateDate: 'Estimated date when the order will be delivered.',
    deliveryCode: 'Delivery code or tracking number of the order.',
    orderLink: 'Link to the supplier order.',
    notes: 'Any additional notes regarding the order.',
    status: 'Current status of the order.',
  },

  packingDetails: {
    lock: 'Information about the lock. You can access packing details, and if someone else is present, it displays lock information.',
    id: 'Unique ID of the packing.',
    priority:
      'Priority of the packing. The higher the priority, the earlier the packing is done.',
    completable: 'Indicates whether the packing can be completed.',
    completableMicroserviceInt:
      'Indicates whether the packing can be completed after other orders are packed.',
    status: 'Current status of the packing.',
    ordered: 'Date and time when the packing was ordered.',
    client: 'Client for whom the packing is being done.',
    paid: 'Indicates whether the packing has been paid for.',
    shipping: 'Shipping details for the packed order.',
    tags: 'Tags associated with the packing that help in searching and organizing the packings.',
    estimateProduct: 'Estimated amount of product required for the packing.',
    estimateDate: 'Estimated date when the packing will be completed.',
    details:
      'Detailed information about the packing, such as list of products, prices, and other details.',
    orderSource: 'Source from where the order for packing came.',
    documents: 'Documents associated with the packing.',
    checkListItems:
      'Information about the last action performed on the packing.',
  },
  customerordersColumns: {
    lock: 'lock',
    id: 'id',
    order: 'order',
    priority: 'priority',
    completable: 'completable',
    completableMicroserviceInt: 'completableAfterPackingOtherInvoices',
    status: 'status',
    ordered: 'ordered',
    author: 'author',
    lastUpdateAuthor: 'lastUpdateAuthor',
    client: 'client',
    paid: 'paid',
    paymentMethod: 'paymentMethod',
    shipping: 'shipping',
    tags: 'tags',
    estimateProduct: 'estimateProduct',
    estimateDate: 'estimateDate',
    details: 'details',
    checkListItems: 'LastAction',
  },
  stockColumns: {
    id: 'id',
    code: 'code',
    ean: 'EAN',
    backend_id: 'backend_id',
    name: 'name',
    producer: 'producer',
    addDate: 'addDate',
    active: 'active',
    tags: 'tags',
    stock: 'stock',
    stockDemand: 'demand',
    stockInvoices: 'invoices',
    stockAfterPacking: 'afterPacking',
    stockLastInvoice: 'lastInvoice',
    stockLastInvoiceDate: 'lastInvoiceDate',
    stockCalculated: 'calculated',
    sellPrice: 'sellPrice',
    stockInProgress: 'inProgress',
    stockAfterOrders: 'afterOrders',
    stockAmountInPackage: 'amountInPackage',
    stockAmountInPackageBool: 'stockAmountInPackageBool',
    stockMOQAfterChecking: 'stockMOQAfterChecking',
    minimumWarnLevel: 'minimumWarnLevel',
    secureWarnLevel: 'secureWarnLevel',
    maximumWarnLevel: 'maximumWarnLevel',
    quantityToOrder: 'quantityToOrder',
    minimumQuantityForSupplierOrder: 'minimumQuantityForSupplierOrder',
    availabilityDate: 'availabilityDate',
    salesPriceBrutto: 'salesPriceBrutto',
    salesPriceNetto: 'salesPriceNetto',
    defaultSupplierPrice: 'defaultSupplierPrice',
    defaultSupplierPriceCurrency: 'defaultSupplierPriceCurrency',
    salesDate: 'salesDate',
    salesTime: 'salesTime',
    permalink: 'permalink',
    image: 'image',
  },
  supplierordersColumns: {
    id: 'id',
    supplierOrderId: 'supplierOrderId',
    supplier: 'supplier',
    creationDate: 'creationDate',
    author: 'author',
    price_type: 'price_type',
    wfirma_vat_id: 'wfirma_vat_id',
    wfirma_id: 'wfirma_id',
    vat: 'vat',
    lastEditDate: 'lastEditDate',
    lastEditAuthor: 'lastEditAuthor',
    estimateDate: 'estimateDate',
    deliveryCode: 'deliveryCode',
    orderLink: 'orderLink',
    notes: 'notes',
    status: 'status',
    currency: 'currency',
  },
  packingColumns: {
    lock: 'lock',
    id: 'id',
    priority: 'priority',
    completable: 'completable',
    completableMicroserviceInt: 'completableAfterPackingOtherInvoices',
    status: 'status',
    ordered: 'ordered',
    client: 'client',
    paid: 'paid',
    shipping: 'shipping',
    tags: 'tags',
    estimateProduct: 'estimateProduct',
    estimateDate: 'estimateDate',
    details: 'details',
    orderSource: 'orderSource',
    documents: 'documents',
    checkListItems: 'LastAction',
  },
  customerorderDetailsColumns: {
    name: 'name',
    code: 'code',
    stock_quantity: 'original',
    afterThis: 'afterThis',
    afterPackingOtherInvoices: 'afterPackingOtherInvoices',
    is_missing: 'Enough products After Packing Other Invoices',
    quantity: 'demand',
    after_orders: 'After Orders',
    supplier_orders: 'Notes from Order',
    product_tags: 'Tags',
    tax: 'tax',
    unit: 'unit',
    weight: 'weight',
    price: 'price',
    image: 'image',
  },
  bankColumns: {
    id: 'id',
    type: 'type',
    user: 'user',
    date: 'date',
    exported: 'exported',
  },
  packingDetailsColumns: {
    name: 'name',
    code: 'code',
    stock_quantity: 'original',
    afterPackingOtherInvoices: 'afterPackingOtherInvoices',
    is_missing: 'Enough products After Packing Other Invoices',
    quantity: 'demand',
    product_tags: 'Location',
    image: 'image',
  },
  actions: {
    search: 'Show table search field',
    columnVisibility: 'Column visibility',
    filters: 'Filters',
    chooseDefaultFilter: 'Choose Default filter',
    choosePredefinedFilter: 'Choose predefined filter',
    columnName: 'Column Name',
    show: 'Show',
    noActions: 'No actions!',
  },
  steps: {
    controlInvoice: 'Control invoice and order details',
    printListProducts: 'Print list of products',
    pickProducts: 'Pick products',
    countProducts: 'Count products',
    checkQualityProducts: 'Check quality of products',
    packProducts: 'Pack products',
    packLeeflets: 'Pack leaflets',
    issueSalesInvoice: 'Issue a sales invoice',
    sendSalesInvoiceEmail: 'Send a sales invoice (e-mail)',
    controlTagsStatusesOrderDetailsOnInvoice:
      'Control tags & statuses on invoice and order details',
    generateShipping: 'Generate shipping',
    stickShippingLabelOnPackageAndSend:
      'Stick a shipping label on a package and send',
  },
  details: {
    Description: 'Description',
    status: 'status',
    completable: 'completable',
    shipping: 'shipping',
    Sum: 'Sum',
    paid: 'paid',
    paymentDifference: 'Payment difference',
    paymentMethod: 'Payment Method',
    estimateDate: 'estimateDate',
    tags: 'tags',
    user_id: 'External Customer ID',
    client: 'External Customer Name',
    completableMicroserviceInt: 'completableMicroserviceInt',
    email: 'Mail',
    phone: 'Phone',
    lastEditDate: 'lastEditDate',
    country_code: 'Country code',
    notes_priv: 'notes_priv',
    ordered: 'Ordered',
  },
  address: {
    firstname: 'firstname',
    lastname: 'lastname',
    company: 'company',
    city: 'city',
    postcode: 'postcode',
    street1: 'street1',
    street2: 'street2',
    country: 'country',
  },
  tableNames: {
    customerorders: 'Customer Orders',
    stock: 'Stock',
    supplierorders: 'Supplier Orders',
    reports: 'Reports',
    bank: 'Bank',
    packing: 'Packing',
    admin: 'Admin',
  },
  buttons: {
    view: 'View',
    locked: 'Locked:',
    lockedBy: 'Locked By:',
    cancel: 'Cancel',
    clearFilters: 'Clear Filters',
    apply: 'Apply',
    predefinedFilter: 'Predefined Filter',
    newFilter: 'New Fiter',
    selectAll: 'All',
    selectPage: 'Page',
    rowsPerPage: 'Rows per page:',
    switchLayout: 'Switch Layout',
    enter: 'Enter',
    setPriority: 'Set Priority',
    selectFirstStep: 'Select first step',
    print: 'Print',
    printWithoutData: 'Print Without Customer Data',
    goToCustomerView: 'Go to customer order view',
    editSelected: 'Edit Selected',
    addToSelected: 'Add Products to Selected Supplier Orders',
    supplierProducts: 'Supplier Products',
    copyToClipboard: 'Copy To Clipboard',
    copied: 'Copied!',
    downloadFile: 'Download file',
    search: 'Search...',
    searchAll: 'Search all',
    setGlobalSearch: 'Set Global Search...',
    createPurchaseOrder: 'Create Purchase Order',
  },
  headers: {
    details: 'Details',
    deliveryAddress: 'Delivery Address',
    billingAddress: 'Billing Address',
    historyOfActions: 'History of Actions',
    customerOrder: 'Customer Order',
    nextStep: 'Next Step',
    listOfProducts: 'List of Products',
  },
  packable: {
    allMissing: 'All Missing',
    someMissing: 'Some Missing',
    packable: 'Packable',
    incorrectValue: 'Incorrect Value',
  },
  status: {
    '0': 'Cancelled',
    '1': 'Exported to excel',
    '2': 'Sent to supplier',
    '3': 'In Progress',
    '4': 'Finished',
    '5': 'In Progress (delay)',
    '6': 'In Progress (reclamation)',
    default: `Incorrect Value`,
  },
  options: {
    tableButtons: 'Table Buttons',
    columnVisibility: 'Column Visibility',
    queryFilers: 'Query Filters',
  },
  pagination: {
    page: 'Page',
    showing: 'Showing',
    of: 'of',
    entries: 'entries',
  },
  columns: {
    sort: 'Sort',
    none: 'None',
    descending: 'Descending',
    ascending: 'Ascending',
    info: 'Info',
    text: 'Text',
    wrapText: 'Wrap Text',
    clipText: 'Clip Text',
    groupBy: 'Group By',
    values: 'Values',
    uniqueValeus: 'Unique Values',
    sum: 'Sum',
    median: 'Median',
    average: 'Average',
    move: 'Move',
    moveLeft: 'Move Left',
    moveRight: 'Move Right',
    backToDefault: 'Back to Default Settings',
  },
  userInfo: {
    displayName: 'Display Name',
    givenName: 'Given Name',
    surname: 'Surname',
    mail: 'User Mail',
    jobTitle: 'Job Title',
    mobilePhone: 'Mobile Phone',
    officeLocation: 'Office Location',
    preferredLanguage: 'Preferred Language',
  },
  warnings: {
    vieved:
      'This product details is currently being viewed by another user. Do you want to enter anyway?',
  },
  errors: {
    noDesc: 'No description for this column provided',
  },
}
