import styled from 'styled-components'

export const TableCellArrowCont = styled.div`
  position: relative;
`

interface TableCellExpandProps {
  margin?: string
  height?: string
  width?: string
}

export const TableCellExpand = styled.img<TableCellExpandProps>`
  height: ${(props) => (props.height ? props.height : '12px')};
  width: ${(props) => (props.width ? props.width : '12px')};
  margin: ${(props) => (props.margin ? props.margin : '16px 0 24px 0')};
  top: 0;
  left: 0;
`

interface TableDataProps {
  selected: boolean
  wrapText: boolean
  border: boolean
  selectedColor?: string
  boxShadow?: string
  customColor?: string
}

export const TableData = styled.div<TableDataProps>`
  width: 100%;
  min-height: 40px;
  padding: 2px;
  display: ${(props) => props.wrapText && 'flex'};
  justify-content: ${(props) => (props.wrapText ? 'center' : 'flex-start')};
  align-items: ${(props) => props.wrapText && 'flex-start'};
  text-align: ${(props) => (props.wrapText ? 'center' : 'left')};
  white-space: ${(props) => !props.wrapText && 'nowrap'};
  overflow-wrap: ${(props) => (props.wrapText ? 'break-word' : 'normal')};
  overflow: ${(props) => !props.wrapText && 'hidden'};
  word-break: ${(props) => (props.wrapText ? 'break-word' : 'keep-all')};
  text-overflow: ellipsis;
  background-color: ${(props) =>
    props.selected
      ? props.selectedColor || 'rgba(203, 165, 175, 0.4)'
      : props.customColor || 'transparent'};
  border-left: ${(props) => props.border && '1px solid black'};
  border-right: ${(props) => props.border && '1px solid black'};
  box-shadow: ${(props) =>
    props.selected
      ? props.boxShadow || '0px 1px 3px rgba(0, 0, 0, 0.06)'
      : 'none'};

  > {
    margin-top: -8px;
  }

  transition: none !important;
`
export const TableAbsolute = styled.div`
  position: absolute;
`
