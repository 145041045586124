import styled from 'styled-components'
import { breakpoints } from '../../../static/breakpoints'

interface TableHeadProps {
  marginTop?: string
  bgColor?: string
}

export const TableHead = styled.div<TableHeadProps>`
  margin-top: ${(props) => (props.marginTop ? props.marginTop : '0.75rem')};
  background-color: ${(props) => (props.bgColor ? props.bgColor : '#f9fafb')};
  display: flex;
  flex-direction: column;
  top: 0;
  flex-grow: 1;
  z-index: 2;
  position: sticky !important;
  width: 100%;

  * {
    transition: none !important;
  }
`

export const TableHead2 = styled.div`
  display: flex;
  justify-content: space-between;
  margin-left: 16px;
  @media screen and (max-width: ${breakpoints.tablet}) {
    flex-direction: column;
  }
`

/*eslint-disable */
interface RowInnerPropsInterface {
  select?: boolean
  odd?: boolean
  oddBg?: string
  paddingTop?: string
  paddingBottom?: string
  selectBgHover?: string
  oddBgHover?: string
  bgColorHover?: string
}

export const TableRow = styled.div<RowInnerPropsInterface>`
  background-clip: padding-box;
  border-bottom-width: 1px;
  position: relative;
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  padding-top: ${(props) => (props.paddingTop ? props.paddingTop : '4px')};
  padding-bottom: ${(props) =>
    props.paddingBottom ? props.paddingBottom : '4px'};
  background-repeat: repeat;

  :hover {
    background-color: ${(props) =>
      props.select
        ? props.selectBgHover
        : props.odd
        ? props.oddBgHover
          ? props.oddBgHover
          : '#e8e8e8' // eslint-disable-line no-use-before-define
        : props.bgColorHover
        ? props.bgColorHover
        : '#e8e8e8'}; // eslint-disable-line no-use-before-define
  }

  scrollbar-width: none;
  @media print {
    overflow-x: visible;
  }

  ::-webkit-scrollbar {
    display: none;
  }
`

interface HeaderRowInnerPropsInterface {
  width?: number
}

export const TableHeaderRow = styled.div<HeaderRowInnerPropsInterface>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &:last-child div:first-child div:last-child {
    border: none;
  }
`
/*eslint-enable */
