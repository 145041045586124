import { useContext, useEffect } from 'react'
import { observer } from 'mobx-react'

import BankTable from '../../components/bankTable/BankTable.component'
import Breadcrumb from '../../components/breadcrumb/Breadcrumb.component'

import { StoreContext } from '../../App'
import { Container } from '../../styles/LayoutStyles'

const Bank = observer(() => {
  return (
    <Container>
      <BankTable />
    </Container>
  )
})
export default Bank
