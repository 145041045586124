import styled from 'styled-components'
import { breakpoints } from '../../../static/breakpoints'

interface ButtonProps {
  padding?: string
  borderRadius?: string
  border?: string
  margin?: string
  color?: string
  fontSize?: string
  lineHeight?: string
  marginText?: string
}

/* eslint-disable */

export const Wrap = styled.div`
  position: absolute;
  z-index: 1;
  right: 90px;

  @media screen and (max-width: ${breakpoints.tablet}) {
    right: 20px;
    transform: translateY(-30px);
  }
`
export const Button = styled.button<ButtonProps>`
  height: 24px;
  font-weight: 400;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${(props) => (props.padding ? props.padding : '4px 10px 4px 6px')};
  margin: ${(props) => (props.margin ? props.margin : '0 2px')};
  font-size: ${(props) => (props.fontSize ? props.fontSize : '12px')};
  line-height: ${(props) => (props.lineHeight ? props.lineHeight : '16px')};
  border: ${(props) => (props.border ? props.border : '1px solid #303030')};
  border-radius: ${(props) =>
    props.borderRadius ? props.borderRadius : '6px'};

  p {
    color: ${(props) => (props.color ? props.color : '#303030')} !important;
    margin-left: ${(props) => (props.marginText ? props.marginText : '4px')};
    margin-right: 24px;
  }

  :hover {
    cursor: pointer;
    background-color: rgba(221, 175, 77, 0.4);
  }

  img {
    height: 16px;
  }
`
export const ButtonText = styled.p`
  margin-top: 4px;
  margin-bottom: 4px;
  white-space: nowrap;
`
