import { observer } from 'mobx-react'
import { useContext } from 'react'

import { StoreContext } from '../../App'
import { BreadcrumbWrapper } from './Breadcrumb.styles'
import { NavLink } from 'react-router-dom'

interface BreadcrumbProps {
  treeArray: Array<string>
}

const Breadcrumb = observer(({ treeArray }: BreadcrumbProps) => {
  const store = useContext(StoreContext)
  const { Theme } = store.Theme
  const lastItem = treeArray.length - 1

  return (
    <BreadcrumbWrapper {...Theme.breadcrumbStyles.breadcrumbWrapper}>
      {treeArray.map((item, index) => (
        <NavLink
          to={index == 0 ? '/' : `/${store.RouteStore.currentPage}`}
          key={index}
        >
          {item} {index !== lastItem ? '-> ' : ''}
        </NavLink>
      ))}
    </BreadcrumbWrapper>
  )
})

export default Breadcrumb
