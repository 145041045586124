import { RootStore } from './Root.store'
import { action, makeAutoObservable } from 'mobx'
import axios from '../axiosconfig'

export class MockStore {
  rootStore
  loaded = false
  customersData = []
  stockData = []
  suppliersData = []
  packingData = []
  customerDetailsData = []

  constructor(rootStore: RootStore) {
    makeAutoObservable(this)
    this.rootStore = rootStore
  }

  @action.bound fetchCustomersData = async () => {
    const url = `${process.env.REACT_APP_BASE_URL}experience/v1/customer_orders_view?size=10&from=0`
    const body = {}
    const config = {
      headers: {
        'x-apikey': `${process.env.REACT_APP_API_KEY}`,
        username: this.rootStore.UserStore.user,
      },
    }
    const response = await axios.post(url, body, config)
    this.customersData = await response.data.orders
  }

  @action.bound fetchStockData = async () => {
    const url = `${process.env.REACT_APP_BASE_URL}experience/v1/stock_view?size=10&from=0`
    const body = {}
    const config = {
      headers: {
        'x-apikey': `${process.env.REACT_APP_API_KEY}`,
        username: this.rootStore.UserStore.user,
      },
    }
    const response = await axios.post(url, body, config)
    this.stockData = await response.data.products
  }

  @action.bound fetchSuppliersData = async () => {
    const url = `${process.env.REACT_APP_BASE_URL}experience/v1/supplierorder_view?size=10&from=0`
    const body = {}
    const config = {
      headers: {
        'x-apikey': `${process.env.REACT_APP_API_KEY}`,
        username: this.rootStore.UserStore.user,
      },
    }
    const response = await axios.post(url, body, config)
    this.suppliersData = await response.data.orders
  }

  @action.bound fetchPackingData = async () => {
    const url = `${process.env.REACT_APP_BASE_URL}experience/v1/packing_view?size=10&from=0`
    const body = {}
    const config = {
      headers: {
        'x-apikey': `${process.env.REACT_APP_API_KEY}`,
        username: this.rootStore.UserStore.user,
      },
    }
    const response = await axios.post(url, body, config)
    this.packingData = await response.data.orders
  }

  @action.bound fetchCustomerDetailsData = async (id: string) => {
    const url = `${process.env.REACT_APP_BASE_URL}experience/v1/customer_order/${id}`
    const res = await axios.get(url, {
      headers: {
        'x-apikey': `${process.env.REACT_APP_API_KEY}`,
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET,HEAD,PUT,PATCH,POST,DELETE',
        'Access-Control-Allow-Credentials': 'true',
      },
    })
    this.customerDetailsData = res.data
    this.loaded = true
  }
  @action.bound setLoaded = (value: boolean) => {
    this.loaded = value
  }
  @action.bound fetchAllMockData = async () => {
    //await this.fetchCustomersData()
    //await this.fetchStockData()
    await this.fetchSuppliersData()
    //await this.fetchPackingData()
    //await this.fetchCustomerDetailsData('54008')
    await this.setLoaded(true)
  }
}
