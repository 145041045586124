import { RootStore } from './Root.store'
import { action, makeAutoObservable, toJS } from 'mobx'
import axios from '../axiosconfig'
import { toast } from 'react-toastify'
import { ReplaceEmptyStringsForNulls } from '../columnsConfig'

export class CategoriesStore {
  rootStore
  data = []
  loaded = false
  pagination: {
    tableSize: number
    pageSize: number
    pageIndex: number
    totalCount: number
  } = { tableSize: 0, pageSize: 10, pageIndex: 1, totalCount: 0 }

  constructor(rootStore: RootStore) {
    makeAutoObservable(this)
    this.rootStore = rootStore
  }

  @action.bound fetchData = async () => {
    this.loaded = false
    const url = `${
      process.env.REACT_APP_BASE_URL
    }experience/v1/categories_view?size=${this.pagination.pageSize}&from=${
      (this.pagination.pageIndex - 1) * this.pagination.pageSize
    }`
    console.log(url)

    const data = {
      skipChildren: false,
      flatten: true,
    }
    const config = {
      headers: {
        'x-apikey': `${process.env.REACT_APP_API_KEY}`,
      },
    }
    const response = await axios.post(url, data, config)

    this.data = await response.data.categories

    this.loaded = true
    this.pagination.tableSize = await response.data.count

    this.pagination.totalCount = await response.data.count
  }

  @action.bound fetchAllData = async (flatten: boolean) => {
    this.loaded = false
    setTimeout(async () => {
      const url = `${process.env.REACT_APP_BASE_URL}experience/v1/categories_view?size=100&from=0`
      const data = {
        skipChildren: false,
        flatten: flatten || false,
      }
      const config = {
        headers: {
          'x-apikey': `${process.env.REACT_APP_API_KEY}`,
        },
      }
      const response = await axios.post(url, data, config)

      this.data = await response.data.categories
      this.loaded = true
    }, 1000)
  }

  @action.bound clearData = () => {
    this.data = []
    /*this.pagination = {
                                                                                                                  tableSize: 0,
                                                                                                                  pageSize: 10,
                                                                                                                  pageIndex: 1,
                                                                                                                  totalCount: 0,
                                                                                                                }*/
  }

  @action.bound setPagination = (
    tableSize: number,
    pageSize: number,
    pageIndex: number,
    totalCount: number
  ) => {
    this.rootStore.SlidingPanelState.setSelectedSlider('')
    this.pagination = {
      tableSize,
      pageSize,
      pageIndex,
      totalCount,
    }
    try {
      this.fetchData()
    } catch (e: any) {
      toast(e.message.toString(), { type: 'error' })
    }
  }
}
