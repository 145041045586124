import styled from 'styled-components'
export const Container = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
`
interface StyledInputInterface {
  activeBgColor?: string
  inactiveBgColor?: string
  boxShadow?: string
  borderRadius?: string
  padding?: string
  color?: string
  fontSize?: string
  fontWeight?: number
  lineHeight?: string
  letterSpacing?: string
  marginLeft?: string
}

export const Input = styled.input<StyledInputInterface>`
  position: relative;
  width: 85% !important;
  background-color: #ffffff;
  border: ${(props) => props.activeBgColor || '1px solid #D6D6D6'};
  box-shadow: ${(props) =>
    props.boxShadow ? props.boxShadow : '0 1px 3px rgba(0, 0, 0, 0.06)'};
  border-radius: ${(props) =>
    props.borderRadius ? props.borderRadius : '6px'};
  padding: 2px 2px;
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : 400)};
  font-size: ${(props) => (props.fontSize ? props.fontSize : '12px')};
  line-height: ${(props) => (props.lineHeight ? props.lineHeight : '24px')};
  letter-spacing: ${(props) =>
    props.letterSpacing ? props.letterSpacing : '0.15px'};
  color: ${(props) => (props.color ? props.color : '#1f1f1f')};
`

export const Textarea = styled.textarea<StyledInputInterface>`
  position: relative;
  width: 85% !important;
  background-color: #ffffff;
  border: ${(props) => props.activeBgColor || '1px solid #D6D6D6'};
  box-shadow: ${(props) =>
    props.boxShadow ? props.boxShadow : '0 1px 3px rgba(0, 0, 0, 0.06)'};
  border-radius: ${(props) =>
    props.borderRadius ? props.borderRadius : '6px'};
  padding: 2px 2px;
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : 400)};
  font-size: ${(props) => (props.fontSize ? props.fontSize : '12px')};
  line-height: ${(props) => (props.lineHeight ? props.lineHeight : '24px')};
  letter-spacing: ${(props) =>
    props.letterSpacing ? props.letterSpacing : '0.15px'};
  color: ${(props) => (props.color ? props.color : '#1f1f1f')};
`
