import Table from '../TableGeneric/table/Table.component'

import { observer } from 'mobx-react'
import { useContext, useEffect } from 'react'
import { StoreContext } from '../../App'

import { Cell } from 'react-table'
import TableLock from '../TableGeneric/tableLock/TableLock.component'
import TableTags from '../TableGeneric/tableTags/TableTags.component'
import TableDate from '../TableGeneric/tableDate/TableDate.component'
import TableCompleteable from '../TableGeneric/tableCompleteable/TableCompleteable.component'
import TablePriorityMap from '../TableGeneric/tablePriorityMap/TablePriorityMap.component'
import TableLastAction from '../TableGeneric/tableLastAction/TableLastAction.component'
import TableNewTagsComponent from '../TableGeneric/tableNewTags/TableNewTags.component'
import TableSystemComponent from '../TableGeneric/tableSystem/TableSystem.component'
/* eslint-disable */
const ProformsTable = observer(() => {
  const store = useContext(StoreContext)
  const tableStore = store.TableStore
  const locales = store.TranslationsState.translations

  let columns: { [key: string]: any } = {
    lock: {
      Header: locales.customerordersColumns.lock,
      Cell: (cell: Cell) => (
        <TableLock page={'customerorders'} label={cell.value} cell={cell} />
      ),
      canFilter: false,
      editable: false,
      editType: 'text',
      canSort: false,
    },
    id: {
      Header: locales.customerordersColumns.id,
      canFilter: true,
      editable: false,
      editType: 'text',
    },
    order: {
      Header: 'Order_number_in_source',
      canFilter: true,
      editable: false,
      editType: 'number',
    },
    order_number: {
      canFilter: true,
      editable: false,
      editType: 'number',
    },
    priority: {
      Header: locales.customerordersColumns.priority,
      Cell: (cell: Cell) => <TablePriorityMap value={cell.value} />,
      canFilter: true,
      editable: false,
      editType: 'number',
    },
    completable: {
      Header: locales.customerordersColumns.completable,
      Cell: (cell: Cell) => <TableCompleteable value={parseInt(cell.value)} />,
      canFilter: true,
      editable: true,
      editType: 'select',
      selectValues: [
        { value: '0', label: locales.packable.allMissing },
        { value: '1', label: locales.packable.someMissing },
        { value: '2', label: locales.packable.packable },
      ],
    },
    completableMicroserviceInt: {
      Header: locales.customerordersColumns.completableMicroserviceInt,
      Cell: (cell: Cell) => <TableCompleteable value={parseInt(cell.value)} />,
      canFilter: true,
      editable: true,
      editType: 'select',
      selectValues: [
        { value: '0', label: locales.packable.allMissing },
        { value: '1', label: locales.packable.someMissing },
        { value: '2', label: locales.packable.packable },
      ],
    },
    status: {
      Header: locales.customerordersColumns.status,
      canFilter: true,
      editable: true,
      editType: 'text',
    },
    ordered: {
      Header: locales.customerordersColumns.ordered,
      Cell: (cell: Cell) => <TableDate date={cell.value} />,
      canFilter: true,
      editable: false,
      editType: 'datetime-local',
    },
    author: {
      Header: locales.customerordersColumns.author,
      canFilter: true,
      editable: false,
      editType: 'text',
    },
    lastUpdateAuthor: {
      Header: locales.customerordersColumns.lastUpdateAuthor,
      canFilter: true,
      editable: false,
    },
    client: {
      Header: locales.customerordersColumns.client,
      canFilter: true,
      editable: true,
      editType: 'text',
    },
    paid: {
      Header: locales.customerordersColumns.paid,
      canFilter: true,
      editable: true,
      editType: 'number',
    },
    paymentMethod: {
      Header: locales.customerordersColumns.paymentMethod,
      canFilter: true,
      editable: false,
      editType: 'text',
    },
    shipping: {
      Header: locales.customerordersColumns.shipping,
      canFilter: true,
      editable: true,
      editType: 'text',
    },
    estimateProduct: {
      Header: locales.customerordersColumns.estimateProduct,
      canFilter: true,
      editable: true,
      editType: 'text',
    },
    estimateDate: {
      Header: locales.customerordersColumns.estimateDate,
      canFilter: true,
      editable: true,
      editType: 'date',
    },
    details: {
      Header: locales.customerordersColumns.details,
      canFilter: true,
      editable: true,
      editType: 'text',
    },
    checkListItems: {
      Header: locales.customerordersColumns.checkListItems,
      Cell: (cell: Cell) => (
        <TableLastAction key={cell.value} checkListItems={cell.value} />
      ),
      canFilter: false,
      canSort: false,
      editable: false,
    },
    system: {
      Header: 'System',
      Cell: (cell: Cell) => (
        <TableSystemComponent key={cell.value} data={cell.value} />
      ),
      canFilter: false,
      editable: false,
    },
  }

  if (store.UserStore.features.includes('tagmgm')) {
    columns = {
      ...columns,
      tags: {
        Header: locales.customerordersColumns.tags,
        Cell: (cell: Cell) => <TableTags key={cell.value} tags={cell.value} />,
        canFilter: false,
        canSort: false,
        editable: false,
      },
      object_tags: {
        Header: locales.customerordersColumns.tags,
        Cell: (cell: Cell) => (
          <TableNewTagsComponent key={cell.value} tags={cell.value} />
        ),
        canFilter: true,
        canSort: false,
        editable: false,
        editType: 'select',
        selectValues: store.TagsStore.getIndicesTags().map((tag: any) => ({
          value: tag.id,
          label: tag.name.EN,
        })),
      },
    }
  }

  return (
    <Table
      config={{
        tableName: 'customerorders',
        data: store.TableStore.data || [],
        fetchData: () => store.TableStore.fetchData(),
        columnHeaders: columns,
        columnVisibility: tableStore.configColumnVisibility,
        allowColumnResize: tableStore.configAllowColumnResize,
        displaySearch: tableStore.configDisplaySearch,
        displaySelect: tableStore.configDisplaySelect,
        displayExpand: tableStore.configDisplayExpand,
        displayTooltip: tableStore.configDisplayTooltip,
        displayEntry: tableStore.configDisplayEntry,
        displayPaginator: tableStore.configDisplayPaginator,
        displayInfinitySwitch: true,
        allowSort: tableStore.configAllowSort,
        bank: tableStore.configBankFeatures,
        actions: {
          display: true,
          label: 'Order',
          edit: `${process.env.REACT_APP_BASE_URL}experience/v1/customer_order`,
          create: null,
          remove: `${process.env.REACT_APP_BASE_URL}experience/v1/customer_order`,
          assignTag: `${process.env.REACT_APP_BASE_URL}experience/v1/tag/update`,
          custom: ['assignCollection'],
        },
        headline: {
          visible: store.Theme.Navigation === 'side',
          title: 'Customer Orders',
          subTitle: 'List of Customer Orders',
        },
        breadcrumb: ['Panel', 'Customer Orders'],
        loaded: store.TableStore.loaded,
        pagination: tableStore.pagination.customerorders,
        setPagination: tableStore.setPagination,
      }}
    ></Table>
  )
})
export default ProformsTable
