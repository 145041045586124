import { observer } from 'mobx-react'
import {
  ButtonCont,
  DataCont,
  Entry,
  Footer,
  IconImage,
  ImageCont,
  InnerCont,
  Label,
  NameCont,
  PrimaryButton,
  Setting,
  SettingsCategory,
  SingleProfileInfo,
  SubTitleText,
  TitleText,
} from './MyUserProfile.styles'
import { useContext, useEffect, useState } from 'react'
import { StoreContext } from '../../../../App'
import Profile from '../../../../assets/icons/profile.png'

export const MyUserProfileComponent = observer(() => {
  const store = useContext(StoreContext)
  const { Theme } = store.Theme
  const locales = store.TranslationsState.translations

  /*const handleInputChange = (key: string, value: string) => {
      setUserInfo({ ...userInfo, [key]: value })
    }
  
    const handleCancel = () => {
      console.log(toJS(userInfo))
      console.log(toJS(initialUserInfo))
      setUserInfo(initialUserInfo)
    }*/

  const compareObjects = (
    obj1: { [key: string]: string | null },
    obj2: { [key: string]: string | null }
  ) => {
    const keys1 = Object.keys(obj1)
    const keys2 = Object.keys(obj2)

    if (keys1.length !== keys2.length) {
      return false
    }

    for (const key of keys1) {
      if (obj1[key] !== obj2[key]) {
        return false
      }
    }

    return true
  }

  /*const handleSave = async () => {
      const modifiedUserInfo = Object.fromEntries(
        Object.entries(userInfo).map(([key, value]) => [
          key,
          value === '' ? null : value,
        ])
      )
  
      const headers = {
        Authorization: `Bearer ${store.UserStore.token}`,
      }
      try {
        const response = await axios.patch(
          `https://graph.microsoft.com/v1.0/users/${store.UserStore.userID}`,
          modifiedUserInfo,
          { headers }
        )
        if (response) {
          await store.UserStore.getData()
          toast('Success! Your profile has been updated.', {
            type: 'success',
            autoClose: 5000,
          })
        }
      } catch (error) {
        console.error('Error updating user data: ', error)
        throw error
      }
    }
  
    useEffect(() => {
      setUserInfo(store.UserStore.userInfo)
      setInitialUserInfo(store.UserStore.userInfo)
    }, [store.UserStore.userInfo])
  */
  return (
    <SettingsCategory>
      <h1>My User Profile</h1>
      <NameCont>
        <ImageCont>
          {store.UserStore.image !== '' ? (
            <img src={store.UserStore.image} />
          ) : (
            <IconImage src={Profile} />
          )}
        </ImageCont>
        <DataCont>
          <TitleText>{store.UserStore.user}</TitleText>
          <SubTitleText>{store.UserStore.email}</SubTitleText>
        </DataCont>
      </NameCont>
      {/*<InnerCont>
        <h2>
          <Label>Profile information:</Label>
        </h2>
        {Object.keys(store.UserStore.userInfo).map((key, index) => (
          <SingleProfileInfo key={`userprofile-${index}`}>
            <div>
              {locales && locales.userInfo ? locales.userInfo[key] : key}
            </div>
            <input
              type={'text'}
              value={userInfo[key]}
              disabled={key === 'mail'}
              onChange={(event) => handleInputChange(key, event.target.value)}
            />
          </SingleProfileInfo>
        ))}
        {!compareObjects(userInfo, initialUserInfo) ? (
          <ButtonCont>
            <PrimaryButton
              onClick={handleSave}
              {...Theme.buttons.primaryButton}
            >
              SAVE
            </PrimaryButton>
            <PrimaryButton
              onClick={handleCancel}
              {...Theme.buttons.primaryButton}
            >
              CANCEL
            </PrimaryButton>
          </ButtonCont>
        ) : (
          <></>
        )}
      </InnerCont>*/}
    </SettingsCategory>
  )
})
