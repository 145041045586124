import { observer } from 'mobx-react'
import { FC, PropsWithChildren, useContext, useEffect, useState } from 'react'

import { StoreContext } from '../../../App'

import { NavLink } from 'react-router-dom'
import {
  ActiveButton,
  Container,
  Footer,
  PrimaryButton,
  SecondaryButton,
  StyledModal,
  Text,
  WarningCont,
} from './TableLock.styles'

interface Props extends PropsWithChildren {
  status?: number
  label?: string
  page: string
  cell: any
  id?: string
}

const TableLock: FC<Props> = observer((props: Props) => {
  const store = useContext(StoreContext)
  const { Theme } = store.Theme
  const locales = store.TranslationsState.translations

  const [id, setId] = useState(0)
  const [openModal, setOpenModal] = useState(false)
  const [lockUser, setLockUser] = useState<{
    id: string
    user: string
    lockId: string
  } | null>(null)

  const lockData = () => {
    const userData = store.RouteStore.lockedValues.find(
      (e) =>
        e.lockId ===
        props.cell.cell.row.allCells.find((e: any) => e.column.id === 'id')
          .value
    )
    if (userData) {
      setLockUser(userData)
    } else {
      setLockUser(null)
    }
  }
  useEffect(() => {
    if (
      props.cell.cell.row.allCells.find((e: any) => e.column.id === 'id').value
    ) {
      setId(
        props.cell.cell.row.allCells.find((e: any) => e.column.id === 'id')
          .value
      )
      lockData()
    }
  }, [])

  useEffect(() => {
    lockData()
  }, [store.RouteStore.lockedValues])

  return (
    <Container>
      {lockUser ? (
        <>
          <StyledModal open={openModal} onClose={() => setOpenModal(false)}>
            <WarningCont>
              <p>{locales.warnings.vieved}</p>
              <Footer>
                <PrimaryButton
                  to={`/${props.page}/${id}`}
                  {...Theme.buttons.primaryButton}
                >
                  {'ENTER'}
                </PrimaryButton>
                <SecondaryButton
                  onClick={() => setOpenModal(false)}
                  {...Theme.buttons.secondaryButton}
                >
                  {'CANCEL'}
                </SecondaryButton>
              </Footer>
            </WarningCont>
          </StyledModal>
          <ActiveButton
            onClick={() => setOpenModal(true)}
            active={!lockUser}
            {...Theme.tableLockStyles.activeButton}
          >
            {locales.buttons.locked}
          </ActiveButton>
        </>
      ) : (
        <NavLink to={`/${props.page}/${id}`}>
          <ActiveButton
            active={!lockUser}
            {...Theme.tableLockStyles.activeButton}
          >
            {locales.buttons.view}
          </ActiveButton>
        </NavLink>
      )}
      {lockUser ? (
        <>
          <Text {...Theme.tableLockStyles.text}>
            {locales.buttons.lockedBy}
          </Text>
          <Text {...Theme.tableLockStyles.text}>{lockUser.user}</Text>
        </>
      ) : (
        <></>
      )}
    </Container>
  )
})
export default TableLock
