import styled from 'styled-components'
import { breakpoints } from '../../../static/breakpoints'
import Pagination from '@mui/material/Pagination'

interface SelectWrapperProps {
  bgColor?: string
  marginTop?: string
  color?: string
  boxShadow?: string
}

export const SelectedWrapper = styled.div<SelectWrapperProps>`
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 50;
  margin-top: ${(props) => (props.marginTop ? props.marginTop : '8px')};
  width: 100%;
  background-color: ${(props) => (props.bgColor ? props.bgColor : '#000000')};
  color: ${(props) => (props.color ? props.color : '#fafafa')};
  display: flex;
  justify-content: flex-start;
  align-items: center;
  box-shadow: ${(props) =>
    props.boxShadow ? props.boxShadow : '0 6px 16px rgba(0, 0, 0, 0.1))'};
  padding-bottom: 4px;
  margin-left: 4px;

  flex-wrap: wrap;
  overflow: visible;
`

interface SelectedParagraphProps {
  fontWeight?: number
  fontSize?: string
  lineHeight?: string
  color?: string
}

export const SelectedParagraph = styled.p<SelectedParagraphProps>`
  display: flex;
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : 400)};
  font-size: ${(props) => (props.fontSize ? props.fontSize : '12px')};
  line-height: ${(props) => (props.lineHeight ? props.lineHeight : '16px')};
  color: ${(props) => (props.color ? props.color : '#ffffff')};
  cursor: pointer;
`
export const CreateOrderCont = styled(SelectedParagraph)`
  background-color: #926f34;
  padding: 4px 8px;
  border-radius: 6px;
  margin-left: 8px;
  cursor: pointer;
  white-space: nowrap;
`
export const RowsCount = styled.b`
  margin-left: 4px;
`
export const StyledPlus = styled.img`
  margin-right: 7px;
  cursor: pointer;
`
export const StyledCross = styled.div`
  margin: 14px;
  cursor: pointer;
`

interface PaginationWrapperProps {
  lineHeight?: string
  fontSize?: string
  margin?: string
  color?: string
}

export const PaginationWrapper = styled.div<PaginationWrapperProps>`
  font-size: ${(props) => (props.fontSize ? props.fontSize : '0.875rem')};
  line-height: ${(props) => (props.lineHeight ? props.lineHeight : '1.25rem')};
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin: ${(props) => (props.margin ? props.margin : '15px 0 0px 0')};
  position: relative;
  color: ${(props) => (props.color ? props.color : 'black')};
  @media screen and (max-width: ${breakpoints.tablet}) {
    flex-direction: column;
    align-items: center;
    flex-wrap: wrap-reverse;
  }
  @media print {
    display: none;
  }
`
export const LeftInnerPagination = styled.div`
  display: flex;
  position: relative;
  align-items: flex-start;
  @media screen and (max-width: ${breakpoints.mobile}) {
    flex-direction: column;
    margin-bottom: 8px;
    align-items: center;
  }
`
export const RightInnerPagination = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`
export const SelectedCount = styled.div`
  margin-right: 10px;
  margin-left: 32px;
  margin-top: 8px;
`

interface RowsPerPageProps {
  fontWeight?: number
  fontSize?: string
  lineHeight?: string
  color?: string
}

export const RowsPerPage = styled.div<RowsPerPageProps>`
  font-style: normal;
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : 400)};
  font-size: ${(props) => (props.fontSize ? props.fontSize : '14px')};
  line-height: ${(props) => (props.lineHeight ? props.lineHeight : '16px')};
  display: flex;
  align-items: center;
  text-align: right;
  font-feature-settings: 'lnum' on;
  color: ${(props) => (props.color ? props.color : '#1f1f1f')};
  margin-right: 8px;
`

interface OptionsSelectProps {
  padding?: string
  border?: string
  borderRadius?: string
  color?: string
}

export const OptionsSelect = styled.select<OptionsSelectProps>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: ${(props) => (props.padding ? props.padding : '4px 4px 4px 8px')};
  gap: 10px;
  border: ${(props) => (props.border ? props.border : '1.5px solid #303030')};
  border-radius: ${(props) =>
    props.borderRadius ? props.borderRadius : '6px'};
  color: ${(props) => (props.color ? props.color : 'black')};
`

interface PaginationButtonProps {
  padding?: string
  margin?: string
  fontSize?: string
  border?: string
  borderRadius?: string
}

export const PaginationButton = styled.div<PaginationButtonProps>`
  padding: ${(props) => (props.padding ? props.padding : '8px 14px')};
  margin: ${(props) => (props.margin ? props.margin : '0 2px')};
  font-size: ${(props) => (props.fontSize ? props.fontSize : '1.2em')};
  border: ${(props) => (props.border ? props.border : '2px solid black')};
  border-radius: ${(props) =>
    props.borderRadius ? props.borderRadius : '4px'};
  cursor: pointer;
`

interface PaginationNavButtonProps {
  padding?: string
  margin?: string
  fontSize?: string
  border?: string
  borderRadius?: string
}

export const PaginationNavButton = styled.button<PaginationNavButtonProps>`
  padding: ${(props) => (props.padding ? props.padding : '8px 14px')};
  margin: ${(props) => (props.margin ? props.margin : '0 2px')};
  font-size: ${(props) => (props.fontSize ? props.fontSize : '1.2em')};
  border-radius: ${(props) =>
    props.borderRadius ? props.borderRadius : '4px'};
  cursor: pointer;
`

export const LeftInnerPaginationTop = styled.div`
  display: flex;
  margin-bottom: 10px;
`

interface StyledPaginatorProps {
  themebackgroundcolor?: string
  themecolor?: string
}

/* eslint-disable */
export const StyledPaginator = styled(Pagination)<StyledPaginatorProps>`
  * {
    transition-duration: 0s !important;
    color: ${(props) =>
      props.themecolor ? props.themecolor : '#ffffff'} !important;
  }

  .Mui-selected {
    border-radius: 8px;
    transition-duration: 0s !important;
    color: white !important;
    background-color: ${(props) =>
      props.themebackgroundcolor || '#ffffff'} !important;

    :focus-visible {
      transition-duration: 0s !important;
    }
  }

  .MuiPaginationItem-sizeMedium {
    border-radius: 8px;
  }
`

interface LeftInnerPaginationBotProps {
  fontSize?: string
}

export const LeftInnerPaginationBot = styled.div<LeftInnerPaginationBotProps>`
  font-size: ${(props) => (props.fontSize ? props.fontSize : '12px')};
`
export const CopyRightMark = styled.div`
  position: relative;
  width: 100%;
  font-size: 12px;

  @media print {
    display: none;
  }
`
