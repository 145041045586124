import { observer } from 'mobx-react'
import React, { useContext } from 'react'
import { StoreContext } from '../../../../App'
import EditTag from '../../genericTree/editTag/EditTree.component'
import AssignTag from '../edits/assignTag/AssignTag.component'
import Table from '../../../TableGeneric/table/Table.component'
import { Cell } from 'react-table'
import { ArrayDisplayCont, ObjectDisplayCont } from '../Tags.styles'
import { SlidingPanel } from '../../../slidingContainer/SlidingContainer.component'
import FilterIcon from '../../../../assets/icons/filter-icon.svg'
import CreateTagComponent from '../edits/createTag/CreateTag.component'
import TableNewTagsComponent from '../../../TableGeneric/tableNewTags/TableNewTags.component'
import { LanguageBasedCell } from '../edits/languageBasedCell/LanguageBasedCell.component'
import RemoveRootComponent from '../edits/removeRoot/RemoveRoot.component'

export const RootTagsTable = observer(() => {
  const store = useContext(StoreContext)
  const tableStore = store.TableStore

  const columns = {
    id: {
      canFilter: false,
      canSort: false,
      editable: false,
    },
    name: {
      Cell: (cell: Cell) => <LanguageBasedCell cellValue={cell.value} />,
      canFilter: false,
      canSort: false,
      editable: true,
      mandatory: true,
      editType: 'languageObject',
      mandatoryLanguage: 'EN',
    },
    groupID: {
      canFilter: false,
      canSort: false,
      editable: true,
      editType: 'groupSearch',
    },
    description: {
      Cell: (cell: Cell) => <LanguageBasedCell cellValue={cell.value} />,
      canFilter: false,
      canSort: false,
      editable: true,
      mandatory: true,
      editType: 'languageObject',
      mandatoryLanguage: 'EN',
    },
    indicesAllowed: {
      Cell: (cell: Cell) => (
        <ArrayDisplayCont>
          {cell.value &&
            cell.value.map((value: any, index: number) => (
              <div key={`${index}-value-name`}>{value}</div>
            ))}
        </ArrayDisplayCont>
      ),
      editable: true,
      canSort: false,
      editType: 'stringArraySelect',
      arrayValues: store.TagsStore.avaliableIndicies,
    },
  }

  return (
    <Table
      config={{
        tableName: 'roottags',
        data: store.TagsStore.data || [],
        fetchData: () => store.TagsStore.getData(),
        columnHeaders: columns,
        columnVisibility: false,
        allowColumnResize: true,
        displaySearch: false,
        displaySelect: false,
        displayExpand: false,
        displayTooltip: false,
        displayEntry: false,
        displayPaginator: true,
        displayInfinitySwitch: false,
        allowSort: false,
        bank: false,
        actions: {
          display: true,
          label: 'Root Tag',
          edit: `${process.env.REACT_APP_BASE_URL}experience/v1/tag/roottag`,
          create: `${process.env.REACT_APP_BASE_URL}experience/v1/tag/roottag/create`,
          remove: `${process.env.REACT_APP_BASE_URL}experience/v1/tag/roottag/remove`,
          assignTag: null,
          custom: [],
        },
        headline: {
          visible: true,
          title: 'Root Tags',
          subTitle: 'List of Root Tags',
        },
        breadcrumb: [],
        loaded: store.TagsStore.dataLoaded,
        pagination: store.TagsStore.pagination,
        setPagination: store.TagsStore.setPagination,
      }}
    />
  )
})
