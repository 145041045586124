import styled from 'styled-components'

interface ContButtonProps {
  padding?: string
  borderRadius?: string
  border?: string
  margin?: string
  color?: string
  fontSize?: string
  lineHeight?: string
}

export const ContButton = styled.button<ContButtonProps>`
  display: flex;
  justify-content: center;
  background-color: #926f34;
  padding: ${(props) => (props.padding ? props.padding : '4px 8px')};
  margin: ${(props) => (props.margin ? props.margin : '0 4px')};
  font-size: ${(props) => (props.fontSize ? props.fontSize : '12px')};
  line-height: ${(props) => (props.lineHeight ? props.lineHeight : '16px')};
  border-radius: ${(props) =>
    props.borderRadius ? props.borderRadius : '8px'};
  height: 24px;
  white-space: nowrap;
  align-items: center;
`
