import styled from 'styled-components'

export const Cont = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`

interface SingleTagInterface {
  bgColor?: string
  color?: string
  fontSize?: string
  borderRadius?: string
  hoverBgColor?: string
}

/* eslint-disable */
export const SingleTag = styled.div<SingleTagInterface>`
  padding: 4px;
  margin: 2px;
  background-color: ${(props) => (props.bgColor ? props.bgColor : '#505050')};
  color: ${(props) => (props.color ? props.color : '#dddddd')};
  border-radius: ${(props) =>
    props.borderRadius ? props.borderRadius : '12px'};
  font-size: ${(props) => (props.fontSize ? props.fontSize : '10px')};
  @media print {
    font-size: 14px;
    font-weight: 500;
    color: #000000;
    background-color: rgb(255, 255, 153);
  }
  :hover {
    background-color: ${(props) =>
      props.hoverBgColor ? props.hoverBgColor : '#303030'};
  }
`
