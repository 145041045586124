import styled from 'styled-components'
export const Container = styled.div`
  width: 100%;
  height: 100%;
  min-width: 120px;
  border: 1px solid #e5e5e5;
  display: flex;
  min-height: 100px;
  flex-wrap: wrap;
  flex-direction: column;
  font-size: 12px;
`
