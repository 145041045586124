/* eslint-disable */
import axios from '../../axiosconfig'
import { observer } from 'mobx-react'
import { useContext, useEffect, useRef, useState } from 'react'
import useWindowDimensions from '../../methods/hooks/useWindowDimensions'

import {
  hiddenColumns,
  ReplaceEmptyStringsForNulls,
  ReplaceEmptyStringsForZeros,
  ValidateArray,
} from '../../columnsConfig'
import { StoreContext } from '../../App'
import { DataInput } from '../dataInput/DataInput.component'

import {
  InnerCont,
  Footer,
  PrimaryButton,
  SecondaryButton,
  LoadingWrapper,
} from './EditMultipleProductsByQuery.styles'
import { Container, Wrap } from '../../styles/Loading.styles'
import { Column } from 'react-table'
import { socket } from '../../socket/socket'
import { toast } from 'react-toastify'

interface formInterface {
  keys: Array<string>
}

const EditMultipleProductsByQuery = observer(({ keys }: formInterface) => {
  const store = useContext(StoreContext)
  const { Theme } = store.Theme
  const divRef = useRef<any>()
  const dataChange = store.TableStore.dataInput
  const [inputColumns, setInputColumns] = useState(1)
  const [triggerChange, setTriggerChange] = useState(false)
  const [changedObject, setChangedObject] = useState(false)
  const { width, height } = useWindowDimensions()

  const defaultValues: any = {
    stockAmountInPackageBool: true,
  }

  const initialState = [
    ...keys.map((e) => {
      return {
        header: e,
        value: defaultValues.hasOwnProperty(e) ? defaultValues[e] : '',
        error: '',
        rules: ValidateArray[e] ? ValidateArray[e] : [],
      }
    }),
  ]
  const [form, setForm] = useState(initialState)

  const handleSetForm = (
    tempForm: Array<{
      header: string
      value: string
      error: string
      rules: Array<string>
    }>
  ) => {
    setForm([...tempForm])
  }

  useEffect(() => {
    let changed = false
    form.map((e, index) => {
      if (e.value !== '') {
        changed = true
        return
      }
    })
    form.map((e, index) => {
      if (e.error !== '') {
        changed = false
        return
      }
    })
    setChangedObject(changed)
  }, [triggerChange])

  const handleSave = async () => {
    const url = `${process.env.REACT_APP_BASE_URL}experience/v1/stock_view?from=0&size=${store.TableStore.selectedRowId.length}`
    let data: any = {
      filterGroups: {
        rules: [],
        combinator: 'or',
        not: false,
      },
    }

    store.TableStore.selectedRowId.map((e: string, index: number) =>
      data.filterGroups.rules.push({
        field: 'id',
        operator: '=',
        valueSource: 'value',
        value: e,
      })
    )
    const config = {
      headers: {
        'x-apikey': `${process.env.REACT_APP_API_KEY}`,
        username: store.UserStore.user.length
          ? store.UserStore.user
          : 'No data',
      },
    }
    const response = await axios.post(url, data, config)
    if (response && response.status === 200) {
      const includeIds: string[] = []
      response.data.products.forEach((e: any) => {
        includeIds.push(e.id)
      })

      let bodyData: any = {}
      form.map((f: any, index) => {
        const selected =
          divRef.current.querySelector(`input[name="${f.header}"]`) &&
          divRef.current.querySelector(`input[name="${f.header}"]`).checked
        if (selected) {
          if (ReplaceEmptyStringsForNulls.includes(f.header) && f.value === '')
            bodyData = { ...bodyData, [f.header]: null }
          if (f.header === 'status' && f.value === '')
            bodyData = { ...bodyData, [f.header]: '0' }
          else bodyData = { ...bodyData, [f.header]: f.value }
        }
      })
      const editUrl = `${process.env.REACT_APP_BASE_URL}experience/v1/products_update`

      const editData = {
        updateJSON: store.TableStore.formatRequestData(bodyData),
        includeIds: includeIds,
        filterGroups: {
          rules: [],
          combinator: 'or',
          not: false,
        },
        excludeIds: [],
        searchAll: `**`,
      }

      console.log(editData)
      const res = await axios.post(editUrl, editData, config)
      store.TableStore.setLoading(false)
      await console.log(res)
      await setTimeout(async () => await store.TableStore.fetchData(), 2000)
      store.SlidingPanelState.setVisibility(false)
      await toast(`${res.data.updated} of ${res.data.total} has been changed`, {
        type: 'success',
      })
      if (res.data.failures.length > 0) {
        await toast(`Failed to update: ${JSON.stringify(res.data.failures)}`, {
          type: 'error',
        })
      }
      await setTimeout(() => store.TableStore.fetchData(), 2000)
      await setTimeout(
        () =>
          includeIds.forEach((e: any) => {
            socket.emit('editOrder', {
              id: e,
              user: store.UserStore.user,
              page: store.TableStore.getApiRequestFromUrl(),
            })
          }),
        2000
      )
    }
  }

  useEffect(() => {
    setInputColumns(
      Math.ceil(
        (90 * keys.filter((e) => !hiddenColumns.includes(e)).length) /
          (height * 0.8)
      )
    )
  }, [height])

  useEffect(() => {
    let changed = false
    form.map((e, index) => {
      if (e.value !== initialState[index].value) {
        changed = true
        return
      }
    })
    form.map((e, index) => {
      if (e.error !== '') {
        changed = false
        return
      }
    })
  }, [form])

  return (
    <div>
      <InnerCont cols={inputColumns} ref={divRef}>
        {keys.map((e, index) => (
          <DataInput
            vals={{ header: e, value: '' }}
            form={form}
            index={index}
            setForm={handleSetForm}
            key={`edit-multiple-${index}`}
            triggerChange={() => setTriggerChange(!triggerChange)}
          />
        ))}
      </InnerCont>
      <Footer {...Theme.editProductsStyles.styledFooter}>
        <SecondaryButton
          onClick={() => store.SlidingPanelState.setVisibility(false)}
          {...Theme.buttons.secondaryButton}
        >
          {'CANCEL'}
        </SecondaryButton>
        <PrimaryButton
          onClick={() => {
            if (changedObject) handleSave()
          }}
          active={changedObject}
          {...Theme.buttons.primaryButton}
        >
          {'SAVE'}
        </PrimaryButton>
      </Footer>
    </div>
  )
})
export default EditMultipleProductsByQuery
