import { observer } from 'mobx-react'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { StoreContext } from '../../../App'
import {
  ArrowCont,
  Container,
  LoadingPage,
  MenuList,
  MenuListButton,
  OuterContainer,
  RootTagList,
} from './GenericTree.styles'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { SingleTreeGenericAddComponent } from './singleTreeTag/SingleTreeGenericAdd.component'
import { ArcherContainer } from 'react-archer'
import { toJS } from 'mobx'
import Menu, { Item as MenuItem, SubMenu } from 'rc-menu'
import { IconChevronDown, IconSquareChevronDown } from '@tabler/icons-react'
import Arrow from '../../../assets/icons/arrow-left.svg'
import useOutSideClick from '../../../methods/methods/useOutSideClick'
import {
  Container as LoadingContainer,
  Wrap,
} from '../../../styles/Loading.styles'

interface GeneralTreeInterface {
  data: any[]
  setData: () => void
  lang: string
  mainDesc: string
  firstLevelChild: string
  secondLevelChild: string
  firstLevelParent: string
  secondLevelParent: string
  baseUrl: string
  dropFunction: (id: string, newParent: string, newTag: string) => void
}

export const GenericTreeComponent = observer(
  ({
    data,
    setData,
    lang,
    mainDesc,
    firstLevelChild,
    secondLevelChild,
    firstLevelParent,
    secondLevelParent,
    baseUrl,
    dropFunction,
  }: GeneralTreeInterface) => {
    const store = useContext(StoreContext)
    const [selectedRootTag, setSelectedRootTag] = useState<{
      [key: string]: any
    } | null>(null)
    const [selectedTag, setSelectedTag] = useState(0)
    const [visibleMenu, setVisibleMenu] = useState(false)
    const archerRef: any = useRef()
    const menuRef: any = useRef()
    /* eslint-disable */
    const tagName: string = data[selectedTag]
      ? //@ts-ignore
        data[selectedTag].name[lang]
      : ''

    useEffect(() => {
      setData()
    }, [])

    useEffect(() => {
      if (selectedRootTag) {
        const newData = data.find((e: any) => e.id === selectedRootTag.id)
        if (newData) setSelectedRootTag(newData)
      }
    }, [data])

    useOutSideClick(menuRef, () => setVisibleMenu(false))

    return (
      <OuterContainer>
        {!store.TagsStore.treeLoaded ? (
          <LoadingPage>
            <LoadingContainer>
              <Wrap></Wrap>
            </LoadingContainer>
          </LoadingPage>
        ) : (
          <></>
        )}
        <RootTagList>
          <b>
            Current {mainDesc}:{'  '}
            <MenuListButton onClick={() => setVisibleMenu(true)}>
              {selectedRootTag ? tagName : `Select a ${mainDesc}`}
              <ArrowCont src={Arrow} selected={visibleMenu} />
            </MenuListButton>
          </b>
          <div ref={menuRef}>
            {visibleMenu ? (
              <MenuList
                mode='inline'
                style={{ margin: 0 }}
                expandIcon={<IconChevronDown size='18px' />}
              >
                {data.map((e: any, index: number) => {
                  return (
                    <MenuItem
                      style={{ paddingRight: '32px' }}
                      onClick={() => {
                        setSelectedRootTag(data[index])
                        setSelectedTag(index)
                        setVisibleMenu(false)
                      }}
                      key={`tree-root-${index}`}
                    >
                      <b>{e.name[lang]}</b>
                    </MenuItem>
                  )
                })}
              </MenuList>
            ) : (
              <></>
            )}
          </div>
        </RootTagList>
        <div style={{ width: '100%' }}>
          <ArcherContainer
            ref={archerRef}
            strokeColor='black'
            strokeWidth={2}
            endShape={{
              arrow: {
                arrowLength: 0,
                arrowThickness: 0,
              },
            }}
            svgContainerStyle={{ transition: 'none' }}
          >
            {archerRef.current ? (
              <Container onScroll={archerRef.current.refreshScreen}>
                {selectedRootTag && Object.keys(selectedRootTag).length > 0 ? (
                  <DndProvider backend={HTML5Backend}>
                    <SingleTreeGenericAddComponent
                      objectData={selectedRootTag}
                      mainDesc={mainDesc}
                      firstLevel={true}
                      data={toJS(data)}
                      setData={setData}
                      firstLevelChild={firstLevelChild}
                      secondLevelChild={secondLevelChild}
                      firstLevelParent={firstLevelParent}
                      secondLevelParent={secondLevelParent}
                      lang={lang}
                      baseUrl={baseUrl}
                      dropFunction={dropFunction}
                    />
                  </DndProvider>
                ) : (
                  <></>
                )}
              </Container>
            ) : (
              <></>
            )}
          </ArcherContainer>
        </div>
      </OuterContainer>
    )
  }
)
