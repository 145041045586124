const avaliableRules = (rule: string, value: string) => {
  switch (rule) {
    case 'positive':
      return parseInt(value) >= 1 ? '' : 'Value smaller than one'
    case 'required':
      //return value.length >= 0 ? '' : `Min amount of characters: ${0}`
      return ''
    case 'length':
      //return value.length >= 0 ? '' : `Min amount of characters: ${0}`
      return ''
    case 'amount':
      return parseFloat(value) >= 0 ? '' : 'Value smaller than zero'
  }
}

export function validate(rules: Array<string> = [], value: string) {
  let error = ''
  rules.map((e: string) => {
    const errorMessage = avaliableRules(e, value)
    if (errorMessage) {
      error = errorMessage
    }
  })
  return error
}
