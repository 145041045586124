import { Button, ButtonText, Wrap } from './SearchFieldButton.styles'
import { observer } from 'mobx-react'
import { useContext } from 'react'
import { StoreContext } from '../../../App'

interface Props {
  text: string
  onClick: () => void
  icon?: JSX.Element
  absoluteWrap?: boolean
}

const SearchFieldButton = observer((props: Props) => {
  const store = useContext(StoreContext)
  const { Theme } = store.Theme
  const locales = store.TranslationsState.translations

  if (props.absoluteWrap)
    return (
      <Wrap onClick={props.onClick}>
        <Button {...Theme.contButton.contButton}>
          {props.icon}
          <ButtonText>{props.text}</ButtonText>
        </Button>
      </Wrap>
    )
  return (
    <Button onClick={props.onClick} {...Theme.contButton.contButton}>
      {props.icon}
      <ButtonText>{props.text}</ButtonText>
    </Button>
  )
})
export default SearchFieldButton
