import React, {
  PropsWithChildren,
  FC,
  useEffect,
  useState,
  useMemo,
  useContext,
} from 'react'
import {
  Column,
  useTable,
  useFlexLayout,
  useFilters,
  ColumnInstance,
  useRowSelect,
  TableToggleRowsSelectedProps,
  Row,
  Cell,
  HeaderProps,
  useColumnOrder,
  useResizeColumns,
  useSortBy,
} from 'react-table'
import axios from '../../../axiosconfig'
import { ScrollSync, ScrollSyncPane } from 'react-scroll-sync'

import TableTags from '../tableTags/TableTags.component'
import { DefaultColumnFilter } from '../../filters/Filters.components'
import { TableRenderedCheckbox } from '../../checkboxSelect/CheckboxSelect.component'

import {
  Body,
  CellStyles,
  Container,
  Header,
  HeaderColumn,
  HeaderGroup,
  RowStyle,
  Table,
  HeaderSearch,
  HeaderText,
  FooterCont,
  FooterValue,
  OptionsContainer,
} from './TableRendered.styles'
import { socket } from '../../../socket/socket'

import { observer } from 'mobx-react'
import { StoreContext } from '../../../App'
import TableDifferentValues from '../TableDifferentValues/TableDifferentValues.component'
import TableMoreActions from '../tableMoreActions/TableMoreActions.component'
import EditRenderedProductComponent from '../../editRenderedProduct/EditRenderedProduct.component'
import { TableQuickEditComponent } from '../TableQuickEdit/TableQuickEdit.component'
import RemoveRenderedProduct from '../../removeSupplierProduct/RemoveRenderedProduct.component'
import TableHeaderCheckboxes from '../tableHeaderCheckboxes/TableHeaderCheckboxes.component'
import TableRenderedCheckboxesComponent from '../tableRenderedCheckboxes/TableRenderedCheckboxes.component'
import { toJS } from 'mobx'
import FilterIcon from '../../../assets/icons/filter-icon.svg'
import Menu, { SubMenu } from 'rc-menu'
import { IconChevronDown, IconSquareChevronDown } from '@tabler/icons-react'
import {
  SwitchCont,
  SwitchInput,
  SwitchInputText,
  SwitchStyled,
  TableOptionsCont,
} from '../table/Table.styles'
import ColumnVisibility from '../columnVisibility/ColumnVisibility.component'
import { SlidingPanel } from '../../slidingContainer/SlidingContainer.component'
import TableOptions from '../../../assets/icons/table-options.svg'
import TableHeaderOptions from '../tableHeaderOptions/TableHeaderOptions.component'
import TableNewTagsComponent from '../tableNewTags/TableNewTags.component'
import AddSupplierProduct from '../../addSupplierProduct/AddSupplierProduct.component'
import { SingleTag } from '../tableTags/TableTags.styles'
import AssignOrderLineTagComponent from '../../settings/tags/edits/assignOrderLineTag/AssignOrderLineTagAllTags.component'

/* eslint-disable */
interface Props extends PropsWithChildren {
  id: number
  row: any
  toggleAllRowsSelected: any
  selectedMainRows: number
  data?: any
}

type RowProps = {
  getToggleRowSelectedProps?: (
    props?: Partial<TableToggleRowsSelectedProps>
  ) => TableToggleRowsSelectedProps
  isExpanded?: boolean
  original?: boolean
  row: Row
}
const TableRenderedRapper: FC<Props> = observer((props) => {
  const store = useContext(StoreContext)
  const [data, setData] = useState<Array<object>>([])
  const [loaded, setLoaded] = useState<boolean>(false)

  const mapData = async (data: any) => {
    const dataKeys = Object.keys(toJS(data)).filter((e) => e !== 'translations')
    let responseData = [...dataKeys.map((e) => (data[e] ? toJS(data[e]) : {}))]
    responseData = responseData.filter((e) => Object.keys(e).length !== 0)
    responseData.map((e) => delete e.translations)
    setData(responseData)
  }

  const handleDefaultRowSelect = () => {
    const id = props.row.allCells.find((e: any) => e.column.id === 'id')?.value
    const selectedDefaultRows = store.TableStore.selectedExpanded
      .filter((e) => e.rowId === id)
      .map((e: any) => e.values.ProductID)
    let arrayIndexes: string[] = []
    data.map((e: any, index: number) => {
      if (selectedDefaultRows.includes(e.ProductID)) {
        arrayIndexes = [`${index}`, ...arrayIndexes]
      }
    })
    let selectedObject = {}
    arrayIndexes.map((e) => {
      selectedObject = { ...selectedObject, [e]: 'true' }
    })
    //console.log(selectedObject)
    return selectedObject
  }
  const getData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}experience/v1/supplier_order/${props.row.values.id}`,
        {
          headers: {
            'Content-Type': 'application/json',
            'x-apikey': `${process.env.REACT_APP_API_KEY}`,
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Methods': 'GET,HEAD,PUT,PATCH,POST,DELETE',
            'Access-Control-Allow-Credentials': 'true',
          },
        }
      )
      //await console.log(response?.data.products)
      await mapData(response?.data.products)
    } catch (e) {
      setLoaded(true)
    }
  }

  useEffect(() => {
    let dataId = 0
    while (JSON.stringify(data[dataId]) == '{}') {
      dataId++
    }
    handleDefaultRowSelect()
    //console.log(data)
  }, [data])

  useEffect(() => {
    getData()
    socket.on('orderChange', (object) => {
      //console.log(object.id)
      //console.log(props.row.values.id)
      if (object.id === props.row.values.id) {
        console.log('getData')
        getData()
      }
    })

    store.TableStore.setExpandedRows({
      [props.row.values.id]: true,
      ...store.TableStore.expandedRows,
    })

    return () => {
      socket.off('orderChange')
      const expanded = Object.keys(store.TableStore.expandedRows).filter(
        (key) => key != props.row.values.id
      )
      let expandedObject = {}
      expanded.map(
        (e: string) => (expandedObject = { [e]: true, ...expandedObject })
      )
      if (store.TableStore.loaded)
        store.TableStore.setExpandedRows(expandedObject)
    }
  }, [])

  if (data.length)
    return (
      <TableRendered
        setData={setData}
        selectedRowIds={handleDefaultRowSelect()}
        {...props}
        data={data}
      />
    )
  else return <></>
})

interface InnerProps extends PropsWithChildren {
  row: Row
  selectedMainRows: number
  selectedRowIds: Record<string, boolean>
  data: Array<object>
  setData: any
}

const TableRendered: FC<InnerProps> = observer(
  ({ row, selectedMainRows, data, setData, selectedRowIds }) => {
    const store = useContext(StoreContext)
    const locales = store.TranslationsState.translations
    const { Theme } = store.Theme
    const [change, toggleChange] = useState(false)
    const columnHeaders: {
      [e: string]: {
        Cell?: (cell: Cell, row: Row) => JSX.Element
        Header?: string
        filter?: string
        Filter?: any
        aggregate?: string
        Aggregated?: (val: any) => string
        isVisible?: boolean
      }
    } = {
      ProductID: {},
      ProductCode: {},
      Name: {},
      ProducerName: {},
      SupplierDeliveryCode: {},
      OrderLink: {},
      ean: {},
      PurchasePrice: {},
      Tags: {
        Cell: (cell: Cell) => <TableTags key={cell.value} tags={cell.value} />,
      },
      OrderLineTags: {
        Cell: (cell: Cell) => (
          <TableNewTagsComponent
            key={cell.value}
            tags={cell.value}
            orderLine={true}
          />
        ),
      },
      SupplierDeliveryDate: {
        Header: 'Estimated Delievery Date',
        Cell: (cell: Cell) => (
          <>
            {cell.row.allCells.find((e) => e.column.id === 'OrderedQuantity')
              ?.value !==
            cell.row.allCells.find((e) => e.column.id === 'DeliveredQuantity')
              ?.value ? (
              <TableDifferentValues
                key={cell.value}
                value={cell.value}
                fromValue={
                  row.allCells.find((e) => e.column.id === 'estimateDate')
                    ?.value
                }
              />
            ) : (
              cell.value
            )}
          </>
        ),
      },
      OrderedQuantity: {},
      DeliveredQuantity: {
        Cell: (cell: Cell) => (
          <TableDifferentValues
            key={cell.value}
            value={cell.value}
            form={true}
            fromValue={
              cell.row.allCells.find((e) => e.column.id === 'OrderedQuantity')
                ?.value
            }
            productId={
              cell.row.allCells.find((e) => e.column.id === 'ProductCode')
                ?.value
            }
            orderId={row.values.id}
            columnName={'DeliveredQuantity'}
            type={'number'}
          />
        ),
      },
      DeliveredDate: {
        Cell: (cell: Cell) =>
          cell.row.allCells.find((e) => e.column.id === 'SupplierDeliveryDate')
            ?.value &&
          cell.row.allCells.find((e) => e.column.id === 'SupplierDeliveryDate')
            ?.value < cell.value ? (
            <TableDifferentValues
              key={cell.value}
              value={cell.value}
              form={true}
              fromValue={
                cell.row.allCells.find(
                  (e) => e.column.id === 'SupplierDeliveryDate'
                )?.value
              }
              productId={
                cell.row.allCells.find((e) => e.column.id === 'ProductCode')
                  ?.value
              }
              orderId={row.values.id}
              columnName={'DeliveredDate'}
              type={'date'}
            />
          ) : (
            <TableQuickEditComponent
              productId={
                cell.row.allCells.find((e) => e.column.id === 'ProductCode')
                  ?.value
              }
              orderId={row.values.id}
              defaultValue={cell.value}
              columnName={'DeliveredDate'}
              type={'date'}
            />
          ),
      },
      Notes: {
        Cell: (cell: Cell) => (
          <TableQuickEditComponent
            type={'textarea'}
            productId={
              cell.row.allCells.find((e) => e.column.id === 'ProductCode')
                ?.value
            }
            orderId={row.values.id}
            defaultValue={cell.value}
            columnName={'Notes'}
          />
        ),
      },
      CreatedBy: {},
      LastEditAuthor: {},
    }

    const id = row.allCells.filter((e: any) => e.column.id === 'id')[0].value
    const actions = [
      { title: 'Edit Single Product', component: EditRenderedProductComponent },
      {
        title: 'Remove this Product',
        component: RemoveRenderedProduct,
      },
      {
        title: 'Assign Tag to OrderLine',
        component: AssignOrderLineTagComponent,
      },
    ]

    const columns: Column<object>[] = useMemo(
      () => [
        ...Object.keys(columnHeaders).map((e) => {
          return {
            Header: e,
            accessor: e,
            id: e,
            ...(columnHeaders[e].Filter && { Filter: columnHeaders[e].Filter }),
            ...(columnHeaders[e].Header && { Header: columnHeaders[e].Header }),
            ...(columnHeaders[e].filter && {
              filter: { name: columnHeaders[e].filter },
            }),
            ...(columnHeaders[e].Cell && { Cell: columnHeaders[e].Cell }),
            ...(columnHeaders[e].aggregate && {
              aggregate: columnHeaders[e].aggregate,
            }),
            ...(columnHeaders[e].Aggregated && {
              Aggregated: columnHeaders[e].Aggregated,
            }),
            ...(columnHeaders[e].isVisible && {
              isVisible: columnHeaders[e].isVisible,
            }),
          }
        }),
      ],
      [data]
    )

    const defaultColumn = React.useMemo(
      () => ({
        width: 70,
        Filter: DefaultColumnFilter,
        headerCustomOptions: {
          sort: {
            desc: true,
          },
          settings: {
            wrapText: true,
          },
        },
      }),
      []
    )

    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      rows,
      prepareRow,
      selectedFlatRows,
      toggleAllRowsSelected,
      allColumns,
      getToggleHideAllColumnsProps,
      setColumnOrder,
      setSortBy,
    } = useTable(
      {
        columns,
        data,
        defaultColumn,
        initialState: {
          selectedRowIds: selectedRowIds,
          hiddenColumns: ['ProductID'],
        },
      },
      useFilters,
      useSortBy,
      useRowSelect,
      useResizeColumns,
      useColumnOrder,
      useFlexLayout,
      (hooks) => {
        hooks.visibleColumns.push((columns: ColumnInstance<object>[]) => [
          {
            id: 'lp',
            width: 20,
            Cell: ({ row }: RowProps) => (
              <div>{`${parseInt(row.id) + 1}.`}</div>
            ),
          },
          {
            id: 'selection',
            width: 40,
            Header: ({
              getToggleAllRowsSelectedProps,
            }: HeaderProps<object>) => (
              <TableRenderedCheckboxesComponent
                getToggleAllRowsSelectedProps={getToggleAllRowsSelectedProps}
                data={data}
              />
            ),
            Cell: ({ row }: RowProps) => (
              <TableRenderedCheckbox
                selectedMainRows={selectedMainRows}
                handleClick={() => {
                  store.TableStore.setAllSelectedRows(false)
                  store.TableStore.setSelectedRows([])
                }}
                {...row.getToggleRowSelectedProps()}
              />
            ),
          },
          ...columns,
          {
            id: '_options',
            width: 40,
            disableSortBy: true,
            Cell: ({ row }: RowProps) => (
              <TableMoreActions
                loaded={store.TableStore.loaded}
                actions={actions}
                columns={Object.keys(columnHeaders)}
                row={row}
                rendered={true}
                tableRow={id}
              />
            ),
          },
        ])
      }
    )

    const getOrderedProductsSum = () => {
      return data.reduce(
        (total, obj: any) => total + parseInt(obj.OrderedQuantity),
        0
      )
    }
    const getDeliveredProductsSum = () => {
      return data.reduce(
        (total, obj: any) => total + parseInt(obj.DeliveredQuantity),
        0
      )
    }
    useEffect(() => {
      const id = row.allCells.filter((e: any) => e.column.id === 'id')[0].value
      const selectedArray = [
        ...store.TableStore.selectedExpanded.filter((a) => a.rowId !== id),
        ...selectedFlatRows.map((d) => ({ rowId: id, values: d.original })),
      ]
      store.TableStore.setSelectedExpanded(selectedArray)
    }, [selectedFlatRows])

    useEffect(() => {
      if (selectedMainRows) toggleAllRowsSelected(false)
    }, [selectedMainRows])

    useEffect(() => {
      toggleAllRowsSelected(false)
    }, [store.TableStore.changeExpandedRows])

    return (
      <Container>
        <OptionsContainer>
          <TableOptionsCont>
            <img
              onClick={() =>
                store.SlidingPanelState.setSelectedSlider(
                  `optionsList-rendered-${id}`
                )
              }
              src={TableOptions}
            />
          </TableOptionsCont>
        </OptionsContainer>
        <ScrollSync>
          <Table {...getTableProps()}>
            <Header
              zIndex={
                !/^tableHeader-.*-main$/.test(
                  store.SlidingPanelState.selectedSlider
                )
              }
            >
              {headerGroups.map((headerGroup, i) => (
                <ScrollSyncPane key={`table-rendered-row-${i}`}>
                  <HeaderGroup {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column: any, index) => (
                      <HeaderColumn {...column.getHeaderProps()} key={index}>
                        <TableHeaderOptions
                          columns={column}
                          setSortBy={setSortBy}
                          columnWidth={true}
                          change={change}
                          toggleChange={toggleChange}
                          index={index}
                          identifier={id}
                          setColumnOrder={setColumnOrder}
                          keys={[
                            ...headerGroup.headers.map((e: { id: string }) => {
                              return e.id
                            }),
                          ]}
                        />
                      </HeaderColumn>
                    ))}
                  </HeaderGroup>
                </ScrollSyncPane>
              ))}
            </Header>
            <Body {...getTableBodyProps()}>
              {rows.map((row, i) => {
                prepareRow(row)
                if (data[i])
                  return (
                    <ScrollSyncPane key={`table-rendered-row-${i}`}>
                      <RowStyle
                        select={row.isSelected}
                        {...row.getRowProps()}
                        odd={i % 2 == 0}
                        lastChild={i + 1 === rows.length}
                      >
                        {row.cells.map((cell: any, index) => {
                          return (
                            <CellStyles
                              wrapText={
                                cell.column.headerCustomOptions
                                  ? cell.column.headerCustomOptions.settings
                                      .wrapText
                                  : false
                              }
                              {...cell.getCellProps()}
                              key={index}
                            >
                              {cell.render('Cell')}
                            </CellStyles>
                          )
                        })}
                      </RowStyle>
                    </ScrollSyncPane>
                  )
              })}
            </Body>
          </Table>
        </ScrollSync>
        <FooterCont>
          <FooterValue>
            Sum of ordered products: {getOrderedProductsSum()}
          </FooterValue>
          <FooterValue>
            Sum of delievered products: {getDeliveredProductsSum()}
          </FooterValue>
        </FooterCont>
        <SlidingPanel
          contentId={`optionsList-rendered-${id}`}
          title={'Options List'}
          icon={FilterIcon}
        >
          <Menu
            mode='inline'
            style={{ margin: 0, width: '100%' }}
            expandIcon={<IconChevronDown size='18px' />}
            defaultOpenKeys={['1']}
          >
            <SubMenu title={locales.options.columnVisibility} key={'2'}>
              <ColumnVisibility
                getToggleHideAllColumnsProps={getToggleHideAllColumnsProps}
                allColumns={allColumns}
              />
            </SubMenu>
          </Menu>
        </SlidingPanel>
      </Container>
    )
  }
)
export default TableRenderedRapper
