import styled from 'styled-components'

export const Cont = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`

interface SingleTagInterface {
  bgColor?: string
  color?: string
  fontSize?: string
  borderRadius?: string
  hoverBgColor?: string
  size: string
}

/* eslint-disable */
export const SingleTag = styled.div<SingleTagInterface>`
  padding: 0.3rem;
  margin: 2px;
  cursor: pointer;
  color: #dddddd;
  background-color: ${(props) => (props.color ? props.color : '#505050')};
  border-radius: ${(props) =>
    props.borderRadius ? props.borderRadius : '12px'};
  font-size: ${(props) => (props.size ? props.size : '12px')};
  align-items: center;
  display: flex;
  @media print {
    font-size: 14px;
    font-weight: 500;
    color: #000000;
    background-color: rgb(255, 255, 153);
  }
  :hover {
    background-color: ${(props) =>
      props.hoverBgColor ? props.hoverBgColor : '#303030'};
  }
  img {
    height: 16px;
    margin-right: 4px;
  }
`
export const SingleTagCross = styled.div`
  margin-left: 4px;
  margin-right: 4px;
  margin-top: 1px;
  img {
    width: 0.8rem;
    height: 0.8rem;
  }
`