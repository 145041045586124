/* eslint-disable */
import {
  ChangeEvent,
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from 'react'
import { Row } from 'react-table'

import {
  Container,
  Footer,
  InnerCont,
  PrimaryButton,
  SecondaryButton,
  TagCont,
} from './RemoveMultipleTags.styles'

import { StoreContext } from '../../../../../App'

import axios from '../../../../../axiosconfig'
import { toast } from 'react-toastify'
import SearchTagComponent from '../searchTag/SearchTag.component'
import { toJS } from 'mobx'
import {
  SingleTag,
  SingleTagCross,
} from '../../../../TableGeneric/tableNewTags/TableNewTags.styles'
import Cross from '../../../../../assets/icons/cross-white.svg'
import SearchTagNewComponent from '../searchTagNew/SearchTagNew.component'

const RemoveMultipleTags = () => {
  const store = useContext(StoreContext)
  const { Theme } = store.Theme
  const [changedObject, setChangedObject] = useState(false)

  const [currentTagList, setCurrentTagList] = useState<Array<any>>([])
  const applySearch = (tag: any) => {
    const addedTag = [...currentTagList, tag]
    const unique = [
      ...new Map(addedTag.map((item) => [item.id, item])).values(),
    ]
    setCurrentTagList(unique)
    setChangedObject(true)
  }
  const applyClearTag = (tag: any) => {
    setCurrentTagList([...currentTagList.filter((e) => e.id !== tag.id)])
    setChangedObject(true)
  }
  const handleSave = async () => {
    const url = `${process.env.REACT_APP_BASE_URL}experience/v1/tag/multipleunassign`
    const tagMap = [...currentTagList.map((e) => e.id)]
    const objectsList = store.TableStore.selectedRowId
    const data = {
      tags: toJS(tagMap),
      objects: objectsList,
    }
    const config = {
      headers: {
        'x-apikey': `${process.env.REACT_APP_API_KEY}`,
        username: store.UserStore.user.length
          ? store.UserStore.user
          : 'No data',
      },
    }
    console.log(url, data, config)
    await axios.post(url, data, config)
    await toast('Success!', { type: 'success' })
    await store.SlidingPanelState.setVisibility(false)
    await store.TableStore.fetchData()
  }

  return (
    <Container {...Theme.editProductsStyles.container}>
      <InnerCont cols={1}>
        <TagCont>
          Current Tags:{' '}
          {currentTagList.map((e: any, index) => {
            if (e)
              return (
                <SingleTag
                  key={`new-tag-${e}`}
                  size={'12px'}
                  style={{ whiteSpace: 'nowrap' }}
                  color={e.hasOwnProperty('colour') ? e.colour : '#505050'}
                >
                  {e.name.EN}
                  <SingleTagCross onClick={() => applyClearTag(e)}>
                    <img src={Cross} />
                  </SingleTagCross>
                </SingleTag>
              )
          })}
        </TagCont>
        <SearchTagNewComponent
          view={'tag'}
          header={'Remove New Tag'}
          applySearch={applySearch}
          clearInputValue={true}
        />
      </InnerCont>
      <Footer {...Theme.editProductsStyles.styledFooter}>
        <SecondaryButton
          onClick={() => store.SlidingPanelState.setVisibility(false)}
          {...Theme.buttons.secondaryButton}
        >
          {'CANCEL'}
        </SecondaryButton>
        <PrimaryButton
          onClick={() => changedObject && handleSave()}
          active={changedObject}
          {...Theme.buttons.primaryButton}
        >
          {'SAVE'}
        </PrimaryButton>
      </Footer>
    </Container>
  )
}

export default RemoveMultipleTags
