import styled from 'styled-components'

interface GlobalFilterInputProps {
  borderRadius?: string
  border?: string
  boxShadow?: string
  padding?: string
  margin?: string
}
export const StyledForm = styled.div`
  display: flex;
  align-items: center;
  p {
    cursor: pointer;
  }
`
export const StyledClearCont = styled.div`
  display: flex;
  flex-direction: column;
  button {
    margin: 3px;
  }
`
export const GlobalFilterInput = styled.input<GlobalFilterInputProps>`
  border-radius: ${(props) =>
    props.borderRadius ? props.borderRadius : '4px'};
  height: 28px;
  padding: ${(props) => (props.padding ? props.padding : '8px 6px')};
  border: ${(props) => (props.border ? props.border : ' 1px solid #e5e5e5')};
  width: 200px;
  margin: 12px;
  box-shadow: ${(props) =>
    props.boxShadow ? props.boxShadow : '0 1px 3px rgba(0, 0, 0, 0.06)'};
`

interface DefaultColumnInputProps {
  borderRadius?: string
  border?: string
  boxShadow?: string
  padding?: string
  margin?: string
}

export const DefaultColumnInput = styled.input<DefaultColumnInputProps>`
  border-radius: ${(props) =>
    props.borderRadius ? props.borderRadius : '4px'};
  height: 32px;
  padding: ${(props) => (props.padding ? props.padding : '8px 6px')};
  border: ${(props) => (props.border ? props.border : ' 1px solid #e5e5e5')};
  width: 95%;
  margin-bottom: 12px;
  box-shadow: ${(props) =>
    props.boxShadow ? props.boxShadow : '0 1px 3px rgba(0, 0, 0, 0.06)'};
`

interface StyledSelectColumnProps {
  borderRadius?: string
  border?: string
  boxShadow?: string
  padding?: string
  margin?: string
  bgColor?: string
}

export const StyledSelectColumn = styled.select<StyledSelectColumnProps>`
  border-radius: ${(props) =>
    props.borderRadius ? props.borderRadius : '4px'};
  background: ${(props) => (props.bgColor ? props.bgColor : '#ffffff')};
  height: 32px;
  padding: ${(props) => (props.padding ? props.padding : '8px 6px')};
  border: ${(props) => (props.border ? props.border : '1px solid #e5e5e5')};
  width: 95%;
  margin-bottom: 12px;
  box-shadow: ${(props) =>
    props.boxShadow ? props.boxShadow : '0 1px 3px rgba(0, 0, 0, 0.06)'};
`

interface StyledSelectOptionProps {
  letterSpacing?: string
  color?: string
}

export const StyledSelectOption = styled.option<StyledSelectOptionProps>`
  letter-spacing: ${(props) =>
    props.letterSpacing ? props.letterSpacing : '0.5px'};
  color: ${(props) => (props.color ? props.color : '#575757')};
`
export const SliderCont = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`
export const SliderInput = styled.input`
  width: 90%;
`

interface SliderButtonProps {
  borderRadius?: string
  border?: string
  boxShadow?: string
  padding?: string
  margin?: string
  bgColor?: string
  fontWeight?: number
}

export const SliderButton = styled.button<SliderButtonProps>`
  border-radius: ${(props) =>
    props.borderRadius ? props.borderRadius : '4px'};
  background-color: ${(props) => (props.bgColor ? props.bgColor : '#ffffff')};
  height: 24px;
  padding: ${(props) => (props.padding ? props.padding : '4px 3px')};
  border: ${(props) => (props.border ? props.border : '1px solid #e5e5e5')};
  width: 45%;
  font-family: 'Inter', sans-serif;
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : 600)};
  box-shadow: ${(props) =>
    props.boxShadow ? props.boxShadow : '0 1px 3px rgba(0, 0, 0, 0.06)'};
`
