import { useContext, useEffect, useRef, useState } from 'react'
import { StoreContext } from '../../App'
import { observer } from 'mobx-react'
import { DataInput } from '../dataInput/DataInput.component'
import useWindowDimensions from '../../methods/hooks/useWindowDimensions'
import {
  InnerCont,
  Footer,
  PrimaryButton,
  SecondaryButton,
} from './EditSupplierProduct.styles'
import { toJS } from 'mobx'
import axios from '../../axiosconfig'
import { Container } from '../editGeneric/EditGeneric.styles'
import { socket } from '../../socket/socket'

const EditSupplierProduct = observer(() => {
  const store = useContext(StoreContext)
  const { Theme } = store.Theme

  const hiddenColumns = ['ProductID', 'DeliveredDate', 'SupplierName']

  const keys = [
    'Name',
    'ProductCode',
    'SupplierName',
    'SupplierDeliveryCode',
    'SupplierOrderId',
    'OrderLink',
    'Notes',
    'ProducerName',
    'OrderedQuantity',
    'SupplierDeliveryDate',
    'DeliveredDate',
    'DeliveredQuantity',
  ]
  const [inputColumns, setInputColumns] = useState(1)
  const { width, height } = useWindowDimensions()
  const divRef = useRef<any>(null)

  const initialState = [
    ...keys.map((e) => {
      return {
        header: e,
        value: '',
        error: '',
        rules: ['required', 'length'],
      }
    }),
  ]

  const [changedObject, setChangedObject] = useState(false)
  const [form, setForm] = useState(initialState)

  const handleSetForm = (
    tempForm: Array<{
      header: string
      value: string
      error: string
      rules: Array<string>
    }>
  ) => {
    setForm([...tempForm])
  }
  const handleSave = async () => {
    const uniqueProducts = [
      ...new Set(store.TableStore.selectedExpanded.map((item) => item.rowId)),
    ]
    const fetchPromises = uniqueProducts.map((productId) => {
      const idProducts = store.TableStore.selectedExpanded.filter(
        (e) => e.rowId === productId
      )
      let productsData = {}
      idProducts.map((e: any) => {
        let formData = e.values
        form.map((f: any) => {
          const selected =
            divRef.current.querySelector(`input[name="${f.header}"]`) &&
            divRef.current.querySelector(`input[name="${f.header}"]`).checked
          if (selected) {
            formData = { ...formData, [f.header]: f.value }
          }
        })
        productsData = {
          ...productsData,
          [e.values.ProductCode]: toJS(formData),
        }
      })
      const url = `${process.env.REACT_APP_BASE_URL}experience/v1/supplier_order/${productId}`
      const data = {
        id: productId,
        products: productsData,
      }
      const formattedData = store.TableStore.formatRequestData(data)
      return axios.post(url, formattedData, {
        headers: {
          'Content-Type': 'application/json',
          'x-apikey': `${process.env.REACT_APP_API_KEY}`,
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Methods': 'GET,HEAD,PUT,PATCH,POST,DELETE',
          'Access-Control-Allow-Credentials': 'true',
        },
      })
    })
    await Promise.all(fetchPromises)
    await store.SlidingPanelState.setVisibility(false)
    await setTimeout(async () => await store.TableStore.fetchData(), 2000)
  }

  useEffect(() => {
    setInputColumns(
      Math.ceil(
        (90 * keys.filter((e) => !hiddenColumns.includes(e)).length) /
          (height * 0.8)
      )
    )
  }, [height])

  useEffect(() => {
    let changed = false
    form.map((e, index) => {
      if (e.value !== initialState[index].value) {
        changed = true
        return
      }
    })
    setChangedObject(changed)
  }, [form])

  return (
    <Container>
      <InnerCont ref={divRef} cols={inputColumns}>
        {keys.map((e, index) => (
          <DataInput
            vals={{ header: e, value: '' }}
            form={form}
            index={index}
            setForm={handleSetForm}
            key={`edit-supplier-${index}`}
          />
        ))}
      </InnerCont>
      <Footer {...Theme.editProductsStyles.styledFooter}>
        <SecondaryButton
          onClick={() => store.SlidingPanelState.setVisibility(false)}
          {...Theme.buttons.secondaryButton}
        >
          {'CANCEL'}
        </SecondaryButton>
        <PrimaryButton
          onClick={() => changedObject && handleSave()}
          active={changedObject}
          {...Theme.buttons.primaryButton}
        >
          {'SAVE'}
        </PrimaryButton>
      </Footer>
    </Container>
  )
})

export default EditSupplierProduct
