import React, { forwardRef, useContext, useEffect, useMemo } from 'react'
import { Container } from './CheckboxSelect.styles'
import { StoreContext } from '../../App'
import { Row } from 'react-table'
import { toJS } from 'mobx'

interface Props {
  indeterminate?: boolean
  allSelected?: boolean
  row: Row
  rowId?: string
  name?: string
  selected?: boolean
}

export const IndeterminateCheckbox = forwardRef<HTMLInputElement, Props>(
  ({ indeterminate, row, rowId, selected, ...rest }, ref: any) => {
    const store = useContext(StoreContext)
    const tableStore = store.TableStore
    const defaultRef = React.useRef()
    const resolvedRef = ref || defaultRef
    const rowVal =
      row?.allCells.filter((e) => e.column.id === 'id')[0].value || null

    useEffect(() => {
      if (rowVal) {
        if (store.TableStore.selectedRowId.includes(rowVal)) {
          row.toggleRowSelected(true)
        } else row.toggleRowSelected(false)
        resolvedRef.current.checked =
          store.TableStore.selectedRowId.includes(rowVal)
      }
    }, [store.TableStore.selectedRowId])

    useEffect(() => {
      if (store.TableStore.selectedExpanded.length > 0) {
        row.toggleRowSelected(false)
        resolvedRef.current.checked = false
        selected = false
      }
    }, [store.TableStore.selectedExpanded])

    const addRowData = () => {
      const isSelected = !selected
      if (isSelected && rowId && !store.TableStore.allSelectedRows) {
        const tempData = [rowId, ...tableStore.selectedRowId]
        const uniqueData = [...new Set(tempData)]
        tableStore.setSelectedRows(uniqueData)
      } else if (!isSelected || store.TableStore.allSelectedRows) {
        if (rowId) {
          const index = tableStore.selectedRowId.indexOf(rowId)
          const newArray = tableStore.selectedRowId
          newArray.splice(index, 1)
          tableStore.setSelectedRows(newArray)
        }
      }
      store.TableStore.setAllSelectedRows(false)
    }

    return (
      <Container>
        <input
          onClick={() => addRowData()}
          readOnly={true}
          type='checkbox'
          ref={resolvedRef}
          {...rest}
        />
      </Container>
    )
  }
)
IndeterminateCheckbox.displayName = 'IndeterminateSelectCheckbox'

interface PageProps {
  indeterminate?: boolean
  allSelected?: boolean
  name?: string
  selected?: boolean
}

export const PageSelectCheckbox = forwardRef<HTMLInputElement, PageProps>(
  ({ indeterminate, allSelected, ...rest }, ref: any) => {
    const store = useContext(StoreContext)
    const defaultRef = React.useRef()
    const resolvedRef = ref || defaultRef

    const updatePageData = () => {
      const MapId: Array<string> = [
        ...store.TableStore.data.map((e: any) => `${e.id}`),
      ]
      const checked = resolvedRef.current.checked
      if (checked) {
        const combinedArray = [...MapId, ...store.TableStore.selectedRowId]
        const uniqueArray = [...new Set(combinedArray)]
        store.TableStore.setSelectedRows(uniqueArray)
      } else {
        const reducedArray = store.TableStore.selectedRowId.filter(
          (a) => !MapId.includes(a)
        )
        store.TableStore.setAllSelectedRows(false)
        store.TableStore.setSelectedRows(reducedArray)
      }
    }
    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate
    }, [resolvedRef, indeterminate])

    return (
      <Container>
        <input
          onClick={() => updatePageData()}
          onChange={() => console.log('change')}
          type='checkbox'
          readOnly={true}
          ref={resolvedRef}
          {...rest}
        />
      </Container>
    )
  }
)
PageSelectCheckbox.displayName = 'AllSelectCheckbox'

interface PageRenderedInterface {
  indeterminate?: boolean
  allSelected?: boolean
  name?: string
  selected?: boolean
  data: object[]
}

export const PageRenderedCheckbox = forwardRef<
  HTMLInputElement,
  PageRenderedInterface
>(({ indeterminate, allSelected, data, ...rest }, ref: any) => {
  const store = useContext(StoreContext)
  const defaultRef = React.useRef()
  const resolvedRef = ref || defaultRef

  //const updatePageData = () => {}
  React.useEffect(() => {
    resolvedRef.current.indeterminate = indeterminate
  }, [resolvedRef, indeterminate])

  return (
    <Container>
      <input
        onClick={() => console.log('')}
        type='checkbox'
        readOnly={true}
        ref={resolvedRef}
        {...rest}
      />
    </Container>
  )
})
PageRenderedCheckbox.displayName = 'AllSelectCheckbox'

interface AllProps {
  indeterminate?: boolean
  allSelected?: boolean
}

export const AllSelectCheckbox = forwardRef<HTMLInputElement, AllProps>(
  ({ indeterminate, allSelected, ...rest }, ref: any) => {
    const store = useContext(StoreContext)
    const defaultRef = React.useRef()
    const resolvedRef = ref || defaultRef

    React.useEffect(() => {
      resolvedRef.current.checked =
        store.TableStore.selectedRowId.length ===
          store.TableStore.pagination[store.RouteStore.currentPage].tableSize &&
        store.TableStore.selectedRowId.length > 0
    }, [store.TableStore.allSelectedRows, indeterminate])

    return (
      <Container>
        <input
          onClick={() => {
            store.TableStore.setSelectAll()
          }}
          onChange={() => console.log('change')}
          type='checkbox'
          ref={resolvedRef}
          readOnly={true}
          checked={store.TableStore.allSelectedRows}
          {...rest}
        />
      </Container>
    )
  }
)
AllSelectCheckbox.displayName = 'AllSelectCheckbox'

interface RenderedProps {
  indeterminate?: boolean
  allSelected?: boolean
  name?: string
  handleClick?: any
  selectedMainRows: number
}

export const TableRenderedCheckbox = forwardRef<
  HTMLInputElement,
  RenderedProps
>(({ indeterminate, handleClick, selectedMainRows, ...rest }, ref: any) => {
  const defaultRef = React.useRef()
  const resolvedRef = ref || defaultRef

  React.useEffect(() => {
    resolvedRef.current.indeterminate = indeterminate
  }, [resolvedRef, indeterminate, selectedMainRows])

  return (
    <Container>
      <input
        type='checkbox'
        onClick={handleClick}
        ref={resolvedRef}
        readOnly={true}
        {...rest}
      />
    </Container>
  )
})
TableRenderedCheckbox.displayName = 'TableRenderedCheckbox'
