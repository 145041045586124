import { PropsWithChildren, FC, useContext } from 'react'

import { Container, LockParagraph, SelectStyled } from './TableStatus.styles'
import { StoreContext } from '../../../App'
import { observer } from 'mobx-react'

interface Props extends PropsWithChildren {
  status: string
}

const TableStatus: FC<Props> = observer((props) => {
  const store = useContext(StoreContext)
  const locales = store.TranslationsState.translations

  return (
    <Container>
      <div>
        {`${locales.status[props.status]} (${props.status})` ||
          `${locales.status.default} (${props.status})`}
      </div>
    </Container>
  )
})
export default TableStatus
