import styled from 'styled-components'

export const ContainerLayout = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 64px 64px;
  > * {
    margin-bottom: 64px;
  }
`
export const PanelLayout = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  width: 100%;
  > div {
    width: 100%;
  }
`
