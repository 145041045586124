import { RootStore } from './Root.store'
import { action, makeAutoObservable } from 'mobx'
import { socket } from '../socket/socket'

export class RouterStore {
  rootStore
  currentPage = ''
  currentDataId = ''
  params: { size?: string; from?: string } = {}
  socketId = ''
  lockedValues: { id: string; user: string; lockId: string }[] = []
  queryHandler = false

  constructor(rootStore: RootStore) {
    makeAutoObservable(this)
    this.rootStore = rootStore
  }

  setParams = (paramsURL: string) => {
    let params = {}
    const paramsArray = paramsURL.split('&')
    paramsArray.map((e) => {
      const singleParam: Array<string> = e.split('=')
      params = { ...params, [singleParam[0]]: singleParam[1] }
    })
    this.params = params
  }

  @action.bound handlePageChange = async (
    pathname: string,
    paramsUrl: string
  ) => {
    try {
      socket.emit('orderUnlock', this.socketId)
      socket.emit('pageUnlock', this.socketId)

      this.currentPage =
        pathname.substring(1).split('/')[0] || pathname.substring(1)
      this.currentDataId = pathname.substring(1).split('/')[1] || ''
      this.setParams(paramsUrl)

      if (this.rootStore.SlidingPanelState) {
        await this.rootStore.SlidingPanelState.setSelectedSlider('')
      }

      if (this.rootStore.FiltersStore && this.currentDataId === '') {
        await this.rootStore.FiltersStore.filterFilters()
      }

      if (this.currentPage !== 'packages' && this.rootStore.TableStore) {
        await this.rootStore.TableStore.setConfig()
        await this.rootStore.TableStore.setMobileOptions(false)
        await this.rootStore.TableStore.setInfinitySwitch(false)
        await this.rootStore.TableStore.setAllSelectedRows(false)
        await this.rootStore.TableStore.setDefaultSort()
        await this.rootStore.TableStore.setSelectedRows([])
        await this.rootStore.TableStore.setGlobalSearch('')
        await this.rootStore.TableStore.getDataCount()

        if (this.currentDataId === '') {
          await this.rootStore.TableStore.getLocalQuery()
        }

        if (this.rootStore.ViewStore) {
          await this.rootStore.ViewStore.clearValues()
        }
      }

      if (this.currentDataId !== '' && this.currentPage === 'customerorders') {
        socket.emit('pageLock', {
          id: this.socketId,
          user: this.rootStore.UserStore.user,
          lockId: this.currentDataId,
        })
      }
    } catch (error) {
      console.error('Error handling page change:', error)
    }
  }

  @action.bound setSocketId = (id: string) => {
    this.socketId = id
  }

  @action.bound setLockedValues = (
    lockedValues: { id: string; user: string; lockId: string }[]
  ) => {
    this.lockedValues = lockedValues
  }

  @action.bound setQueryHandler = (queryHandler: boolean) => {
    this.queryHandler = queryHandler
  }
}
