import {
  ChangeEvent,
  KeyboardEventHandler,
  useContext,
  useEffect,
  useState,
} from 'react'
import { toJS } from 'mobx'
import { observer } from 'mobx-react'
import { StoreContext } from '../../App'
import { DefaultColumnInput, DefaultColumnSelect } from './HeaderFilter.styles'
import SearchSelectComponent from '../searchSelect/SearchSelect.component'

interface Props {
  column: any
  fetchData: () => void
  params?: any
  type?: string
}

const FilterSwitch = observer(({ column, fetchData }: Props) => {
  const tempColumn = column.id.toString()

  const store = useContext(StoreContext)

  if (!column.headerCustomOptions.canFilter) return <></>
  else {
    switch (column.editCustomOptions.editType) {
      case 'text':
        return <HeaderStringFilter column={tempColumn} fetchData={fetchData} />
      case 'number':
        return <HeaderNumberFilter column={tempColumn} fetchData={fetchData} />
      case 'select':
      case 'selectBool':
        return (
          <SelectFilter
            column={tempColumn}
            fetchData={fetchData}
            params={column.editCustomOptions}
          />
        )
      case 'date':
      case 'datetime-local':
        return (
          <HeaderDateRangeFilter
            column={tempColumn}
            fetchData={fetchData}
            type={column.editCustomOptions.editType}
          />
        )
      default:
        return <HeaderStringFilter column={tempColumn} fetchData={fetchData} />
    }
  }
})

const HeaderStringFilter = observer(({ column, fetchData }: Props) => {
  const store = useContext(StoreContext)
  const locales = store.TranslationsState.translations
  const getDefaultValue = () => {
    if (
      toJS(
        store.TableStore.query.rules.filter(
          (e) => e.field === column && e.operator === 'contains'
        )[0]
      )
    )
      return toJS(
        store.TableStore.query.rules.filter(
          (e) => e.field === column && e.operator === 'contains'
        )[0]
      ).value
    else return ''
  }

  const [value, setValue] = useState<string>(getDefaultValue())

  const AddColumnFilter = (e: ChangeEvent<HTMLInputElement>) => {
    let rules: any[] = toJS(store.TableStore.query.rules)
    rules = rules.filter((e) => e.field !== column || e.operator !== 'contains')
    if (e.target.value.length > 0) {
      rules = [
        ...rules,
        {
          field: column,
          operator: 'contains',
          valueSource: 'value',
          value: e.target.value,
        },
      ]
    }
    setValue(e.target.value)
    store.TableStore.setQuery({ ...store.TableStore.query, rules: rules })
  }

  const handleKeyDown = async (event: any) => {
    if (event.key === 'Enter' || event.keyCode === 13) {
      store.TableStore.applyQuery(fetchData)
    }
  }

  useEffect(() => {
    setValue(getDefaultValue())
  }, [store.TableStore.query.rules])

  return (
    <DefaultColumnInput
      value={value}
      onChange={(e) => AddColumnFilter(e)}
      onKeyDown={handleKeyDown}
      enterKeyHint='enter'
      placeholder={locales.buttons.search}
    />
  )
})

const HeaderNumberFilter = observer(({ column, fetchData }: Props) => {
  const store = useContext(StoreContext)
  const locales = store.TranslationsState.translations
  const getDefaultValue = () => {
    if (
      toJS(
        store.TableStore.query.rules.filter(
          (e) => e.field === column && e.operator === '='
        )[0]
      )
    )
      return toJS(
        store.TableStore.query.rules.filter(
          (e) => e.field === column && e.operator === '='
        )[0]
      ).value
    else return ''
  }

  const [value, setValue] = useState<string>(getDefaultValue())

  const AddColumnFilter = (e: ChangeEvent<HTMLInputElement>) => {
    let rules: any[] = toJS(store.TableStore.query.rules)
    rules = rules.filter((e) => e.field !== column || e.operator !== '=')
    if (e.target.value.length > 0) {
      rules = [
        ...rules,
        {
          field: column,
          operator: '=',
          valueSource: 'value',
          value: e.target.value,
        },
      ]
    }
    setValue(e.target.value)
    store.TableStore.setQuery({ ...store.TableStore.query, rules: rules })
  }

  const handleKeyDown = async (event: any) => {
    if (event.key === 'Enter' || event.keyCode === 13) {
      store.TableStore.applyQuery(fetchData)
    }
  }

  useEffect(() => {
    setValue(getDefaultValue())
  }, [store.TableStore.query.rules])

  return (
    <DefaultColumnInput
      value={value}
      type={'number'}
      enterKeyHint='enter'
      onChange={(e) => AddColumnFilter(e)}
      onKeyDown={handleKeyDown}
      placeholder={locales.buttons.search}
    />
  )
})

const SelectFilter = observer(({ column, fetchData, params }: Props) => {
  const store = useContext(StoreContext)
  const locales = store.TranslationsState.translations
  const getDefaultValue = () => {
    if (
      toJS(
        store.TableStore.query.rules.find(
          (e) => e.field === column && e.operator === '='
        )
      )
    )
      return toJS(
        store.TableStore.query.rules.find(
          (e) => e.field === column && e.operator === '='
        )
      ).value
    else return ''
  }

  const [value, setValue] = useState<string>(getDefaultValue())

  const AddColumnFilter = (e: any) => {
    let rules: any[] = toJS(store.TableStore.query.rules)
    rules = rules.filter((e) => e.field !== column || e.operator !== '=')
    if (e.length > 0) {
      rules = [
        ...rules,
        {
          field: column,
          operator: '=',
          valueSource: 'value',
          value: e,
        },
      ]
    }
    setValue(e)
    store.TableStore.setQuery({ ...store.TableStore.query, rules: rules })
    store.TableStore.applyQuery(fetchData)
  }

  useEffect(() => {
    setValue(getDefaultValue())
  }, [store.TableStore.query.rules])

  return (
    <SearchSelectComponent
      Container={DefaultColumnSelect}
      value={value}
      onClick={(e) => {
        AddColumnFilter(e.value)
      }}
      options={[{ value: '', label: ' ' }, ...params.selectValues]}
    />
  )

  /*return (
                                  <DefaultColumnSelect
                                    value={value}
                                    onChange={(e) => {
                                      AddColumnFilter(e)
                                    }}
                                    onKeyDown={handleKeyDown}
                                  >
                                    <div>
                                      <input />
                                      test test test
                                    </div>
                                    {getOptions()}
                                  </DefaultColumnSelect>
                                )*/
})

const HeaderDateRangeFilter = observer(({ column, fetchData, type }: Props) => {
  const store = useContext(StoreContext)
  const locales = store.TranslationsState.translations
  const rules = toJS(store.TableStore.query.rules)

  // Helper function to get the default "from" value
  const getDefaultFromValue = () => {
    const fromRule = rules.find((e) => e.field === column && e.operator === '>')
    return fromRule ? fromRule.value : ''
  }

  // Helper function to get the default "to" value
  const getDefaultToValue = () => {
    const toRule = rules.find((e) => e.field === column && e.operator === '<')
    return toRule ? toRule.value : ''
  }

  const [fromValue, setFromValue] = useState<string>(getDefaultFromValue())
  const [toValue, setToValue] = useState<string>(getDefaultToValue())

  const AddColumnFilter = (
    e: ChangeEvent<HTMLInputElement>,
    operator: '>' | '<'
  ) => {
    let rules: any[] = toJS(store.TableStore.query.rules)
    rules = rules.filter((e) => e.field !== column || e.operator !== operator)
    if (e.target.value.length > 0) {
      rules = [
        ...rules,
        {
          field: column,
          operator: operator,
          valueSource: 'value',
          value: e.target.value,
        },
      ]
    }
    operator === '<' ? setToValue(e.target.value) : setFromValue(e.target.value)
    store.TableStore.setQuery({ ...store.TableStore.query, rules: rules })
  }

  const handleKeyDown = async (event: any) => {
    if (event.key === 'Enter' || event.keyCode === 13) {
      store.TableStore.applyQuery(fetchData)
    }
  }

  useEffect(() => {
    setFromValue(getDefaultFromValue())
    setToValue(getDefaultToValue())
  }, [store.TableStore.query.rules])

  return (
    <div style={{ display: 'flex' }}>
      <DefaultColumnInput
        value={fromValue}
        type={type}
        onChange={(e) => AddColumnFilter(e, '>')}
        onKeyDown={handleKeyDown}
        placeholder={locales.buttons.search}
      />
      <DefaultColumnInput
        value={toValue}
        type={type}
        onChange={(e) => AddColumnFilter(e, '<')}
        onKeyDown={handleKeyDown}
        placeholder={locales.buttons.search}
      />
    </div>
  )
})

export default FilterSwitch
