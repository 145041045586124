/* eslint-disable */
import { FC } from 'react'
import { Cont, SingleTag } from './TableTags.styles'

interface Props {
  tags?: { [key: string]: string }
}

const TableTags: FC<Props> = (props: Props) => {
  return (
    <Cont>
      {props.tags ? (
        Object.values(props.tags).map((e) => {
          return <SingleTag key={e}>{e}</SingleTag>
        })
      ) : (
        <></>
      )}
    </Cont>
  )
}
export default TableTags
