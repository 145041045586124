import React, { ErrorInfo, ReactNode } from 'react'
import axios from '../../axiosconfig'
import { observer } from 'mobx-react'

interface StandardErrorBoundaryProps {
  children: ReactNode
  user: string
}

interface StandardErrorBoundaryState {
  hasError: boolean
  error?: Error
}

@observer
class StandardErrorBoundary extends React.Component<
  StandardErrorBoundaryProps,
  StandardErrorBoundaryState
> {
  constructor(props: StandardErrorBoundaryProps) {
    super(props)
    this.state = {
      hasError: false,
      error: undefined,
    }
  }

  static getDerivedStateFromError(error: Error): StandardErrorBoundaryState {
    return {
      hasError: true,
      error: error,
    }
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    console.log('Error caught!')
    console.error(error.toString())
    console.error(errorInfo)

    const url = `${process.env.REACT_APP_BASE_URL}experience/v1/audit_new`
    const data = {
      action: 'Error',
      //@ts-ignore
      entity: errorInfo.componentStack.split('at')[1],
      date: new Date().toISOString(),
      // Assuming you have a user object in UserStore
      username: this.props.user,
    }
    const config = {
      headers: {
        'x-apikey': `${process.env.REACT_APP_API_KEY}`,
      },
    }
    // Uncomment to send the error
    axios.post(url, data, config)
  }

  render() {
    if (this.state.hasError) {
      return (
        <div>
          An Error has occured! Refresh your page or contact the administrator
        </div>
      )
    }
    return this.props.children
  }
}

export default StandardErrorBoundary
